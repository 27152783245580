import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";


const Chapter01Screen03 = () => {

	const navigate = useNavigate();
	const [checkedQues2_1, setCheckedQues2_1] = useState('');
	const [checkedQues2_2, setCheckedQues2_2] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "given-" + checkedQues2_1 + ",chosen-" + checkedQues2_2,
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-02/chapter-01-screen-04')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const checkedVal = (param, ans) => {
		if (param === '2') {
			const __ans = ans.split(',');
			const given = __ans[0].split('-');
			const chosen = __ans[1].split('-');

			setCheckedQues2_1(given[1])
			setCheckedQues2_2(chosen[1])
		}




	}


	const BtnDisabled = () => {
		if (checkedQues2_1 !== '' && checkedQues2_2 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="2" star="1" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-3">
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h2 className="h2">Engage</h2>
								<p className="mb-2 mt-3">Think of the identities which were given to you when you were born. For example: your gender, religion, caste, class, name, relationship etc. Think of the identities that you chose for yourself as you were growing up- Education, career, relationships, sexual orientation and others. List all of them down below</p>
								<p className="mb-2 mt-3">
									{" "}
									<b>Given</b>
								</p>
								<div className="mb-3 positon-submit">
									<textarea
										className="form-control"
										rows={1}
										placeholder="Write Something here....."
										onChange={(e) => setCheckedQues2_1(e.target.value)}
										data-quesId="2"
										defaultValue={checkedQues2_1}
									/>
								</div>
								<p className="mb-2 mt-3">
									{" "}
									<b>Chosen</b>
								</p>
								<div className="mb-3 positon-submit">
									<textarea
										className="form-control"
										rows={1}
										placeholder="Write Something here....."
										onChange={(e) => setCheckedQues2_2(e.target.value)}
										data-quesId="2"
										defaultValue={checkedQues2_2}
									/>
								</div>
							</div>
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										className="mt-4"
										width="100%"
										src="/assets/img/md2/names-in-circle.svg"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-02/chapter-01-screen-02" />

									{/* <NextBtn link="/module-02/chapter-01-screen-04" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen03;
