import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
const Chapter04Screen05 = () => {

	const navigate = useNavigate();

	const [checkedQues5, setCheckedQues5] = useState([]);
	const [checkedQues5Option1, setCheckedQues5Option1] = useState('');
	const [checkedQues5Option2, setCheckedQues5Option2] = useState('');
	const [checkedQues5Option3, setCheckedQues5Option3] = useState('');
	const [checkedQues5Option4, setCheckedQues5Option4] = useState('');
	const [checkedQues5Val, setCheckedQues5Val] = useState('');

	const [checkedQues6Option1, setCheckedQues6Option1] = useState('');
	const [checkedQues6Option2, setCheckedQues6Option2] = useState('');
	const [checkedQues6Option3, setCheckedQues6Option3] = useState('');
	const [checkedQues6Option4, setCheckedQues6Option4] = useState('');
	const [checkedQues6Option5, setCheckedQues6Option5] = useState('');
	const [checkedQues6Val, setCheckedQues6Val] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);
		checkedVal(quesId, e.target.value)
	}

	const saveGenderBased = (e) => {
		const res = e.target.value;
		const checked = e.target.checked;
		checkBoxChecked(res, checked);
		setCheckedQues5(checkedQues5 => [...checkedQues5, res]);
	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": checkedQues5.join(','),
					"ques_id": "5"
				},
				"ques6": {
					"ans": checkedQues6Val,
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-04/chapter-04-screen-06')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues5.length > 0 && checkedQues6Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {

		if (param === '5') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked);
			})
			setCheckedQues5(ansArr)
		}


		// if (param === '5') {
		//   if (ans === 'option1') {
		//     setCheckedQues5Option1('checked')
		//     setCheckedQues5Option2('');
		//     setCheckedQues5Option3('')
		//     setCheckedQues5Option4('')
		//   } else if (ans === 'option2') {
		//     setCheckedQues5Option1('')
		//     setCheckedQues5Option2('checked');
		//     setCheckedQues5Option3('')
		//     setCheckedQues5Option4('')
		//     }else if (ans === 'option3') {
		//       setCheckedQues5Option1('')
		//       setCheckedQues5Option2('');
		//       setCheckedQues5Option3('checked')
		//       setCheckedQues5Option4('')
		//       }else if (ans === 'option4') {
		//         setCheckedQues5Option1('')
		//         setCheckedQues5Option2('');
		//         setCheckedQues5Option3('')
		//         setCheckedQues5Option4('checked')
		//         }
		//     setCheckedQues5Val(ans)
		// }

		if (param === '6') {
			if (ans === 'option1') {
				setCheckedQues6Option1('checked')
				setCheckedQues6Option2('');
				setCheckedQues6Option3('')
				setCheckedQues6Option4('')
				setCheckedQues6Option5('')
			} else if (ans === 'option2') {
				setCheckedQues6Option1('')
				setCheckedQues6Option2('checked');
				setCheckedQues6Option3('')
				setCheckedQues6Option4('')
				setCheckedQues6Option5('')
			} else if (ans === 'option3') {
				setCheckedQues6Option1('')
				setCheckedQues6Option2('');
				setCheckedQues6Option3('checked')
				setCheckedQues6Option4('')
				setCheckedQues6Option5('')
			} else if (ans === 'option4') {
				setCheckedQues6Option1('')
				setCheckedQues6Option2('');
				setCheckedQues6Option3('')
				setCheckedQues6Option4('checked')
				setCheckedQues6Option5('')
			} else if (ans === 'option5') {
				setCheckedQues6Option1('')
				setCheckedQues6Option2('');
				setCheckedQues6Option3('')
				setCheckedQues6Option4('')
				setCheckedQues6Option5('checked')
			}
			setCheckedQues6Val(ans)
		}
	}

	const checkBoxChecked = (val, checked) => {
		if (val === 'option1') {
			if (checked === true) {
				setCheckedQues5Option1('checked');
			} else {
				setCheckedQues5Option1('');
			}
		}
		if (val === 'option2') {
			if (checked === true) {
				setCheckedQues5Option2('checked');
			} else {
				setCheckedQues5Option2('');
			}
		}
		if (val === 'option3') {
			if (checked === true) {
				setCheckedQues5Option3('checked');
			} else {
				setCheckedQues5Option3('');
			}
		}
		if (val === 'option4') {
			if (checked === true) {
				setCheckedQues5Option4('checked');
			} else {
				setCheckedQues5Option4('');
			}
		}


	}


	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="65" star="65" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-2 mt-3">
									<b>5. Society defines an ideal man as someone who is</b>
								</p>
								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle"
											defaultValue="option1"
											onClick={saveGenderBased}
											data-quesId="5"
											checked={checkedQues5Option1}
										/>
										<label className="form-check-label" htmlFor="mycirle">
											{" "}
											Physically strong and tall
										</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle2"
											defaultValue="option2"
											onClick={saveGenderBased}
											data-quesId="5"
											checked={checkedQues5Option2}
										/>
										<label className="form-check-label" htmlFor="mycirle2">
											Who is emotional
										</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle3"
											defaultValue="option3"
											onClick={saveGenderBased}
											data-quesId="5"
											checked={checkedQues5Option3}
										/>
										<label className="form-check-label" htmlFor="mycirle3">
											{" "}
											Who can provide and protect his family
										</label>
									</div>
								</div>
								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle4"
											defaultValue="option4"
											onClick={saveGenderBased}
											data-quesId="5"
											checked={checkedQues5Option4}
										/>
										<label className="form-check-label" htmlFor="mycirle4">
											{" "}
											Who can perform well sexually
										</label>
									</div>
								</div>
								<p className="mb-2 mt-3">
									<b>6. Violent masculinity leads to</b>
								</p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="namecircle2"
											id="mycirle6"
											defaultValue="option1"
											onClick={saveRadio}
											data-quesId="6"
											checked={checkedQues6Option1}
										/>
										<label className="form-check-label" htmlFor="mycirle6">
											{" "}
											Murders{" "}
										</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="namecircle2"
											id="mycirle7"
											defaultValue="option2"
											onClick={saveRadio}
											data-quesId="6"
											checked={checkedQues6Option2}
										/>
										<label className="form-check-label" htmlFor="mycirle7">
											Suicides{" "}
										</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="namecircle2"
											id="mycirle8"
											defaultValue="option3"
											onClick={saveRadio}
											data-quesId="6"
											checked={checkedQues6Option3}
										/>
										<label className="form-check-label" htmlFor="mycirle8">
											Physical and sexual assault
										</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="namecircle2"
											id="mycirle9"
											defaultValue="option4"
											onClick={saveRadio}
											data-quesId="6"
											checked={checkedQues6Option4}
										/>
										<label className="form-check-label" htmlFor="mycirle9">
											Wars
										</label>
									</div>
								</div>
								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="namecircle2"
											id="mycirle10"
											defaultValue="option5"
											onClick={saveRadio}
											data-quesId="6"
											checked={checkedQues6Option5}
										/>
										<label className="form-check-label" htmlFor="mycirle10">
											Terrorrism{" "}
										</label>
									</div>
								</div>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="75%"
										draggable="false"
										src="/assets/img/md4/md4-shap-4-6.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-04-screen-04" />

									{/* <NextBtn link="/module-04/chapter-04-screen-06" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen05;
