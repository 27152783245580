import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen09 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="45" star="45" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-2 mt-3">
									In the first situation option b is the most effective to
									communicate since it involves being able to express yourself
									clearly based on facts, sharing your feelings, your need or
									value and eventually requesting for an action.{" "}
								</p>
								<p className="mb-2 mt-3">
									In option a communication reflects judgement and criticism
									towards the other person and demanding instead of requesting.
									It also has no mention of feeling, need or value. This way of
									communication will most likely not be received by the other
									person with an open heart and they will most likely be
									defensive, deflect or be offended.</p>
								<p> In the second situation,
									option a is the most effective way to communicate since as a
									receiver of what your parents told you, you have been
									empathetic towards them by keeping an open mind and
									understanding their feelings and needs. You have reflected
									that back clearly in your communication and tried to request
									them by ensuring their needs are fulfilled through your
									promise.{" "}
								</p>
								<p className="mb-2 mt-3"> In option b, information/communication from parents have not been received empathically which has resulted in not understanding their feelings and needs and instead blaming them  and judging them for not fulfilling your feelings and needs.</p>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img width="70%" src="/assets/img/md2/md2-shap-3-9.png" alt="" />
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-03-screen-08" />

								<NextBtn link="/module-02/chapter-03-screen-10" text="Go Next" />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen09;
