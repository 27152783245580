import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Module04IntroScreen = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="0" star="0" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h4>
									{" "}
									<b>Objectives</b>
								</h4>
								<div className="para-color-box mt-4">
									<p>
										<b>
											In this module, you will be introduced to the concept of
											Masculinity. This module has been divided into different
											chapters that
										</b>
									</p>
									<ul className="list-style-set list-circle mt-1">
										<li>
											Unpack the meaning of masculinity and the different forms
											of masculinities that exist in our society
										</li>
										<li>
											Describe the role of media in defining masculinities
										</li>
										<li>Describe the Impact of toxic masculinity on society</li>
										<li>
											Establish the definition and need of Positive masculinity
										</li>
									</ul>
								</div>
							</div>
							<div
								className="col-lg-3 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="90%"
										draggable="false"
										src="/assets/img/md4/md4-shap-1-inro-screen.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.3s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-05/score" />

									<NextBtn link="/module-04/chapter-01-screen-01" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Module04IntroScreen;
