import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { NavLink } from "react-router-dom";

const Chapter05Screen06 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="75" star="75" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between mt-2">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="">
									{" "}
									<b>
										Let’s watch 2 more videos to understand what can be done to
										ensure inclusive and gender equitable masculinities amongst
										boys and men
									</b>{" "}
								</h3>
							</div>
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<a href="https://www.youtube.com/watch?v=-hcti8ViynU" target="_blank">
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>
												{" "}
												Unacademy | <br />
												<b>Teach Them Young</b>{" "}
											</p>
										</div>
									</div>
								</a>
								<a href="https://www.youtube.com/watch?v=Zq3Wm_923pw" target="_blank">
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>
												This International Men's Day teach your boys kya
												#NahiChaltaHai with <br />
												Mankind | Adil Hussain | Hindi{" "}
											</p>
										</div>
									</div>
								</a>

								<p>The videos reflect light on positive behaviours which should be taught to boys and harmful behaviours and actions which should be called out.{" "}</p>
								<p>This can be done in various ways to ensure systemic and structural change at an individual and collective level.{" "}</p>

								<p className="mb-2 mt-3">
									Awareness and behavior change work with boys and men, gender
									responsive parenting work with parents and other stakeholders
									who impact the lives of boys and men. Holding media
									accountable for projecting and influencing the culture of
									violent masculinity and instead inspiring gender equitable and
									inclusive masculinities in media. Using positive examples from
									the media for awareness and behaviour change. Working with the
									stakeholders in the state- police, law enforcement,
									politicians, civil servants etc. and service providers in the
									various sectors especially educational institutions. Across
									all the sectors and spheres engaging with boys and men
									actively to inspire gender equitable and inclusive
									masculinities. This will enable them as activists and allies
									for a gender-just and equitable world.
								</p>
							</div>
							{/* <div
                className="col-lg-4 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="31%"
                    draggable="false"
                    src="/assets/img/md4/md4-shap-5-6.png"
                    alt=""
                  />
                </center>
              </div> */}
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-05-screen-05" />

									<NextBtn link="/module-04/chapter-05-screen-08" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen06;
