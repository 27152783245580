import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter01Screen04 = () => {
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="3" star="3" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-center pt-20 mt-3">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.1s"
							>
								<h2 className="h2">Learn</h2>
								<p>
									Many times when we hear or read names we try to assume the
									gender of the person and based on that we imagine what that
									person may look like. In this activity too one could choose
									objects based on what they think a person wears, eats, does
									etc. based on their gender and that would be easy, but if we
									didn’t know their gender it would be difficult to imagine what
									that person could need. Why does it happen? This happens due
									to the process of socialisation where each gender is expected
									to appear, behave and play certain roles and responsibilities
									based on the set gender norms in the society. The names are
									also given based on the sex or gender of the person. We will
									learn more about this going forward.
								</p>
							</div>
							<div
								className="col-lg-4 offset-lg-1 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="75%"
										src="/assets/img/chapter1/bag-img.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-01-screen-02" />

								<NextBtn link="/module-01/chapter-01-screen-05" text="Go Next" classtype="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen04;
