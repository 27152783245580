import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";


const Chapter04Screen15 = () => {

	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="36" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-top justify-content-between pt-20 mt-2">


							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h4 className="mb-3 h3 mt-3"> <b> References and resources </b></h4>


								<a href="https://www.ohchr.org/en/what-are-human-rights" target="_blank" rel="noreferrer">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>What are human rights?</p>
										</div>
									</div>
								</a>
								<a href="https://www.unfpa.org/resources/human-rights-principles" target="_blank" rel="noreferrer">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Human Rights principles: </p>
											{/* <p>Human Rights Principles | United Nations Population Fund</p> */}
										</div>
									</div>
								</a>

								<a href="http://www.un.org/en/universal-declaration-human-rights/index.html" target="_blank" rel="noreferrer">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p> The complete Universal Declaration of Human Right’s document </p>
											{/* <p> <b>The complete Universal Declaration of Human Right’s document: </b>  Universal declaration human rights </p> */}
										</div>
									</div>
								</a>
								<a href="https://www.youtube.com/watch?v=K50LAk28RXw&list=PLbyiiPziC3QofMeGNC88n-l93sxrT1_pX&index=8" target="_blank" rel="noreferrer">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>	A young bride fights back  </p>
											{/* <p>A Young Bride Fights Back | Breakthrough India </p> */}
										</div>
									</div>
								</a>

								<a href="https://www.youtube.com/watch?v=4Rx8S1ouy2U" target="_blank" rel="noreferrer">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Make it Safer </p>
										</div>
									</div>
								</a>
								<a href="https://d1ns4ht6ytuzzo.cloudfront.net/oxfamdata/oxfamdatapublic/2022-01/India%20Supplement%202022%20lo-res%20single.pdf?qpboOXJULM6jrm1QUPjW_e2zSPYHDVhx" target="_blank" rel="noreferrer">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Inequality kills-  </p>
											{/* <p>Inequality kills- <b>Inequality report India edition</b> </p> */}
										</div>
									</div>
								</a>

							</div>

							<div
								className="col-lg-4 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										className="mt-0"
										width="70%"
										src="/assets/img/md3/md3-shap-4-15.png"
										alt=""
									/>
								</center>
							</div>

						</div>


					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-04-screen-14" />
									<NextBtn link="/module-03/score" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen15;
