import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen06 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Learn  </h3>
								<p className="mb-2  mt-3"> Resistance to diverse sexual identities is based on cultural ideals that define anything apart from the norm, as unnatural and abnormal. Sexuality norms consider only heterosexual relationships between man and woman as acceptable and desirable for marriage and procreation. Recall once again, the sexuality wheel we discussed. The socio-cultural aspect of sexuality i.e. our surroundings and influences on our life play a huge role in our sexuality development. </p>


								<a href="https://www.youtube.com/watch?v=q30UwR1aATU" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>  This video shines a light on this perception of same sex relationships and orientations.   </p>
										</div>
									</div>
								</a>




							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="40%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-3-20.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-05n" />
								<NextBtn link="/module-05/chapter-02-screen-07" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen06;
