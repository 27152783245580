import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";

const Chapter04Screen24 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Learn </h3>
								<p className="mb-2 mt-3">While sexual activities have wide ranging consequences for women’s bodies and lives (due to an ability to reproduce), they have very little agency or decision-making within them.  </p>

							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="65%"
										draggable="false"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>


						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-24" />
								<NextBtn link="/module-05/chapter-04-screen-25" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>




		</>
	);
};

export default Chapter04Screen24;
