import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
const Chapter04Screen10 = () => {
	const navigate = useNavigate();
	const [checkedQues13, setCheckedQues13] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": checkedQues13,
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-04-screen-11')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": "13"
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					setCheckedQues13(results.ans)
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	const BtnDisabled = () => {
		if (checkedQues13 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="78" star="56" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-4">
									What is one step you will take to ensure that you are not
									buying into the regressive Patriarchal mindset propagated by
									pop culture through mass media? What is one step you will take
									to make others around you aware about the same?
								</p>
							</div>
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="mb-4 positon-submit">
									<textarea
										className="form-control"
										rows={7}
										placeholder="Write Something here....."
										onChange={(e) => setCheckedQues13(e.target.value)}
										data-quesId="13"
										defaultValue={checkedQues13}
									/>
									{/* <button
                    type="button"
                    className="btn btn-primary custom-btn m-auto submit-btn-set"
                  >
                    Submit
                  </button> */}
								</div>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img width="65%" src="/assets/img/md1/md1-shap-4-6.png" alt="" />
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-04-screen-09" />
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen10;
