import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { Modal, Button } from "react-bootstrap";

const Chapter04Screen19 = () => {

	const navigate = useNavigate();

	const [checkedQues24Option1, setCheckedQues24Option1] = useState('');
	const [checkedQues24Option2, setCheckedQues24Option2] = useState('');

	const [checkedQues24Val, setCheckedQues24Val] = useState('');

	const [checkedQues25Option1, setCheckedQues25Option1] = useState('');
	const [checkedQues25Option2, setCheckedQues25Option2] = useState('');

	const [checkedQues25Val, setCheckedQues25Val] = useState('');

	const [checkedQues26Option1, setCheckedQues26Option1] = useState('');
	const [checkedQues26Option2, setCheckedQues26Option2] = useState('');

	const [checkedQues26Val, setCheckedQues26Val] = useState('');

	const [checkedQues27Option1, setCheckedQues27Option1] = useState('');
	const [checkedQues27Option2, setCheckedQues27Option2] = useState('');

	const [checkedQues27Val, setCheckedQues27Val] = useState('');

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showModalText, setShowModalText] = useState();







	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");


		if (quesId === '24') {
			setShowModalText('Often in many STIs people will not have symptoms. Getting regularly checked and using contraception are the only way to be safe')
		}


		if (quesId === '25') {
			setShowModalText('Even if you have been treated for an STI once, you can get it from another partner.')
		}

		if (quesId === '26') {
			setShow(false);
			checkedVal(quesId, e.target.value);
			return false;

			setShowModalText('Access and information on contraception promotes safer sexual health practices, strengthens the agency for women to decide about her body and life. It does not make women “promiscuous”, this is a stereotype which promotes discrimination against women, and is used curb her affirmative sexual experiences')
		}

		if (quesId === '27') {
			setShowModalText('STIs require medical treatment. Having sex with any other person only puts them at risk.')
		}


		setShow(true);

		// alert(e.target.value);
		checkedVal(quesId, e.target.value)
	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques24": {
					"ans": checkedQues24Val,
					"ques_id": "24"
				},
				"ques25": {
					"ans": checkedQues25Val,
					"ques_id": "25"
				},
				"ques26": {
					"ans": checkedQues26Val,
					"ques_id": "26"
				},
				"ques27": {
					"ans": checkedQues27Val,
					"ques_id": "27"
				},


			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-05/chapter-04-screen-20')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues24Val !== '' && checkedQues25Val !== '' && checkedQues26Val !== '' && checkedQues27Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '24') {
			if (ans === 'option1') {
				setCheckedQues24Option1('checked')
				setCheckedQues24Option2('');

			} else if (ans === 'option2') {
				setCheckedQues24Option1('')
				setCheckedQues24Option2('checked');

			}
			setCheckedQues24Val(ans)
		}

		if (param === '25') {
			if (ans === 'option1') {
				setCheckedQues25Option1('checked')
				setCheckedQues25Option2('');

			} else if (ans === 'option2') {
				setCheckedQues25Option1('')
				setCheckedQues25Option2('checked');

			}
			setCheckedQues25Val(ans)
		}

		if (param === '26') {
			if (ans === 'option1') {
				setCheckedQues26Option1('checked')
				setCheckedQues26Option2('');

			} else if (ans === 'option2') {
				setCheckedQues26Option1('')
				setCheckedQues26Option2('checked');

			}
			setCheckedQues26Val(ans)
		}

		if (param === '27') {
			if (ans === 'option1') {
				setCheckedQues27Option1('checked')
				setCheckedQues27Option2('');

			} else if (ans === 'option2') {
				setCheckedQues27Option1('')
				setCheckedQues27Option2('checked');

			}
			setCheckedQues27Val(ans)
		}




	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-0">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								{/* yahan popup rahega */}

								<p className="mb-2 mt-3 pn"> <b>Let’s see what we know about STIs – whether it’s a myth or a fact!   </b> </p>



								<p className="mb-2 mt-3 pn"><span><b>1.</b></span> <b>People can have STIs without any symptoms.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle"
											id="mycirle1"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues24Option1}
											data-quesId="24"

										/>
										<label className="form-check-label" htmlFor="mycirle1">Myth</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle" id="mycirle2"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues24Option2}
											data-quesId="24"

										/>
										<label className="form-check-label" htmlFor="mycirle2">Fact</label>
									</div>
								</div>

								<p className="mb-2 mt-3 pn"><span><b>2.</b></span> <b>You can get an STI only once in a lifetime.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle2" id="mycirle3"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues25Option1}
											data-quesId="25"

										/>
										<label className="form-check-label" htmlFor="mycirle3">Myth</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle2" id="mycirle4"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues25Option2}
											data-quesId="25"
										/>
										<label className="form-check-label" htmlFor="mycirle4">Fact</label>
									</div>
								</div>

								<p className="mb-2 mt-3 pn"><span><b>3.</b></span> <b> Condoms and other barrier methods are the most effective in preventing STIs.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3"
											id="mycirle5"
											defaultValue="option1"
											onClick={saveRadio}
											checked={checkedQues26Option1}
											data-quesId="26"
										/>
										<label className="form-check-label" htmlFor="mycirle5">Myth</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle6"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues26Option2}
											data-quesId="26"
										/>
										<label className="form-check-label" htmlFor="mycirle6">Fact</label>
									</div>
								</div>


								<p className="mb-2 mt-3 pn"><span><b>4.</b></span> <b> STIs are cured if an infected person has sex with another person.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle42"
											id="mycirle7"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues27Option1}
											data-quesId="27"

										/>
										<label className="form-check-label" htmlFor="mycirle7">Myth</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle42" id="mycirle8"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues27Option2}
											data-quesId="27"

										/>
										<label className="form-check-label" htmlFor="mycirle8">Fact</label>
									</div>
								</div>
							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="80%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-4-19.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-18" />
								{/* <NextBtn link="/module-05/chapter-04-screen-20" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Modal */}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					{/* <Modal.Title>Women are physically weak :</Modal.Title> */}
				</Modal.Header>

				<Modal.Body>
					<p>{showModalText}</p>
				</Modal.Body>
			</Modal>



		</>
	);
};

export default Chapter04Screen19;
