import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
const Chapter02Screen05 = () => {
	const navigate = useNavigate();
	const [checkedQues3, setCheckedQues3] = useState([]);
	const [checkedQues3Option1, setCheckedQues3Option1] = useState('');
	const [checkedQues3Option2, setCheckedQues3Option2] = useState('');
	const [checkedQues3Option3, setCheckedQues3Option3] = useState('');
	const [checkedQues3Option4, setCheckedQues3Option4] = useState('');

	const [checkedQues4, setCheckedQues4] = useState([]);
	const [checkedQues4Option1, setCheckedQues4Option1] = useState('');
	const [checkedQues4Option2, setCheckedQues4Option2] = useState('');
	const [checkedQues4Option3, setCheckedQues4Option3] = useState('');
	const [checkedQues4Option4, setCheckedQues4Option4] = useState('');
	const [checkedQues4Option5, setCheckedQues4Option5] = useState('');
	const [checkedQues4Option6, setCheckedQues4Option6] = useState('');
	const [checkedQues4Option7, setCheckedQues4Option7] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);
		checkedVal(quesId, e.target.value)
	}
	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;
		checkBoxChecked(res, checked, quesId);
		if (quesId === '3') {
			setCheckedQues3(checkedQues3 => [...checkedQues3, res]);
		}

		if (quesId === '4') {
			setCheckedQues4(checkedQues4 => [...checkedQues4, res]);
		}

	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3.join(','),
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4.join(','),
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-02-screen-06')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues3.length > 0 && checkedQues4.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '3') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues3(ansArr)
		}

		if (param === '4') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues4(ansArr)
		}


	}
	const checkBoxChecked = (val, checked, quesId) => {
		if (quesId === '3') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues3Option1('checked');
				} else {
					setCheckedQues3Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues3Option2('checked');
				} else {
					setCheckedQues3Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues3Option3('checked');
				} else {
					setCheckedQues3Option3('');
				}
			}

			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues3Option4('checked');
				} else {
					setCheckedQues3Option4('');
				}
			}

		}

		if (quesId === '4') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues4Option1('checked');
				} else {
					setCheckedQues4Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues4Option2('checked');
				} else {
					setCheckedQues4Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues4Option3('checked');
				} else {
					setCheckedQues4Option3('');
				}
			}

			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues4Option4('checked');
				} else {
					setCheckedQues4Option4('');
				}
			}

			if (val === 'option5') {
				if (checked === true) {
					setCheckedQues4Option5('checked');
				} else {
					setCheckedQues4Option5('');
				}
			}

			if (val === 'option6') {
				if (checked === true) {
					setCheckedQues4Option6('checked');
				} else {
					setCheckedQues4Option6('');
				}
			}

			if (val === 'option7') {
				if (checked === true) {
					setCheckedQues4Option7('checked');
				} else {
					setCheckedQues4Option7('');
				}
			}




		}

	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="25" star="2" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>



								<h4 className="mb-2 pn"> <span> <b>3.</b></span> <b>What rights were violated?  </b></h4>

								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle"
											defaultValue="option1"

											onClick={saveGenderBased}
											checked={checkedQues3Option1}
											data-quesId="3"
										/>
										<label className="form-check-label" htmlFor="mycirle">Right to dignity  </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle2"

											defaultValue="option2"

											onClick={saveGenderBased}
											checked={checkedQues3Option2}
											data-quesId="3"
										/>
										<label className="form-check-label" htmlFor="mycirle2">Right to be respected  </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle3"
											defaultValue="option3"

											onClick={saveGenderBased}
											checked={checkedQues3Option3}
											data-quesId="3"

										/>
										<label className="form-check-label" htmlFor="mycirle3"> Right to assert yourself </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle4"
											defaultValue="option4"


											onClick={saveGenderBased}
											checked={checkedQues3Option4}
											data-quesId="3"
										/>
										<label className="form-check-label" htmlFor="mycirle4"> Right to expression </label>
									</div>
								</div>
								<h4 className="mb-2 mt-3 pn"> <span> <b>4.</b></span> <b>Who was responsible for taking action? Select as many that apply.  </b></h4>

								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle5"
											defaultValue="option1"


											onClick={saveGenderBased}
											checked={checkedQues4Option1}
											data-quesId="4"
										/>
										<label className="form-check-label" htmlFor="mycirle5">Harbhajan  </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle6"
											defaultValue="option2"

											onClick={saveGenderBased}
											checked={checkedQues4Option2}
											data-quesId="4"
										/>
										<label className="form-check-label" htmlFor="mycirle6">S. Sreesanth  </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle7"
											defaultValue="option3"

											onClick={saveGenderBased}
											checked={checkedQues4Option3}
											data-quesId="4"
										/>
										<label className="form-check-label" htmlFor="mycirle7"> IPL Chairman Lalit Modi </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle8"
											defaultValue="option4"

											onClick={saveGenderBased}
											checked={checkedQues4Option4}
											data-quesId="4"
										/>
										<label className="form-check-label" htmlFor="mycirle8">Match referee </label>
									</div>
								</div>

								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle9"
											defaultValue="option5"

											onClick={saveGenderBased}
											checked={checkedQues4Option5}
											data-quesId="4"
										/>
										<label className="form-check-label" htmlFor="mycirle9"> Coach of Kings XI Punjab, Tom Moody </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle10"
											defaultValue="option6"

											onClick={saveGenderBased}
											checked={checkedQues4Option6}
											data-quesId="4"
										/>
										<label className="form-check-label" htmlFor="mycirle10"> Mohali team owner Preity Zinta</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle11"
											defaultValue="option7"

											onClick={saveGenderBased}
											checked={checkedQues4Option7}
											data-quesId="4"
										/>
										<label className="form-check-label" htmlFor="mycirle11">Team mate, Yuvraj Singh  </label>
									</div>
								</div>



							</div>
							<div
								className="col-lg-3 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="90%"
										draggable="false"
										src="/assets/img/md3/md3-shap-2-5.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-02-screen-04" />
									{/* <NextBtn link="/module-03/chapter-02-screen-06" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen05;
