import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter01Screen03 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="0" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Learn:  </h3>
								<p className="mb-2 mt-3">Sexuality is a central aspect of being human throughout life and encompasses sex, gender identities and roles, sexual Identity, eroticism, pleasure, intimacy, and reproduction. Sexuality is experienced and expressed in thoughts, fantasies, desires, beliefs, attitudes, values, behaviours, practices, roles, and relationships. While sexuality can include all of these dimensions, not all of them are always experienced or expressed. Sexuality is influenced by the interaction of biological, psychological, social, economic, political, cultural, ethical, legal, historical, religious and spiritual factors.</p>
								<p className="mb-2 mt-3"><i>[World Health Organization (WHO) working definitions 2002. They do not represent an official position of WHO]</i></p>


							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-1-3.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-01-screen-02" />
								<NextBtn link="/module-05/chapter-01-screen-04" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen03;
