import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";

const Chapter04Screen26 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2 hpgi">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>

				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-0">
							<div className="col-lg-5 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
								<h3 className="h3">Learn </h3>
								<p className="mb-2 mt-3">What does this image above tell us?  </p>
								<p className="mb-2 mt-3">It provides evidence of the double standards around us. Women have to make sure they do not get pregnant yet their husbands decide if contraception will be used, and what kind. The consequences and responsibilities of sexual activity are women’s – but the power, access to resources, decision-making rests in the hands of men. The same holds true in dating relationships. Male partners exert their power and decision making over women when deciding contraception use, sexual activity etc.</p>

								<p className="mb-2 mt-3"> Most forms of birth control in our cultural context are to control population and not enhance female reproductive choice / agency. Hence, the female body is more likely to be the intervention object.</p>
							</div>


							<div className="col-lg-7 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/chap02-4/26.png"
										alt=""
									/>
								</center>
							</div>


						</div>
					</div>
				</div>
			</div>




			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-25" />
								{/* <NextBtn link="/module-05/chapter-01-screen-06" text="Go Next"/> */}

								<button className="button_su">
									<span className="su_button_circle desplode-circle">
									</span>
									<a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </a>
								</button>


							</div>
						</div>
					</div>
				</div>
			</div>




			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>



							<p className="text-white text-center">In India, the most common means of contraception promoted and practiced is female sterilization (more than 1/3rd of sexually active population. Condom use in India reduced by almost half till 2018 and vasectomies fell by 73% - showing a rising reluctance among men to use birth control. The use of emergency contraceptives like IPill increased by over 100% in the same period.</p>


							<div className=" d-flex justify-content-center mt-3">
								<button className="button_su m-auto" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="/module-05/chapter-04-screen-28" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen26;
