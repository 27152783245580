import React from "react";
import { useDrag } from "react-dnd";
import { NavLink } from 'react-router-dom';

function Image({ id, url, imageName }) {
	const [{ isDragging }, drag] = useDrag(() => ({
		type: "image",
		item: { id: id },
		collect: (monitor) => ({
			isDragging: !!monitor.isDragging(),
		}),
	}));
	return (
		<>
			<div className="col col-lg-2 col-sm-6 col-xs-12" ref={drag} >
				<NavLink to="">
					<div className="img-box-text">
						<div className="img-box-drag">
							<img

								src={"/assets/img/chapter1/" + url}
								draggable="false"
								alt=""
								id={id}
								width="150px"
								style={{ border: isDragging ? "5px solid pink" : "0px" }}

							/>
						</div>
						<div className="img-box-content">{imageName}</div>
					</div>
				</NavLink>
			</div>
		</>
	);
}

export default Image;
