import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen04 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2 hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2 mt-m-3">

						<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>




								<h3 className="h3">Learn:  </h3>
								<p className="mb-2  mt-3">Most humans experience sexual desires and seek pleasure. Surprisingly though, when we talk about sexuality, the conversation around pleasure and desire is missing! When they are discussed, it is only to stress “dangerous” and “risky” negative conditions e.g. unwanted pregnancies, sexual diseases, sexual violence, ideas of sexual promiscuity for girls, ideas of abnormal/ unnatural for LGBTQIA+ and more.   </p>
								<p className="mb-2  mt-3">Sexual activity with any partner/s or by oneself at a time when a person feels ready is fun, satisfying and very enjoyable! Sexual pleasure has physical and psychological aspects that can make us more connected to others and ourselves.  </p>

							


							</div>



						<div
								className="col-lg-10 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/chap02-2/4.jpg"
										alt=""
									/>
								</center>
							</div>
							
							
							
							<p className="mb-2  mt-3">The different parts of pleasure are grounded in sexual rights and sexual health (more in the next chapter). It is not just about saying no, but also saying yes to what we want and like!  </p>





						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-03" />
								<NextBtn link="/module-05/chapter-03-screen-05" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen04;
