import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate, NavLink } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";

const Chapter01Screen12 = () => {
	const navigate = useNavigate();

	const [pictureList, setPictureList] = useState([
		{ "id": "1", "name": "plan", "image": "md-5-chap-1-12.png", "defaultValue": "pen" },
		{ "id": "2", "name": "plan", "image": "md-5-chap-1-12.png", "defaultValue": "pencil" },
		{ "id": "3", "name": "plan", "image": "md-5-chap-1-12.png", "defaultValue": "pen" },
		{ "id": "4", "name": "plan", "image": "md-5-chap-1-12.png", "defaultValue": "pen" },
		{ "id": "5", "name": "plan", "image": "md-5-chap-1-12.png", "defaultValue": "pen" },
		{ "id": "6", "name": "plan", "image": "md-5-chap-1-12.png", "defaultValue": "pen" },
		{ "id": "7", "name": "plan", "image": "md-5-chap-1-12.png", "defaultValue": "pen" },
		{ "id": "8", "name": "plan", "image": "md-5-chap-1-12.png", "defaultValue": "pen" },
		{ "id": "9", "name": "plan", "image": "md-5-chap-1-12.png", "defaultValue": "pen" },
		{ "id": "10", "name": "plan", "image": "md-5-chap-1-12.png", "defaultValue": "pen" },
	])

	const [showResults, setShowResults] = useState(false)

	const saveRadio = (e) => {
		// alert(e.target.checked);
		setShowResults(true);

	}

	const ShowPopUp = () => {
		if (showResults === true) {

			return (
				<>

					<div className="alert alert-primary text-center mt-3" role="alert">
						Nearly 3bn people, or 37% of global population, have never used internet
						<a href="https://www.theguardian.com/technology/2021/nov/30/more-than-a-third-of-worlds-population-has-never-used-the-internet-says-un" target="_blank" className="text-center">
							<div className="d-flex align-items-center justify-content-center mange-icon mt-3">
								<div>
									<img src="/assets/img/chapter1/list-icon.png" alt="" />
								</div>
								<div>
									<p className="text-white">More than a third of world's population have never used internet, says UN </p>
								</div>
							</div>
						</a>
					</div>
				</>
			)

		}
	}





	// console.log(showResults);

	const BtnDisabled = () => {
		if (showResults === true) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<NavLink to="/module-03/chapter-01-screen-13" className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</NavLink>
					</button>
				</>
			);
		} else if (showResults === false) {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next  <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper md-5-bg">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="5" star="4" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-center justify-content-center pt-20 mt-5 mt-m-3">

							<div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">

								<div className="row align-items-center justify-content-center select-op-new">

									{
										pictureList.map((item, index) => {
											return (
												<>
													<div className="col-3 col-lg-1 col-sm-2 mt-2 select-pset">
														<div className="plans ">
															<label className="plan basic-plan" htmlFor={"basic" + index}>
																<input type="radio" name="plan" id={"basic" + index} onClick={saveRadio} defaultValue={item.defaultValue} />
																<div className="plan-content"><img loading="lazy" src={"/assets/img/md5/" + item.image} alt="" /></div>
															</label>
														</div>
													</div>
												</>
											)
										})
									}

								</div>


								<p className="mb-2 mt-3">The following images represent access & use of the internet in the world. Here, the first image represents full access to the internet and related facilities, the last image represents limited to no access to internet, and related facilities. Please select the image that represents you on the scale.   </p>


								<ShowPopUp />

							</div>

						</div>

					</div>
				</div>
			</div>


			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-03/chapter-01-screen-11" />

								{/* <NextBtn link="/module-03/chapter-01-screen-13" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen12;
