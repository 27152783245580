import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { Modal, Button } from "react-bootstrap";

const Chapter04Screen25 = () => {

	const navigate = useNavigate();

	const [checkedQues28Option1, setCheckedQues28Option1] = useState('');
	const [checkedQues28Option2, setCheckedQues28Option2] = useState('');

	const [checkedQues28Val, setCheckedQues28Val] = useState('');

	const [checkedQues29Option1, setCheckedQues29Option1] = useState('');
	const [checkedQues29Option2, setCheckedQues29Option2] = useState('');

	const [checkedQues29Val, setCheckedQues29Val] = useState('');

	const [checkedQues30Option1, setCheckedQues30Option1] = useState('');
	const [checkedQues30Option2, setCheckedQues30Option2] = useState('');

	const [checkedQues30Val, setCheckedQues30Val] = useState('');

	const [checkedQues31Option1, setCheckedQues31Option1] = useState('');
	const [checkedQues31Option2, setCheckedQues31Option2] = useState('');

	const [checkedQues31Val, setCheckedQues31Val] = useState('');

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showModalText, setShowModalText] = useState();



	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		// alert(e.target.value);
		if (quesId === '28') {
			setShowModalText('Safer sex behaviours and preventing STIs/pregnancy is the equal shared responsibility of partners in a sexual relationship. While the outcomes for women may require that, they be the source of decision-making – male partners must be aware, engaged and affirming of contraception use')
		}


		if (quesId === '29') {
			setShowModalText('Open and honest communication helps to strengthen a relationship and build trust among partners. However, there could be situations where taboo, stigma and/ or the fear of violence might stop a person especially women from sharing about her contraceptive choices and practices with her sexual partner]')
		}

		if (quesId === '30') {
			setShowModalText('Access and information on contraception promotes safer sexual health practices, strengthens the agency for women to decide about her body and life. It does not make women “promiscuous”, this is a stereotype which promotes discrimination against women, and is used curb her affirmative sexual experiences')
		}

		if (quesId === '31') {
			setShowModalText('Practices like these create distrust among partners and weakens the relationship. Practices like this could make the other partner vulnerable to abuse, unwanted pregnancies, and STIs.')
		}


		setShow(true);
		checkedVal(quesId, e.target.value)
	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques28": {
					"ans": checkedQues28Val,
					"ques_id": "28"
				},
				"ques29": {
					"ans": checkedQues29Val,
					"ques_id": "29"
				},
				"ques30": {
					"ans": checkedQues30Val,
					"ques_id": "30"
				},
				"ques31": {
					"ans": checkedQues31Val,
					"ques_id": "31"
				},


			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-05/chapter-04-screen-26')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues28Val !== '' && checkedQues29Val !== '' && checkedQues30Val !== '' && checkedQues31Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {

		if (param === '28') {
			if (ans === 'option1') {
				setCheckedQues28Option1('checked')
				setCheckedQues28Option2('');

			} else if (ans === 'option2') {
				setCheckedQues28Option1('')
				setCheckedQues28Option2('checked');

			}
			setCheckedQues28Val(ans)
		}

		if (param === '29') {
			if (ans === 'option1') {
				setCheckedQues29Option1('checked')
				setCheckedQues29Option2('');

			} else if (ans === 'option2') {
				setCheckedQues29Option1('')
				setCheckedQues29Option2('checked');

			}
			setCheckedQues29Val(ans)
		}

		if (param === '30') {
			if (ans === 'option1') {
				setCheckedQues30Option1('checked')
				setCheckedQues30Option2('');

			} else if (ans === 'option2') {
				setCheckedQues30Option1('')
				setCheckedQues30Option2('checked');

			}
			setCheckedQues30Val(ans)
		}

		if (param === '31') {
			if (ans === 'option1') {
				setCheckedQues31Option1('checked')
				setCheckedQues31Option2('');

			} else if (ans === 'option2') {
				setCheckedQues31Option1('')
				setCheckedQues31Option2('checked');

			}
			setCheckedQues31Val(ans)
		}




	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-0">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								{/* yahan popup rahega */}

								<h3 className="h3">Reflect  </h3>
								<p className="mb-2 mt-3">Let us unpack the relationship between use of contraceptives, and gender relations through an exercise! Read the statements below, and mark them as true or false based on your thoughts.  </p>

								<p className="mb-2 mt-3 pn"><span><b>1.</b></span> <b> Using contraception and other safer sex behaviours to prevent pregnancy is a woman’s responsibility.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle" id="mycirle2"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues28Option1}
											data-quesId="28"


										/>
										<label className="form-check-label" htmlFor="mycirle2">True</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle" id="mycirle3"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues28Option2}
											data-quesId="28"

										/>
										<label className="form-check-label" htmlFor="mycirle3">False</label>
									</div>
								</div>

								<p className="mb-2 mt-3 pn"><span><b>2.</b></span> <b> Being open and honest with sexual partners about use of birth control is ethical behaviour in relationships. </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle2" id="mycirle356"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues29Option1}
											data-quesId="29"

										/>
										<label className="form-check-label" htmlFor="mycirle356">True</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle2" id="mycirle44"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues29Option2}
											data-quesId="29"

										/>
										<label className="form-check-label" htmlFor="mycirle44">False</label>
									</div>
								</div>

								<p className="mb-2 mt-3 pn"><span><b>3.</b></span> <b> Easy access to contraception and birth control makes women promiscuous.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle5"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues30Option1}
											data-quesId="30"

										/>
										<label className="form-check-label" htmlFor="mycirle5">True </label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle6"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues30Option2}
											data-quesId="30"

										/>
										<label className="form-check-label" htmlFor="mycirle6">False</label>
									</div>
								</div>


								<p className="mb-2 mt-3 pn"><span><b>4.</b></span> <b>Pressuring your partner to have unprotected sexual relations, promising to ‘pull out’ with no intention of doing so, or removing a condom without your partner’s knowledge, are some examples of unethical behaviour in sexual relationships. </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle4" id="mycirle7"
											defaultValue="option1"


											onClick={saveRadio}
											checked={checkedQues31Option1}
											data-quesId="31"
										/>
										<label className="form-check-label" htmlFor="mycirle7">True</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle4" id="mycirle8"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues31Option2}
											data-quesId="31"
										/>
										<label className="form-check-label" htmlFor="mycirle8">False</label>
									</div>
								</div>








							</div>

							<div
								className="col-lg-2 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-2.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-24n" />
								{/* <NextBtn link="/module-05/chapter-04-screen-26" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Modal */}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					{/* <Modal.Title>Women are physically weak :</Modal.Title> */}
				</Modal.Header>

				<Modal.Body>
					<p>{showModalText}</p>
				</Modal.Body>
			</Modal>

		</>
	);
};

export default Chapter04Screen25;
