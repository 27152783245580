import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter04Screen29 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-0">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3">Learn  </h3>
								<p className="mb-2 mt-3">Lack of sexual and bodily autonomy for certain groups like women, <b>LGBTQIA+ </b> that find expression in laws and through institutions become especially harmful. <b>LGBTQIA+ </b> people affected by STIs or other sex related issues face isolation, neglect, legal discrimination, lack of access to health services, societal exclusion. Similarly for women, especially unmarried who engage in sexual activities that result in a pregnancy. </p>


							</div>

							<div
								className="col-lg-2 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-2.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-28" />
								<NextBtn link="/module-05/chapter-04-screen-30" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>





		</>
	);
};

export default Chapter04Screen29;
