import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter04Screen05 = () => {

	const navigate = useNavigate();
	const [checkedQues35ConcYes, setcheckedQues35ConcYes] = useState('');
	const [checkedQues35ConcNo, setcheckedQues35ConcNo] = useState('');
	const [checkedQues35ValConc, setCheckedQues35ValConc] = useState('');

	const [checkedQues35NonConcYes, setcheckedQues35NonConcYes] = useState('');
	const [checkedQues35NonConcNo, setcheckedQues35NonConcNo] = useState('');
	const [checkedQues35ValNonConc, setCheckedQues35ValNonConc] = useState('');

	const [checkedQues36ConcYes, setcheckedQues36ConcYes] = useState('');
	const [checkedQues36ConcNo, setcheckedQues36ConcNo] = useState('');
	const [checkedQues36ValConc, setCheckedQues36ValConc] = useState('');

	const [checkedQues36NonConcYes, setcheckedQues36NonConcYes] = useState('');
	const [checkedQues36NonConcNo, setcheckedQues36NonConcNo] = useState('');
	const [checkedQues36ValNonConc, setCheckedQues36ValNonConc] = useState('');

	const [checkedQues37ConcYes, setcheckedQues37ConcYes] = useState('');
	const [checkedQues37ConcNo, setcheckedQues37ConcNo] = useState('');
	const [checkedQues37ValConc, setCheckedQues37ValConc] = useState('');

	const [checkedQues37NonConcYes, setcheckedQues37NonConcYes] = useState('');
	const [checkedQues37NonConcNo, setcheckedQues37NonConcNo] = useState('');
	const [checkedQues37ValNonConc, setCheckedQues37ValNonConc] = useState('');

	const [checkedQues38ConcYes, setcheckedQues38ConcYes] = useState('');
	const [checkedQues38ConcNo, setcheckedQues38ConcNo] = useState('');
	const [checkedQues38ValConc, setCheckedQues38ValConc] = useState('');

	const [checkedQues38NonConcYes, setcheckedQues38NonConcYes] = useState('');
	const [checkedQues38NonConcNo, setcheckedQues38NonConcNo] = useState('');
	const [checkedQues38ValNonConc, setCheckedQues38ValNonConc] = useState('');

	const [checkedQues39ConcYes, setcheckedQues39ConcYes] = useState('');
	const [checkedQues39ConcNo, setcheckedQues39ConcNo] = useState('');
	const [checkedQues39ValConc, setCheckedQues39ValConc] = useState('');

	const [checkedQues39NonConcYes, setcheckedQues39NonConcYes] = useState('');
	const [checkedQues39NonConcNo, setcheckedQues39NonConcNo] = useState('');
	const [checkedQues39ValNonConc, setCheckedQues39ValNonConc] = useState('');

	const [checkedQues40ConcYes, setcheckedQues40ConcYes] = useState('');
	const [checkedQues40ConcNo, setcheckedQues40ConcNo] = useState('');
	const [checkedQues40ValConc, setCheckedQues40ValConc] = useState('');

	const [checkedQues40NonConcYes, setcheckedQues40NonConcYes] = useState('');
	const [checkedQues40NonConcNo, setcheckedQues40NonConcNo] = useState('');
	const [checkedQues40ValNonConc, setCheckedQues40ValNonConc] = useState('');

	const [checkedQues41ConcYes, setcheckedQues41ConcYes] = useState('');
	const [checkedQues41ConcNo, setcheckedQues41ConcNo] = useState('');
	const [checkedQues41ValConc, setCheckedQues41ValConc] = useState('');

	const [checkedQues41NonConcYes, setcheckedQues41NonConcYes] = useState('');
	const [checkedQues41NonConcNo, setcheckedQues41NonConcNo] = useState('');
	const [checkedQues41ValNonConc, setCheckedQues41ValNonConc] = useState('');

	const [checkedQues42ConcYes, setcheckedQues42ConcYes] = useState('');
	const [checkedQues42ConcNo, setcheckedQues42ConcNo] = useState('');
	const [checkedQues42ValConc, setCheckedQues42ValConc] = useState('');

	const [checkedQues42NonConcYes, setcheckedQues42NonConcYes] = useState('');
	const [checkedQues42NonConcNo, setcheckedQues42NonConcNo] = useState('');
	const [checkedQues42ValNonConc, setCheckedQues42ValNonConc] = useState('');

	const [checkedQues43ConcYes, setcheckedQues43ConcYes] = useState('');
	const [checkedQues43ConcNo, setcheckedQues43ConcNo] = useState('');
	const [checkedQues43ValConc, setCheckedQues43ValConc] = useState('');

	const [checkedQues43NonConcYes, setcheckedQues43NonConcYes] = useState('');
	const [checkedQues43NonConcNo, setcheckedQues43NonConcNo] = useState('');
	const [checkedQues43ValNonConc, setCheckedQues43ValNonConc] = useState('');

	const [checkedQues44ConcYes, setcheckedQues44ConcYes] = useState('');
	const [checkedQues44ConcNo, setcheckedQues44ConcNo] = useState('');
	const [checkedQues44ValConc, setCheckedQues44ValConc] = useState('');

	const [checkedQues44NonConcYes, setcheckedQues44NonConcYes] = useState('');
	const [checkedQues44NonConcNo, setcheckedQues44NonConcNo] = useState('');
	const [checkedQues44ValNonConc, setCheckedQues44ValNonConc] = useState('');

	const [checkedQues45ConcYes, setcheckedQues45ConcYes] = useState('');
	const [checkedQues45ConcNo, setcheckedQues45ConcNo] = useState('');
	const [checkedQues45ValConc, setCheckedQues45ValConc] = useState('');

	const [checkedQues45NonConcYes, setcheckedQues45NonConcYes] = useState('');
	const [checkedQues45NonConcNo, setcheckedQues45NonConcNo] = useState('');
	const [checkedQues45ValNonConc, setCheckedQues45ValNonConc] = useState('');













	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const concentType = e.target.getAttribute("data-concentType");
		// alert(concentType);

		checkedVal(quesId, e.target.value, concentType)


	}

	const saveAns = () => {
		// alert();
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques35": {
					"ans": checkedQues35ValConc,
					"ans2": checkedQues35ValNonConc,
					"ques_id": "35"
				},
				"ques36": {
					"ans": checkedQues36ValConc,
					"ans2": checkedQues36ValNonConc,
					"ques_id": "36"
				},

				"ques37": {
					"ans": checkedQues37ValConc,
					"ans2": checkedQues37ValNonConc,
					"ques_id": "37"
				},

				"ques38": {
					"ans": checkedQues38ValConc,
					"ans2": checkedQues38ValNonConc,
					"ques_id": "38"
				},

				"ques39": {
					"ans": checkedQues39ValConc,
					"ans2": checkedQues39ValNonConc,
					"ques_id": "39"
				},

				"ques40": {
					"ans": checkedQues40ValConc,
					"ans2": checkedQues40ValNonConc,
					"ques_id": "40"
				},
				"ques41": {
					"ans": checkedQues41ValConc,
					"ans2": checkedQues41ValNonConc,
					"ques_id": "41"
				},
				"ques42": {
					"ans": checkedQues42ValConc,
					"ans2": checkedQues42ValNonConc,
					"ques_id": "42"
				},
				"ques43": {
					"ans": checkedQues43ValConc,
					"ans2": checkedQues43ValNonConc,
					"ques_id": "43"
				},
				"ques44": {
					"ans": checkedQues44ValConc,
					"ans2": checkedQues44ValNonConc,
					"ques_id": "44"
				},
				"ques45": {
					"ans": checkedQues45ValConc,
					"ans2": checkedQues45ValNonConc,
					"ques_id": "45"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-04-screen-06')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues35ValConc !== '' && checkedQues36ValConc !== ''
			&& checkedQues37ValConc !== '' && checkedQues38ValConc !== ''
			&& checkedQues39ValConc !== '' && checkedQues40ValConc !== ''
			&& checkedQues41ValConc !== '' && checkedQues42ValConc !== ''
			&& checkedQues43ValConc !== '' && checkedQues44ValConc !== ''
			&& checkedQues45ValConc !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans, concentType) => {

		if (param === '35') {

			if (concentType === 'concent') {
				if (ans === 'yes') {
					setcheckedQues35ConcYes('checked')
					setcheckedQues35ConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues35ConcYes('')
					setcheckedQues35ConcNo('checked');
				}
				setCheckedQues35ValConc(ans)
			}

			if (concentType === 'nonconcent') {
				if (ans === 'yes') {
					setcheckedQues35NonConcYes('checked')
					setcheckedQues35NonConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues35NonConcYes('')
					setcheckedQues35NonConcNo('checked');
				}
				setCheckedQues35ValNonConc(ans)
			}


		}

		if (param === '36') {

			if (concentType === 'concent') {
				if (ans === 'yes') {
					setcheckedQues36ConcYes('checked')
					setcheckedQues36ConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues36ConcYes('')
					setcheckedQues36ConcNo('checked');
				}
				setCheckedQues36ValConc(ans)
			}

			if (concentType === 'nonconcent') {
				if (ans === 'yes') {
					setcheckedQues36NonConcYes('checked')
					setcheckedQues36NonConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues36NonConcYes('')
					setcheckedQues36NonConcNo('checked');
				}
				setCheckedQues36ValNonConc(ans)
			}


		}

		if (param === '37') {

			if (concentType === 'concent') {
				if (ans === 'yes') {
					setcheckedQues37ConcYes('checked')
					setcheckedQues37ConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues37ConcYes('')
					setcheckedQues37ConcNo('checked');
				}
				setCheckedQues37ValConc(ans)
			}

			if (concentType === 'nonconcent') {
				if (ans === 'yes') {
					setcheckedQues37NonConcYes('checked')
					setcheckedQues37NonConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues37NonConcYes('')
					setcheckedQues37NonConcNo('checked');
				}
				setCheckedQues37ValNonConc(ans)
			}


		}

		if (param === '38') {

			if (concentType === 'concent') {
				if (ans === 'yes') {
					setcheckedQues38ConcYes('checked')
					setcheckedQues38ConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues38ConcYes('')
					setcheckedQues38ConcNo('checked');
				}
				setCheckedQues38ValConc(ans)
			}

			if (concentType === 'nonconcent') {
				if (ans === 'yes') {
					setcheckedQues38NonConcYes('checked')
					setcheckedQues38NonConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues38NonConcYes('')
					setcheckedQues38NonConcNo('checked');
				}
				setCheckedQues38ValNonConc(ans)
			}


		}

		if (param === '39') {

			if (concentType === 'concent') {
				if (ans === 'yes') {
					setcheckedQues39ConcYes('checked')
					setcheckedQues39ConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues39ConcYes('')
					setcheckedQues39ConcNo('checked');
				}
				setCheckedQues39ValConc(ans)
			}

			if (concentType === 'nonconcent') {
				if (ans === 'yes') {
					setcheckedQues39NonConcYes('checked')
					setcheckedQues39NonConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues39NonConcYes('')
					setcheckedQues39NonConcNo('checked');
				}
				setCheckedQues39ValNonConc(ans)
			}


		}

		if (param === '40') {

			if (concentType === 'concent') {
				if (ans === 'yes') {
					setcheckedQues40ConcYes('checked')
					setcheckedQues40ConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues40ConcYes('')
					setcheckedQues40ConcNo('checked');
				}
				setCheckedQues40ValConc(ans)
			}

			if (concentType === 'nonconcent') {
				if (ans === 'yes') {
					setcheckedQues40NonConcYes('checked')
					setcheckedQues40NonConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues40NonConcYes('')
					setcheckedQues40NonConcNo('checked');
				}
				setCheckedQues40ValNonConc(ans)
			}


		}

		if (param === '41') {

			if (concentType === 'concent') {
				if (ans === 'yes') {
					setcheckedQues41ConcYes('checked')
					setcheckedQues41ConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues41ConcYes('')
					setcheckedQues41ConcNo('checked');
				}
				setCheckedQues41ValConc(ans)
			}

			if (concentType === 'nonconcent') {
				if (ans === 'yes') {
					setcheckedQues41NonConcYes('checked')
					setcheckedQues41NonConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues41NonConcYes('')
					setcheckedQues41NonConcNo('checked');
				}
				setCheckedQues41ValNonConc(ans)
			}


		}

		if (param === '42') {

			if (concentType === 'concent') {
				if (ans === 'yes') {
					setcheckedQues42ConcYes('checked')
					setcheckedQues42ConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues42ConcYes('')
					setcheckedQues42ConcNo('checked');
				}
				setCheckedQues42ValConc(ans)
			}

			if (concentType === 'nonconcent') {
				if (ans === 'yes') {
					setcheckedQues42NonConcYes('checked')
					setcheckedQues42NonConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues42NonConcYes('')
					setcheckedQues42NonConcNo('checked');
				}
				setCheckedQues42ValNonConc(ans)
			}


		}

		if (param === '43') {

			if (concentType === 'concent') {
				if (ans === 'yes') {
					setcheckedQues43ConcYes('checked')
					setcheckedQues43ConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues43ConcYes('')
					setcheckedQues43ConcNo('checked');
				}
				setCheckedQues43ValConc(ans)
			}

			if (concentType === 'nonconcent') {
				if (ans === 'yes') {
					setcheckedQues43NonConcYes('checked')
					setcheckedQues43NonConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues43NonConcYes('')
					setcheckedQues43NonConcNo('checked');
				}
				setCheckedQues43ValNonConc(ans)
			}


		}

		if (param === '44') {

			if (concentType === 'concent') {
				if (ans === 'yes') {
					setcheckedQues44ConcYes('checked')
					setcheckedQues44ConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues44ConcYes('')
					setcheckedQues44ConcNo('checked');
				}
				setCheckedQues44ValConc(ans)
			}

			if (concentType === 'nonconcent') {
				if (ans === 'yes') {
					setcheckedQues44NonConcYes('checked')
					setcheckedQues44NonConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues44NonConcYes('')
					setcheckedQues44NonConcNo('checked');
				}
				setCheckedQues44ValNonConc(ans)
			}


		}

		if (param === '45') {

			if (concentType === 'concent') {
				if (ans === 'yes') {
					setcheckedQues45ConcYes('checked')
					setcheckedQues45ConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues45ConcYes('')
					setcheckedQues45ConcNo('checked');
				}
				setCheckedQues45ValConc(ans)
			}

			if (concentType === 'nonconcent') {
				if (ans === 'yes') {
					setcheckedQues45NonConcYes('checked')
					setcheckedQues45NonConcNo('');
					// setCheckedQues5Option3('')
					// setCheckedQues5Option4('')
				} else if (ans === 'no') {
					setcheckedQues45NonConcYes('')
					setcheckedQues45NonConcNo('checked');
				}
				setCheckedQues45ValNonConc(ans)
			}


		}




	}

	const checkedValNonCon = (param, ans, ans2) => {

		if (param === '35') {
			if (ans === 'yes') {
				setcheckedQues35ConcYes('checked')
				setcheckedQues35ConcNo('');
				// setCheckedQues5Option3('')
				// setCheckedQues5Option4('')
			} else if (ans === 'no') {
				setcheckedQues35ConcYes('')
				setcheckedQues35ConcNo('checked');
			}

			if (ans2 === 'yes') {
				setcheckedQues35NonConcYes('checked')
				setcheckedQues35NonConcNo('');
				// setCheckedQues5Option3('')
				// setCheckedQues5Option4('')
			} else if (ans2 === 'no') {
				setcheckedQues35NonConcYes('')
				setcheckedQues35NonConcNo('checked');
			}

			setCheckedQues35ValConc(ans)
			setCheckedQues35ValNonConc(ans2)
		}

		if (param === '36') {
			// alert();
			if (ans === 'yes') {
				setcheckedQues36ConcYes('checked')
				setcheckedQues36ConcNo('');
				// setCheckedQues5Option3('')
				// setCheckedQues5Option4('')
			} else if (ans === 'no') {
				setcheckedQues36ConcYes('')
				setcheckedQues36ConcNo('checked');
			}

			if (ans2 === 'yes') {
				setcheckedQues36NonConcYes('checked')
				setcheckedQues36NonConcNo('');
				// setCheckedQues5Option3('')
				// setCheckedQues5Option4('')
			} else if (ans2 === 'no') {
				setcheckedQues36NonConcYes('')
				setcheckedQues36NonConcNo('checked');
			}

			setCheckedQues36ValConc(ans)
			setCheckedQues36ValNonConc(ans2)
		}

		if (param === '37') {
			// alert();
			if (ans === 'yes') {
				setcheckedQues37ConcYes('checked')
				setcheckedQues37ConcNo('');
				// setCheckedQues5Option3('')
				// setCheckedQues5Option4('')
			} else if (ans === 'no') {
				setcheckedQues37ConcYes('')
				setcheckedQues37ConcNo('checked');
			}

			if (ans2 === 'yes') {
				setcheckedQues37NonConcYes('checked')
				setcheckedQues37NonConcNo('');
				// setCheckedQues5Option3('')
				// setCheckedQues5Option4('')
			} else if (ans2 === 'no') {
				setcheckedQues37NonConcYes('')
				setcheckedQues37NonConcNo('checked');
			}

			setCheckedQues37ValConc(ans)
			setCheckedQues37ValNonConc(ans2)
		}

		if (param === '38') {
			// alert();
			if (ans === 'yes') {
				setcheckedQues38ConcYes('checked')
				setcheckedQues38ConcNo('');
				// setCheckedQues5Option3('')
				// setCheckedQues5Option4('')
			} else if (ans === 'no') {
				setcheckedQues38ConcYes('')
				setcheckedQues38ConcNo('checked');
			}

			if (ans2 === 'yes') {
				setcheckedQues38NonConcYes('checked')
				setcheckedQues38NonConcNo('');
				// setCheckedQues5Option3('')
				// setCheckedQues5Option4('')
			} else if (ans2 === 'no') {
				setcheckedQues38NonConcYes('')
				setcheckedQues38NonConcNo('checked');
			}

			setCheckedQues38ValConc(ans)
			setCheckedQues38ValNonConc(ans2)
		}


		if (param === '39') {
			// alert();
			if (ans === 'yes') {
				setcheckedQues39ConcYes('checked')
				setcheckedQues39ConcNo('');

			} else if (ans === 'no') {
				setcheckedQues39ConcYes('')
				setcheckedQues39ConcNo('checked');
			}

			if (ans2 === 'yes') {
				setcheckedQues39NonConcYes('checked')
				setcheckedQues39NonConcNo('');

			} else if (ans2 === 'no') {
				setcheckedQues39NonConcYes('')
				setcheckedQues39NonConcNo('checked');
			}

			setCheckedQues39ValConc(ans)
			setCheckedQues39ValNonConc(ans2)
		}

		if (param === '40') {
			// alert();
			if (ans === 'yes') {
				setcheckedQues40ConcYes('checked')
				setcheckedQues40ConcNo('');

			} else if (ans === 'no') {
				setcheckedQues40ConcYes('')
				setcheckedQues40ConcNo('checked');
			}

			if (ans2 === 'yes') {
				setcheckedQues40NonConcYes('checked')
				setcheckedQues40NonConcNo('');

			} else if (ans2 === 'no') {
				setcheckedQues40NonConcYes('')
				setcheckedQues40NonConcNo('checked');
			}

			setCheckedQues40ValConc(ans)
			setCheckedQues40ValNonConc(ans2)
		}

		if (param === '41') {
			// alert();
			if (ans === 'yes') {
				setcheckedQues41ConcYes('checked')
				setcheckedQues41ConcNo('');

			} else if (ans === 'no') {
				setcheckedQues41ConcYes('')
				setcheckedQues41ConcNo('checked');
			}

			if (ans2 === 'yes') {
				setcheckedQues41NonConcYes('checked')
				setcheckedQues41NonConcNo('');

			} else if (ans2 === 'no') {
				setcheckedQues41NonConcYes('')
				setcheckedQues41NonConcNo('checked');
			}

			setCheckedQues41ValConc(ans)
			setCheckedQues41ValNonConc(ans2)
		}

		if (param === '42') {
			// alert();
			if (ans === 'yes') {
				setcheckedQues42ConcYes('checked')
				setcheckedQues42ConcNo('');

			} else if (ans === 'no') {
				setcheckedQues42ConcYes('')
				setcheckedQues42ConcNo('checked');
			}

			if (ans2 === 'yes') {
				setcheckedQues42NonConcYes('checked')
				setcheckedQues42NonConcNo('');

			} else if (ans2 === 'no') {
				setcheckedQues42NonConcYes('')
				setcheckedQues42NonConcNo('checked');
			}

			setCheckedQues42ValConc(ans)
			setCheckedQues42ValNonConc(ans2)
		}

		if (param === '43') {
			// alert();
			if (ans === 'yes') {
				setcheckedQues43ConcYes('checked')
				setcheckedQues43ConcNo('');

			} else if (ans === 'no') {
				setcheckedQues43ConcYes('')
				setcheckedQues43ConcNo('checked');
			}

			if (ans2 === 'yes') {
				setcheckedQues43NonConcYes('checked')
				setcheckedQues43NonConcNo('');

			} else if (ans2 === 'no') {
				setcheckedQues43NonConcYes('')
				setcheckedQues43NonConcNo('checked');
			}

			setCheckedQues43ValConc(ans)
			setCheckedQues43ValNonConc(ans2)
		}

		if (param === '44') {
			// alert();
			if (ans === 'yes') {
				setcheckedQues44ConcYes('checked')
				setcheckedQues44ConcNo('');

			} else if (ans === 'no') {
				setcheckedQues44ConcYes('')
				setcheckedQues44ConcNo('checked');
			}

			if (ans2 === 'yes') {
				setcheckedQues44NonConcYes('checked')
				setcheckedQues44NonConcNo('');

			} else if (ans2 === 'no') {
				setcheckedQues44NonConcYes('')
				setcheckedQues44NonConcNo('checked');
			}

			setCheckedQues44ValConc(ans)
			setCheckedQues44ValNonConc(ans2)
		}


		if (param === '45') {
			// alert();
			if (ans === 'yes') {
				setcheckedQues45ConcYes('checked')
				setcheckedQues45ConcNo('');

			} else if (ans === 'no') {
				setcheckedQues45ConcYes('')
				setcheckedQues45ConcNo('checked');
			}

			if (ans2 === 'yes') {
				setcheckedQues45NonConcYes('checked')
				setcheckedQues45NonConcNo('');

			} else if (ans2 === 'no') {
				setcheckedQues45NonConcYes('')
				setcheckedQues45NonConcNo('checked');
			}

			setCheckedQues45ValConc(ans)
			setCheckedQues45ValNonConc(ans2)
		}













	}


	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedValNonCon(result.ques_id.toString(), result.ans, result.ans2)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	return (
		<>
			<div className="wrapper page-auto bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 ">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p>Tell us which of these behaviours are consensual and which are “not consent”. The first is done as an example! </p>
								<h3 className="h3 mt-2"> Is this consent? </h3>
								<table className="table table-bordered table-dark table-red table-padding-less mt-1 mb-7">
									<tbody>
										<tr>
											<td> </td>
											<td>Element of Consent </td>
											<td>Not Consent </td>
										</tr>
										<tr>
											<td>Your partner didn’t say no but didn’t say yes either  </td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check"
															type="radio"
															name="inlineRadioOptions"
															id="inlineRadio1"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="35"
															checked={checkedQues35ConcYes}
															data-concentType="concent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions"
															id="inlineRadio2"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="35"
															checked={checkedQues35ConcNo}
															data-concentType="concent"
														/>
														<label className="form-check-label " htmlFor="inlineRadio2">No</label>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check"
															type="radio"
															name="inlineRadioOptions2"
															id="inlineRadio3"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="35"
															checked={checkedQues35NonConcYes}
															data-concentType="nonconcent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio3">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions2"
															id="inlineRadio4"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="35"
															checked={checkedQues35NonConcNo}
															data-concentType="nonconcent"

														/>
														<label className="form-check-label " htmlFor="inlineRadio4">No</label>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>You say yes without feeling pressured or threatened  </td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check"
															type="radio"
															name="inlineRadioOptions3"
															id="inlineRadio5"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="36"
															checked={checkedQues36ConcYes}
															data-concentType="concent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio5">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions3"
															id="inlineRadio6"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="36"
															checked={checkedQues36ConcNo}
															data-concentType="concent"
														/>
														<label className="form-check-label " htmlFor="inlineRadio6">No</label>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check"
															type="radio"
															name="inlineRadioOptions4"
															id="inlineRadio7"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="36"
															checked={checkedQues36NonConcYes}
															data-concentType="nonconcent"
														/>
														<label className="form-check-label" htmlFor="inlineRadio7">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions4"
															id="inlineRadio8"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="36"
															checked={checkedQues36NonConcNo}
															data-concentType="nonconcent"

														/>
														<label className="form-check-label " htmlFor="inlineRadio8">No</label>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Your partner said yes last time </td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions5"
															id="inlineRadio9"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="37"
															checked={checkedQues37ConcYes}
															data-concentType="concent"
														/>
														<label className="form-check-label" htmlFor="inlineRadio9">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions5"
															id="inlineRadio10"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="37"
															checked={checkedQues37ConcNo}
															data-concentType="concent"
														/>
														<label className="form-check-label " htmlFor="inlineRadio10">No</label>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions6"
															id="inlineRadio11"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="37"
															checked={checkedQues37NonConcYes}
															data-concentType="nonconcent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio11">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions6"
															id="inlineRadio12"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="37"
															checked={checkedQues37NonConcNo}
															data-concentType="nonconcent"

														/>
														<label className="form-check-label " htmlFor="inlineRadio12">No</label>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>You are tired of saying no so you say yes </td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions7"
															id="inlineRadio13"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="38"
															checked={checkedQues38ConcYes}
															data-concentType="concent"
														/>
														<label className="form-check-label" htmlFor="inlineRadio13">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions7"
															id="inlineRadio14"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="38"
															checked={checkedQues38ConcNo}
															data-concentType="concent"

														/>
														<label className="form-check-label " htmlFor="inlineRadio14">No</label>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions8"
															id="inlineRadio15"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="38"
															checked={checkedQues38NonConcYes}
															data-concentType="nonconcent"
														/>
														<label className="form-check-label" htmlFor="inlineRadio15">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions8"
															id="inlineRadio15"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="38"
															checked={checkedQues38NonConcNo}
															data-concentType="nonconcent"

														/>
														<label className="form-check-label " htmlFor="inlineRadio15">No</label>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Your classmate messages you on internet apps with pornographic links </td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions9"
															id="inlineRadio16"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="39"
															checked={checkedQues39ConcYes}
															data-concentType="concent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio16">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions9"
															id="inlineRadio17"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="39"
															checked={checkedQues39ConcNo}
															data-concentType="concent"

														/>
														<label className="form-check-label " htmlFor="inlineRadio17">No</label>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions10"
															id="inlineRadio17"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="39"
															checked={checkedQues39NonConcYes}
															data-concentType="nonconcent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio17">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions10"
															id="inlineRadio18"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="39"
															checked={checkedQues39NonConcNo}
															data-concentType="nonconcent"
														/>
														<label className="form-check-label " htmlFor="inlineRadio18">No</label>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Both partners feel safe and comfortable  </td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions101"
															id="inlineRadio16"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="40"
															checked={checkedQues40ConcYes}
															data-concentType="concent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio16">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions101"
															id="inlineRadio17"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="40"
															checked={checkedQues40ConcNo}
															data-concentType="concent"

														/>
														<label className="form-check-label " htmlFor="inlineRadio17">No</label>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions111"
															id="inlineRadio17"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="40"
															checked={checkedQues40NonConcYes}
															data-concentType="nonconcent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio17">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions111"
															id="inlineRadio18"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="40"
															checked={checkedQues40NonConcNo}
															data-concentType="nonconcent"
														/>
														<label className="form-check-label " htmlFor="inlineRadio18">No</label>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>You were flirting and it was an invitation  </td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1041"
															id="inlineRadio16"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="41"
															checked={checkedQues41ConcYes}
															data-concentType="concent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio16">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1041"
															id="inlineRadio17"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="41"
															checked={checkedQues41ConcNo}
															data-concentType="concent"

														/>
														<label className="form-check-label " htmlFor="inlineRadio17">No</label>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1141"
															id="inlineRadio17"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="41"
															checked={checkedQues41NonConcYes}
															data-concentType="nonconcent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio17">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1141"
															id="inlineRadio18"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="41"
															checked={checkedQues41NonConcNo}
															data-concentType="nonconcent"
														/>
														<label className="form-check-label " htmlFor="inlineRadio18">No</label>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>You push the other person away   </td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1042"
															id="inlineRadio16"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="42"
															checked={checkedQues42ConcYes}
															data-concentType="concent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio16">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1042"
															id="inlineRadio17"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="42"
															checked={checkedQues42ConcNo}
															data-concentType="concent"

														/>
														<label className="form-check-label " htmlFor="inlineRadio17">No</label>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1142"
															id="inlineRadio17"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="42"
															checked={checkedQues42NonConcYes}
															data-concentType="nonconcent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio17">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1142"
															id="inlineRadio18"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="42"
															checked={checkedQues42NonConcNo}
															data-concentType="nonconcent"
														/>
														<label className="form-check-label " htmlFor="inlineRadio18">No</label>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Your partner says yes enthusiastically  </td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1043"
															id="inlineRadio16"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="43"
															checked={checkedQues43ConcYes}
															data-concentType="concent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio16">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1043"
															id="inlineRadio17"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="43"
															checked={checkedQues43ConcNo}
															data-concentType="concent"

														/>
														<label className="form-check-label " htmlFor="inlineRadio17">No</label>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1143"
															id="inlineRadio17"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="43"
															checked={checkedQues43NonConcYes}
															data-concentType="nonconcent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio17">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1143"
															id="inlineRadio18"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="43"
															checked={checkedQues43NonConcNo}
															data-concentType="nonconcent"
														/>
														<label className="form-check-label " htmlFor="inlineRadio18">No</label>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>You can change your mind and say no to sexual activity   </td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1044"
															id="inlineRadio16"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="44"
															checked={checkedQues44ConcYes}
															data-concentType="concent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio16">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1044"
															id="inlineRadio17"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="44"
															checked={checkedQues44ConcNo}
															data-concentType="concent"

														/>
														<label className="form-check-label " htmlFor="inlineRadio17">No</label>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1144"
															id="inlineRadio17"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="44"
															checked={checkedQues44NonConcYes}
															data-concentType="nonconcent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio17">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1144"
															id="inlineRadio18"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="44"
															checked={checkedQues44NonConcNo}
															data-concentType="nonconcent"
														/>
														<label className="form-check-label " htmlFor="inlineRadio18">No</label>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>Your partner shared intimate photos of you with friends   </td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1045"
															id="inlineRadio16"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="45"
															checked={checkedQues45ConcYes}
															data-concentType="concent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio16">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1045"
															id="inlineRadio17"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="45"
															checked={checkedQues45ConcNo}
															data-concentType="concent"

														/>
														<label className="form-check-label " htmlFor="inlineRadio17">No</label>
													</div>
												</div>
											</td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1145"
															id="inlineRadio17"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="45"
															checked={checkedQues45NonConcYes}
															data-concentType="nonconcent"

														/>
														<label className="form-check-label" htmlFor="inlineRadio17">Yes</label>
													</div>
													<div className="form-check form-check-inline">
														<input className="form-check-input custom-check" type="radio"
															name="inlineRadioOptions1145"
															id="inlineRadio18"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="45"
															checked={checkedQues45NonConcNo}
															data-concentType="nonconcent"
														/>
														<label className="form-check-label " htmlFor="inlineRadio18">No</label>
													</div>
												</div>
											</td>
										</tr>

									</tbody>
								</table>
							</div>








							{/* <div
                className="col-lg-10 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="100%"
                    draggable="false"
                    src="/assets/img/md5/chap02-2/4.jpg"
                    alt=""
                  />
                </center>
              </div>

                */}



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-04" />
								{/* <NextBtn link="/module-05/chapter-04-screen-06" text="Go Next"/> */}
								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen05;
