import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";

const Chapter04Screen09 = () => {

  const navigate = useNavigate();


  const [checkedQues2Option1, setCheckedQues2Option1] = useState('');
  const [checkedQues2Option2, setCheckedQues2Option2] = useState('');

  const [checkedQues2Val, setCheckedQues2Val] = useState('');

  const [checkedQues3Option1, setCheckedQues3Option1] = useState('');
  const [checkedQues3Option2, setCheckedQues3Option2] = useState('');
  const [checkedQues3Option3, setCheckedQues3Option3] = useState('');
  const [checkedQues3Option4, setCheckedQues3Option4] = useState('');

  const [checkedQues3Val, setCheckedQues3Val] = useState('');

 

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    // alert(quesId);
    checkedVal(quesId,e.target.value)
  }
 
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans":  "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": checkedQues2Val,         
          "ques_id": "2"
        },
        "ques3": {
          "ans": checkedQues3Val,         
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans":"",
          "ques_id": "6"
        },
        "ques7": {
          "ans":"",
          "ques_id": "7"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/HumanRightsFinalQuiz',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-03/chapter-04-screen-10')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues2Val !== '' && checkedQues3Val !== '') {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {

    if (param === '2') {
      if (ans === 'option1') {
        setCheckedQues2Option1('checked')
        setCheckedQues2Option2('');
       
       
      } else if (ans === 'option2') {
        setCheckedQues2Option1('')
        setCheckedQues2Option2('checked');
       
       
        }
        setCheckedQues2Val(ans)
    }


    if (param === '3') {
      if (ans === 'option1') {
        setCheckedQues3Option1('checked')
        setCheckedQues3Option2('');
        setCheckedQues3Option3('');
        setCheckedQues3Option4('');
       
      } else if (ans === 'option2') {
        setCheckedQues3Option1('')
        setCheckedQues3Option2('checked');
        setCheckedQues3Option3('')
        setCheckedQues3Option4('')
       
        }else if (ans === 'option3') {
          setCheckedQues3Option1('')
          setCheckedQues3Option2('');
          setCheckedQues3Option3('checked')
          setCheckedQues3Option4('')
         
          }else if (ans === 'option4') {
            setCheckedQues3Option1('')
            setCheckedQues3Option2('');
            setCheckedQues3Option3('')
            setCheckedQues3Option4('checked')
           
            }
        setCheckedQues3Val(ans)
    }

   

    
  }

  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/HumanRightsFinalQuizResult',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
 



  
  return (
    <>
      <div className="wrapper md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="3/4" percentage="75" star="36" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">

          <div className="row align-items-top justify-content-between pt-20 mt-2">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <h4 className="mb-2 mt-3 pn"> <span> <b>Q2.</b></span> <b>Select the correct definition for the word mentioned below. What does the term  Inalienable mean?</b></h4> 
                <div className="d-flex ">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio" name="namecircle2" id="ns1"
                      defaultValue="option1" 
                     onClick={saveRadio}
                         checked={checkedQues2Option1}
                         data-quesId="2" 
                      />
                    <label className="form-check-label" htmlFor="ns1">States assume duties under international law to respect, to protect and to fulfil human rights. This would include States respecting and refrain from interfering with or curtailing the enjoyment of human rights, States protect individuals and groups against human rights abuses and States must take positive action to facilitate the enjoyment of basic human rights. At the individual level, while we are entitled to our human rights, we must also respect the human rights of others.</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio" name="namecircle2" id="ns21" 
                     defaultValue="option2" 
                     onClick={saveRadio}
                         checked={checkedQues2Option2}
                         data-quesId="2" 
                     />
                    <label className="form-check-label" htmlFor="ns21">Rights and freedoms cannot be challenged or taken away from a person and each person is born with the same rights </label>
                  </div>
                </div>
                



                <h4 className="mb-2 mt-3 pn"> <span> <b>Q3.</b></span> <b> Which of the following statistics are incorrect?</b></h4> 
                <div className="d-flex ">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio" name="namecircle12" id="ns51" 
                    defaultValue="option1"
   
                     onClick={saveRadio}
                         checked={checkedQues3Option1}
                         data-quesId="3" 

                     />
                    <label className="form-check-label" htmlFor="ns51">1 in 3 urban residents live in slums in developing countries.</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio" name="namecircle12" id="ns61" 
                    
                    defaultValue="option2"
   
                    onClick={saveRadio}
                    checked={checkedQues3Option2}
                    data-quesId="3" 
                     />
                    <label className="form-check-label" htmlFor="ns61">An estimated 617 million children and adolescents around the world are unable to reach minimum proficiency levels in reading and mathematics, even though two thirds of them are in school. </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio" name="namecircle12" id="ns31" 
                    defaultValue="option3" 
                          
                   
                      onClick={saveRadio}
                    checked={checkedQues3Option3}
                    data-quesId="3" 
                    />
                    <label className="form-check-label" htmlFor="ns31">The wealth of the 10 richest men has doubled, while the incomes of 99% of humanity are worse off, because of COVID-19   </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio" name="namecircle12" id="ns41" 
                    defaultValue="option4"
                   
   
                      onClick={saveRadio}
                    checked={checkedQues3Option4}
                      data-quesId="3" 
                     />
                    <label className="form-check-label" htmlFor="ns41">The 10 richest men in the world own more than the bottom 1 billion people </label>
                  </div>
                </div>





              </div>
          

            </div>
         

          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-03/chapter-04-screen-08"/>                  
                
                {/* <NextBtn link="/module-03/chapter-04-screen-10" text="Go Next"/> */}

                <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter04Screen09;
