import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen13 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-3">
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3">Learn: </h3>
								<p className="mb-2  mt-3">Disrespectful, toxic relationships are based on power and control, not equality and respect. Learning to draw boundaries asserting our values is important for our well-being in all relationships. By setting boundaries, partners in intimate relationships have a deeper understanding of each other and the type of relationship they can have, what makes each partner comfortable and what they would like/not like to happen within the relationship.  </p>
								<p className="mb-2  mt-3">The personal boundaries circle below elaborates on this.  </p>




							</div>

							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/chap02-3/154.svg"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-12" />
								<NextBtn link="/module-05/chapter-03-screen-15" text="Go Next" />

							</div>
						</div>
					</div>
				</div>
			</div>





		</>
	);
};

export default Chapter03Screen13;
