import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen02 = () => {
  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"
        
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="1/4" percentage="43" star="50" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between pt-20 mt-2 mt-m-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
            

              <p className="mb-2 mt-2 text-center">Breakthrough intends to bring change through deep engagement with young adults while simultaneously creating an enabling environment around them so they can act for change. This involves working with the community and the larger ecosystem. </p>
              {/* <p className="mb-2 mt-2 red"> <i>Design Instruction: This section needs to be designed in an interactive way</i></p> */}

            <div className="row align-items-center justify-content-center">
              <div className="col-lg-6  ">
                <div className="para-color-box p-4">
                  <p className="mb-2 mt-2"> <b>As a result </b></p>
                  <ul class=" without-list list-circle">
                    <li><i>Young Adults will transform self, influence peers, build healthy relationships and drive public action</i> </li>
                    <li><i>By impacting social norms that perpetrate violence</i> </li>
                    <li><i>Reduce violence & discrimination against women & girls</i> </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-center mt-2">
              <div className="col-lg-6  ">
                <div className="para-color-box p-4">
                  <p className="mb-2 mt-2"> <b>Deep engagement with young adults </b></p>
                  <ul class="without-list list-circle ">
                    <li>Young Adults believe in their ability to make a difference</li>
                    <li>Young Adults understand their role in perpetuating the same</li>
                    <li>Young Adults are sensitised and empowered to recognise discrimination and violence against women and girls </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-6  ">
                <div className="para-color-box p-4">
                    <p className="mb-2 mt-2"> <b>Enabling environment </b></p>
                    <ul class="without-list list-circle ">
                      <li>A movement is created around the issue to drive momentum</li>
                      <li>Legitimacy of actions established</li>
                      <li>Stakeholders in immediate environment sensitised on GBV and GBD</li>
                    </ul>
                </div>
              </div>
            </div>




              </div>
             
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
               
                <PrevBtn link="/module-06/chapter-02-screen-03"/>  
                <NextBtn link="/module-06/chapter-02-screen-05" text="Go Next"/>
                 

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen02;
