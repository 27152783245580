import React from "react";
import { NavLink } from "react-router-dom";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Header from "../../../header/Header";

const Chapter04Screen05N = () => {
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="68" star="46" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h2 className="h2">Learn</h2>

								<p> Earlier in this chapter we spoke about how the
									media is responsible for perpetuating and continuing the
									regressive norms and practices of Patriarchy in our society,
									however, as we saw in this clip, the media can also break the
									narrative of Patriarchal norms and culture and promote
									equality. Pop culture which is propagated through mass media
									has a huge influence in society. Therefore, it is imperative
									that it shows progressive content promoting gender equality
									and inclusivity.
								</p>
							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="50%"
										src="/assets/img/md1/md1-shap-4-5n.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-04-screen-05" />
								<NextBtn
									link="/module-01/chapter-04-screen-06"
									text="Go Next"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen05N;
