import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
const Chapter04Screen13 = () => {
  const navigate = useNavigate();
  const [checkedQues5Argue, setCheckedQues5Argue] = useState('');
  const [checkedQues5Empathise, setCheckedQues5Empathise] = useState('');
  const [checkedQues5Challenge, setCheckedQues5Challenge] = useState('');
  const [checkedQues5Manipulate, setCheckedQues5Manipulate] = useState('');
  const [checkedQues5Val, setCheckedQues5Val] = useState('');
  const [checkedQues6, setCheckedQues6] = useState([]);
  const [option1Checked, setOption1Checked] = useState('');
  const [option2Checked, setOption2Checked] = useState('');
  const [option3Checked, setOption3Checked] = useState('');
  const [option4Checked, setOption4Checked] = useState('');
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    // alert(quesId);
    checkedVal(quesId,e.target.value)
  }
  const saveGenderBased = (e) => {
    const res = e.target.value;
    const checked = e.target.checked;
    checkBoxChecked(res,checked);
    setCheckedQues6(checkedQues1 => [...checkedQues6, res]);
  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",         
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",         
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": checkedQues5Val,
          "ques_id": "5"
        },
        "ques6": {
          "ans": checkedQues6.join(','),
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        }
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/SelfFinalQuiz',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-02/chapter-04-screen-14')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues6.length > 0 && checkedQues5Val !== '') {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {
    if (param === '6') {
      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked);
      })
      setCheckedQues6(ansArr)
    }
    if (param === '5') {
      if (ans === 'Argue with') {
        setCheckedQues5Argue('checked')
        setCheckedQues5Empathise('');
        setCheckedQues5Challenge('')
        setCheckedQues5Manipulate('')
      } else if (ans === 'Empathise with') {
        setCheckedQues5Argue('')
        setCheckedQues5Empathise('checked');
        setCheckedQues5Challenge('')
        setCheckedQues5Manipulate('')
        }else if (ans === 'Challenge') {
          setCheckedQues5Argue('')
        setCheckedQues5Empathise('');
        setCheckedQues5Challenge('checked')
        setCheckedQues5Manipulate('')
          }else if (ans === 'Manipulate') {
            setCheckedQues5Argue('')
            setCheckedQues5Empathise('');
            setCheckedQues5Challenge('')
            setCheckedQues5Manipulate('checked')
            }
        setCheckedQues5Val(ans)
    }
  }
  const checkBoxChecked = (val,checked)=>{
    if(val === 'option1')
    {
      if(checked === true)
      {
        setOption1Checked('checked');
      }else{
        setOption1Checked('');
      }
    }
    if(val === 'option2')
    {
      if(checked === true)
      {
        setOption2Checked('checked');
      }else{
        setOption2Checked('');
      }
    }
    if(val === 'option3')
    {
      if(checked === true)
      {
        setOption3Checked('checked');
      }else{
        setOption3Checked('');
      }
    }
    if(val === 'option4')
    {
      if(checked === true)
      {
        setOption4Checked('checked');
      }else{
        setOption4Checked('');
      }
    }
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfFinalQuizResult',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="3/4" percentage="95" star="92" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between pt-20 ">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <p className="mb-2 mt-3 pn">
                  <span>
                    <b>5.</b>
                  </span>{" "}
                  <b>
                    Understanding the values and feeling behind a decision helps
                    to________the person
                  </b>{" "}
                </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="namecircle2"
                      id="mycirle5"
                      defaultValue="Argue with"
                      onClick={saveRadio}
                      data-quesId="5"
                      checked={checkedQues5Argue}
                    />
                    <label className="form-check-label" htmlFor="mycirle5">
                      Argue with
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="namecircle2"
                      id="mycirle6"
                      defaultValue="Empathise with"
                      onClick={saveRadio}
                      data-quesId="5"
                      checked={checkedQues5Empathise}
                    />
                    <label className="form-check-label" htmlFor="mycirle6">
                      Empathise with
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="namecircle2"
                      id="mycirle7"
                      defaultValue="Challenge"
                      onClick={saveRadio}
                      data-quesId="5"
                      checked={checkedQues5Challenge}
                    />
                    <label className="form-check-label" htmlFor="mycirle7">
                      Challenge
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="namecircle2"
                      id="mycirle8"
                      defaultValue="Manipulate"
                      onClick={saveRadio}
                      data-quesId="5"
                      checked={checkedQues5Manipulate}
                    />
                    <label className="form-check-label" htmlFor="mycirle8">
                      Manipulate
                    </label>
                  </div>
                </div>
                <p className="mb-2 mt-3 pn">
                  <span>
                    <b>6.</b>
                  </span>{" "}
                  <b> Which of the following statements are true</b>{" "}
                </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle1"
                       defaultValue="option1"
                      onClick={saveGenderBased}
                      checked={option1Checked}
                    />
                    <label className="form-check-label" htmlFor="mycirle1">
                      Effective communication requires one to demand what they
                      want from the other person
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle2"
                      defaultValue="option2"
                      onClick={saveGenderBased}
                      checked={option2Checked}
                    />
                    <label className="form-check-label" htmlFor="mycirle2">
                      Values, feelings and needs are not important when it comes
                      to effective communication.
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle3"
                      defaultValue="option3"
                      onClick={saveGenderBased}
                      checked={option3Checked}
                    />
                    <label className="form-check-label" htmlFor="mycirle3">
                      When communication reflects judgement and criticism
                      towards the other person they will most likely be
                      defensive and not receptive.
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle4"
                      defaultValue="option4"
                      onClick={saveGenderBased}
                      checked={option4Checked}
                    />
                    <label className="form-check-label" htmlFor="mycirle4">
                      Effective communication is based on facts and involves
                      sharing your feelings, your need or value and requesting
                      for an action.{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                  <PrevBtn link="/module-02/chapter-04-screen-12" />
                  {/* <NextBtn
                    link="/module-02/chapter-04-screen-14"
                    text="Go Next"
                  /> */}
                  <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Chapter04Screen13;
