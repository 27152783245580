import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";

const Chapter02Screen08 = () => {
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/5" percentage="40" star="20" />
					</div>
				</div>
				<div className="data-adjust">
					<div
						className="container-fluid cf-set wow fadeInDown"
						data-wow-duration="1s"
						data-wow-delay="0.1s"
					>
						<div className="row align-items-top justify-content-between mt-4 pt-20">

							<div className="col-lg-9">
								<h2 className="h2 mb-1">Additional Resources </h2>
								<a
									href="https://www.youtube.com/watch?v=LdEAz3mjaSw"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p> Gender Roles in Society</p>
										</div>
									</div>
								</a>
								<a
									href="https://www.youtube.com/watch?v=R_yx_SC3l9g"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Cycle of Socialization, Gender</p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2021/08/23/family-personal-space-women-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Film juice and write up on it</p>
										</div>
									</div>
								</a>

								<a
									href="https://www.youtube.com/watch?v=KBPynp-jBvk&list=PLuOmNjZTlhxRYaT_pdRnEAd73BAjR7M2v&index=4"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>#GrownUpGirls: Parvati- reimagine Work</p>
										</div>
									</div>
								</a>

								<a
									href="https://www.youtube.com/watch?v=hzK5_ogMOg8"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>10 Questions You Always Wanted to Ask a Gender Fluid Person</p>
										</div>
									</div>
								</a>

								<a
									href="https://feminisminindia.com/2022/06/13/what-are-gender-pronouns-and-why-should-we-use-them-accurately/ "
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>What Are Gender Pronouns And Why Should We Use Them Accurately?|Feminism In India</p>
										</div>
									</div>
								</a>

								<div className="d-flex align-items-top justify-content-start mt-2"></div>
							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.1s"
							>
								<center>
									<img
										width="80%"
										draggable="false"
										src="/assets/img/md1/md1-shap-2-8.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-02-screen-07" />

								<NextBtn
									link="/module-01/chapter-03-screen-01"
									text="Go Next"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen08;
