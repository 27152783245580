import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter05Screen06 = () => {
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="86" star="66" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
								style={{
									visibility: "visible",
									animationDuration: "1s",
									animationDelay: "0.2s",
									animationName: "fadeInDown",
								}}
							>
								<p className="mb-4">
									Payal was soon married to Sunil, a truck driver, and went to
									live with his family. Within four months she was pregnant. By
									the time she was 20, she had three daughters. She was always
									tired, her health was poor, and she often felt isolated and
									depressed. Though she couldn’t read, she had heard about
									family planning and suggested to her husband that they
									consider it so she could have a rest. Sunil became furious and
									beat her. He pointed out that she had not yet provided him
									with a son and that family planning was unnatural, anyway. His
									mother agreed. Payal, feeling that she had been appropriately
									reprimanded for her bold and presumptuous behaviour, did not
									bring up the subject again. Payal’s health continued to
									deteriorate. She was treated several times at the health
									clinic for itchiness and discharge in her genital area. Each
									time, the nurses at the clinic told her she should use condoms
									to prevent this sickness. They would become quite annoyed that
									she had not used them. But Payal knew that Sunil would refuse
									them. Payal’s fourth child was a son and Sunil was very
									pleased. He looked forward to his second and third sons.
									Meanwhile, Payal became sadder and more tired.
								</p>

							</div>
							<div className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s">
								<center>
									<img width="53%" src="/assets/img/md1/md1-shap-5-07n.png" alt="" />
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className="d-flex align-items-top justify-content-end mt-4">
								<PrevBtn link="/module-01/chapter-05-screen-05" />
								<NextBtn
									link="/module-01/chapter-05-screen-07"
									text="Go Next"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen06;
