import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate, NavLink } from "react-router-dom";
const Chapter04Screen20 = () => {

	const navigate = useNavigate();
	const [checkedQues49, setCheckedQues49] = useState([]);
	const [checkedQues49Option1, setCheckedQues49Option1] = useState('');
	const [checkedQues49Option2, setCheckedQues49Option2] = useState('');
	const [checkedQues49Option3, setCheckedQues49Option3] = useState('');
	const [checkedQues49Option4, setCheckedQues49Option4] = useState('');
	const [checkedQues49Option5, setCheckedQues49Option5] = useState('');




	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);
		checkedVal(quesId, e.target.value)
	}

	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;
		checkBoxChecked(res, checked, quesId);
		if (quesId === '49') {
			setCheckedQues49(checkedQues49 => [...checkedQues49, res]);
		}



	}

	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques49": {
					"ans": checkedQues49.join(','),
					"ques_id": "49"
				},



			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-05/chapter-04-screen-22')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	const checkedVal = (param, ans) => {
		if (param === '49') {

			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues49(ansArr)
		}




	}
	const checkBoxChecked = (val, checked, quesId) => {
		if (quesId === '49') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues49Option1('checked');
				} else {
					setCheckedQues49Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues49Option2('checked');
				} else {
					setCheckedQues49Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues49Option3('checked');
				} else {
					setCheckedQues49Option3('');
				}
			}

			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues49Option4('checked');
				} else {
					setCheckedQues49Option4('');
				}
			}

			if (val === 'option5') {
				if (checked === true) {
					setCheckedQues49Option5('checked');
				} else {
					setCheckedQues49Option5('');
				}
			}


		}




	}

	const BtnDisabled = () => {
		if (checkedQues49.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button className="button_su_inner" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header">

					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-0">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3">Learn  </h3>
								<p className="mb-2 mt-3">We are now in the final part of this chapter. Think back to when we discussed abuse, assault, power etc. for example, the case of Vicky’s parents, the video on consent and the story of Rita and her boss - what are some rights of individuals that are violated when they are abused? Pick as many as apply! </p>
								<p className="mb-2 mt-3">What is violated?  </p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle2"
											defaultValue="option1"
											onClick={saveGenderBased}
											data-quesId="49"
											checked={checkedQues49Option1}
										/>
										<label className="form-check-label" htmlFor="mycirle2">	The right to say no. </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="checkbox" name="namecircle"
											id="mycirle3"
											defaultValue="option2"
											onClick={saveGenderBased}
											data-quesId="49"
											checked={checkedQues49Option2}
										/>
										<label className="form-check-label" htmlFor="mycirle3">	The right to one’s body. </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle4"
											defaultValue="option3"
											onClick={saveGenderBased}
											data-quesId="49"
											checked={checkedQues49Option3}

										/>
										<label className="form-check-label" htmlFor="mycirle4">	The right to control one’s sexual experience </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="checkbox" name="namecircle"
											id="mycirle5"
											defaultValue="option4"
											onClick={saveGenderBased}
											data-quesId="49"
											checked={checkedQues49Option4}
										/>
										<label className="form-check-label" htmlFor="mycirle5">	The right to state one’s sexual limits </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="checkbox" name="namecircle"
											id="mycirle6"
											defaultValue="option5"
											onClick={saveGenderBased}
											data-quesId="49"
											checked={checkedQues49Option5}

										/>
										<label className="form-check-label" htmlFor="mycirle6">	The right to say yes  </label>
									</div>
								</div>

								<p className="mb-2 mt-3">All of us have some universal sexual rights. Safer sex practices, reduced violence and coercion, better sexual health – all of this is possible to achieve in an environment where our sexual rights are recognised and respected.  </p>

								<p className="mb-2 mt-3">While sexual rights should be equal for everyone, this hardly happens in reality. We have seen examples of this throughout the module.  Those that conform to society’s ideas of sexual behaviour and identity gain acceptance and freedom. These attitudes become a part of laws and institutions. LGBTQ+ persons, women, people with disabilities – are not protected equally by law or given equal rights. It is all our responsibility to not just stand for our own rights, but also those around us. </p>

							</div>
							<div
								className="col-lg-2 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-15.png"
										alt=""
									/>
								</center>
							</div>



						</div>

						
					</div>
					
				</div>

				
			</div>

			
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-19" />
								{/* <NextBtn link="/module-05/chapter-04-screen-21" text="Go Next"/> */}

								{/* <button className="button_su">
                  <span className="su_button_circle desplode-circle">
                  </span>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </a>
                </button> */}

								<BtnDisabled />



							</div>
						</div>
					</div>
				</div>
			</div>







			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>
							<p className="text-white text-center">A list of sexual rights and their meanings are in the additional resources at the end of the chapter</p>


							<div className=" d-flex justify-content-center mt-3">
								<button className="button_su m-auto" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<button onClick={saveAns} className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </button>
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter04Screen20;
