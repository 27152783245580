import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter04Screen07 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<div className="chat-area">
									<div className="left-chat">
										<span>A</span>
										<p>You were looking so good today! Sab bol rahe the class mein! </p>
									</div>
									<div className="left-chat right-chat justify-content-end">
										<p>Haha thanks!  </p>
										<span>D</span>
									</div>

									<div className="left-chat">
										<span>A</span>
										<p>Abhi kya pehna hai tumne?  </p>
									</div>
									<div className="left-chat right-chat justify-content-end">
										<p>Haha kyu pooch rahe ho?  </p>
										<span>D</span>
									</div>

									<div className="left-chat">
										<span>A</span>
										<p>Batao na, why are you being shy?   </p>
									</div>
									<div className="left-chat right-chat justify-content-end">
										<p>Bas pajamas. I’m about to go to bed.  </p>
										<span>D</span>
									</div>

									<div className="left-chat">
										<span>A</span>
										<p>arre, use pehle send me a photo na. Without clothes. You are so beautiful.  </p>
									</div>
									<div className="left-chat right-chat justify-content-end">
										<p>Ummm, No! Yeh kaha se aa gaya?  </p>
										<span>D</span>
									</div>

									<div className="left-chat">
										<span>A</span>
										<p>Kyu? Do you not trust me? Tumse toh pooch sakta hoon na, you are my girlfriend.  </p>
									</div>


								</div>


							</div>

							<div
								className="col-lg-5 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="47%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-4-7.png"
										alt=""
									/>
								</center>

								<p className="mb-2 mt-3 text-center">Akshay keeps telling her to send him pictures and they have a fight at the end of the night.</p>



							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-06" />
								<NextBtn link="/module-05/chapter-04-screen-08" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>


		</>
	);
};

export default Chapter04Screen07;
