import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter04Screen08 = () => {

	const navigate = useNavigate();
	const [checkedQues13Yes, setCheckedQues13Yes] = useState('');
	const [checkedQues13No, setCheckedQues13No] = useState('');
	const [checkedQues13Val, setCheckedQues13Val] = useState('');

	const [checkedQues14Yes, setCheckedQues14Yes] = useState('');
	const [checkedQues14No, setCheckedQues14No] = useState('');
	const [checkedQues14Val, setCheckedQues14Val] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);
		checkedVal(quesId, e.target.value)
	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": checkedQues13Val,
					"ques_id": "13"
				},
				"ques14": {
					"ans": checkedQues14Val,
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-02/chapter-04-screen-09')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const checkedVal = (param, ans) => {
		if (param === '13') {
			if (ans === 'yes') {
				setCheckedQues13Yes('checked')
				setCheckedQues13No('')


			} else if (ans === 'no') {
				setCheckedQues13Yes('')
				setCheckedQues13No('checked')


			}
			setCheckedQues13Val(ans)
		}

		if (param === '14') {
			if (ans === 'yes') {
				setCheckedQues14Yes('checked')
				setCheckedQues14No('')


			} else if (ans === 'no') {
				setCheckedQues14Yes('')
				setCheckedQues14No('checked')


			}
			setCheckedQues14Val(ans)
		}



	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	const BtnDisabled = () => {
		if (checkedQues13Val !== '' && checkedQues14Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper bg-bd-2 ">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="80" star="80" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-1 mt-m-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mt-3">
									In the above table where you have found yourself answering yes
									to statements 1, 2 and 3, do you think you are not/less
									assertive with them?
								</p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="a"
											id="ture"
											defaultValue="yes"
											onClick={saveRadio}
											data-quesId="13"
											checked={checkedQues13Yes}
										/>
										<label className="form-check-label" htmlFor="inlineRadio1">
											Yes
										</label>
									</div>
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="a"
											id="false"
											defaultValue="no"
											onClick={saveRadio}
											data-quesId="13"
											checked={checkedQues13No}
										/>
										<label className="form-check-label " htmlFor="inlineRadio2">
											No
										</label>
									</div>
								</div>
								<p className="mt-3">
									In the above table where you have found yourself answering yes
									to statements 4 and 5, do you think you are assertive with
									them?
								</p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="b"
											id="ture"
											defaultValue="yes"
											onClick={saveRadio}
											data-quesId="14"
											checked={checkedQues14Yes}
										/>
										<label className="form-check-label" htmlFor="inlineRadio1">
											Yes
										</label>
									</div>
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="b"
											id="false"
											defaultValue="no"
											onClick={saveRadio}
											data-quesId="14"
											checked={checkedQues14No}
										/>
										<label className="form-check-label " htmlFor="inlineRadio2">
											No
										</label>
									</div>
								</div>

								<h2 className="h3 mt-3">Learn</h2>
								<p className="mt-3">
									Oftentimes, we are conditioned to ‘respect’ elders in a way
									that we are not allowed to be assertive and share our opinions
									with them be it in family, workplace, place of education or
									public places. They are also responsible for taking decisions
									for our lives oftentimes without our participation in them.
									However, it is important to note that being assertive by
									sharing our thoughts, opinions and being part of decision
									making does not take away from respecting our elders, it is in
									fact the first step to ensuring taking charge of our lives and
									building leadership.{" "}
								</p>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									{/* <img
                    width="80%"
                    draggable="false"
                    src="/assets/img/md2/md2-4-9.png"
                    alt=""
                  /> */}
									<img
										width="80%"
										draggable="false"
										src="/assets/img/md2/md2-shap-4-6.png"
										alt=""
									/>


								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-02/chapter-04-screen-07" />

									{/* <NextBtn link="/module-02/chapter-04-screen-09" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen08;
