import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";

const Chapter01Screen02 = () => {
  const navigate = useNavigate();
  const [checkedQues1_1, setCheckedQues1_1] = useState("");
  const [checkedQues1_2, setCheckedQues1_2] = useState("");
  const [checkedQues1_3, setCheckedQues1_3] = useState("");
  const [checkedQues1_4, setCheckedQues1_4] = useState("");
  const [checkedQues1_5, setCheckedQues1_5] = useState("");

  const user_details = JSON.parse(localStorage.getItem("user_details"));

  const saveAns = () => {
    var data = JSON.stringify({
      user_id: user_details.user_id,
      answers: {
        ques1: {
          ans: checkedQues1_1,
          ans2: checkedQues1_2,
          ans3: checkedQues1_3,
          ans4: checkedQues1_4,
          ans5: checkedQues1_5,

          ques_id: "1",
        },
        ques2: {
          ans: "",
          ques_id: "2",
        },
        ques3: {
          ans: "",
          ques_id: "3",
        },
        ques4: {
          ans: "",
          ques_id: "4",
        },
        ques5: {
          ans: "",
          ques_id: "5",
        },
      },
    });
    var config = {
      method: "post",
      url: BaseURL + "/HumanRightsChapter01",
      headers: {
        Authorization: `Bearer ${user_details.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate("/module-03/chapter-01-screen-03");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_details.user_id,
      ques_id: "",
    });
    var config = {
      method: "post",
      url: BaseURL + "/HumanRightsChapter01Result",
      headers: {
        Authorization: `Bearer ${user_details.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;

          results.map((result) => {
            // console.log(result);
            checkedVal(
              result.ques_id.toString(),
              result.ans,
              result.ans2,
              result.ans3,
              result.ans4,
              result.ans5
            );
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const checkedVal = (param, ans, ans2, ans3, ans4, ans5) => {
    if (param === "1") {
      setCheckedQues1_1(ans);
      setCheckedQues1_2(ans2);
      setCheckedQues1_3(ans3);
      setCheckedQues1_4(ans4);
      setCheckedQues1_5(ans5);
    }
  };

  const BtnDisabled = () => {
    if (
      (checkedQues1_1 !== "" && checkedQues1_2 !== "") ||
      checkedQues1_3 !== "" ||
      checkedQues1_4 !== "" ||
      checkedQues1_5 !== ""
    ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div className="wrapper md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="0/4" percentage="2" star="1" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-4">
              <div
                className="col-lg-8 mt-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h2 className="h3">Watch this video </h2>
                <a
                  href="https://www.youtube.com/watch?v=9kUO0bciLK0"
                  target="_blank"
                >
                  <div className="d-flex align-items-center mange-icon">
                    <div>
                      <img src="/assets/img/chapter2/video-icon.png" alt="" />
                    </div>
                    <div>
                      <p>What are Human Rights?(Hindi)</p>
                    </div>
                  </div>
                </a>
                <h4 className="mt-2">
                  <b>
                    In your opinion what would qualify as basic human rights?
                    Please write a minimum 2 rights, and a maximum of 5 rights.
                  </b>
                </h4>

                <p>
                  Note: For each correct answer, participants should be given a
                  score of +1. Any of the 30 rights mentioned in the Universal
                  Declaration of Human Rights document seen below qualify as
                  correct answers.
                </p>

                <div className="mb-3 mt-3 positon-submit">
                  <textarea
                    className="form-control"
                    rows={1}
                    placeholder="Type your Answer........"
                    onChange={(e) => setCheckedQues1_1(e.target.value)}
                    data-quesId="1"
                    defaultValue={checkedQues1_1}
                  />
                </div>

                <div className="mb-3 positon-submit">
                  <textarea
                    className="form-control"
                    rows={1}
                    placeholder="Type your Answer......."
                    onChange={(e) => setCheckedQues1_2(e.target.value)}
                    data-quesId="1"
                    defaultValue={checkedQues1_2}
                  />
                </div>

                <div className="mb-3 positon-submit">
                  <textarea
                    className="form-control"
                    rows={1}
                    placeholder="Type your Answer........"
                    onChange={(e) => setCheckedQues1_3(e.target.value)}
                    data-quesId="1"
                    defaultValue={checkedQues1_3}
                  />
                </div>

                <div className="mb-3 positon-submit">
                  <textarea
                    className="form-control"
                    rows={1}
                    placeholder="Type your Answer......."
                    onChange={(e) => setCheckedQues1_4(e.target.value)}
                    data-quesId="1"
                    defaultValue={checkedQues1_4}
                  />
                </div>
                <div className="mb-3 positon-submit">
                  <textarea
                    className="form-control"
                    rows={1}
                    placeholder="Type your Answer......."
                    onChange={(e) => setCheckedQues1_5(e.target.value)}
                    data-quesId="1"
                    defaultValue={checkedQues1_5}
                  />
                </div>
              </div>
              <div
                className="col-lg-4 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    className="mt-4"
                    width="80%"
                    src="/assets/img/md3/md3-shap-3-2.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                  <PrevBtn link="/module-03/chapter-01-screen-01" />
                  {/* <NextBtn link="/module-03/chapter-01-screen-03" text="Go Next"/> */}
                  <BtnDisabled />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter01Screen02;
