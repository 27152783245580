import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink, useNavigate } from "react-router-dom";

const Chapter02Screen05 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-3 mt-m-3">
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Learn  </h3>

								<p className="mb-2  mt-3">Typically, cisgender (we covered this term in our module on gender, for referecnce please see the pop-up) and heterosexual identities are privileged and valued in society. Sexualities that challenge societal ideals and patriarchal norms are discouraged and discriminated against. Let’s look at one example. Read these real life experiences to see how schools and teachers limit students’ diverse sexuality expression/ identity.  </p>


								<a href="https://citizenmatters.in/india-city-schools-homophobia-lgbt-discrimination-gender-research-6863" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Labelled, bullied, humiliated: What LGBT students in our schools go through </p>
										</div>
									</div>
								</a>

								{/* 
                <h3 className="h3 mt-2">Share</h3>
                <p className="mb-2 mt-1">Can you think of where your early ideas on sexual orientation came from? What were they? (100W)  </p>
                 */}




							</div>

							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/tipically.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-04" />
								<NextBtn link="/module-05/chapter-02-screen-05n" text="Go Next" />




							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter02Screen05;
