import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";

const Chapter02Screen05 = () => {
	return (
		<>
			<div className="wrapper hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/5" percentage="24" star="11" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2">Engage</h2>
								<p>
									Let’s watch a video to see society's expectations based on
									gender norms and practices and how they react when they are
									not fulfilled or followed
								</p>
								<iframe
									width="90%"
									height="360"
									src="https://www.youtube.com/embed/XJqpsdqb1k0"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>

							</div>

							<div
								className="col-lg-3 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="83%"
										draggable="false"
										src="/assets/img/md1/md1-shap-2-8.png"
										alt=""
									/>
								</center>

							</div>


						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-02-screen-04" />

								<NextBtn
									link="/module-01/chapter-02-screen-05n1"
									text="Go Next"
									classtype=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen05;
