import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter05Screen09 = () => {

	const navigate = useNavigate();



	const [checkedQues10, setCheckedQues10] = useState([]);


	const [economicallyChecked, setEconomicChecked] = useState('');
	const [deriveChecked, setDeriveChecked] = useState('');
	const [backlashChecked, setBacklashChecked] = useState('');
	const [littleChecked, setLittleChecked] = useState('');
	const [childrenChecked, setChildrenChecked] = useState('');
	const [tabooChecked, setTabooChecked] = useState('');
	const [jeopardizingChecked, setJeopardizingChecked] = useState('');

	const [helplessChecked, setHelplessChecked] = useState('');
	const [disciplineChecked, setDisciplineChecked] = useState('');
	const [societyChecked, setSocietyChecked] = useState('');
	const [socializedChecked, setSocializedChecked] = useState('');
	const [believeChecked, setBelieveChecked] = useState('');
	const [emotionallyChecked, setEmotionallyChecked] = useState('');
	const [peersChecked, setPeersChecked] = useState('');
	const [crucialChecked, setCrucialChecked] = useState('');





	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveGenderBased = (e) => {
		const res = e.target.value;
		const checked = e.target.checked;


		checkBoxChecked(res, checked);
		setCheckedQues10(checkedQues10 => [...checkedQues10, res]);
	}



	// console.log(checkedQues5);
	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": checkedQues10.join(','),
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter05',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-05-screen-11')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues10 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	const checkedVal = (param, ans) => {



		if (param === '10') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				console.log(el);
				const checked = true;
				checkBoxChecked(el, checked);
			})
			setCheckedQues10(ansArr)
		}


	}

	const checkBoxChecked = (val, checked) => {
		if (val === '1') {
			if (checked === true) {
				setEconomicChecked('checked');

			} else {
				setEconomicChecked('');
			}
		}

		if (val === '2') {
			if (checked === true) {

				setDeriveChecked('checked');
			} else {
				setDeriveChecked('');
			}
		}

		if (val === '3') {
			if (checked === true) {
				setBacklashChecked('checked');
			} else {
				setBacklashChecked('');
			}
		}


		if (val === '4') {
			if (checked === true) {
				setLittleChecked('checked');
			} else {
				setLittleChecked('');
			}
		}
		if (val === '5') {
			if (checked === true) {
				setChildrenChecked('checked');
			} else {
				setChildrenChecked('');
			}
		}

		if (val === '6') {
			if (checked === true) {
				setTabooChecked('checked');
			} else {
				setTabooChecked('');
			}
		}

		if (val === '7') {
			if (checked === true) {
				setJeopardizingChecked('checked');
			} else {
				setJeopardizingChecked('');
			}
		}

		if (val === '8') {
			if (checked === true) {
				setHelplessChecked('checked');
			} else {
				setHelplessChecked('');
			}
		}

		if (val === '9') {
			if (checked === true) {
				setDisciplineChecked('checked');
			} else {
				setDisciplineChecked('');
			}
		}
		if (val === '10') {
			if (checked === true) {
				setSocietyChecked('checked');
			} else {
				setSocietyChecked('');
			}
		}
		if (val === '11') {
			if (checked === true) {
				setSocializedChecked('checked');
			} else {
				setSocializedChecked('');
			}
		}
		if (val === '12') {
			if (checked === true) {
				setBelieveChecked('checked');
			} else {
				setBelieveChecked('');
			}
		}
		if (val === '13') {
			if (checked === true) {
				setEmotionallyChecked('checked');
			} else {
				setEmotionallyChecked('');
			}
		}
		if (val === '14') {
			if (checked === true) {
				setPeersChecked('checked');
			} else {
				setPeersChecked('');
			}
		}
		if (val === '15') {
			if (checked === true) {
				setCrucialChecked('checked');
			} else {
				setCrucialChecked('');
			}
		}







	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter05Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="91" star="72" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-2">
									<b>
										Why don’t Payal and other women like her raise their voice
										against violence?
									</b>
								</p>
								<p className="mb-2">Choose as many as you think apply </p>
								<div className="checkbox-design">
									<form>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle1"
													defaultValue="1"
													onClick={saveGenderBased}
													checked={economicallyChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle1">
													They are economically dependent
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle2"
													defaultValue="2"
													onClick={saveGenderBased}
													checked={deriveChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle2">
													They derive their position in society through marriage
													and therefore, don’t want to sabotage it
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle3"
													defaultValue="3"
													onClick={saveGenderBased}
													checked={backlashChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle3">
													They fear backlash in the form of assault, alienation,
													mistreatment, financial implication etc.
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle4"
													defaultValue="4"
													onClick={saveGenderBased}
													checked={littleChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle4"> They will get little or no support from their family if they choose to raise their voice against violence
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle5"
													defaultValue="5"
													onClick={saveGenderBased}
													checked={childrenChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle5">
													They have children together so they fear being separated
													from their children
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle6"
													defaultValue="6"
													onClick={saveGenderBased}
													checked={tabooChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle6">
													It is a taboo to publicly speak about abuse and violence
													at home
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle7"
													defaultValue="7"
													onClick={saveGenderBased}
													checked={jeopardizingChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle7">
													They will be jeopardizing their family’s position in
													society
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"


													defaultValue="8"
													onClick={saveGenderBased}
													checked={helplessChecked}

												/>
												<label className="form-check-label" htmlFor="mycirle1">
													They have lower self-esteem and feel helpless
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													defaultValue="9"
													onClick={saveGenderBased}
													checked={disciplineChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle2">
													Society teaches them to view violence as a form of
													discipline
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													defaultValue="10"
													onClick={saveGenderBased}
													checked={societyChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle3">
													Society teaches them to view violence as a form of
													discipline
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													defaultValue="11"
													onClick={saveGenderBased}
													checked={socializedChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle4">
													They are socialized to believe that violence at home is
													“normal”
												</label>
											</div>
										</div>


										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													defaultValue="12"
													onClick={saveGenderBased}
													checked={believeChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle5">
													They believe that as women they “deserve” to be abused
													by their male partners as they are the “weaker sex”
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													defaultValue="13"
													onClick={saveGenderBased}
													checked={emotionallyChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle6">
													They are emotionally attached to their violent partners
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													defaultValue="14"
													onClick={saveGenderBased}
													checked={peersChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle7">
													They fear being ex-communicated by their peers
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													defaultValue="15"
													onClick={saveGenderBased}
													checked={crucialChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle8">
													Men play a crucial role in women’s lives and often
													decide their fate
												</label>
											</div>
										</div>



									</form>
								</div>
							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="55%"
										src="/assets/img/md1/md1-shap-2-4.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-05-screen-08" />
								{/* <NextBtn
                    link="/module-01/chapter-05-screen-10"
                    text="Go Next"
                  /> */}

								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen09;
