import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter04Screen09 = () => {
	const navigate = useNavigate();
	// const [checkedQues12Option1, setCheckedQues12Option1] = useState('');
	// const [checkedQues12Option2, setCheckedQues12Option2] = useState('');
	// const [checkedQues12Option3, setCheckedQues12Option3] = useState('');
	// const [checkedQues12Option4, setCheckedQues12Option4] = useState('');
	// const [checkedQues12Option5, setCheckedQues12Option5] = useState('');
	// const [checkedQues12Val, setCheckedQues12Val] = useState('');

	const [checkedQues12, setCheckedQues12] = useState([]);
	const [option1Checked, setOption1Checked] = useState('');
	const [option2Checked, setOption2Checked] = useState('');
	const [option3Checked, setOption3Checked] = useState('');
	const [option4Checked, setOption4Checked] = useState('');
	const [option5Checked, setOption5Checked] = useState('');

	const saveGenderBased = (e) => {
		const res = e.target.value;
		const checked = e.target.checked;
		checkBoxChecked(res, checked);
		setCheckedQues12(checkedQues12 => [...checkedQues12, res]);
	}




	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		checkedVal(quesId, e.target.value)
	}

	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": checkedQues12.join(','),
					"ques_id": "12"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-04/chapter-04-screen-10')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues12.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {

		if (param === '12') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked);
			})
			setCheckedQues12(ansArr)
		}

		// if (param === '12') {
		//   // alert(ans);
		//   if (ans === 'option1') {
		//     setCheckedQues12Option1('checked')
		//     setCheckedQues12Option2('');
		//     setCheckedQues12Option3('')
		//     setCheckedQues12Option4('')
		//     setCheckedQues12Option5('')
		//   } else if (ans === 'option2') {
		//     setCheckedQues12Option1('')
		//     setCheckedQues12Option2('checked');
		//     setCheckedQues12Option3('')
		//     setCheckedQues12Option4('')
		//     setCheckedQues12Option5('')
		//     }else if (ans === 'option3') {
		//       setCheckedQues12Option1('')
		//       setCheckedQues12Option2('');
		//       setCheckedQues12Option3('checked')
		//       setCheckedQues12Option4('')
		//       setCheckedQues12Option5('')
		//       }else if (ans === 'option4') {
		//         setCheckedQues12Option1('')
		//         setCheckedQues12Option2('');
		//         setCheckedQues12Option3('')
		//         setCheckedQues12Option4('checked')
		//         setCheckedQues12Option5('')
		//         }else if (ans === 'option5') {
		//           setCheckedQues12Option1('')
		//           setCheckedQues12Option2('');
		//           setCheckedQues12Option3('')
		//           setCheckedQues12Option4('')
		//           setCheckedQues12Option5('checked')
		//           }
		//     setCheckedQues12Val(ans)
		// }


	}


	const checkBoxChecked = (val, checked) => {
		if (val === 'option1') {
			if (checked === true) {
				setOption1Checked('checked');
			} else {
				setOption1Checked('');
			}
		}
		if (val === 'option2') {
			if (checked === true) {
				setOption2Checked('checked');
			} else {
				setOption2Checked('');
			}
		}
		if (val === 'option3') {
			if (checked === true) {
				setOption3Checked('checked');
			} else {
				setOption3Checked('');
			}
		}
		if (val === 'option4') {
			if (checked === true) {
				setOption4Checked('checked');
			} else {
				setOption4Checked('');
			}
		}

		if (val === 'option5') {
			if (checked === true) {
				setOption5Checked('checked');
			} else {
				setOption5Checked('');
			}
		}

	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="68" star="68" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="pn mb-3">
									{" "}
									<span>
										<b>5.</b>
									</span>{" "}
									<b>
										What could be the possible reasons for Babu being violent to
										his wife and son?
									</b>
								</p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle1"
											defaultValue="option1"
											onClick={saveGenderBased}
											data-quesId="12"
											checked={option1Checked}
										/>
										<label className="form-check-label" htmlFor="mycirle1">
											He treats them as his property{" "}
										</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle2"
											defaultValue="option2"
											onClick={saveGenderBased}
											data-quesId="12"
											checked={option2Checked}
										/>
										<label className="form-check-label" htmlFor="mycirle2">
											He needed to impose his power to feel like a man again{" "}
										</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle3"
											defaultValue="option3"
											onClick={saveGenderBased}
											data-quesId="12"
											checked={option3Checked}
										/>
										<label className="form-check-label" htmlFor="mycirle3">
											He took out his anger of all the other people on them{" "}
										</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check ">
										<input
											className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle4"
											defaultValue="option4"
											onClick={saveGenderBased}
											data-quesId="12"
											checked={option4Checked}
										/>
										<label
											className="form-check-label over-content"
											htmlFor="mycirle4"
										>
											He has power over them due to his gender and his
											relationship of a husband and a father{" "}
										</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirleextra"
											defaultValue="option5"
											onClick={saveGenderBased}
											data-quesId="12"
											checked={option5Checked}
										/>
										<label className="form-check-label" htmlFor="mycirleextra">
											He did not fear consequences
										</label>
									</div>
								</div>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="70%"
										draggable="false"
										src="/assets/img/md4/md4-shap-4-9.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-04-screen-08" />

									{/* <NextBtn link="/module-04/chapter-04-screen-10" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen09;
