import React,{useEffect,useState} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter02Screen02 = () => {
  const navigate = useNavigate();
 

  const [checkedQues7, setCheckedQues7] = useState([]);
  const [checkedQues7Option1, setCheckedQues7Option1] = useState('');
  const [checkedQues7Option2, setCheckedQues7Option2] = useState('');
  const [checkedQues7Option3, setCheckedQues7Option3] = useState('');
  const [checkedQues7Option4, setCheckedQues7Option4] = useState('');

  const [checkedQues8, setCheckedQues8] = useState([]);
  const [checkedQues8Option1, setCheckedQues8Option1] = useState('');
  const [checkedQues8Option2, setCheckedQues8Option2] = useState('');
  const [checkedQues8Option3, setCheckedQues8Option3] = useState('');
  const [checkedQues8Option4, setCheckedQues8Option4] = useState('');
  const [checkedQues8Option5, setCheckedQues8Option5] = useState('');
  const [checkedQues8Option6, setCheckedQues8Option6] = useState('');


  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const saveGenderBased = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    const res = e.target.value;
    const checked = e.target.checked;
    
    checkBoxChecked(res,checked,quesId);

    if(quesId === '7')
    {
      setCheckedQues7(checkedQues7 => [...checkedQues7, res]);

    }

    if(quesId === '8')
    {
      setCheckedQues8(checkedQues8 => [...checkedQues8, res]);

    }

  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
    
        "ques7": {
          "ans":checkedQues7.join(','),
          "ques_id": "7"
        },
        "ques8": {
          "ans":checkedQues8.join(','),
          "ques_id": "8"
        },
      
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/ApproachChapter04',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-06/chapter-04-screen-08')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues7.length > 0 && checkedQues8.length > 0  ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {
    if (param === '7') {
      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues7(ansArr)
    }

    if (param === '8') {
      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues8(ansArr)
    }

   
  }
  const checkBoxChecked = (val,checked,quesId)=>{
    if(quesId === '7')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues7Option1('checked');
        }else{
          setCheckedQues7Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues7Option2('checked');
        }else{
          setCheckedQues7Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues7Option3('checked');
        }else{
          setCheckedQues7Option3('');
        }
      }
      if(val === 'option4')
      {
        if(checked === true)
        {
          setCheckedQues7Option4('checked');
        }else{
          setCheckedQues7Option4('');
        }
      }
    }

    if(quesId === '8')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues8Option1('checked');
        }else{
          setCheckedQues8Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues8Option2('checked');
        }else{
          setCheckedQues8Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues8Option3('checked');
        }else{
          setCheckedQues8Option3('');
        }
      }
      if(val === 'option4')
      {
        if(checked === true)
        {
          setCheckedQues8Option4('checked');
        }else{
          setCheckedQues8Option4('');
        }
      }

      if(val === 'option5')
      {
        if(checked === true)
        {
          setCheckedQues8Option5('checked');
        }else{
          setCheckedQues8Option5('');
        }
      }

      if(val === 'option6')
      {
        if(checked === true)
        {
          setCheckedQues8Option6('checked');
        }else{
          setCheckedQues8Option6('');
        }
      }


    }

   
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/approachChapter04Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])




  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"
        
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="3/4" percentage="88" star="50" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
          <div className="row align-items-top justify-content-between pt-20 mr-3 mt-m-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <h3 className="h3">Final Quiz for the module</h3>

                <p className="mb-2 mt-3 pn"><span><b>1.</b></span><b>When it comes to Breakthrough’s work, which of the following statements are true? Mark them below.</b> </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                     name="namecircle"
                      id="mycirle1" 
                      defaultValue="option1"
                      onClick={saveGenderBased}
                      data-quesId="7"
                      checked={checkedQues7Option1}

                      />
                    <label className="form-check-label" htmlFor="mycirle1"> We are an organisation working to create a cultural shift and make discrimination and violence against girls and women unacceptable.</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" 
                    type="checkbox" 
                    name="namecircle" 
                    id="mycirle2"
                    defaultValue="option2"
                      onClick={saveGenderBased}
                      data-quesId="7"
                      checked={checkedQues7Option2}

                     />
                    <label className="form-check-label" htmlFor="mycirle2"> Breakthrough is currently working in partnership with Maharashtra and Madhya Pradesh government</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" 
                    type="checkbox" 
                    name="namecircle" 
                    id="mycirle3" 
                    defaultValue="option3"
                      onClick={saveGenderBased}
                      data-quesId="7"
                      checked={checkedQues7Option3}
                     />
                    <label className="form-check-label" htmlFor="mycirle3">We speak to our large and diverse audience in a language they can relate to, using mediums they respond to - such as music, new media and popular culture. </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                     name="namecircle" 
                     id="mycirle4" 
                     defaultValue="option4"
                      onClick={saveGenderBased}
                      data-quesId="7"
                      checked={checkedQues7Option4}

                     />
                    <label className="form-check-label" htmlFor="mycirle4">Breakthrough was founded in 1999, this was also when Mann Ke Manjeera music album was released </label>
                  </div>
                </div>



                <p className="mb-2 mt-3 pn"><span><b>2.</b></span><b>Mark the states where Breakthrough is currently not working</b> </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" 
                    name="namecircle2"
                     id="mycirle5"
                     defaultValue="option1"
                      onClick={saveGenderBased}
                      data-quesId="8"
                      checked={checkedQues8Option1}

                      />
                    <label className="form-check-label" htmlFor="mycirle5"> Punjab</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" 
                    type="checkbox" 
                    name="namecircl2" 
                    id="mycirle6"
                    defaultValue="option2"
                      onClick={saveGenderBased}
                      data-quesId="8"
                      checked={checkedQues8Option2}

                     />
                    <label className="form-check-label" htmlFor="mycirle6"> Jharkhand</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" 
                    type="checkbox"
                     name="namecircle2"
                      id="mycirle7"
                      defaultValue="option3"
                      onClick={saveGenderBased}
                      data-quesId="8"
                      checked={checkedQues8Option3}
                        />
                    <label className="form-check-label" htmlFor="mycirle7"> Madhya Pradesh </label>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" 
                    name="namecircle2" 
                    id="mycirle8" 
                    defaultValue="option4"
                      onClick={saveGenderBased}
                      data-quesId="8"
                      checked={checkedQues8Option4}

                    />
                    <label className="form-check-label" htmlFor="mycirle8"> Uttar Pradesh</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                     name="namecircl2"
                      id="mycirle9" 
                      defaultValue="option5"
                      onClick={saveGenderBased}
                      data-quesId="8"
                      checked={checkedQues8Option5}

                      />
                    <label className="form-check-label" htmlFor="mycirle9"> Odisha</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" 
                    name="namecircle2" 
                    id="mycirle10" 
                    defaultValue="option6"
                      onClick={saveGenderBased}
                      data-quesId="8"
                      checked={checkedQues8Option6}
                     />
                    <label className="form-check-label" htmlFor="mycirle10"> Assam</label>
                  </div>
                </div>




              </div>

             
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
               
                <PrevBtn link="/module-06/chapter-04-screen-06"/>  
                {/* <NextBtn link="/module-06/chapter-04-screen-08" text="Go Next"/> */}
                <BtnDisabled/>
                 

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen02;
