import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter01Screen04 = () => {
	return (
		<>
			<div className="wrappe page-auto bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="0" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3"> Sexuality  is a Central Aspect of Our Lives </h3>
								<div className="main-timeline mt-3">
									<div className="timeline">
										<span className="timeline-content">
											<div className="timeline-year">1</div>
											<h3 className="title"> Influenced by the interaction of: </h3>
											<p className="description">
												biological, psychological, social, economic, political, cultural, ethical, legal, historical, religious and spiritual factors.
											</p>
										</span>
									</div>
									<div className="timeline">
										<span className="timeline-content">
											<div className="timeline-year">2</div>
											<h3 className="title">Experienced and expressed in: </h3>
											<p className="description">
												Thoughts, fantasies, desires, beliefs, attitudes, values, behaviours, practices, roles, and relationships
											</p>
										</span>
									</div>
									<div className="timeline">
										<span className="timeline-content">
											<div className="timeline-year">3</div>
											<h3 className="title">Encompasses: sex</h3>
											<p className="description">
												gender identities and roles, sexual orientation, eroticism, pleasure, intimacy reproduction
											</p>
										</span>
									</div>
								</div>

								<p className="mb-2 pb-3 mt-3"> <b>While sexuality can include all of these dimensions, not all of them are always experienced or expressed. Further, these dimensions are dynamic, ever-changing, overlap and interact with each other. </b> </p>


							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="76%"
										draggable="false"
										src="/assets/img/md5/jwd.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-01-screen-03" />
								<NextBtn link="/module-05/chapter-01-screen-05" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen04;
