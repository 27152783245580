import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";


const Chapter01Screen16 = () => {


	return (
		<>
			<div className="wrapper md-5-bg hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="5" star="4" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-center justify-content-center pt-20 mt-5">

							<div className="col-lg-7 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
								<center>
									<img width="100%" src="/assets/img/md3/tree-img.png" alt="" />
								</center>
							</div>
							<div className="col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
								<h3 className="h3 text-center">This is our human rights tree</h3>
							</div>


						</div>
					</div>
				</div>
			</div>


			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-03/chapter-01-screen-15" />

								<NextBtn link="/module-03/chapter-01-screen-17" text="Go Next" />
								{/* <BtnDisabled/> */}

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen16;
