import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter05Screen11 = () => {
	return (
		<>
			<>
				<div className="wrapper bg-bd-2">
					<div className="fixed-header">
						<div className="container-fluid cf-set">
							<Header leftChapter="4/5" percentage="80" star="80" />
						</div>
					</div>
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-start mt-2 ">

								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h2 mb-3">Additional Resources </h2>
									<a href="https://hindi.feminisminindia.com/2020/08/10/20-things-men-can-do-for-gender-equality/" target="_blank">
										<div className="d-flex align-items-center mange-icon">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>
													{" "}
													लैंगिक समानता में विश्वास रखने वाले पुरुषों को करने
													चाहिए ये 20 काम{" "}
												</p>
											</div>
										</div>
									</a>
									<a href="https://hindi.feminisminindia.com/2020/02/27/violent-toxic-masculinity-bullying-society-needs-sensitve-men-hindi/" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>
													{" "}
													'हिंसक मर्दानगी' से घुटते समाज को 'संवेदनशील पुरुषों'
													की ज़रूरत है <br />
													<b>| फेमिनिज़म इन इंडिया</b>{" "}
												</p>
											</div>
										</div>
									</a>
									<a href="https://hindi.feminisminindia.com/2019/07/11/how-men-can-stop-violence-women-hindi/" target="_blank">
										<div className="d-flex align-items-center mange-icon">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>
													महिला हिंसा रोकने में मर्दों की भागीदारी | <br />
													<b>| फेमिनिज़म इन इंडिया</b>{" "}
												</p>
											</div>
										</div>
									</a>
									<a href="https://linktr.ee/whatsamanpodcast" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-2 ">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p> whatsamanpodcast </p>
											</div>
										</div>
									</a>
									{/* <h3 className="mt-4">Reference Material</h3>
                  <a href="https://docs.google.com/document/d/1Ztc0d9CH9xzZufuHP80Py7y8auJ6rtPe/edit#" target="_blank">
                    <div className="d-flex align-items-center mange-icon mt-3">
                      <div>
                        <img src="/assets/img/chapter1/list-icon.png" alt="" />
                      </div>
                      <div>
                        <p> Google Docs </p>
                      </div>
                    </div>
                  </a> */}
								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="65%"
											draggable="false"
											src="/assets/img/md4/md4-shap-5-11.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-05-screen-10" />

									<NextBtn link="/module-04/chapter-05-screen-12" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
};

export default Chapter05Screen11;
