import React,{useState,useRef,useEffect} from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter04Screen13 = () => {

   
  const dragItem = useRef();
  const dragOverItem = useRef();
  const navigate = useNavigate();
  // const [list, setList] = useState(['Item 1','Item 2','Item 3','Item 4','Item 5','Item 6']);
  const [list, setList] = useState([
		{ "id": "1", "image":"/assets/img/md3/tree.png" },
		{ "id": "2","image":"/assets/img/md3/tree2.jpg" },
		{ "id": "3","image":"/assets/img/md3/tree3.jpg" },
		{ "id": "4","image":"/assets/img/md3/tree4.jpg" },
		
	]);

  const [list1, setList1] = useState([
    { "id": "1", "name": "Equity" },
		{ "id": "2", "name": "Justice" },
		{ "id": "3", "name": "Inequality" },
		{ "id": "4", "name": "Equality" },
		
	]);

  const [list2, setList2] = useState([
    { "id": "1", "desc":"Evenly distributed tools and assistance"},
		{ "id": "2", "desc":"Unequal access to opportunities" },
		{ "id": "3","desc":"Fixing the system to offer equal access to tools and opportunities" },
		{ "id": "4", "desc":"Custom tools that identify and address inequality" },
		
	]);



  const [arrangeList,setArrangeList] = useState([]);

  const dragStart = (e, position) => {
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };
 
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };

  const drop = (e) => {
    const copyListItems = [...list1];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setList1(copyListItems);
  };

  // for descripton

  const dragStart1 = (e, position) => {
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };
 
  const dragEnter1 = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };

  const drop1 = (e) => {
    const copyListItems = [...list2];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setList2(copyListItems);
  };


  // setArrangeList(list)
  console.log(list);

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveAns = () => {
    // alert();
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",         
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",       
                
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",       
       
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": JSON.stringify(list),
          "ans2": JSON.stringify(list1),
          "ans3": JSON.stringify(list2),
          "ques_id": "9"
        },
        "ques10": {
          "ans": "",
          "ques_id": "10"
        },
        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/HumanRightsFinalQuiz',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-03/chapter-04-screen-14')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
	
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			
			"ques_id": "9"
		});

		var config = {
			method: 'post',
			url: BaseURL+'/HumanRightsFinalQuizResult',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
        if(response.data.success === true)
				{
          // console.log(JSON.parse(response.data.data.chapter.ans));
          const ans = JSON.parse(response.data.data.chapter.ans)
					const ans2 = JSON.parse(response.data.data.chapter.ans2)
					const ans3 = JSON.parse(response.data.data.chapter.ans3)

          // console.log(ans);
					setList(ans);
					setList1(ans2);
					setList2(ans3);
					

				

					
				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);



  const BtnDisabled = () => {
    if (list1.length>0 ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };


  
  return (
    <>
      <div className="wrapper md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="3/4" percentage="75" star="36" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">

          <div className="row align-items-center justify-content-start pt-20 mt-2 wow fadeInDown" 
            data-wow-duration="1s"
            data-wow-delay="0.2s"
            >

            <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h4 className="mb-2 mt-3 pn"> <span> <b>Q9.</b></span> <b>  Match the image with the right terms and definitions.  (Participants will be able to move the terms and definitions to align them with the image)</b></h4> 

                <div className="row">
									<div className="col-md-4">
										<table className="table table-bordered  hand-cursor">

											<tbody>
												<tr>
													<td>S.No.</td>
													<td>Image</td>
												</tr>
												{
													list.map((item, index) => {
                            {/* alert(item.image) */}
														return (
															<>
																<tr className="first-width">
																	<td>{item.id}</td>
																	<td> <img src={item.image} alt="" width="70%"/></td>
																</tr>
															</>
														)
													})
												}


											</tbody>
										</table>
									</div>
									<div className="col-md-4">
										<table className="table table-bordered table-red  hand-cursor">

											<tbody>
												<tr className="color-dark-red">
													{/* <td>ID</td> */}
													<td>Name<br></br><br></br></td>
												</tr>
												{
													list1.map((item1, index) => {
														return (
															<>
																<tr className="color-dark-red" >
																	{/* <td>{item1.id}</td> */}
																	<td>
																		<div
																			onDragStart={(e) => dragStart(e, index)}
																			onDragEnter={(e) => dragEnter(e, index)}
																			onDragEnd={drop}
																			key={index}
																			draggable
																		>
																			{item1.name}
                                      
                                      <br></br><br></br><br></br><br></br>

																		</div>
																	</td>
																</tr>
															</>
														)
													})
												}


											</tbody>
										</table>
									</div>

                  <div className="col-md-4">
										<table className="table table-bordered table-red hand-cursor">

											<tbody>
												<tr className="color-dark-red">
													{/* <td>ID</td> */}
													<td>Description</td>
												</tr>
												{
													list2.map((item2, index) => {
														return (
															<>
																<tr className="color-dark-red" >
																	{/* <td>{item2.id}</td> */}
																	<td>
																		<div
																			onDragStart={(e) => dragStart1(e, index)}
																			onDragEnter={(e) => dragEnter1(e, index)}
																			onDragEnd={drop1}
																			key={index}
																			draggable
																		>
																			{item2.desc}  <br></br><br></br><br></br><br></br>

																		</div>
																	</td>
																</tr>
															</>
														)
													})
												}


											</tbody>
										</table>
									</div>

								</div>
                
              
              


            </div>
          

          </div>
         

          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-03/chapter-04-screen-12"/>                  
                
                {/* <NextBtn link="/module-03/chapter-04-screen-14" text="Go Next"/> */}
                <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter04Screen13;
