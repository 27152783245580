import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter05Screen08 = () => {
	const navigate = useNavigate();
	const [checkedQues9Yes, setCheckedQues9Yes] = useState('');
	const [checkedQues9No, setCheckedQues9No] = useState('');
	const [checkedQues9Val, setCheckedQues9Val] = useState('');


	const [checkedQues8, setCheckedQues8] = useState('');




	const user_details = JSON.parse(localStorage.getItem('user_details'));


	const saveRadio = (e) => {
		checkedVal('9', e.target.value)

	}

	// console.log(checkedQues5);
	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": checkedQues8,
					"ques_id": "8"
				},
				"ques9": {
					"ans": checkedQues9Val,
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter05',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-05-screen-09')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues8 !== '' && checkedQues9Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	const checkedVal = (param, ans) => {


		if (param === '9') {
			if (ans === 'yes') {
				setCheckedQues9Yes('checked')
				setCheckedQues9No('')
				setCheckedQues9Val(ans)
			} else {
				if (ans === 'no') {
					setCheckedQues9No('checked')
					setCheckedQues9Yes('')
					setCheckedQues9Val(ans)
				}
			}
		}

		if (param === '8') {

			setCheckedQues8(ans)
		}


	}



	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter05Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="90" star="70" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-3">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-2">
									If yes, how are they connected? Share in 100-200 words.{" "}
								</p>
								<div className="mb-4 positon-submit">
									<textarea
										className="form-control"
										rows={5}
										placeholder="Write Something here....."
										defaultValue={checkedQues8}
										onChange={(e) => setCheckedQues8(e.target.value)}
									/>
									{/* <button
                    type="button"
                    className="btn btn-primary custom-btn m-auto submit-btn-set"
                  >
                    Submit
                  </button> */}
								</div>
								<p className="mb-2">
									Do you think Gender based violence in her lifecycle could be
									stopped?
								</p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="ques9"
											id="ture"
											defaultValue="yes"
											onClick={saveRadio}
											checked={checkedQues9Yes}
										/>
										<label className="form-check-label" htmlFor="ture">
											Yes
										</label>
									</div>
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="ques9"
											id="false"
											defaultValue="no"
											onClick={saveRadio}
											checked={checkedQues9No}
										/>
										<label className="form-check-label " htmlFor="false">
											No
										</label>
									</div>
								</div>
							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="53%"
										src="/assets/img/md1/md1-shap-5-08.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className="d-flex align-items-top justify-content-end mt-4">
								<PrevBtn link="/module-01/chapter-05-screen-07n" />
								{/* <NextBtn
                    link="/module-01/chapter-05-screen-09"
                    text="Go Next"
                  /> */}

								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen08;
