import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter05Screen10 = () => {
	const navigate = useNavigate();
	const [checkedQues11, setCheckedQues11] = useState([]);
	const [checkedQues11Option1, setCheckedQues11Option1] = useState('');
	const [checkedQues11Option2, setCheckedQues11Option2] = useState('');
	const [checkedQues11Option3, setCheckedQues11Option3] = useState('');
	const [checkedQues11Option4, setCheckedQues11Option4] = useState('');
	const [checkedQues11Option5, setCheckedQues11Option5] = useState('');
	const [checkedQues11Option6, setCheckedQues11Option6] = useState('');
	const [checkedQues11Option7, setCheckedQues11Option7] = useState('');
	const [checkedQues11Option8, setCheckedQues11Option8] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;
		checkBoxChecked(res, checked, quesId);
		if (quesId === '11') {
			setCheckedQues11(checkedQues11 => [...checkedQues11, res]);
		}

	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques11": {
					"ans": checkedQues11.join(','),
					"ques_id": "11"
				},


			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter05',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.success === true) {
					navigate('/module-01/chapter-05-screen-11')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	console.log(checkedQues11.length);
	const BtnDisabled = () => {
		if (checkedQues11.length > 0) {

			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '11') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues11(ansArr)
		}

	}
	const checkBoxChecked = (val, checked, quesId) => {
		if (quesId === '11') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues11Option1('checked');
				} else {
					setCheckedQues11Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues11Option2('checked');
				} else {
					setCheckedQues11Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues11Option3('checked');
				} else {
					setCheckedQues11Option3('');
				}
			}
			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues11Option4('checked');
				} else {
					setCheckedQues11Option4('');
				}
			}

			if (val === 'option5') {
				if (checked === true) {
					setCheckedQues11Option5('checked');
				} else {
					setCheckedQues11Option5('');
				}
			}

			if (val === 'option6') {
				if (checked === true) {
					setCheckedQues11Option6('checked');
				} else {
					setCheckedQues11Option6('');
				}
			}

			if (val === 'option7') {
				if (checked === true) {
					setCheckedQues11Option7('checked');
				} else {
					setCheckedQues11Option7('');
				}
			}

			if (val === 'option8') {
				if (checked === true) {
					setCheckedQues11Option8('checked');
				} else {
					setCheckedQues11Option8('');
				}
			}


		}

	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter05Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])





	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="92" star="74" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-2">
									<b>
										Why don’t Payal and other women like her raise their voice
										against violence?
									</b>
								</p>
								<p className="mb-2">Choose as many as you think apply </p>
								<div className="checkbox-design mt-2">
									<form>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"


													defaultValue="option1"
													onClick={saveGenderBased}
													checked={checkedQues11Option1}
													data-quesId="11"

												/>
												<label className="form-check-label" htmlFor="mycirle1">
													They have lower self-esteem and feel helpless
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													defaultValue="option2"
													onClick={saveGenderBased}
													checked={checkedQues11Option2}
													data-quesId="11"
												/>
												<label className="form-check-label" htmlFor="mycirle2">
													Society teaches them to view violence as a form of
													discipline
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													defaultValue="option3"
													onClick={saveGenderBased}
													checked={checkedQues11Option3}
													data-quesId="11"
												/>
												<label className="form-check-label" htmlFor="mycirle3">
													Society teaches them to view violence as a form of
													discipline
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													defaultValue="option4"
													onClick={saveGenderBased}
													checked={checkedQues11Option4}
													data-quesId="11"
												/>
												<label className="form-check-label" htmlFor="mycirle4">
													They are socialized to believe that violence at home is
													“normal”
												</label>
											</div>
										</div>


										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													defaultValue="option5"
													onClick={saveGenderBased}
													checked={checkedQues11Option5}
													data-quesId="11"
												/>
												<label className="form-check-label" htmlFor="mycirle5">
													They believe that as women they “deserve” to be abused
													by their male partners as they are the “weaker sex”
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													defaultValue="option6"
													onClick={saveGenderBased}
													checked={checkedQues11Option6}
													data-quesId="11"
												/>
												<label className="form-check-label" htmlFor="mycirle6">
													They are emotionally attached to their violent partners
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													defaultValue="option7"
													onClick={saveGenderBased}
													checked={checkedQues11Option7}
													data-quesId="11"
												/>
												<label className="form-check-label" htmlFor="mycirle7">
													They fear being ex-communicated by their peers
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													defaultValue="option8"
													onClick={saveGenderBased}
													checked={checkedQues11Option8}
													data-quesId="11"
												/>
												<label className="form-check-label" htmlFor="mycirle8">
													Men play a crucial role in women’s lives and often
													decide their fate
												</label>
											</div>
										</div>



									</form>
								</div>
							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="60%"
										src="/assets/img/md1/md1-shap-2-3.png"
										alt=""
									/>
								</center>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-05-screen-09" />
								{/* <NextBtn
                    link="/module-01/chapter-05-screen-11"
                    text="Go Next"
                  /> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen10;
