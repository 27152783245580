import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter01Screen07 = () => {
	return (
		<>
			<div className="wrapper page-auto bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="0" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Additional Reading </h3>
								<p className="mb-2 mt-3"><b>Sex -</b>  Either of two categories, male or female, that individuals are assigned at birth based on their sex organs. Some people may have both or either male and female organs that may be indeterminate. They are called intersex.  </p>
								<p className="mb-2 mt-3"><b>Sexual orientation -</b>  A person’s emotional and sexual attraction towards someone. This can be a person of the same sex, opposite, both or neither.  </p>
								<p className="mb-2 mt-3"><b>Gender identity –</b>  A person’s self-identification as male, female, both, or none. Gender identity can be same or different from sex assigned at birth as you learnt in the gender module.  </p>
								<p className="mb-2 mt-3"><b>Gender expression -</b> How a person presents their gender identity. This can be pronouns, appearance, behaviour, dressing, mannerisms etc. Recall from the gender module, this may or not be aligned to what is considered a norm in society.  </p>
								<p className="mb-2 mt-3"><b>Sexual behaviour –</b>  How a person experiences and expresses sexuality physically - kissing, holding hands, sexual touch, sex, self-pleasure. A person can also be asexual, and not feel any attraction towards others or desire for sexual activity.  </p>

								<p className="mb-2 mt-3"><b>Self-image –</b> How a person feels about their appearance and personality. How we act is based on our self-image. Self-image is made up of body image and self-esteem. This is discussed in detail in a later section.  </p>
								<p className="mb-2 mt-3"><b>Social norms –</b> In all societies/groups, there is a shared understanding of what sexual identities, experiences, and roles are standard and typical; and those that lie outside. These are the social norms of that society/ group.  </p>
								<p className="mb-2 mt-3"><b>Socialization –</b> Every person born into that society or group, will learn the same social norms (values, practices, and behaviours) – this is called socialization.  </p>
								<p className="mb-2 mt-3"><b>Institutions –</b> Media, religion, legal system, political bodies, educational, family and peers <br /><b>(social institution) – </b>these are some of the institutions that influence our sexuality. They decide and influence what is ‘allowed’, our sexual rights, the freedom we have, what behaviour is rewarded and what is punished among other factors.  </p>


							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="60%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-1-7.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-01-screen-06" />
								<NextBtn link="/module-05/chapter-01-screen-08" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen07;
