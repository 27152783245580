import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Header from "../../../header/Header";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";

const Chapter04Screen05 = () => {
	const navigate = useNavigate();
	const [checkedQues4, setCheckedQues4] = useState('');
	const [checkedQues5, setCheckedQues5] = useState('');
	const [checkedQues6, setCheckedQues6] = useState('');
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4,
					"ques_id": "4"
				},
				"ques5": {
					"ans": checkedQues5,
					"ques_id": "5"
				},
				"ques6": {
					"ans": checkedQues6,
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-04-screen-05n')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	// console.log(checkedQues6);


	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					results.map((elem, i) => {
						// console.log(i);
						if (i === 3) {
							setCheckedQues4(elem.ans)
						}
						if (i === 4) {
							setCheckedQues5(elem.ans)
						}
						if (i === 5) {
							setCheckedQues6(elem.ans)
						}
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	const BtnDisabled = () => {
		if (checkedQues4 !== '' && checkedQues5 !== '' && checkedQues6 !== '') {
			// alert();
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="68" star="46" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-3">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2">Engage </h2>
								<p>
									Let’s watch a scene from a mainstream bollywood film Dil
									Dhadakne Do
								</p>

								<a
									href="https://www.youtube.com/watch?v=82kaRWSh7iM"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Sunny And Manav Argue</p>
										</div>
									</div>
								</a>




								<p>
									Which character made comments rooted in Patriarchy, Sunny or
									Manav?</p>
								<textarea
									className="form-control"
									rows={1}
									placeholder="Write Something here....."
									onChange={(e) => setCheckedQues4(e.target.value)}
									data-quesId="4"
									defaultValue={checkedQues4}
								/>

								<p> What message around Patriarchy is communicated through
									this scene?</p>
								<textarea
									className="form-control"
									rows={1}
									placeholder="Write Something here....."
									onChange={(e) => setCheckedQues5(e.target.value)}
									data-quesId="5"
									defaultValue={checkedQues5}
								/>
								<p> Why is it important to have such content in the
									popular media?</p>
								<textarea
									className="form-control"
									rows={1}
									placeholder="Write Something here....."
									onChange={(e) => setCheckedQues6(e.target.value)}
									data-quesId="6"
									defaultValue={checkedQues6}
								/>


							</div>
							<div
								className="col-lg-5 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										src="/assets/img/md1/md1-shap-4-5.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-04-screen-04" />
								{/* <NextBtn
                  link="/module-01/chapter-04-screen-05n"
                  text="Go Next"
                /> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen05;
