import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../config/Baseurl";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const Certificate = () => {
  const navigate = useNavigate();
  const user_details = JSON.parse(localStorage.getItem("user_details"));

  const name = user_details.name;

  
  const rawHTML =
  ` <div style="box-sizing: border-box;margin:0;font-family: Plus Jakarta Sans; width:1200px;display:flex;justify-content: center;">
  <div style="max-width:1200px;margin:auto;">
              <div style="background-image: url(assets/img/certificate/bg.png);height: 667px;position: relative;/* margin: auto; *//* left: 10%; */width: 868px;overflow: hidden;background-size: contain;background-repeat: no-repeat;">
                          <div class="certificate" style="
                          height: 386px;
                        
                          position:relative;
                          padding-left:40px;
                          z-index: 10;
                          width: 635px;
                          top: 108px;
                          left: 32px;
                          border-radius: 0px 60px 0px 60px;
                          ">
                             
                              <h2 style="position: relative;top: 78px;font-size: 33px;font-weight: bold;">CERTIFICATE <span style="color:#946642">OF</span> COMPLETION</h2>
                              <p style="position: relative;top: 74px;font-size: 18px;font-weight: 600;">Breakthrough awards this certificate to</p>
                              <input type="text" value="${name}"  style="position: relative;top: 62px;height:40px;outline: none;border: none;box-shadow: rgb(154 109 70) 0px 2px 0px 0px;">
                              <p class="mt-5" style="position: relative;top: 62px;font-size: 18px;font-weight: 600;">for completing the online modules to became a Team Change Leader <br>
                              to end  gender-based discrimation and violence</p>
                             
                          </div>
                      </div>
     
                      </div>
                      <div class="footer d-flex" style="position: relative;width: 268px;z-index: 10;top: -95px;display: flex;display: none;left: 167px;">
                          <a class="breakthrough_btn" style=" height: 53px;width: 141px;background:rgb(170, 12, 12);display: flex;align-items: center;justify-content: center;border-radius: 4px;position:relative;top: 7px;font-size: 15px;color: white;text-decoration: none;cursor: pointer;">Breakthrough</a>
                          <div style="height:70px;width:3px;background:white;position:relative;left:40px;display:block;"></div>
                          <div class="footer_text" style=" color:white;position:relative;left:80px;">
                              <h2 style="margin:0;position:relative;left: 25px;font-size: 24px;">Team</h2>
                              <h1 style="font-size: 27px;margin:0;color:#946740;font-weight:900;">Change</h1>
                          </div>
                      </div>
                  

                  </div>
  `;

  
const pdfDownload = () => {
  

  const user_id = user_details.user_id;

  var data = JSON.stringify({
    user_id: user_id,
    
    download_status: "1",
  });

  var config = {
    method: "post",
    url: BaseURL + "/isDownloaded",
    headers: { 
      'Authorization': `Bearer ${user_details.token} `, 
      'Content-Type': 'application/json'
    },
    data: data,
  };

  axios(config)
    .then(function (response) {

     
      // console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
    

  var iframe = document.createElement('iframe');
  iframe.setAttribute("style", "width:0px !important;height:0px !important");
  var printPDF = document.getElementById('printPDF');
  printPDF.appendChild(iframe);
  setTimeout(function () {
    var iframedoc = iframe.contentDocument || iframe.contentWindow.document;
    iframedoc.body.innerHTML = rawHTML;
    html2canvas(iframedoc.body).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'l', // landscape
        unit: 'pt', // points, pixels won't work properly
        format: [1600, 1200] // set needed dimensions for any element
      });
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save('tcl-cetificate.pdf');
      // Remove
      document.querySelectorAll('iframe').forEach(
        function (elem) {
          elem.parentNode.removeChild(elem);
        });
      iframe.setAttribute("style", "display:none;");
    });
  }, 10);
};


  const LoginMenu = () => {
    if (user_details === null || user_details === "" || user_details === "") {
      return (
        <>
          <ul className="nav nav-pills header-ul">
            <li className="nav-item">
              <NavLink to="/register" className="header-btn">
                {" "}
                <img src="/assets/img/icon/profile.png" alt="" /> Sign up
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/login" className="header-btn btn-gray-bg">
                {" "}
                <img src="/assets/img/icon/login.png" alt="" /> Login
              </NavLink>
            </li>
          </ul>
        </>
      );
    } else {
      return (
        <>
          <ul className="nav nav-pills header-ul">
            <li className="nav-item">
              <button onClick={logout} className="header-btn">
                {" "}
                <img src="/assets/img/icon/login.png" alt="" /> Logout
              </button>
            </li>
            <li className="nav-item">
              <NavLink to="/profile" className="header-btn btn-gray-bg">
                {" "}
                <img src="/assets/img/icon/profile.png" alt="" /> Profile
              </NavLink>
            </li>
          </ul>
        </>
      );
    }
  };

  const logout = () => {
    localStorage.removeItem("user_details");
    navigate("/");
  };

  return (
    <>
      <div className="wrapper">
        <div className="fixed-header black-header">
          <div className="container-fluid cf-set">
            <header className="d-flex flex-wrap justify-content-center align-items-center">
              <NavLink
                to="/"
                className="d-flex align-items-center mb-md-0 me-md-auto  text-decoration-none"
              >
                <img
                  className="brand-logo"
                  src="/assets/img/logo-black.svg"
                  alt=""
                />
              </NavLink>
              <LoginMenu />
            </header>
          </div>
        </div>

       
            <div>
  <div style={{"max-width":"1200px","margin":"auto"}}>
  
    <div style={{"background-image":"url(assets/img/certificate/bg.png)","height":"667px","position":"relative","width":"868px","overflow":"hidden","background-size":"contain","background-repeat":"no-repeat"}}>
      <div  style={{"height":"386px","position":"relative","padding-left":"40px","z-index":"10","width":"635px","top":"108px","left":"32px","border-radius":"0px 60px 0px 60px"}}>
        {/* <img src="logo.png" alt="" style=" width:200px;position:absolute;top:-110px;"> */}
        <h2 style={{"position":"relative","top":"78px","font-size":"33px","font-weight":"bold"}}>CERTIFICATE <span style={{"color":"#946642"}}>OF</span> COMPLETION</h2>
        <p style={{"position":"relative","top":"74px","font-size":"18px","font-weight":"600"}}>Breakthrough awards this certificate to</p>
        <input type="text" value={user_details.name} disabled style={{"position":"relative","top":"62px","height":"40px","outline":"none","border":"none","box-shadow":"rgb(154 109 70) 0px 2px 0px 0px"}} />
        <p  style={{"position":"relative","top":"62px","font-size":"18px","font-weight":"600"}}>for completing the online modules to became a Team Change Leader <br />
          to end  gender-based discrimation and violence</p>
       
      </div>
      
    </div>
    <button className="mr-40" onClick={pdfDownload}>
										{" "}
										<img src="assets/img/certificate-btn.svg" alt="" />
										
									</button>
  </div>
  <div style={{"position":"relative","width":"268px","z-index":"10","top":"-95px","display":"none","left":"167px"}}>
    <a  style={{"height":"53px","width":"141px","background":"rgb(170, 12, 12)","display":"flex","-webkit-align-items":"center","-webkit-box-align":"center","-ms-flex-align":"center","align-items":"center","-webkit-box-pack":"center","-webkit-justify-content":"center","-ms-flex-pack":"center","justify-content":"center","border-radius":"4px","position":"relative","top":"7px","font-size":"15px","color":"white","-webkit-text-decoration":"none","text-decoration":"none","cursor":"pointer"}}>Breakthrough</a>
    <div style={{"height":"70px","width":"3px","background":"white","position":"relative","left":"40px","display":"block"}} />
    <div  style={{"color":"white","position":"relative","left":"80px"}}>
      <h2 style={{"margin":"0","position":"relative","left":"25px","font-size":"24px"}}>Team</h2>
      <h1 style={{"font-size":"27px","margin":"0","color":"#946740","font-weight":"900"}}>Change</h1>
      
    </div>
    
  </div>
  
</div>

    

            </div>
         

      <div id="printPDF">
				</div>
     
    </>
  );
};

export default Certificate;
