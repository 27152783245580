import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter02Screen06 = () => {


	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="25" star="2" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">

							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-2 ">It is important to note that human rights can be violated in our day to day life by people close to us and in such situations it can be difficult to take action owing to the pressures of our relationships and by other people or institutions. Therefore, while having systems, policies and structures in place are essential to ensure protection and implementation of human rights, it is equally important to ensure that every individual is aware about their own human rights, the action they can take if their rights are violated and the support they can seek. Similarly, individuals also need to be aware and sensitive towards human rights of others so they can actively support and advocate and know the repercussions of violating the rights.  {" "} </p>

								<p className="mb-2 mt-3">Rights come with responsibilities. For example, the right to freedom of speech comes with a responsibility to not say untrue or hurtful things which may degrade other people and abuse their right to dignity and respect. We need to maintain a balance between our rights and responsibilities to respect the rights of other people. When one expects people to treat them with respect they should also treat them in the same manner.{" "} </p>

							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="62%"
										draggable="false"
										src="/assets/img/md3/md3-shap-2-7.png"
										alt=""
									/>
								</center>
							</div>

						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-02-screen-05" />

									<NextBtn link="/module-03/chapter-02-screen-07" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen06;
