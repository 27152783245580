import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import BaseURL from "../../config/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";

const Home = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const user_details = JSON.parse(localStorage.getItem("user_details"));
	const [module1, setModule1] = useState('');
	const [module2, setModule2] = useState('');
	const [module3, setModule3] = useState('');
	const [module4, setModule4] = useState('');
	const [module5, setModule5] = useState('');
	const [module6, setModule6] = useState('');

	const [download, setCertificateDownload] = useState();
	const [perce,serPercent] = useState();
	const [show, setShow] = useState(false);

	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);

	const goToProfile = () => {
		const pathdata = location.pathname
		localStorage.setItem('previous_link', JSON.stringify(pathdata));
		navigate("/profile")
	}


	const logout = () => {
		localStorage.removeItem('user_details')
		navigate('/')

	}

	useEffect(() => {

		
		

		var data = JSON.stringify({
			"user_id": user_details.user_id,
		});

		var config = {
			method: 'post',
			url: BaseURL + '/getUnlockModule',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data;

					results.map((result) => {

						// console.log(result.module_no);
						if (result.module_no === 1) {
							// alert('mod1')
							setModule1(result.module_no);
						} else if (result.module_no === 2) {
							// alert('mod2')
							setModule2(result.module_no);

						} else if (result.module_no === 3) {
							setModule3(result.module_no);

						} else if (result.module_no === 4) {
							setModule4(result.module_no);

						} else if (result.module_no === 5) {
							setModule5(result.module_no);

						} else if (result.module_no === 6) {
							setModule6(result.module_no);

						}

					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});

			

	}, []);


	useEffect(() => {
    
		getAnalytics();
		getAlerts();
		down();
		
	});



	

	  const getAnalytics = ()=>{

		
		var data = JSON.stringify({
			"user_id": user_details.user_id,
		  });
		
		  var config = {
			method: "post",
			url: BaseURL + "/getAnalytics",
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data,
		  };
		
		  axios(config)
			.then(function (response) {
			  if (response.data.success === true) {
				console.warn(response.data.data.complete_activity_percent);
				const finalPercent = response.data.data;
		
				serPercent(finalPercent.complete_activity_percent);
		
			 
				
			   
			  }
			  // console.log(JSON.stringify(response.data.data.data));
			})
			.catch(function (error) {
			  console.log(error);
			});
	  }
	  
	  
	  
	  const down = ()=>{
		  if(perce === 100)
		  
		  {
			  if(download != 1)
			  {
				  
				  if(location.pathname != '/certificate')
				  {
					 
					 handleShow();
		
				   }
				 }
		}
	  }


	  const getAlerts= ()=>{
		
		var data = JSON.stringify({
		  token:
		  `${user_details.token}`,
		});
	  
		var config = {
		  method: "post",
		  url: BaseURL+"/get_user",
		  headers: {
			"Content-Type": "application/json",
		  },
		  data: data,
		};
	  
		axios(config)
		  .then(function (response) {
	  
	   
			// console.log(response);
			setCertificateDownload(response.data.data.certificate_download)
			if(response.status === "Token is Expired")
			{
			  window.localStorage.clear();
			  navigate('/welcome')
			}
	  
			if(response.data.message === "Token Invalid")
			{
			  window.localStorage.clear();
			  navigate('/welcome')
			}
	  
	  
			  if(response.data === '' || response.data === null)
			  {
				window.localStorage.clear();
				navigate('/welcome')
			  }
			// console.log(JSON.stringify(response.data));
		  })
		  .catch(function (error) {
			// console.log(error);
		  });
	  }





	const LoginMenu = () => {
		if (user_details === null || user_details === '' || user_details === '') {
			return (
				<>
					<ul className="nav nav-pills header-ul">
						<li className="nav-item">
							<NavLink to="/register" className="header-btn">
								{" "}
								<i class="bi bi-people"></i> Sign up
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to="/login" className="header-btn btn-gray-bg">
								{" "}
								<i class="bi bi-box-arrow-in-right"></i> Login
							</NavLink>
						</li>
					</ul>
				</>
			);
		} else {

			return (
				<>
					<ul className="nav nav-pills header-ul">
						<li className="nav-item">
							<button onClick={logout} className="header-btn">
								{" "}
								<i class="bi bi-box-arrow-right"></i> Logout
							</button>
						</li>
						<li className="nav-item">
							<button onClick={goToProfile} className="header-btn btn-gray-bg">
								{" "}
								<i class="bi bi-person-fill"></i> Profile
							</button>
						</li>
					</ul>
				</>
			);



		}
	};

	// console.log(module1);

	const Module1 = () => {
		if (user_details !== null) {
			if (module1 === 1) {
				return (
					<>
						<NavLink
							to="/module-01/chapter-01-intro-screen"
							className="home-box-btn"
						>
							{" "}
							<i class="bi bi-unlock-fill"></i> Module 2
						</NavLink>
					</>
				)
			} else {
				return (
					<>
						<NavLink
							to="#"
							className="home-box-btn disabled-btn"
						>
							{" "}
							<i class="bi bi-lock-fill"></i> Module 2
						</NavLink>
					</>
				)
			}

		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 2{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module2 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink to="/module-02/chapter-01-intro-screen" className="home-box-btn">
						{" "}
						<i class="bi bi-unlock-fill"></i> Module 1{" "}
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 1{" "}
					</NavLink>
				</>
			)
		}
	}





	const Module5 = () => {
		if (user_details !== null) {

			if (module2 === 2) {
				return (
					<>
						<NavLink to="/module-05/module-05-intro-screen" className="home-box-btn">
							{" "}
							<i class="bi bi-unlock-fill"></i> Module 3
						</NavLink>
					</>
				)
			} else {
				return (
					<>
						<NavLink to="#" className="home-box-btn disabled-btn">
							{" "}
							<i class="bi bi-lock-fill"></i> Module 3
						</NavLink>
					</>
				)
			}

		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 3{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module4 = () => {
		if (user_details !== null) {
			if (module3 === 3) {
				return (
					<>
						<NavLink to="/module-04/module-04-intro-screen" className="home-box-btn">
							{" "}
							<i class="bi bi-unlock-fill"></i> Module 4
						</NavLink>
					</>
				)
			} else {
				return (
					<>
						<NavLink to="#" className="home-box-btn disabled-btn">
							{" "}
							<i class="bi bi-lock-fill"></i> Module 4
						</NavLink>
					</>
				)
			}

		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 4{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module3 = () => {
		if (user_details !== null) {
			if (module4 === 4) {
				return (
					<>
						<NavLink to="/module-03/chapter-01-intro-screen" className="home-box-btn">
							{" "}
							<i class="bi bi-unlock-fill"></i> Module 5{" "}
						</NavLink>
					</>
				)
			} else {
				return (
					<>
						<NavLink to="#" className="home-box-btn disabled-btn">
							{" "}
							<i class="bi bi-lock-fill"></i> Module 5{" "}
						</NavLink>
					</>
				)
			}

		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 5{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module6 = () => {
		if (user_details !== null) {
			if (module5 === 5) {
				return (

					<>
						<NavLink
							to="/module-06/module-06-intro-screen"
							className="home-box-btn"
						>
							{" "}
							<i class="bi bi-unlock-fill"></i> Module 6
						</NavLink>
					</>
				)
			} else {
				return (

					<>
						<NavLink
							to="#"
							className="home-box-btn disabled-btn"
						>
							{" "}
							<i class="bi bi-lock-fill"></i>  Module 6
						</NavLink>
					</>
				)
			}

		} else {
			return (
				<>
					<NavLink to="/module-06/module-06-intro-screen" className="home-box-btn">
						{" "}
						Module 6
					</NavLink>
				</>
			)
		}
	}




	return (
		<>
			<div className="wrapper">
				<div className="fixed-header black-header">
					<div className="container-fluid cf-set">
						<header className="d-flex flex-wrap justify-content-center align-items-center">
							<NavLink
								to="/"
								className="d-flex align-items-center mb-md-0 me-md-auto  text-decoration-none"
							>
								<img
									className="brand-logo"
									src="/assets/img/logo-black.svg"
									alt=""
								/>
							</NavLink>
							<LoginMenu />
						</header>
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-center pt-20">


							<div
								className="col-lg-4 mt-4 order-2 order-sm-2 order-md-2 order-lg-1  wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="80%"
										src="/assets/img/chapter1/Jhole-wali-didi2.png"
										alt=""
									/>
								</center>

							</div>
							<div className="col-lg-7 order-1 order-sm-1 order-md-1 order-lg-2 offset-lg-1">
								<div className="row ">

									<div className="col-lg-6 mt-4">
										<div className="profile-enam green-bg ">
											<div className="d-flex align-items-center">
												<div className="content-profile ">
													<h6 className="home-box-heading">
														Understanding <br></br> Self{" "}
													</h6>
													<Module2 />
												</div>
												<div className="home-box-img">
													<img
														src="/assets/img/understanding-self.png"
														alt=""
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-6 mt-4">
										<div className="profile-enam">
											<div className="d-flex align-items-center">
												<div className="content-profile ">
													<h6 className="home-box-heading">
														Understanding  <br />
														Gender{" "}
													</h6>

													<Module1 />
												</div>
												<div className="home-box-img">
													<img src="/assets/img/understanding-gender.png" alt="" />

												</div>
											</div>
										</div>
									</div>



									<div className="col-lg-6 mt-4">
										<div className="profile-enam light-yellow-bg ">
											<div className="d-flex align-items-center">
												<div className="content-profile ">
													<h6 className="home-box-heading">
														Understanding <br /> Sexuality{" "}
													</h6>
													<Module5 />
												</div>
												<div className="home-box-img">
													<img
														src="/assets/img/understanding-sexuality.png"
														alt=""
													/>
												</div>
											</div>
										</div>
									</div>


									<div className="col-lg-6 mt-4">
										<div className="profile-enam light-blue-bg ">
											<div className="d-flex align-items-center">
												<div className="content-profile ">
													<h6 className="home-box-heading"> Understanding <br /> Masculinities </h6>
													<Module4 />
												</div>
												<div className="home-box-img">
													<img
														src="/assets/img/understanding-masculinities.png"
														alt=""
													/>
												</div>
											</div>
										</div>
									</div>


									<div className="col-lg-6 mt-4">
										<div className="profile-enam light-pink-bg ">
											<div className="d-flex align-items-center">
												<div className="content-profile ">
													<h6 className="home-box-heading">
														Understanding <br /> Human Rights{" "}

													</h6>
													<Module3 />
												</div>
												<div className="home-box-img">
													<img
														src="/assets/img/understanding-human-rights.png"
														alt=""
													/>
												</div>
											</div>
										</div>
									</div>



									<div className="col-lg-6 mt-4">
										<div className="profile-enam last-box-color">
											<div className="d-flex align-items-center">
												<div className="content-profile ">
													<h6 className="home-box-heading">
														Breakthrough's   <br />
														Approach{" "}
													</h6>
													<Module6 />
												</div>
												<div className="home-box-img">
													<img src="/assets/img/understanding-gender.png" alt="" />

												</div>
											</div>
										</div>
									</div>



								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            {/* <button
              type="button"
              className="close c-newp "
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button> */}

            <div className="addjustment-popup">
              <div className="container-fluid">
               
                <div className="row align-items-center justify-content-between mt-0">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <p className="">
                    Download Certificate
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12">
                 
                                <NavLink to="/certificate" className="btnset m-auto myreport-btn">
                                Certificate
                                </NavLink>
                             
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>

		</>
	);
};

export default Home;
