import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen02 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2 hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="85" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mr-3 mt-m-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3 mt-3">Government Partnerships</h3>

								<p className="mb-2 mt-3">Breakthrough is also working in partnership with the government of Punjab and Odisha to scale up the work with adolescents on gender equity. <a className="display-inline" href="https://inbreakthrough.org/focus-area/building-gender-equitable-behaviours-in-adolescents-through-government-partnerships/" target="_blank"> <b>Read More</b> </a> </p>


								<p className="mb-2 mt-3">Read more about Breakthrough’s campaigns here
									<a className="display-inline" href="https://inbreakthrough.org/campaigns/" target="_blank">Campaigns | Breakthrough India | Women Empowerment  </a> and do watch the films and follow the youtube channel for more recent content here
									<a className="display-inline" href="https://www.youtube.com/c/breakthroughtv" target="_blank">Breakthrough India - YouTube.  </a>
								</p>
								<p className="mb-2 mt-3">Read more about Breakthrough and connect with us through the links below </p>

								<div className="social-word">
									<a href="https://inbreakthrough.org/" target="_blank" class="btn btn-outline-danger"><i class="bi bi-suit-heart"></i> Website </a>
									<a href="https://www.facebook.com/BreakthroughIN" target="_blank" class="btn btn-outline-danger"><i class="bi bi-facebook"></i> Facebook </a>
									<a href="https://twitter.com/INBreakthrough" target="_blank" class="btn btn-outline-danger"><i class="bi bi-twitter"></i> Twitter  </a>
									<a href="https://www.instagram.com/INBreakthrough/" target="_blank" class="btn btn-outline-danger"><i class="bi bi-instagram"></i> Instagram </a>
									<a href="https://www.linkedin.com/company/breakthrough-india?originalSubdomain=in" target="_blank" class="btn btn-outline-danger"><i class="bi bi-linkedin"></i> LinkedIn </a>
									<a href="https://www.youtube.com/user/LetsBreakthrough" target="_blank" class="btn btn-outline-danger"><i class="bi bi-youtube"></i> YouTube </a>
								</div>



							</div>

							<div className="col-lg-3 mt-m-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">
								<center>
									<img
										width="50%"
										src="/assets/img/md6/md-60chap-2-3.png"
										alt=""
									/>
								</center>
							</div>


						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-06/chapter-04-screen-05" />
								<NextBtn link="/module-06/chapter-04-screen-07" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
