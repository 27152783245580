import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter02Screen06 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/5" percentage="28" star="28" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h3 mb-1">Group C</h2>
								<ul className="list-style-set list-circle mt-2">
									<li>
										Comes from an underprivileged category as they do not belong
										to the dominant caste, class, religion, and ability status
									</li>
									<li>
										Believes in patriarchy, but can’t enjoy it fully,
										marginalized because of being underprivileged
									</li>
									<li>
										Admires people who display traits and behaviours described
										in group A and are often violated by them
									</li>
									<li>
										Does not question or challenge these traits and behaviours
									</li>
								</ul>
								<h2 className="h3 mt-3">Group D</h2>
								<ul className="list-style-set list-circle mt-2">
									<li>
										Men who exhibit qualities that are opposite to those valued
										in Group A such as physical weakness and expressing emotions
										etc.
									</li>
									<li>
										Dominated and bullied by other men for being weak and
										different.
									</li>
								</ul>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="65%"
										draggable="false"
										src="/assets/img/md4/md4-shap-2-6.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-02-screen-05" />

									<NextBtn link="/module-04/chapter-02-screen-07" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen06;
