import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter03Screen06 = () => {
  return (
    <>
      <div className="wrapper md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="1/4" percentage="25" star="2" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-2">
             
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
               <p className="mb-2 mt-3">If most of your identities match the ones in column A you belong to the category of people with most power and privilege, access to rights and resources whereas if most of your identities are in column D you belong to the category of people who struggle to ensure their rights and access to resources.  {" "} </p>
               <p className="mb-2 mt-3">Similarly, column B has privileges and power which are less than column A and column C has access to rights and resources which are more than column D but less than column A and B.  {" "} </p>
               <p className="mb-2 mt-3">Many times  we have multiple overlapping identities which influence whether our rights are denied or protected.   Certain intersections of identities further marginalize or gives more power and privileges to people  {" "} </p>
               <p className="mb-2 mt-3">This happens due to the way the structures and systems operate in our country which ensures and ends up giving more power and privileges to certain identities and qualifications over others. {" "} </p>
               <p className="mb-2 mt-3">People who have identities with more Power and privileges are the ones who have access to and control over resources which means that they decide who will get the resources, how much and when.  {" "} </p>
               <p className="mb-2 mt-3">This is done structurally and systemically and may vary based on the context, time, geographies i.e. experiences of discrimination for a cisgender woman with disabilities might differ from that of a transgender woman with disabilities. Similarly, the experiences of women with disabilities in a country where there is more accessibility might differ from those of women with disabilities where there is limited accessibility. {" "} </p>


              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-03/chapter-03-screen-03"/>  
                <NextBtn link="/module-03/chapter-03-screen-07" text="Go Next"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen06;
