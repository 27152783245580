import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";

const Chapter03Screen07 = () => {

	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const [always, setAlways] = useState();
	const [rarely, setRarely] = useState();

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/selfChapter03ShowResultMsg',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					setAlways(response.data.data.always);
					setRarely(response.data.data.rarely);
					// console.log(response);
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	const Msg = () => {
		if (always >= 5) {
			return (
				<>
					<p className="mb-2 mt-3">
						You are mostly able to keep calm, remain objective and level-headed
						during disagreements, in conflict situations and when you are
						angry. You try to keep an open-mind about others in challenging
						situations and practice active listening. You are perceptive about
						others feelings and you are also self-aware about your own. You
						are understanding, compassionate and empathetic towards others and
						you are also humble about sharing your own feelings and
						expectations if need be. These are great qualities for effective
						communication which is key to becoming a good leader and
						collaborating. The next activity will enable you to strengthen
						your skills for the same.{" "}
					</p>
				</>
			)
		}

		if (rarely >= 5) {
			return (
				<>
					<p className="mb-2 mt-3">
						You have scope of learning how to communicate in a way which is more
						compassionate, empathetic, objective and effective. Being more
						open-minded about the other person and the situation to prevent
						yourself from getting overwhelmed such that you are able to
						actively listen to the other person even when you disagree with
						them. This allows you to perceive how the other person is feeling
						along with being perceptive about your own feelings and being able
						to share it effectively and constructively. These are important
						qualities for effective communication which is key to becoming a
						good leader and collaborating. The next activity will enable you
						to strengthen your skills for the same.
					</p>
				</>
			)
		}

	}
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="40" star="0" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">

							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2 mb-1"> Results</h2>
								<Msg />
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img width="90%" src="/assets/img/md2/md2-shap-3-7.png" alt="" />
								</center>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-03-screen-06" />
								<NextBtn link="/module-02/chapter-03-screen-08" text="Go Next" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	);
};

export default Chapter03Screen07;
