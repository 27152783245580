import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";


const Chapter04Screen03 = () => {

	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="36" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">


						<div className="row align-items-center justify-content-between pt-20 mt-3">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h4 className="mb-3"> <b> While it’s important for us to be aware of Human rights, respect others and take actions to ensure Human rights for all, our governments are instrumental in ensuring and safeguarding human rights.   </b></h4>

								<p className=" mt-1">Governments must never violate our human rights, instead they must proactively ensure people’s rights are upheld and fulfilled. For example, it’s one thing to have the right to an education written in a declaration or charter of rights, but unless our governments create the policy settings that ensure schools are functioning and accessible to all children, that right won’t be realised. Likewise, without a system in place that allows us to safely and freely cast a vote, our right to have a say in choosing our government becomes meaningless.</p>

								<a
									href="https://ishr.ch/about-human-rights/who-protects-human-rights/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Who protects human rights  </p>
										</div>
									</div>
								</a>
								<p className=" mt-1">It is also important to remember that unfortunately, Human rights activist and defenders also face the consequences of holding governments and big corporations accountable. Please see some examples below: </p>



								<a
									href="https://www.youtube.com/watch?v=SthbN5Nc9NY&list=PLWqowEyMVW8ZbdwqJf12V4qkbtFRNdYrE&index=25"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>भीमा कोरेगांव – एल्गार परिषद षड़यंत्र केस का सच | Karwan e Mohabbat</p>
										</div>
									</div>
								</a>

								<a
									href="https://www.hrw.org/news/2020/04/15/india-activists-detained-peaceful-dissent"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>India: Activists Detained for Peaceful Dissent | Human Rights Watch </p>
										</div>
									</div>
								</a>

								<a
									href="https://thewire.in/rights/harsh-mander-enforcement-directorate-raid"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>ED Raids Activist Harsh Mander's Home, Office in Delhi</p>
										</div>
									</div>
								</a>

								<a
									href="http://karwanemohabbat.in/karwan-in-solidarity-with-political-prisoners/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Karwan in Solidarity</p>
										</div>
									</div>
								</a>


							</div>
						</div>


					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-04-screen-02" />

									<NextBtn link="/module-03/chapter-04-screen-04" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen03;
