import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";

const Chapter02Screen02 = () => {

	const navigate = useNavigate();

	const [checkedQues1, setCheckedQues1] = useState([]);
	const [washingChecked, setWashingChecked] = useState('');
	const [roamingChecked, setRoamingChecked] = useState('');
	const [wearingChecked, setWearingChecked] = useState('');
	const [teachingChecked, setTeachingChecked] = useState('');
	const [notAllowingChecked, setNotAllowingChecked] = useState('');
	const [diapersChecked, setDiapersChecked] = useState('');
	const [respectGirlsChecked, setrespectGirlsChecked] = useState('');
	const [bullyingChecked, setBullyingChecked] = useState('');
	const [toiletChecked, setToiletChecked] = useState('');
	const [livingChecked, setLivingChecked] = useState('');
	const [violentChecked, setViolentChecked] = useState('');
	const [wearingSareeChecked, setWearingSareeChecked] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveGenderBased = (e) => {
		const res = e.target.value;
		const checked = e.target.checked;


		checkBoxChecked(res, checked);
		setCheckedQues1(checkedQues1 => [...checkedQues1, res]);
	}
	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues1.join(','),
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-04/chapter-02-screen-03')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues1.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	const checkedVal = (param, ans) => {


		if (param === '1') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked);
			})
			setCheckedQues1(ansArr)
		}


	}

	const checkBoxChecked = (val, checked) => {
		if (val === 'Washing clothes') {
			if (checked === true) {
				setWashingChecked('checked');

			} else {
				setWashingChecked('');
			}
		}

		if (val === 'Roaming outside') {
			if (checked === true) {
				setRoamingChecked('checked');
			} else {
				setRoamingChecked('');
			}
		}

		if (val === 'Wearing nail paint') {
			if (checked === true) {
				setWearingChecked('checked');
			} else {
				setWearingChecked('');
			}
		}

		if (val === 'Teaching gender equality') {
			if (checked === true) {
				setTeachingChecked('checked');
			} else {
				setTeachingChecked('');
			}
		}

		if (val === 'Not allowing their wife to work') {
			if (checked === true) {
				setNotAllowingChecked('checked');
			} else {
				setNotAllowingChecked('');
			}
		}

		if (val === 'Changing diapers') {
			if (checked === true) {
				setDiapersChecked('checked');
			} else {
				setDiapersChecked('');
			}
		}
		if (val === 'Respecting girls and women') {
			if (checked === true) {
				setrespectGirlsChecked('checked');
			} else {
				setrespectGirlsChecked('');
			}
		}
		if (val === 'Bullying and harassing other men and women') {
			if (checked === true) {
				setBullyingChecked('checked');
			} else {
				setBullyingChecked('');
			}
		}
		if (val === 'Cleaning toilet') {
			if (checked === true) {
				setToiletChecked('checked');
			} else {
				setToiletChecked('');
			}
		}

		if (val === 'Living at their in-laws place') {
			if (checked === true) {
				setLivingChecked('checked');
			} else {
				setLivingChecked('');
			}
		}

		if (val === 'Being violent and abusive') {
			if (checked === true) {
				setViolentChecked('checked');
			} else {
				setViolentChecked('');
			}
		}

		if (val === 'Wearing a saree') {
			if (checked === true) {
				setWearingSareeChecked('checked');
			} else {
				setWearingSareeChecked('');
			}
		}





	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/5" percentage="20" star="20" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set lavel-content-set">
						<div className="row align-items-center justify-content-between">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="row">
									<div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
										<h2 className="h2 mb-1">Let’s watch a video to begin</h2>
										<a
											href="https://www.youtube.com/watch?v=NpdAk5demtQ"
											target="_blank"
											rel="noreferrer"
										>
											<div className="d-flex align-items-center mange-icon mt-2">
												<div>
													<img src="/assets/img/chapter2/video-icon.png" alt="" />
												</div>
												<div>
													<p>Masculinities in India: Prashant</p>
												</div>
											</div>
										</a>

										<p className="mb-2 mt-3 ">
											<b>
												Which of the following have you seen boys and men around you
												doing? Select all that apply
											</b>
										</p>
									</div>
									<div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
										<div className="d-flex ">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle"

													defaultValue="Washing clothes"
													onClick={saveGenderBased}
													checked={washingChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle">
													{" "}
													Washing clothes{" "}
												</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle2"
													defaultValue="Roaming outside"
													onClick={saveGenderBased}
													checked={roamingChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle2">
													Roaming outside
												</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle3"
													defaultValue="Wearing nail paint"
													onClick={saveGenderBased}
													checked={wearingChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle3">
													{" "}
													Wearing nail paint{" "}
												</label>
											</div>
										</div>
										<div className="d-flex ">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle4"
													defaultValue="Teaching gender equality"
													onClick={saveGenderBased}
													checked={teachingChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle4">
													{" "}
													Teaching gender equality{" "}
												</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle5"
													defaultValue="Not allowing their wife to work"
													onClick={saveGenderBased}
													checked={notAllowingChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle5">
													Not allowing their wife to work
												</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle6"
													defaultValue="Changing diapers"
													onClick={saveGenderBased}
													checked={diapersChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle6">
													{" "}
													Changing diapers{" "}
												</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle7"
													defaultValue="Respecting girls and women"
													onClick={saveGenderBased}
													checked={respectGirlsChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle7">
													{" "}
													Respecting girls and women{" "}
												</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle8"
													defaultValue="Bullying and harassing other men and women"
													onClick={saveGenderBased}
													checked={bullyingChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle8">
													Bullying and harassing other men and women
												</label>
											</div>
										</div>
									</div>
									<div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle9"
													defaultValue="Cleaning toilet"
													onClick={saveGenderBased}
													checked={toiletChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle9">
													Cleaning toilet
												</label>
											</div>
										</div>
										<div className="d-flex ">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle10"
													defaultValue="Living at their in-laws place"
													onClick={saveGenderBased}
													checked={livingChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle10">
													Living at their in-laws place{" "}
												</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle11"

													defaultValue="Being violent and abusive"
													onClick={saveGenderBased}
													checked={violentChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle11">
													Being violent and abusive
												</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle12"
													defaultValue="Wearing a saree"
													onClick={saveGenderBased}
													checked={wearingSareeChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle12">
													Wearing a saree
												</label>
											</div>
										</div>
									</div>
								</div>

							</div>



							<div
								className="col-lg-4 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md4/md4-shap-2-2.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-02-screen-01" />

									{/* <NextBtn link="/module-04/chapter-02-screen-03" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
