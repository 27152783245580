import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen35 = () => {
  return (
    <>
      <div className="wrapper page-auto bg-bd-2">
        <div
          className="fixed-header"
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="2/4" percentage="50" star="50" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-1">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <h3 className="h3 mt-3">Apply   </h3>

                <p className="mb-2  mt-3"><b>Read this story of Vicky’s parents. There are many instances in this case which show us patriarchy and unequal power. Mark the sentences you feel best signify these based on the questions below.</b></p>
                <p className="mb-2  mt-3">Vicky heard the car in front of his home, and saw it was Dad. He threw the book in his hands and ran to the door. “Vicky, pick up the book. How many times have I told you your father does not like untidiness?” his mother said.  Vicky sneered and ignored his mother’s instructions. </p>
                <p className="mb-2  mt-3">Vicky’s father said he had a bad day at work and Vicky could smell the alcohol on him. Immediately he realised to not test his father and be careful with his behaviour. As his father walked into the living room, he saw the book on the floor and yelled – “Vicky come and pick this I hate mess, you just do this to make me angry”. As he picked up the book, his father hit him on the head and said, “Don’t make me tell you again”. </p>
                <p className="mb-2  mt-3">Vicky rubbed where his father had hit him, feeling a little bubble of anger in him. When he got married, he thought he would find a wife to clean up after him just like his friends’ mothers did. His mother would look tired all day and for what reason? What did she even do? Vicky thought to himself. They had a great house. She had her own car and Dad was good to them. She just had to do a bit of washing and stuff.  </p>
                <p className="mb-2  mt-3">But she was stupid. Vicky’s father often mocked his mother. ‘Don’t know how such a stupid woman could give birth to such a smart kid. Dad would tell Vicky about “stupid women” everywhere. Vicky was fourteen and he knew all about them. “Never trust them, Vicky Never trust a woman,” his dad would say.</p>

                <p className="mb-2 mb-7 mt-3">Dad had had a few drinks. Sometimes he let Vicky have a glass of beer as well. It made him feel important. Sometimes he felt like Superman when he had had a bit to drink. Vicky guessed it made his dad feel that way too. ‘Where’s the bloody dinner?’ said his father. His mother started to get up from the couch. His father pushed his finger into her ribs. “Move it Madam Cow, move towards the kitchen...” Vicky smiled and went to put the book in his room.  </p>

               
                


               </div>

               


              
             
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
               
                <PrevBtn link="/module-05/chapter-03-screen-34"/>  
                <NextBtn link="/module-05/chapter-03-screen-36" text="Go Next"/>

              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default Chapter03Screen35;
