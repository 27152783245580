import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter03Screen09 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="2/5" percentage="60" star="60" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">

							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2 mb-4">Additional Resources </h2>
								<a
									href="https://www.youtube.com/watch?v=0S8v0A51HXU"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>
												What's So Great About <br /> <b>"Being a Man"? </b>
											</p>
										</div>
									</div>
								</a>
								<a
									href="https://www.youtube.com/watch?v=cpA0J62LMB0"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>
												Gentleman kise kehte hai? <br />{" "}
												<b>Ayushmann Khurrana for The Man Company </b>
											</p>
										</div>
									</div>
								</a>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="50%"
										draggable="false"
										src="/assets/img/md4/md4-shap-3-9.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-03-screen-08" />

									<NextBtn link="/module-04/chapter-04-screen-01" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen09;
