import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter02Screen10 = () => {
	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="25" star="2" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h2 mb-1">Additional Resources</h3>
							</div>
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<a href="https://blog.mygov.in/we-the-people-we-the-citizen/#:~:text=Being%20a%20citizen%20of%20India,taxes%20with%20honesty%20promptly%2C%20protect" target="_blank">
									<div className="d-flex align-items-center justify-content-start mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p className="text-left">We the People, We the Citizen - <b>MyGov Blogs</b> </p>
										</div>
									</div>
								</a>
								<a href="https://www.youtube.com/watch?v=V-gvE7MGIBw&feature=youtu.be" target="_blank" >
									<div className="d-flex align-items-center justify-content-start mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p className="">TOI LEAD INDIA TREE </p>
										</div>
									</div>
								</a>

								<a href="https://meta.wikimedia.org/wiki/FII_Wiki_Project/Edit-a-thons/Transgender_Rights_Activists_of_South_Asia" target="_blank" >
									<div className="d-flex align-items-center justify-content-start mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p className="">Transgender Rights Activists of South Asia - Meta </p>
										</div>
									</div>
								</a>
							</div>


							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										className="mt-0"
										width="45%"
										src="/assets/img/md3/md3-shap-2-7.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-02-screen-09" />
									<NextBtn link="/module-03/chapter-03-screen-01" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen10;
