import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { NavLink } from "react-router-dom";

const Chapter04Screen03 = () => {
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="64" star="40" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2">Learn </h2>
								<p>
									There are many reality shows on television and online streaming
									platforms which are reflective of the Patriarchal society, however
									fictional shows, movies and other content too have been
									reinforcing Patriarchy by instilling the viewers with gender
									biased, stereotypical and sexist content. The representation is
									also limited to the binary definitions of gender. There is hardly
									any representation of trans, gender non-binary and queer people
									and whatever minimal representation can be seen is stereotypical
									and biased.</p>
								<p><b>Let’s watch a video to understand how ads have been
									promoting gender stereotypes.{" "}</b>
								</p>

								<a
									href="https://hindi.feminisminindia.com/2021/09/30/ad-women-stereotypes-india-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>आखिर विज्ञापनों में औरतों को स्टीरियोटाइप क्यों किया जाता है?</p>
										</div>
									</div>
								</a>


							</div>
							<div
								className="col-lg-5 mt-m-2 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img width="70%" src="/assets/img/md1/md1-shap-4-3.png" alt="" />
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-04-screen-02" />
								<NextBtn link="/module-01/chapter-04-screen-04" text="Go Next" />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	);
};

export default Chapter04Screen03;
