import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";

const Chapter04Screen16 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-0">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3">Learn: </h3>
								<p className="mb-2 mt-3"> A lot of the safer sex behaviour related to our sexuality are actual physical measures to be undertaken when sexually active. The most important of these is the use of contraception during sexual activity to protect from unintended pregnancies and STIs. </p>
								<p className="mb-2 mt-3"> Various undesirable, unsafe consequences of sexual activity can all be prevented by using a contraceptive method. Especially in our society, where unintended pregnancies and abortions are a huge taboo, preventing pregnancy is a responsibility of every person that is sexually active.  </p>




							</div>

							<div
								className="col-lg-2 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-2.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-15" />
								{/* <NextBtn link="/module-05/chapter-04-screen-17" text="Go Next"/> */}

								<button className="button_su" >
									<span className="su_button_circle">
									</span>
									<a href="" className="button_su_inner" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
										<span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span>
									</a>
								</button>


							</div>
						</div>
					</div>
				</div>
			</div>




			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>
							<p className="text-white text-center">We will discuss abortion stigma and contraception inequality, in more detail in the advanced section of this chapte </p>


							<div className=" d-flex justify-content-center mt-3">
								<button className="button_su m-auto" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="/module-05/chapter-04-screen-17" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen16;
