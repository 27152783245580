import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../config/Baseurl";

const Profile = () => {

	const location = useLocation();

	const navigate = useNavigate();
	const [user, setUser] = useState([]);
	const [totalStar, setTotalStar] = useState([]);
	const [report, setReport] = useState([]);
	const user_details = JSON.parse(localStorage.getItem("user_details"));
	const previous_link = JSON.parse(localStorage.getItem('previous_link'));


	const goToChangePassword = () => {
		const pathdata = location.pathname
		localStorage.setItem('previous_link', JSON.stringify(pathdata));
		navigate("/change-password")
	}


	useEffect(() => {

		analytics();

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/studentProfile',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					// const results = response.data.data.chapter;
					setUser(response.data.data.user);
					setTotalStar(response.data.data.totalStar);
					// console.log(response.data.data.total);

				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const analytics = ()=>{
		
			var data = JSON.stringify({
			  user_id: user_details.user_id,
			});
		
			var config = {
			  method: "post",
			  url: BaseURL + "/getAnalytics",
			  headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			  data: data,
			};
		
			axios(config)
			  .then(function (response) {

				
				if (response.data.success === true) {
				  const item1 = response.data.data;
				 
				  setReport(item1);
				  
				}
				// console.log(JSON.stringify(response.data.data.data));
			  })
			  .catch(function (error) {
				console.log(error);
			  });
		  
	}

	const logout = () => {
		localStorage.removeItem('user_details')
		navigate('/')

	}

	// console.log(report.complete_activity_percent);

	const LoginMenu = () => {
		if (user_details === null || user_details === '' || user_details === '') {
			return (
				<>
					<ul className="nav nav-pills header-ul">
						<li className="nav-item">
							<NavLink to="/register" className="header-btn">
								{" "}
								<img src="/assets/img/icon/profile.png" alt="" /> Sign up
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to="/login" className="header-btn btn-gray-bg">
								{" "}
								<img src="/assets/img/icon/login.png" alt="" /> Login
							</NavLink>
						</li>
					</ul>
				</>
			);
		} else {

			return (
				<>
					<ul className="nav nav-pills header-ul">
						<li className="nav-item">
							<button onClick={logout} className="header-btn">
								{" "}
								<img src="/assets/img/icon/login.png" alt="" /> Logout
							</button>
						</li>
						<li className="nav-item">
							<NavLink to="/profile" className="header-btn btn-gray-bg">
								{" "}
								<img src="/assets/img/icon/profile.png" alt="" /> Profile
							</NavLink>
						</li>
					</ul>
				</>
			);



		}
	};

	const Module1 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink
						to="/module-01/chapter-01-intro-screen"
						className="home-box-btn"
					>
						{" "}
						Module 1
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 1{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module2 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink to="/module-02/chapter-01-intro-screen" className="home-box-btn">
						{" "}
						Module 2{" "}
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 2{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module3 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink to="/module-03/chapter-01-intro-screen" className="home-box-btn">
						{" "}
						Module 3{" "}
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 3{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module4 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink to="/module-04/module-04-intro-screen" className="home-box-btn">
						{" "}
						Module 4
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 4{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module5 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink to="#" className="home-box-btn">
						{" "}
						Module 5
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 5{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module6 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink
						to="/module-01/chapter-01-intro-screen"
						className="home-box-btn"
					>
						{" "}
						Module 6
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 6
					</NavLink>
				</>
			)
		}
	}

	const CheckMobPhone = () => {
		if (user.email !== '') {
			return (
				<>
					<div className="red-box-profile d-flex justify-content-between align-items-center">
						<div className="text-white">Email- {user.email}</div>
						{/* <div className=""><img  width="40px" src="/assets/img/edit-icon.png" alt="" /></div> */}
					</div>
				</>
			)
		} else if (user.phone !== '') {

			return (
				<>
					<div className="red-box-profile d-flex justify-content-between align-items-center">
						<div className="text-white">Phone- {user.phone}</div>

					</div>
				</>
			)


		}
	}

	// console.log(previous_link);
	const PreviouLink = () => {
		if (previous_link === '/profile') {
			return (
				<>
					{/* <button class="button_su undefined" onClick={() => navigate(-3)}>Back</button>  */}
					<button onClick={() => navigate(-3)} className="button_su_inner" ><span className="button_text_container"> <i class="bi bi-arrow-left"></i> Back </span></button>

					{/* <NavLink to="/home" class="button_su undefined" data-wow-duration="1s" data-wow-delay="0.2s"><span class="su_button_circle"></span><button className="button_su_inner" ><span className="button_text_container"> <i class="bi bi-arrow-left"></i> Back </span></button></NavLink> */}
				</>
			);
		} else {
			return (
				<>
					<NavLink to={previous_link} class="button_su undefined" data-wow-duration="1s" data-wow-delay="0.2s"><span class="su_button_circle"></span><button className="button_su_inner" ><span className="button_text_container"> <i class="bi bi-arrow-left"></i> Back </span></button></NavLink>
				</>
			);
		}
	}



	return (
		<>
			<div className="wrapper">
				<div className="fixed-header black-header">
					<div className="container-fluid cf-set">
						<header className="d-flex flex-wrap justify-content-center align-items-center">
							<NavLink
								to="/"
								className="d-flex align-items-center mb-md-0 me-md-auto  text-decoration-none"
							>
								<img
									className="brand-logo"
									src="/assets/img/logo-black.svg"
									alt=""
								/>
							</NavLink>
							<LoginMenu />
						</header>
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-5">
							<div
								className="col-lg-4  wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="red-box-profile d-flex justify-content-between align-items-center">
									<div className="text-white">Name- {user.name}</div>
									{/* <div className=""><img  width="40px" src="/assets/img/edit-icon.png" alt="" /></div> */}
								</div>
							</div>

							<div
								className="col-lg-4  wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<CheckMobPhone />
							</div>
							<div
								className="col-lg-4  wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="red-box-profile d-flex justify-content-between align-items-center">
									<div> <NavLink to="#" className="text-white">Change Password</NavLink></div>
									<div className=""><button onClick={goToChangePassword} className="text-white edit-button"><img width="40px" src="/assets/img/edit-icon.png" alt="" /></button></div>
								</div>
							</div>
						</div>

						<div className="row align-items-center justify-content-between pt-20 mt-5">
							<div
								className="col-lg-4  wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								
							</div>

							{(report.complete_activity_percent === 100)?
							<div
								className="col-lg-4  wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<NavLink to="/certificate" class="button_su undefined" data-wow-duration="1s" data-wow-delay="0.2s"><span class="su_button_circle"></span><button className="button_su_inner" ><span className="button_text_container">  Certificate </span></button></NavLink>
							</div>
							:""
							}
							<div
								className="col-lg-4  wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								
							</div>
						</div>


						<div className="row align-items-center justify-content-between headingsetporo pt-20 mt-5">
							<div
								className="col-lg-4  wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="profile-enam">
									<div className="d-flex align-items-center justify-content-between">
										<div className="content-profile ">
											<h6 className="home-box-heading">
												Module 01 <br></br> Understanding Self
											</h6>
										</div>
										<div className="home-box-img star-design">
											<p className="content-position">{totalStar.self}</p>
											<img width="40px" src="/assets/img/star42.png" alt="" />
										</div>
									</div>
								</div>
							</div>

							<div
								className="col-lg-4 mt-4  wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="profile-enam light-yellow-bg">
									<div className="d-flex align-items-center justify-content-between">
										<div className="content-profile ">
											<h6 className="home-box-heading">
												Module 02 <br /> Understanding Gender
											</h6>
										</div>
										<div className="home-box-img star-design">
											<p className="content-position">{totalStar.gender}</p>
											<img width="40px" src="/assets/img/star2.png" alt="" />
										</div>
									</div>
								</div>
							</div>

							<div
								className="col-lg-4 mt-4  wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="profile-enam green-bg">
									<div className="d-flex align-items-center justify-content-between">
										<div className="content-profile ">
											<h6 className="home-box-heading">
												Module 03 <br /> Understanding Sexuality

											</h6>
										</div>
										<div className="home-box-img star-design">
											<p className="content-position">{totalStar.sexuality}</p>
											{/* <p className="content-position">{totalStar.human_rights}</p> */}
											<img width="40px" src="/assets/img/star3.png" alt="" />
										</div>
									</div>
								</div>
							</div>

							<div
								className="col-lg-4 mt-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="profile-enam light-pink-bg">
									<div className="d-flex align-items-center justify-content-between">
										<div className="content-profile ">
											<h6 className="home-box-heading">Module 04 <br />Understanding Masculinities</h6>
										</div>
										<div className="home-box-img star-design">
											<p className="content-position">{totalStar.masculinities}</p>
											<img width="40px" src="/assets/img/star4.png" alt="" />
										</div>
									</div>
								</div>
							</div>

							<div
								className="col-lg-4 mt-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="profile-enam light-blue-bg">
									<div className="d-flex align-items-center justify-content-between">
										<div className="content-profile ">
											<h6 className="home-box-heading">Module 05 <br />Understanding Human Rights </h6>
										</div>
										<div className="home-box-img star-design">
											<p className="content-position">{totalStar.human_rights}</p>
											<img width="40px" src="/assets/img/star4.png" alt="" />
										</div>
									</div>
								</div>
							</div>

							<div
								className="col-lg-4 mt-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="profile-enam  dark-blue-bg">
									<div className="d-flex align-items-center justify-content-between">
										<div className="content-profile ">
											<h6 className="home-box-heading">Module 06 <br /> Breakthrough's Appr.... </h6>
										</div>
										<div className="home-box-img star-design">
											<p className="content-position">{totalStar.approach}</p>
											<img width="40px" src="/assets/img/star4.png" alt="" />
										</div>
									</div>
								</div>
							</div>


						</div>


						<div className="row mt-5 wow fadeInDown"
							data-wow-duration="1s"
							data-wow-delay="0.2s">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-center">

									<PreviouLink />
									<button class="button_su undefined" data-wow-duration="1s" data-wow-delay="0.2s"><span class="su_button_circle"></span><button className="button_su_inner" onClick={logout} ><span className="button_text_container"> LOGOUT <i className="bi bi-box-arrow-right"></i></span></button></button>

								</div>
							</div>
						</div>

					</div>
				</div>





			</div>
		</>
	);
};

export default Profile;
