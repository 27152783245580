import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate, NavLink } from "react-router-dom";

const Chapter02Screen05 = () => {
  const navigate = useNavigate();
  const [checkedQues5, setCheckedQues5] = useState("");

  const user_details = JSON.parse(localStorage.getItem("user_details"));

  const saveAns = () => {
    // alert();
    var data = JSON.stringify({
      user_id: user_details.user_id,
      answers: {
        ques5: {
          ans: checkedQues5,
          ques_id: "5",
        },
      },
    });
    var config = {
      method: "post",
      url: BaseURL + "/SexualityChapter02",
      headers: {
        Authorization: `Bearer ${user_details.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate("/module-05/chapter-02-screen-06");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const checkedVal = (param, ans) => {
    if (param === "5") {
      setCheckedQues5(ans);
    }
  };
  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_details.user_id,
      ques_id: "",
    });
    var config = {
      method: "post",
      url: BaseURL + "/sexualityChapter02Result",
      headers: {
        Authorization: `Bearer ${user_details.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(), result.ans);
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const BtnDisabled = () => {
    if (checkedQues5 !== "") {
      return (
        <>
          <button className="button_su">
            <span className="su_button_circle desplode-circle"></span>
            <button
              className="button_su_inner"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="2/4" percentage="50" star="50" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-4 mt-m-3">
              <div
                className="col-lg-8 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h3 className="h3 mt-2">Share</h3>
                <p className="mb-2 mt-1">
                  Can you think of where your early ideas on sexual orientation
                  came from? What were they? (100 Words){" "}
                </p>

                <textarea
                  className="form-control"
                  rows={7}
                  placeholder="Write Something here....."
                  onChange={(e) => setCheckedQues5(e.target.value)}
                  data-quesId="8"
                  defaultValue={checkedQues5}
                />

                <p className="mb-2 mt-1">
                  While many societies see gender from a binary lens based on
                  the binary definitions of sex assigned at birth, many
                  individuals may not necessarily identify as the sex and gender
                  they were assigned at birth or/and in binary. Such individuals
                  identify as gender non-binary, gender fluid or gender queer.
                  Individuals who identify with the sex and gender assigned at
                  birth are called cisgender.{" "}
                </p>
              </div>

              <div
                className="col-lg-3 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="65%"
                    draggable="false"
                    src="/assets/img/chapter1/reading-book.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-05/chapter-02-screen-05" />
                {/* <NextBtn link="/module-05/chapter-02-screen-06" text="Go Next"/> */}
                {/* <button className="button_su" >
                    <span className="su_button_circle desplode-circle">
                    </span>
                    <button onClick={saveAns}  className="button_su_inner" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <span className="button_text_container">
                      Go Next <i className="bi bi-arrow-right" />
                    </span>
                  </button>
                </button> */}

                <BtnDisabled />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close close-set"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="bi bi-x-lg" />
              </button>

              <p className="text-white text-center">
                Some prompts can be: relationships are between man and woman
                only; <br /> we have to act a certain way sexually based on our
                gender
              </p>

              <div class=" d-flex justify-content-center mt-3">
                <button
                  className="button_su"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="su_button_circle desplode-circle"></span>
                  <button onClick={saveAns} className="button_su_inner">
                    <span className="button_text_container">
                      {" "}
                      Go Next <i className="bi bi-arrow-right" />
                    </span>{" "}
                  </button>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen05;
