import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";

const Chapter01Screen18 = () => {
	const navigate = useNavigate();




	const [checkedQues3, setCheckedQues3] = useState([]);
	const [checkedQues3Option1, setCheckedQues3Option1] = useState('');
	const [checkedQues3Option2, setCheckedQues3Option2] = useState('');
	const [checkedQues3Option3, setCheckedQues3Option3] = useState('');
	const [checkedQues3Option4, setCheckedQues3Option4] = useState('');
	const [checkedQues3Option5, setCheckedQues3Option5] = useState('');
	const [checkedQues3Option6, setCheckedQues3Option6] = useState('');
	const [checkedQues3Option7, setCheckedQues3Option7] = useState('');
	const [checkedQues3Option8, setCheckedQues3Option8] = useState('');
	const [checkedQues3Option9, setCheckedQues3Option9] = useState('');
	const [checkedQues3Option10, setCheckedQues3Option10] = useState('');
	const [checkedQues3Option11, setCheckedQues3Option11] = useState('');
	const [checkedQues3Option12, setCheckedQues3Option12] = useState('');
	const [checkedQues3Option13, setCheckedQues3Option13] = useState('');
	const [checkedQues3Option14, setCheckedQues3Option14] = useState('');
	const [checkedQues3Option15, setCheckedQues3Option15] = useState('');




	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;

		checkBoxChecked(res, checked, quesId);


		if (quesId === '3') {
			setCheckedQues3(checkedQues3 => [...checkedQues3, res]);

		}




	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3.join(','),
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-01-screen-20')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues3.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {


		if (param === '3') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues3(ansArr)
		}




	}
	const checkBoxChecked = (val, checked, quesId) => {


		if (quesId === '3') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues3Option1('checked');
				} else {
					setCheckedQues3Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues3Option2('checked');
				} else {
					setCheckedQues3Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues3Option3('checked');
				} else {
					setCheckedQues3Option3('');
				}
			}
			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues3Option4('checked');
				} else {
					setCheckedQues3Option4('');
				}
			}

			if (val === 'option5') {
				if (checked === true) {
					setCheckedQues3Option5('checked');
				} else {
					setCheckedQues3Option5('');
				}
			}

			if (val === 'option6') {
				if (checked === true) {
					setCheckedQues3Option6('checked');
				} else {
					setCheckedQues3Option6('');
				}
			}

			if (val === 'option7') {
				if (checked === true) {
					setCheckedQues3Option7('checked');
				} else {
					setCheckedQues3Option7('');
				}
			}

			if (val === 'option8') {
				if (checked === true) {
					setCheckedQues3Option8('checked');
				} else {
					setCheckedQues3Option8('');
				}
			}

			if (val === 'option9') {
				if (checked === true) {
					setCheckedQues3Option9('checked');
				} else {
					setCheckedQues3Option9('');
				}
			}

			if (val === 'option10') {
				if (checked === true) {
					setCheckedQues3Option10('checked');
				} else {
					setCheckedQues3Option10('');
				}
			}

			if (val === 'option11') {
				if (checked === true) {
					setCheckedQues3Option11('checked');
				} else {
					setCheckedQues3Option11('');
				}
			}

			if (val === 'option12') {
				if (checked === true) {
					setCheckedQues3Option12('checked');
				} else {
					setCheckedQues3Option12('');
				}
			}
			if (val === 'option13') {
				if (checked === true) {
					setCheckedQues3Option13('checked');
				} else {
					setCheckedQues3Option13('');
				}
			}

			if (val === 'option14') {
				if (checked === true) {
					setCheckedQues3Option14('checked');
				} else {
					setCheckedQues3Option14('');
				}
			}

			if (val === 'option15') {
				if (checked === true) {
					setCheckedQues3Option15('checked');
				} else {
					setCheckedQues3Option15('');
				}
			}









		}



	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])



	return (
		<>
			<div className="wrapper md-5-bg">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="5" star="4" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-top justify-content-between pt-20 mt-3">

							<div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s" >

								<div className="checkbox-design ">
									<form>

									</form>
								</div>
							</div>

							<div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s" >
								<h4 className="mb-2 mt-2 pn"> <span> <b>Q2.</b></span> <b>Of the following choices provided to you, what will fit in the leaves and fruits?</b></h4>
							</div>

							<div className="col-lg-8 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s" >
								<div className="checkbox-design ">
									<form>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number1"
													defaultValue="option1"
													onClick={saveGenderBased}
													checked={checkedQues3Option1}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number1">Food</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number2"
													defaultValue="option2"
													onClick={saveGenderBased}
													checked={checkedQues3Option2}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number2">Prevention of Sexual Harassment Of Women At Workplace Act </label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number3"
													defaultValue="option3"
													onClick={saveGenderBased}
													checked={checkedQues3Option3}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number3">Schools, teachers, principals </label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number4"
													defaultValue="option4"
													onClick={saveGenderBased}
													checked={checkedQues3Option4}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number4">Gender and/ or sexual identity</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number5"
													defaultValue="option5"
													onClick={saveGenderBased}
													checked={checkedQues3Option5}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number5">Prevention Of Child Marriage Act </label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number6"
													defaultValue="option6"
													onClick={saveGenderBased}
													checked={checkedQues3Option6}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number6">Anganwadi centres </label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number7"
													defaultValue="option7"
													onClick={saveGenderBased}
													checked={checkedQues3Option7}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number7">Choice of marriage</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number8"
													defaultValue="option8"
													onClick={saveGenderBased}
													checked={checkedQues3Option8}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number8">Protection Of Women From Domestic Violence Act </label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number91"
													defaultValue="option9"
													onClick={saveGenderBased}
													checked={checkedQues3Option9}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number91">Parents  </label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number101"
													defaultValue="option10"
													onClick={saveGenderBased}
													checked={checkedQues3Option10}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number101">Property rights </label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number111"
													defaultValue="option11"
													onClick={saveGenderBased}
													checked={checkedQues3Option11}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number111">Protection Of Children From Sexual Offences Act </label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number9"
													defaultValue="option12"
													onClick={saveGenderBased}
													checked={checkedQues3Option12}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number9">Frontline workers  </label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number10"
													defaultValue="option13"
													onClick={saveGenderBased}
													checked={checkedQues3Option13}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number10">Choice of partner</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number12"
													defaultValue="option14"
													onClick={saveGenderBased}
													checked={checkedQues3Option14}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number12">Pre-Conception Pre-Natal Diagnostic Technique Act  </label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" type="checkbox" id="number13"
													defaultValue="option15"
													onClick={saveGenderBased}
													checked={checkedQues3Option15}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="number13">Government officers  </label>
											</div>
										</div>


									</form>
								</div>
							</div>
							<div className="col-lg-4 mt-m-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.2s', animationName: 'fadeInDown' }}>
								<center>
									<img width="88%" src="/assets/img/md3/md3-shap-3-21.png" alt="" />
								</center>
							</div>
						</div>

					</div>
				</div>
			</div>


			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-03/chapter-01-screen-17" />

								{/* <NextBtn link="/module-03/chapter-01-screen-19" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen18;
