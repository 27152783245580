import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen14 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items- justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3 mt-3">Learn</h3>
								<p className="mb-2  mt-3">The effects of societal value systems on human sexuality is summarized in this quote!  </p>
								<p className="mb-2  mt-2"> All societies have faced the problem of <a href="https://www.merriam-webster.com/dictionary/reconciling" target="_blank" className="d-inline-block">Reconciling</a>  the need of controlling <a href="https://www.merriam-webster.com/dictionary/reconciling" target="_blank" className="d-inline-block">Sex</a>  with that of giving it adequate expression, and all have solved it by some combination of cultural taboos, permissions, and injunctions. Prohibitory regulations curb the socially more disruptive forms of sexual competition. Permissive regulations allow at least the minimum impulse gratification required for individual well-being. Very commonly, moreover, sex behavior is specifically enjoined by obligatory regulations where it appears directly to subserve the interests of society</p>




								<a href="https://www.britannica.com/topic/human-sexual-activity/Social-and-cultural-aspects " target="_blank">
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p> Social and cultural aspects</p>
										</div>
									</div>
								</a>

								<p className="mb-2  mt-3"> We have already explored the role of family, friends, media and institutions in individuals’ sexuality experience. What makes it dangerous is when social norms and society’s definitions of sexuality find backing in law. Then what behaviour is accepted or not culturally becomes institutionalised and punishable.  For instance, in India, the majority of sex laws are concerned with maintaining morality, rather than protecting persons.  </p>
								<p className="mb-2  mt-3">In this section, we will see how laws, norms and institutions disadvantage diverse sexualities. </p>



							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="50%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-2.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-13" />
								<NextBtn link="/module-05/chapter-02-screen-15" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen14;
