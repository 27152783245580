import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";


const Chapter01Screen23 = () => {

	const navigate = useNavigate();
	const [checkedQues2_1, setCheckedQues2_1] = useState('');
	const [checkedQues2_2, setCheckedQues2_2] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "given-" + checkedQues2_1 + ",chosen-" + checkedQues2_2,
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-02/chapter-01-screen-04')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const checkedVal = (param, ans) => {
		if (param === '2') {
			const __ans = ans.split(',');
			const given = __ans[0].split('-');
			const chosen = __ans[1].split('-');

			setCheckedQues2_1(given[1])
			setCheckedQues2_2(chosen[1])
		}




	}


	const BtnDisabled = () => {
		if (checkedQues2_1 !== '' && checkedQues2_2 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="2" star="1" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h2 mb-1">Additional Resources</h3>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<a href="https://www.youtube.com/watch?v=nDgIVseTkuE" target="_blank">
									<div className="d-flex align-items-center justify-content-start mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p className="text-left">What are the universal human rights? - <b>Benedetta Berti</b> </p>
										</div>
									</div>
								</a>
								<a href="https://www.youtube.com/watch?v=ew993Wdc0zo" target="_blank" >
									<div className="d-flex align-items-center justify-content-start mange-icon mt-1">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p className="">Human rights in two minutes </p>
										</div>
									</div>
								</a>

								<a href="https://www.youtube.com/watch?v=JpY9s1Agbsw" target="_blank" >
									<div className="d-flex align-items-center justify-content-start mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p className="">What is a human right? </p>
										</div>
									</div>
								</a>


								<a href="https://www.ohchr.org/sites/default/files/Documents/Issues/Defenders/Declaration/fa-hindi.pdf" target="_blank" >
									<div className="d-flex align-items-center justify-content-start mange-icon mt-1">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p className="">मानवाधिकार रक्षकों पर संयुक्त राष्ट उदघोषणा </p>
										</div>
									</div>
								</a>
								<a href="https://worldhappiness.report/ed/2022/" target="_blank" >
									<div className="d-flex align-items-center justify-content-start mange-icon mt-0">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p className="">World Happiness Report 2022 </p>
										</div>
									</div>
								</a>

								<a href="https://oxfamilibrary.openrepository.com/bitstream/handle/10546/621341/bp-inequality-kills-170122-en.pdf" target="_blank">
									<div className="d-flex align-items-center justify-content-start mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p className="">Inequality kills </p>
										</div>
									</div>
								</a>
								<a href="https://freedomhouse.org/sites/default/files/2022-02/FIW_2022_PDF_Booklet_Digital_Final_Web.pdf" target="_blank" >
									<div className="d-flex align-items-center justify-content-start mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p className="">FREEDOM IN THE WORLD 2022</p>
										</div>
									</div>
								</a>
								<a href="https://worldpopulationreview.com/country-rankings/freedom-index-by-country" target="_blank" >
									<div className="d-flex align-items-center justify-content-start mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p className="">Freedom Index by Country 2022 </p>
										</div>
									</div>
								</a>



							</div>


							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>



								<a href="https://www.hrw.org/sites/default/files/media_2022/01/World%20Report%202022%20web%20pdf_0.pdf" target="_blank">
									<div className="d-flex align-items-center justify-content-start mange-icon mt-1">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p className="">World Report 2022 |  <b> Human Rights Watch</b></p>
										</div>
									</div>
								</a>
								<a href="https://data.unicef.org/topic/education/secondary-education/" target="_blank" >
									<div className="d-flex align-items-center justify-content-start mange-icon ">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p className="">Secondary Education and Enrollment Statistics - UNICEF Data</p>
										</div>
									</div>
								</a>

								<a href="https://hdr.undp.org/content/2021-global-multidimensional-poverty-index-mpi#/indicies/MPI" target="_blank" >
									<div className="d-flex align-items-center justify-content-start mange-icon mt-0">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p className="">The 2021 Global Multidimensional Poverty Index (MPI) | Human Development Reports</p>
										</div>
									</div>
								</a>
								<a href="https://hdr.undp.org/data-center/country-insights#/ranks" target="_blank" >
									<div className="d-flex align-items-center justify-content-start mange-icon mt-1">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p className="">Latest Human Development Index Ranking </p>
										</div>
									</div>
								</a>

								<a href="https://www.globalhungerindex.org/ranking.html" target="_blank">
									<div className="d-flex align-items-center justify-content-start mange-icon mt-0">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p className="">Global Hunger Index Scores by 2021 GHI Rank</p>
										</div>
									</div>
								</a>
								<a href="https://www.visionofhumanity.org/maps/#/" target="_blank" >
									<div className="d-flex align-items-center justify-content-start mange-icon mt-0">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p className="">Global Peace Index Map » The Most & Least Peaceful Countries</p>
										</div>
									</div>
								</a>

							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										className="mt-0"
										width="70%"
										src="/assets/img/md3/md3-shap-3-23.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-01-screen-22" />
									<NextBtn link="/module-03/chapter-02-screen-01" text="Go Next" />

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen23;
