import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter05Screen03 = () => {
	return (
		<>
			<>
				<div className="wrapper">
					<div
						className="fixed-header"

					>
						<div className="container-fluid cf-set">
							<Header leftChapter="4/5" percentage="82" star="62" />
						</div>
					</div>
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between pt-20 mt-5">
								<div
									className="col-lg-6 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h2">Learn</h2>
									{/* <a
                    href="https://www.youtube.com/watch?v=7U1Zb1Wzq3U"
                    className="watch-now"
                  >
                    {" "}
                    <img src="/assets/img/chapter2/video-icon.png" alt="" />{" "}
                    Domestic Violence
                  </a> */}
									<p>
										What is Violence? WHO defines violence as the intentional or
										unintentional use of physical force, power and threat
										against oneself, another person, or against a group or
										community that either results in, or has a likelihood of,
										resulting in injury, death, psychological harm,
										maldevelopment or deprivation. Violence has many forms
										including economic, sexual, verbal, psychological, emotional
										and physical. It can be propagated in a structural way
										through various institutions. It is a form of aggression
										where the abuser uses fear, bullying, and threats to gain
										power and control over the other person{" "}
									</p>
								</div>
								<div
									className="col-lg-6 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<iframe
										width="100%"
										height="315"
										src="https://www.youtube.com/embed/7U1Zb1Wzq3U"
										title="YouTube video player"
										frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
									></iframe>

								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.3s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-01/chapter-05-screen-02" />
									<NextBtn
										link="/module-01/chapter-05-screen-04"
										text="Go Next"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
};

export default Chapter05Screen03;
