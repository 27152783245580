import React from "react";


import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter01IntroScreen = () => {
	return (
		<div className="wrapper">
			<div
				className="fixed-header"

			>
				<div className="container-fluid cf-set">
					<Header leftChapter="0/5" percentage="0" star="0" />
				</div>
			</div>
			<div className="data-adjust">
				<div className="container-fluid cf-set">
					<div className="row align-items-center justify-content-between pt-20">
						<div
							className="col-lg-9 wow fadeInDown"
							data-wow-duration="1s"
							data-wow-delay="0.2s"
						>
							<h4>
								{" "}
								<b>
									In this module, you will be introduced to the concept of Gender
									and Patriarchy.
								</b>
							</h4>
							<div className="para-color-box">
								<p>
									<b>This module has been divided into different chapters that</b>
								</p>
								<ul className="list-style-set list-circle mt-1">
									<li>unpack the meaning of gender, </li>
									<li>
										how we are taught to perceive and follow gender ‘norms’, roles
										and expected behaviours,
									</li>
									<li>
										the effect these have on our daily lives and the role of society
										and media in it,
									</li>
									<li>the system of Patriarchy under which all of this operates</li>
								</ul>
								<p>
									<b>Objectives of the module:</b>{" "}
								</p>
								<ul className="list-style-set list-circle list-number mt-1">
									<li>
										Explain the differences between various terminologies related to
										gender{" "}
									</li>
									<li>
										Recognise how gender norms unfolds when it comes to traits,
										expression, behaviour, cultural practices in their own lives and
										in the larger society
									</li>
									<li>
										Analyse how the structures and systems perpetuate gender based
										violence and discrimination{" "}
									</li>
									<li>
										Plan and implement small actions in their own lives and in
										society against GBDV{" "}
									</li>
								</ul>
							</div>
							{/* <p>
            <b>Duration of the module: </b> 1 week
          </p> */}
						</div>
						<div
							className="col-lg-3 wow fadeInDown"
							data-wow-duration="1s"
							data-wow-delay="0.2s"
						>
							<center>
								<img
									width="60%"
									draggable="false"
									src="/assets/img/chapter1/reading-book.png"
									alt=""
								/>
							</center>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/score" />

								<NextBtn link="/module-01/chapter-01-screen-01" text="Go Next" classtype="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	);
};

export default Chapter01IntroScreen;
