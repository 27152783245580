import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";

const Chapter04Screen14 = () => {

	const navigate = useNavigate();



	const [checkedQues10, setCheckedQues10] = useState([]);
	const [checkedQues10Option1, setCheckedQues10Option1] = useState('');
	const [checkedQues10Option2, setCheckedQues10Option2] = useState('');
	const [checkedQues10Option3, setCheckedQues10Option3] = useState('');
	const [checkedQues10Option4, setCheckedQues10Option4] = useState('');



	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);
		checkedVal(quesId, e.target.value)
	}
	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;
		checkBoxChecked(res, checked, quesId);
		if (quesId === '10') {
			setCheckedQues10(checkedQues10 => [...checkedQues10, res]);
		}





	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": checkedQues10.join(','),
					"ques_id": "10"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsFinalQuiz',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-04-screen-15')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues10.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {


		if (param === '10') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues10(ansArr)
		}





	}
	const checkBoxChecked = (val, checked, quesId) => {



		if (quesId === '10') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues10Option1('checked');
				} else {
					setCheckedQues10Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues10Option2('checked');
				} else {
					setCheckedQues10Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues10Option3('checked');
				} else {
					setCheckedQues10Option3('');
				}
			}

			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues10Option4('checked');
				} else {
					setCheckedQues10Option4('');
				}
			}












		}


	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsFinalQuizResult',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])





	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="36" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-top justify-content-between pt-20 mt-4 mt-m-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>


								<h4 className="mb-2 mt-3 pn"> <span> <b>Q10.</b></span> <b>  Why are equity and justice important ? Select all the
									reasons that apply</b></h4>

								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle"
											defaultValue="option1"

											onClick={saveGenderBased}
											checked={checkedQues10Option1}
											data-quesId="10"
										/>
										<label className="form-check-label" htmlFor="mycirle">Because certain identities have more power and privileges historically and they are the ones who are largely in decision making positions in the society which often leads to securing all the resources for themselves hence violating the rights of others. </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle2"
											defaultValue="option2"
											onClick={saveGenderBased}
											checked={checkedQues10Option2}
											data-quesId="10"
										/>
										<label className="form-check-label" htmlFor="mycirle2">Because certain identities with historically less power and privileges end up struggling to ensure their basic rights which often deprives them from growing beyond their struggles, leave alone make it to the decision making positions. This constant cycle leads to further rise in inequality </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle3"
											defaultValue="option3"
											onClick={saveGenderBased}
											checked={checkedQues10Option3}
											data-quesId="10"
										/>
										<label className="form-check-label" htmlFor="mycirle3">Because everyone does not have access to equal opportunities, tools and resources  </label>
									</div>
								</div>

								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle5"
											defaultValue="option4"
											onClick={saveGenderBased}
											checked={checkedQues10Option4}
											data-quesId="10"
										/>
										<label className="form-check-label" htmlFor="mycirle5">Because there is a need to change the systems and structures to ensure justice  </label>
									</div>
								</div>
							</div>

							<div
								className="col-lg-4 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										className="mt-0"
										width="100%"
										src="/assets/img/md3/md3-shap-4-14.png"
										alt=""
									/>
								</center>
							</div>


						</div>


					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-04-screen-13" />
									{/* <NextBtn link="/module-03/chapter-04-screen-15" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen14;
