import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";

const Chapter01Screen06 = () => {
	const navigate = useNavigate();
	const [checkedQues4, setCheckedQues4] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4,
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-02/chapter-01-screen-06')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const checkedVal = (param, ans) => {
		if (param === '4') {
			setCheckedQues4(ans)
		}




	}


	const BtnDisabled = () => {
		if (checkedQues4 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper overscroll md-5-bg">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="5" star="4" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-top justify-content-center pt-20 mt-3 mb-5">
							<div className="col-lg-8 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">

								<p>In 1948, the United Nations created the Universal Declaration of Human Rights, to provide a global understanding of how to treat individuals. According to this declaration, we have 30 basic rights. They can be broadly categorised under civil and political rights, economic, social and cultural rights and collective rights. The State has the obligation to respect, protect and fulfill these rights. </p>

								<p>An abbreviated version of these is shown below:</p>
								<h2 className="h4"> <b>To access the complete Universal Declaration of Human Right’s document, please visit this link</b></h2>



								<a href="https://www.un.org/en/about-us/universal-declaration-of-human-rights" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Universal declaration of human rights</p>
										</div>
									</div>
								</a>

								<ul className="without-list list-number ml-1 mt-3">
									<li>Right to Equality</li>
									<li>Freedom from Discrimination</li>
									<li>Right to Life, Liberty and Personal Security</li>
									<li>Freedom from Slavery </li>
									<li>Freedom from Torture and Degrading Treatment-</li>
									<li>Right to Recognition as a Person Before the Law </li>
									<li>Right to Equality Before the Law </li>
									<li>Right to Remedy by Competent Tribunal</li>
									<li>Freedom from Arbitrary Arrest and Exile</li>
									<li>Right to Fair Public Hearing  </li>
									<li>Right to be Considered Innocent until Proven Guilty</li>
									<li>Freedom from Interference with Privacy, Family, Home and Correspondence</li>



									<li>Right to Free Movement In and Out of the Country</li>
									<li>Right to Asylum in Other Countries from Persecution</li>
									<li>Right to a Nationality and the Freedom to Change It</li>
									<li>Right to Marriage and Family</li>
									<li>Right to Own Property</li>
									<li>Freedom of Belief and Religion </li>
									<li>Freedom of Opinion and Information</li>
									<li>Right to Peaceful Assembly and Association</li>
									<li>Right to Participate in Government and in Free Elections</li>
									<li>Right to Social Security </li>
									<li>Right to Desirable Work and to Join Trade Unions</li>
									<li>Right to Rest and Leisure</li>
									<li>Right to Adequate Living Standard</li>
									<li>Right to Education</li>
									<li>Right to Participate in the Cultural Life of Community</li>
									<li>Right to a Social Order that Articulates this Document</li>
									<li>Community Duties Essential to Free and Full Development</li>
									<li>Freedom from State or Personal Interference in the above Rights</li>
								</ul>
							</div>
							<div className="col-lg-4 mt-m-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
								<center>
									<img width="100%" src="/assets/img/md3/md3-shap-3-6.png" alt="" />
								</center>
							</div>
						</div>

					</div>
				</div>
			</div>


			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-03/chapter-01-screen-04" />

								<NextBtn link="/module-03/chapter-01-screen-08" text="Go Next" />
								{/* <BtnDisabled/> */}

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen06;
