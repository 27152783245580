import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL, { AdminUrl } from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter02Screen17 = () => {

	const navigate = useNavigate();
	const [imageUrl, setImageUrl] = useState();
	const [checkedQues7, setCheckedQues7] = useState('');


	const [images, setImages] = useState([]);


	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const onImageChange = async (e) => {
		// alert();

		setImages([...e.target.files]);



		const questId = '7';

		const user_id = user_details.user_id;
		const files = e.target.files;
		const formData = new FormData();


		formData.append("image1", files[0]);
		formData.append("user_id", user_id);
		formData.append("ques_id", questId);

		// console.log(user_details.user_id);

		fetch(BaseURL + "/sexualityImageUploadCh2", {
			method: "POST",
			body: formData,
		}).then((res) => {

			res.json().then((result) => {


			});
		});

		// console.log(questId);
	};

	const saveAns = () => {

		// alert();
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {

				"ques7": {
					"ans": checkedQues7,
					"ques_id": "7"
				},


			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-02-screen-24')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans) => {


		if (param === '7') {

			setCheckedQues7(ans)
		}




	}



	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": "7"
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					checkedVal(results.ques_id.toString(), results.ans)
					setImageUrl(results.ans2);
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {
		if (checkedQues7 !== '') {
			return (
				<>


					<button className="button_su" >
						<span className="su_button_circle desplode-circle">
						</span>
						<button onClick={saveAns} className="button_su_inner" >
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div className="col-lg-8 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
								<h3 className="h3 mt-3">Share</h3>


								<p className="mb-2  mt-3">Payal and Pia are sisters. Payal has known for a long time that Pia feels differently about her gender and desires to be a boy. During college, Pia comes out to her family and friends about her gender identity and feelings of distress and discomfort in being a girl. Her family and friends are supportive, especially her sister, Payal. Pia undergoes her transformation into a boy and is now Piyush.  </p>
								<p className="mb-2  mt-3">He finally feels safe and comfortable in his body and self. He is happy that he can now do the things he likes and feels like himself. He wants to join the men’s football team in college as he loves the game. However, the coach and college authorities argue that he cannot be a part of the team as he is not “naturally” a boy.</p>
								<p className="mb-2  mt-3">Situations like this are very common in our society. Imagine yourself to be Payal. How would you convince the coach to allow Piyush into the team in a way that is respectful and accepting of his identity? Write a short letter and upload a photo! </p>


								<div className="row ">
									<div className="col-lg-8">
										<div class="mt-4 positon-submit">
											<textarea class="form-control" rows="2"
												placeholder="Write Something here....."
												onChange={(e) => setCheckedQues7(e.target.value)}
												data-quesId="7"
												defaultValue={checkedQues7}
											></textarea>
										</div>
									</div>
									{/* <div className="col-lg-4">
                    <div className="box mt-3">
                      <form>
                        <input id="demo1" className="demo1" type="file" multiple placeholder="Select Files" name="demo1[]" />
                      </form>
                    </div>
                  </div> */}


									<div class="file-input">
										<input type="file" name="file-input" id="file-input" class="file-input__input" onChange={onImageChange} />
										<label class="file-input__label" for="file-input">
											<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" class="svg-inline--fa fa-upload fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
												<path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
											</svg>
											<span>Upload file</span></label>
										<a href={AdminUrl + "/public/mascul/" + imageUrl} target="__blank">{imageUrl}</a>
									</div>


								</div>






							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="65%"
										draggable="false"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-22" />
								{/* <NextBtn link="/module-05/chapter-02-screen-24" text="Go Next"/> */}

								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen17;
