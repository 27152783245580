import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen17 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-2">
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3 mt-3">Learn   </h3>
								<p className="mb-2  mt-3">The idea behind laws is to protect individuals’ rights and freedoms.  In the case of India, we have seen in several cases, state and legal protection for inter-caste and inter-faith marriages where couples are threatened or intimidated (recall Chapter 1). However, this has not been the case for diverse sexual and gender identities. Sexuality laws have been made in such a way that LGBTQIA+ communities have been given an inferior status based on who they love. The laws invade their privacy, ability to have relationships, and degrade their dignity. Section 377 of the Indian Penal Code is an example of this!</p>
								<p className="mb-2  mt-3"> <i>“Whoever voluntarily has carnal intercourse against the order of nature with any man, woman, or animal, shall be punished with imprisonment for life, or with imprisonment of either description for a term which may extend to 10 years, and shall also be liable for fine.”</i> </p>

							</div>

							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/17.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-15" />
								<NextBtn link="/module-05/chapter-02-screen-18" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen17;
