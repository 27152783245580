import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../config/Baseurl";
import { useEffect } from "react";
import { useState } from "react";
import Select from 'react-select'



const Header = (props) => {

	let location = useLocation();
	let url = location.pathname;
	const last = url.split("/")
	const module = last[last.length - 2];

	const navigate = useNavigate();
	const [totalStar, setTotalStar] = useState(0);
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const [module1, setModule1] = useState(module);
	// const [module2,setModule2] = useState();
	// const [module3,setModule3] = useState();
	// const [module4,setModule4] = useState('');
	// const [module5,setModule5] = useState('');
	// const [module6,setModule6] = useState('');

	const user_id = (user_details === null || user_details === '') ? "" : user_details.user_id;
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_id,

		});
		var config = {
			method: 'post',
			url: BaseURL + '/gettotalStars',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				console.log(response);
				if (response.data.success === true) {
					setTotalStar(response.data.data.user_star);
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	const goToFaq = () => {
		const pathdata = location.pathname
		localStorage.setItem('previous_link', JSON.stringify(pathdata));
		navigate("/faq")
	}

	const goToProfile = () => {
		const pathdata = location.pathname
		localStorage.setItem('previous_link', JSON.stringify(pathdata));
		navigate("/profile")
	}


	const [selectedItem, setSelectedItem] = useState("Module");
	const [dropdown, setDropdown] = useState(0);
	const sItem = (val) => {
		setSelectedItem(val);
		setDropdown(0);
	}

	const handleChange12 = (e) => {
		const val = e.target.value;
		if (val === 'module1') {
			navigate('/module-01/chapter-01-intro-screen');
		}

		if (val === 'module2') {
			navigate('/module-02/chapter-01-intro-screen');
		}

		if (val === 'module3') {
			navigate('/module-03/chapter-01-intro-screen');
		}

		if (val === 'module4') {
			navigate('/module-04/module-04-intro-screen');
		}
		if (val === 'module5') {
			navigate('/module-05/module-05-intro-screen');
		}

		if (val === 'module6') {
			navigate('/module-06/module-06-intro-screen');
		}



		// alert(e.target.value);
	}


	const Checked = () => {

		if (module1 === 'module-01') {
			return (
				<>
					<select className="manage-select" onChange={handleChange12}>
						<option value="module2" ><NavLink to="/module-02/chapter-01-intro-screen">Understanding Self</NavLink> </option>
						<option value="module1" selected ><NavLink to="/module-01/chapter-01-intro-screen">Understanding Gender</NavLink> </option>
						<option value="module5" >Understanding Sexuality{" "}</option>
						<option value="module4"><NavLink to="/module-04/module-04-intro-screen">Understanding Masculinities</NavLink> </option>
						<option value="module3" ><NavLink to="/module-03/chapter-01-intro-screen">Understanding Human Rights</NavLink> </option>
						<option value="module6">Breakthrough Approach</option>
					</select>
				</>
			);
		} else if (module1 === 'module-02') {
			return (
				<>
					<select className="manage-select" onChange={handleChange12}>
						<option value="module2" selected><NavLink to="/module-02/chapter-01-intro-screen">Understanding Self</NavLink> </option>
						<option value="module1"  ><NavLink to="/module-01/chapter-01-intro-screen">Understanding Gender</NavLink> </option>
						<option value="module5" >Understanding Sexuality{" "}</option>
						<option value="module4"><NavLink to="/module-04/module-04-intro-screen">Understanding Masculinities</NavLink> </option>
						<option value="module3" ><NavLink to="/module-03/chapter-01-intro-screen">Understanding Human Rights</NavLink> </option>
						<option value="module6">Breakthrough Approach</option>
					</select>
				</>
			);
		} else if (module1 === 'module-03') {
			return (
				<>
					<select className="manage-select" onChange={handleChange12}>
						<option value="module2" ><NavLink to="/module-02/chapter-01-intro-screen">Understanding Self</NavLink> </option>
						<option value="module1"  ><NavLink to="/module-01/chapter-01-intro-screen">Understanding Gender</NavLink> </option>
						<option value="module5" >Understanding Sexuality{" "}</option>
						<option value="module4"><NavLink to="/module-04/module-04-intro-screen">Understanding Masculinities</NavLink> </option>
						<option value="module3" selected><NavLink to="/module-03/chapter-01-intro-screen">Understanding Human Rights</NavLink> </option>
						<option value="module6">Breakthrough Approach</option>
					</select>
				</>
			);
		} else if (module1 === 'module-04') {
			return (
				<>
					<select className="manage-select" onChange={handleChange12}>
						<option value="module2" ><NavLink to="/module-02/chapter-01-intro-screen">Understanding Self</NavLink> </option>
						<option value="module1"  ><NavLink to="/module-01/chapter-01-intro-screen">Understanding Gender</NavLink> </option>
						<option value="module5" >Understanding Sexuality{" "}</option>
						<option value="module4" selected><NavLink to="/module-04/module-04-intro-screen">Understanding Masculinities</NavLink> </option>
						<option value="module3" ><NavLink to="/module-03/chapter-01-intro-screen">Understanding Human Rights</NavLink> </option>
						<option value="module6">Breakthrough Approach</option>
					</select>
				</>
			);
		} else if (module1 === 'module-05') {
			return (
				<>
					<select className="manage-select" onChange={handleChange12}>
						<option value="module2" ><NavLink to="/module-02/chapter-01-intro-screen">Understanding Self</NavLink> </option>
						<option value="module1"  ><NavLink to="/module-01/chapter-01-intro-screen">Understanding Gender</NavLink> </option>
						<option value="module5" selected>Understanding Sexuality{" "}</option>
						<option value="module4" ><NavLink to="/module-04/module-04-intro-screen">Understanding Masculinities</NavLink> </option>
						<option value="module3" ><NavLink to="/module-03/chapter-01-intro-screen">Understanding Human Rights</NavLink> </option>
						<option value="module6">Breakthrough Approach</option>
					</select>
				</>
			);
		} else if (module1 === 'module-06') {
			return (
				<>
					<select className="manage-select" onChange={handleChange12}>
						<option value="module2" ><NavLink to="/module-02/chapter-01-intro-screen">Understanding Self</NavLink> </option>
						<option value="module1"  ><NavLink to="/module-01/chapter-01-intro-screen">Understanding Gender</NavLink> </option>
						<option value="module5">Understanding Sexuality</option>
						<option value="module4" ><NavLink to="/module-04/module-04-intro-screen">Understanding Masculinities</NavLink> </option>
						<option value="module3" ><NavLink to="/module-03/chapter-01-intro-screen">Understanding Human Rights</NavLink> </option>
						<option value="module6" selected>Breakthrough Approach</option>
					</select>
				</>
			);
		}

	}


	return (
		<>
			<header className="navbar navbar-expand-lg">



				<NavLink className="navbar-brand" to="/home">

					<div className="home-icon">
						<i className="bi bi-house-door-fill" />
					</div>

				</NavLink>

				{/* <a className="navbar-brand" href="#">
            <div className="home-icon">
              <i className="bi bi-house-door-fill" />
            </div>
          </a> */}
				<button className="navbar-toggler justify-content-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon" />
					<span className="navbar-toggler-icon" />
					<span className="navbar-toggler-icon" />
				</button>







				<div className="collapse navbar-collapse" id="navbarSupportedContent">
					<div className="navbar-nav me-auto mb-2 mb-lg-0 mt-m-1">
						<div className="d-flex">
							<div class="dropdown">
								<button class="btn btn-secondary dropdown-toggle arrow-none" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
									About  <i class="bi bi-chevron-down"></i>
								</button>
								<ul class="dropdown-menu " aria-labelledby="dropdownMenuButton1">
									<li><NavLink to="/about-breakthrough" className="dropdown-item">About Breakthrough</NavLink></li>
									<li><NavLink to="/about-course" className="dropdown-item">About Course</NavLink></li>
								</ul>
							</div>

							<div class="dropdown">
								<button class="btn btn-secondary dropdown-toggle arrow-none" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
									Module  <i class="bi bi-chevron-down"></i>
								</button>
								<ul class="dropdown-menu " aria-labelledby="dropdownMenuButton1">
									<li><NavLink to="/module-02/chapter-01-intro-screen" className="dropdown-item">Understanding Self</NavLink> </li>
									<li><NavLink to="/module-01/chapter-01-intro-screen" className="dropdown-item">Understanding Gender</NavLink> </li>
									<li><NavLink to="/module-05/module-05-intro-screen" className="dropdown-item">Understanding Sexuality</NavLink> </li>
									<li><NavLink to="/module-04/module-04-intro-screen" className="dropdown-item">Understanding Masculinities</NavLink> </li>
									<li><NavLink to="/module-03/chapter-01-intro-screen" className="dropdown-item">Understanding Human Rights</NavLink> </li>
									<li><NavLink to="/module-06/module-06-intro-screen" className="dropdown-item">Breakthrough Approach</NavLink> </li>
								</ul>
							</div>




							<div className="d-flex">


								{/* <Checked/> */}

								{/* <Select className="redesign" options={options} /> */}
							</div>
						</div>
					</div>
					<ul className="nav nav-pills header-ul " >
						<li className="nav-item">
							<div className="d-flex prog-box">
								<div>
									<span className="prog-text">{props.leftChapter}</span>
								</div>
								<div className="progress-wset">
									<div className="progress">
										<div
											className="progress-bar"
											role="progressbar"
											style={{ width: `${props.percentage}%` }}
											aria-valuenow={0}
											aria-valuemin={0}
											aria-valuemax={100}
										/>
									</div>
								</div>
								<div>
									<span className="prog-text">Progress</span>
								</div>
							</div>
						</li>
						<li className="nav-item">
							<div className="d-flex prog-box">
								<h3 className="m-star-icon">
									<i className="bi bi-star-fill" /> {totalStar}
								</h3>
							</div>
						</li>
						<li className="nav-item">
							<button onClick={goToProfile} className="nav-link">
								{" "}
								<img src="/assets/img/chapter1/user.png" alt="" />
							</button>
						</li>
						<li className="nav-item">
							<button onClick={goToFaq} className="nav-link">
								{" "}
								<img src="/assets/img/user.png" alt="" />
							</button>
						</li>

					</ul>
				</div>


				{/* <ul className="nav nav-pills header-ul " >
          <li className="nav-item">
            <div className="d-flex prog-box">
              <div>
                <span className="prog-text">{props.leftChapter}</span>
              </div>
              <div className="progress-wset">
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${props.percentage}%` }}
                    aria-valuenow={0}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
              </div>
              <div>
                <span className="prog-text">Progress</span>
              </div>
            </div>
          </li>
          <li className="nav-item">
            <div className="d-flex prog-box">
              <h3 className="m-star-icon">
                <i className="bi bi-star-fill" /> {totalStar}
              </h3>
            </div>
          </li>
          <li className="nav-item">
            <NavLink to="/profile" className="nav-link">
              {" "}
              <img src="/assets/img/chapter1/user.png" alt="" />
            </NavLink>
          </li>
          <li className="nav-item">
            <button onClick={goToFaq} className="nav-link">
              {" "}
              <img src="/assets/img/user.png" alt="" />
            </button>
          </li>

        </ul> */}











			</header>
		</>
	);
};

export default Header;
