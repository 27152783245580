import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
const Chapter02Screen04 = () => {
	const navigate = useNavigate();
	const [checkedQues1, setCheckedQues1] = useState([]);
	const [checkedQues1Option1, setCheckedQues1Option1] = useState('');
	const [checkedQues1Option2, setCheckedQues1Option2] = useState('');
	const [checkedQues1Option3, setCheckedQues1Option3] = useState('');
	const [checkedQues2Option1, setCheckedQues2Option1] = useState('');
	const [checkedQues2Option2, setCheckedQues2Option2] = useState('');
	const [checkedQues2Val, setCheckedQues2Val] = useState('');
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);
		checkedVal(quesId, e.target.value)
	}
	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;

		checkBoxChecked(res, checked, quesId);
		if (quesId === '1') {
			setCheckedQues1(checkedQues1 => [...checkedQues1, res]);
		}
	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues1.join(','),
					"ques_id": "1"
				},
				"ques2": {
					"ans": checkedQues2Val,
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-02-screen-05')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	// console.log(checkedQues1.length);
	const BtnDisabled = () => {
		if (checkedQues1.length > 0 && checkedQues2Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '1') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues1(ansArr)
		}
		if (param === '2') {
			if (ans === 'option1') {
				setCheckedQues2Option1('checked')
				setCheckedQues2Option2('');
			} else if (ans === 'option2') {
				setCheckedQues2Option1('')
				setCheckedQues2Option2('checked');
			}
			setCheckedQues2Val(ans)
		}
	}
	const checkBoxChecked = (val, checked, quesId) => {
		if (quesId === '1') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues1Option1('checked');
				} else {
					setCheckedQues1Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues1Option2('checked');
				} else {
					setCheckedQues1Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues1Option3('checked');
				} else {
					setCheckedQues1Option3('');
				}
			}
		}
	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="25" star="2" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-0 mt-m-3">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-2">Harbhajan too confirmed this and said they had kissed and made up. "It's not a big issue, people making it an issue. As far as both of us are concerned, the matter is solved. Nothing happened between us. He is like my younger brother and respects me. I did push him, may be just a little too hard, but later apologised. We sat, we talked and it's over. I told him I was sorry if he felt bad. We have been together for so long and have to go a long way. These things are between us. We are over with it." </p>

								<p className="mb-2">IPL Chairman Lalit Modi says the authorities have not received any complaint and will take action only after an official complaint is lodged.{" "} </p>
								<p className="mb-2 mt-2">He also said if the match referee complains, action will be taken according to the ICC law.{" "} </p>
								<h4 className="mb-2 pn"> <span> <b>1.</b></span> <b>What form of violation do you see in this news article? Select as many that apply. </b></h4>
								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle"
											defaultValue="option1"
											onClick={saveGenderBased}
											checked={checkedQues1Option1}
											data-quesId="1"
										/>
										<label className="form-check-label" htmlFor="mycirle"> Physical  </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle2"
											defaultValue="option2"
											onClick={saveGenderBased}
											checked={checkedQues1Option2}
											data-quesId="1"
										/>
										<label className="form-check-label" htmlFor="mycirle2">Emotional/ Psychological </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle3"
											defaultValue="option3"
											onClick={saveGenderBased}
											checked={checkedQues1Option3}
											data-quesId="1"
										/>
										<label className="form-check-label" htmlFor="mycirle3"> Sexual </label>
									</div>
								</div>
								<h4 className="mb-2 mt-3 pn"> <span> <b>2.</b></span> <b>Who committed the violation and against whom? </b></h4>
								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio" name="namecircle" id="ns"
											defaultValue="option1"
											onClick={saveRadio}
											checked={checkedQues2Option1}
											data-quesId="2"
										/>
										<label className="form-check-label" htmlFor="ns"> Harbhajan committed a violation against S. Sreesanth</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio" name="namecircle" id="ns2"
											defaultValue="option2"
											onClick={saveRadio}
											checked={checkedQues2Option2}
											data-quesId="2"
										/>
										<label className="form-check-label" htmlFor="ns2">S. Sreesanth committed a violation against Harbhajan </label>
									</div>
								</div>
							</div>
							<div
								className="col-lg-3 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md3/md3-shap-2-4.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-02-screen-02" />
									{/* <NextBtn link="/module-03/chapter-02-screen-05" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Chapter02Screen04;
