import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";

const Chapter02Screen02 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2 hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="67" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-3">
							<div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">

								<h3 className="h3 ">Let’s look at how Breakthrough’s work on adolescent empowerment has been able to create the norm shifts and impact. </h3>

								<div className="row row-cols-2 row-cols-lg-5 mt-4">
									<div className="col d-flex">
										<div className="serviceBox">
											<div className="service-icon">
												<center><img src="/assets/img/md6/adolescent-empowerment-program.gif" alt="" /></center>
											</div>
											<p className="description mt-2">Increase in the age of marriage for girls by almost <span class="red">2 years</span> in Uttar Pradesh.</p>
											<a href="https://inbreakthrough.org/adolescent-empowerment-programme-endline-survey-from-uttar-pradesh-proves-that-social-norm-change-is-possible/" className="read-more" target="_blank">Know More <i class="bi bi-chevron-right"></i></a>
										</div>
									</div>
									<div className="col  d-flex">
										<div className="serviceBox">
											<div className="service-icon">
												<center><img src="/assets/img/md6/sexual-harassment.gif" alt="" /></center>
											</div>
											<p className="description mt-2">Transformed <span class="red">15.5%</span> of gender-regressive views of adolescents in Haryana.</p>
											<a href="https://inbreakthrough.org/building-gender-equitable-behaviours-in-adolescents-through-government-partnerships/" className="read-more" target="_blank">Know More <i class="bi bi-chevron-right"></i></a>
										</div>
									</div>

									<div className="col  d-flex">
										<div className="serviceBox">
											<div className="service-icon">
												<center><img src="/assets/img/md6/early-marriage.gif" alt="" /></center>
											</div>
											<p className="description mt-2"> Likelihood of <span class="red">girls being enrolled</span> in school increased by <span class="red">89%</span> in Bihar.</p>
											<a href="https://inbreakthrough.org/early-marriage-and-continued-learning/" className="read-more" target="_blank">Know More <i class="bi bi-chevron-right"></i></a>
										</div>
									</div>

									<div className="col  d-flex">
										<div className="serviceBox">
											<div className="service-icon">
												<center><img src="/assets/img/md6/gender-biased-sex-selection.gif" alt="" /></center>
											</div>
											<p className="description mt-2"> <span class="red">17%</span> increase in adolescent girls visiting a health facility in Uttar Pradesh.</p>
											<a href="https://inbreakthrough.org/adolescent-empowerment-programme-endline-survey-from-uttar-pradesh-proves-that-social-norm-change-is-possible/" className="read-more" target="_blank">Know More <i class="bi bi-chevron-right"></i></a>
										</div>
									</div>

									<div className="col d-flex">
										<div className="serviceBox">
											<div className="service-icon">
												<center><img src="/assets/img/md6/domestic-violence.gif" alt="" /></center>
											</div>
											<p className="description mt-2"> Increased reporting of violence, both <span class="red">physical and otherwise</span> in Uttar Pradesh.</p>
											<a href="https://inbreakthrough.org/adolescent-empowerment-programme-endline-survey-from-uttar-pradesh-proves-that-social-norm-change-is-possible/" className="read-more" target="_blank">Know More <i class="bi bi-chevron-right"></i></a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-06/chapter-03-screen-05" />
								<NextBtn link="/module-06/chapter-03-screen-07" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
