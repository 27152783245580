import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter05Screen13 = () => {
	return (
		<>
			<div className="wrapper hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="97" star="80" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-2">

							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2 mb-2">Learn</h2>
								<p>
									How can one come out of a violent situation/ deal with
									violence? <br />
									Role of individuals in violence prevention.{" "}
								</p>
								<p className="mt-3">
									<b>Mann Ke Manjeere | Breakthrough India</b>
								</p>

								<a href="https://twitter.com/inbreakthrough/status/1148916038488080385" target="100%">
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Mann Ke Manjeere-Story </p>
										</div>
									</div>
								</a>

							</div>
							<div
								className="col-lg-6 mt-2 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<iframe
									width="100%"
									height="315"
									src="https://www.youtube.com/embed/LsFha77l3RY"
									title="YouTube video player"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-05-screen-12" />
								<NextBtn
									link="/module-01/chapter-05-screen-14"
									text="Go Next"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen13;
