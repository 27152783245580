import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
const Chapter04Screen06 = () => {

	const navigate = useNavigate();
	const [checkedQues9, setCheckedQues9] = useState('');
	const [checkedQues10, setCheckedQues10] = useState('');
	const [checkedQues11, setCheckedQues11] = useState('');




	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",

					"ques_id": "3"
				},
				"ques4": {
					"ans": "",

					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": checkedQues9,
					"ques_id": "9"
				},
				"ques10": {
					"ans": checkedQues10,
					"ques_id": "10"
				},
				"ques11": {
					"ans": checkedQues11,
					"ques_id": "11"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-04-screen-07')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					results.map((result) => {
						console.log(result);
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);
	const checkedVal = (param, ans) => {
		if (param === '9') {
			setCheckedQues9(ans)

		}

		if (param === '10') {
			setCheckedQues10(ans)

		}

		if (param === '11') {
			setCheckedQues11(ans)

		}


	}
	const BtnDisabled = () => {
		if (checkedQues9 !== '' && checkedQues10 !== '' && checkedQues11 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="36" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-top justify-content-between pt-20 mt-2">

							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>


								<p className=" mt-2"> <b>Here are examples of some actions taken at state or national level</b></p>
								<a
									href="https://www.youtube.com/watch?v=j994bFHc9Bc"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Mazhab मज़हब by Poojan Sahil | Karwan e Mohabbat</p>
										</div>
									</div>
								</a>
								<a
									href="https://www.youtube.com/watch?v=SZhlnwArGXA&list=PLWqowEyMVW8ZUeG-7AXJT6Tj8gmrBC1z2&index=2"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>#COVIDHeroes | Compassion In The Times Of COVID-19 | #HumLog | Karwan e Mohabbat</p>
										</div>
									</div>
								</a>
								<a
									href="https://www.youtube.com/watch?v=X4RgCtyp33E&list=PLWqowEyMVW8ZUeG-7AXJT6Tj8gmrBC1z2&index=9"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>COVID-19 Second Wave In India | In Solidarity With The Working Poor | Karwan e Mohabbat</p>
										</div>
									</div>
								</a>



								{/* <p className=" mt-3"><b>Think of one Human right that you do not have access to?</b></p> */}
								<p className=" mt-3"><b>Think of a human right which you wished everyone had access to but do not?</b></p>
								<div className="mt-1 positon-submit">
									<textarea className="form-control fc-height" rows={1} placeholder="Type your Answer......."
										defaultValue={checkedQues9}
										onChange={(e) => setCheckedQues9(e.target.value)}
									/>
								</div>

								<p className=" mt-3"><b>Why does everyone not have access?</b></p>
								<div className="mt-1 positon-submit">
									<textarea className="form-control fc-height" rows={1} placeholder="Type your Answer......."

										defaultValue={checkedQues10}
										onChange={(e) => setCheckedQues10(e.target.value)}

									/>
								</div>
								<p className=" mt-3"><b>What can you do?</b></p>
								<div className="mt-1 positon-submit">
									<textarea className="form-control fc-height" rows={1} placeholder="Type your Answer......."

										defaultValue={checkedQues11}
										onChange={(e) => setCheckedQues11(e.target.value)}
									/>
								</div>







							</div>

							<div
								className="col-lg-3 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										className="mt-0"
										width="65%"
										src="/assets/img/md3/md3-shap-4-06.png"
										alt=""
									/>
								</center>
							</div>

						</div>


					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-04-screen-05n" />
									{/* <NextBtn link="/module-03/chapter-04-screen-07" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen06;
