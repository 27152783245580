import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";


const Chapter01Screen22 = () => {

	const navigate = useNavigate();
	const [checkedQues5_1, setCheckedQues5_1] = useState('');
	const [checkedQues5_2, setCheckedQues5_2] = useState('');
	const [checkedQues5_3, setCheckedQues5_3] = useState('');



	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",


					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ans2": "",
					"ans3": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": checkedQues5_1,
					"ans2": checkedQues5_2,
					"ans3": checkedQues5_3,
					"ques_id": "5"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-01-screen-23')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					results.map((result) => {
						// console.log(result);
						checkedVal(result.ques_id.toString(), result.ans, result.ans2, result.ans3)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const checkedVal = (param, ans, ans2, ans3) => {
		if (param === '5') {
			setCheckedQues5_1(ans)
			setCheckedQues5_2(ans2)
			setCheckedQues5_3(ans3)


		}




	}


	const BtnDisabled = () => {
		if (checkedQues5_1 !== '' || checkedQues5_2 !== '' || checkedQues5_3 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="2" star="1" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Which 3 institutions are directly responsible for it?</h3>
							</div>
							<div
								className="col-lg-8 mt-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>



								<div className="mb-3 positon-submit">
									<textarea
										className="form-control"
										rows={1}
										placeholder="Type your Answer........"
										onChange={(e) => setCheckedQues5_1(e.target.value)}
										data-quesId="2"
										defaultValue={checkedQues5_1}
									/>
								</div>

								<div className="mb-3 positon-submit">
									<textarea
										className="form-control"
										rows={1}
										placeholder="Type your Answer......."
										onChange={(e) => setCheckedQues5_2(e.target.value)}
										data-quesId="2"
										defaultValue={checkedQues5_2}
									/>
								</div>

								<div className="mb-3 positon-submit">
									<textarea
										className="form-control"
										rows={1}
										placeholder="Type your Answer........"
										onChange={(e) => setCheckedQues5_3(e.target.value)}
										data-quesId="2"
										defaultValue={checkedQues5_3}
									/>
								</div>

							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										className="mt-4"
										width="68%"
										src="/assets/img/md3/md3-shap-3-22.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-01-screen-21" />

									{/* <NextBtn link="/module-03/chapter-01-screen-23" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen22;
