import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter03Screen37 = () => {
  const navigate = useNavigate();
  const [checkedQues18Option1, setCheckedQues18Option1] = useState("");
  const [checkedQues18Option2, setCheckedQues18Option2] = useState("");
  const [checkedQues18Option3, setCheckedQues18Option3] = useState("");
  const [checkedQues18Option4, setCheckedQues18Option4] = useState("");
  const [checkedQues18Val, setCheckedQues18Val] = useState("");

  const [checkedQues16, setCheckedQues16] = useState([]);
  const [option1Checked, setOption1Checked] = useState("");
  const [option2Checked, setOption2Checked] = useState("");
  const [option3Checked, setOption3Checked] = useState("");
  const [option4Checked, setOption4Checked] = useState("");

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const saveRadio = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    // alert(quesId);
    checkedVal(quesId, e.target.value);
  };

  const saveAns = () => {
    var data = JSON.stringify({
      user_id: user_details.user_id,
      answers: {
        ques1: {
          ans: "",
          ques_id: "1",
        },

        ques18: {
          ans: checkedQues18Val,
          ques_id: "18",
        },
      },
    });
    var config = {
      method: "post",
      url: BaseURL + "/SexualityChapter03",
      headers: {
        Authorization: `Bearer ${user_details.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.status === "Token is Expired") {
          window.localStorage.clear();
          navigate("/login");
        }
        if (response.data.message === "Token Invalid") {
          window.localStorage.clear();
          navigate("/login");
        }

        if (response.data.success === true) {
          navigate("/module-05/chapter-03-screen-38");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const BtnDisabled = () => {
    if (checkedQues18Val !== "") {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param, ans) => {
    if (param === "18") {
      if (ans === "option1") {
        setCheckedQues18Option1("checked");
        setCheckedQues18Option2("");
        setCheckedQues18Option3("");
        setCheckedQues18Option4("");
      } else if (ans === "option2") {
        setCheckedQues18Option1("");
        setCheckedQues18Option2("checked");
        setCheckedQues18Option3("");
        setCheckedQues18Option4("");
      } else if (ans === "option3") {
        setCheckedQues18Option1("");
        setCheckedQues18Option2("");
        setCheckedQues18Option3("checked");
        setCheckedQues18Option4("");
      } else if (ans === "option4") {
        setCheckedQues18Option1("");
        setCheckedQues18Option2("");
        setCheckedQues18Option3("");
        setCheckedQues18Option4("checked");
      }
      setCheckedQues18Val(ans);
    }
  };
  const checkBoxChecked = (val, checked) => {
    if (val === "option1") {
      if (checked === true) {
        setOption1Checked("checked");
      } else {
        setOption1Checked("");
      }
    }
    if (val === "option2") {
      if (checked === true) {
        setOption2Checked("checked");
      } else {
        setOption2Checked("");
      }
    }
    if (val === "option3") {
      if (checked === true) {
        setOption3Checked("checked");
      } else {
        setOption3Checked("");
      }
    }
    if (val === "option4") {
      if (checked === true) {
        setOption4Checked("checked");
      } else {
        setOption4Checked("");
      }
    }
  };
  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_details.user_id,
      ques_id: "",
    });
    var config = {
      method: "post",
      url: BaseURL + "/sexualityChapter03Result",
      headers: {
        Authorization: `Bearer ${user_details.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(), result.ans);
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="2/4" percentage="50" star="50" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-1">
              <div
                className="col-lg-8 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <p className="mb-2  mt-3">
                  Addressing power dynamics is important to create relationships
                  where pleasure and safety are prioritised and never
                  compromised. Unlearning ideas of patriarchy and power can help
                  give us more agency, correct myths and discard our prejudices,
                  understand risks and stand up for our rights – <b>covered in
                  detail in our next chapter.{" "}</b>
                </p>
              </div>

              <div
                className="col-lg-4 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="40%"
                    draggable="false"
                    src="/assets/img/md5/md-5-chap-1-15.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-05/chapter-03-screen-36" />
                <NextBtn link="/module-05/chapter-03-screen-38" text="Go Next"/>
                {/* <BtnDisabled /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen37;
