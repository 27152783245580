import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen03 = () => {
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header "

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/5" percentage="22" star="10" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.1s"
							>
								<h1 className="h1 text-left">Learn </h1>
								<p className="text-left mb-3">
									We have all heard these messages throughout our lives from
									different people at different places. This is part of the gender
									socialisation process which reinforces the idea of gender binary
									and within that, the idea of masculinity for men and femininity
									for women. This socialisation process conditions us to remain in
									boxes of male (men) and female (women) as defined by the gender
									norms in the society and perform roles accordingly. These norms
									inform what we should wear, how we should sit, eat, walk, what
									sports we play, what we study, where we work, who we become
									friends with, who and when we marry, what values we should hold
									among other things, our entire personality and our decisions are
									influenced by this socialisation process. It creates a belief
									system which sees the above statements as true even when they are
									not.{" "}
								</p>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center><img width="45%" src="/assets/img/md1/md1-shap-2-3.png" alt="" /></center>

							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-02-screen-02" />

								<NextBtn link="/module-01/chapter-02-screen-04" text="Go Next" classtype="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	);
};

export default Chapter02Screen03;
