import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";

const Chapter04Screen18 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-0">



							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Learn </h3>
								<p className="mb-2 mt-3"> During sexual activity, individuals are at risk of sexually transmitted infections or STIs that spread through close sexual contact, intercourse, and sometimes even sharing an infected needle. Some methods of contraception, such as condoms, provide additional protection against STIs. </p>
								<p className="mb-2 mt-3">STI’s are rarely talked about in society, when they are it is through a lens of shame and discrimination. There are several misconceptions perpetuated around STIs that can make affected individuals shy away from treatment, continue risky sexual behaviour and have many other impacts on their life.  </p>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="70%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-4-18.png"
										alt=""
									/>
								</center>
							</div>






						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-17" />
								<NextBtn link="/module-05/chapter-04-screen-19" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>




			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>
							<p className="text-white text-center">Sexting is the digital sharing of intimate images or videos with another person. It is a private type of self-disclosure. While it can bring partners closer, it may also carry some risks. </p>


							<div className=" d-flex justify-content-center mt-3">
								<button className="button_su m-auto" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="/module-05/chapter-03-screen-11" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen18;
