import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter02Screen10 = () => {
  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="1/5" percentage="40" star="36" />
          </div>
        </div>
       

        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between pt-20 mt-5 mt-m-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                
                <div className="d-flex ">
                <label className="form-check-label" htmlFor="mycirle">
                
                Ram is a man with visual impairment; he lost his eyesight when
                  he was three years old. He didn't have many friends in school
                  where he was bullied. His mother took care of him when he was
                  young and he feels he has depended on others for support all
                  his life. He is thrilled hearing the exploits of Ronaldo, the
                  Brazilian soccer player. &nbsp;

                  <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="a"
                      id="mycirle"
                      data-bs-toggle="modal" data-bs-target="#staticBackdropC"
                      
                      
                    />
                    </label>

                 
                </div>

               
                <div className="d-flex ">
                <label className="form-check-label" htmlFor="mycirle">
                Mohit is a manual labourer. As a child, he saw his father beat
                  his mother who did a lot of work at home and outside. Growing
                  up, he questioned why women in the village and in the TV shows
                  he watched were treated unfairly. He treats women and girls
                  differently. He shares in household work, and consults his
                  wife for making decisions. When other men make fun of him, he
                  is not perturbed. As he says, “I prefer to be a different kind
                  of man.”  &nbsp;
                  <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="a"
                      id="mycirle"
                      data-bs-toggle="modal" data-bs-target="#staticBackdropE"
                      
                      
                    />
                    </label>

                 
                </div>

                <div className="d-flex ">
                <label className="form-check-label" htmlFor="mycirle">
                
                Aditya is a software engineer who has worked in 3 continents –
                  modern, educated and well-read. His friends are impressed that
                  he and his wife (who is a scientist) have travelled widely and
                  treat each other as friends. At home, there is a strict
                  division of space and roles. The kitchen is the domain of his
                  wife – he only expects her to serve him food he likes. The
                  living room is his domain, where he watches TV and discusses
                  politics with his friends.  &nbsp;
                  <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="a"
                      id="mycirle"
                      data-bs-toggle="modal" data-bs-target="#staticBackdropB"
                      
                    />
                    </label>

                 
                </div>


                <div className="d-flex ">
                <label className="form-check-label" htmlFor="mycirle">
                
                Girish is a local landlord; he is proud that he is aggressive
                  and keeps his workers and his family under his strong control.
                  He hates men who are "soft" and gentle. He himself slams his
                  wife if she ever talks back at him. &nbsp;
                  <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="a"
                      id="mycirle"
                      data-bs-toggle="modal" data-bs-target="#staticBackdropA"
                    />
                    </label>

                 
                </div>

               
               
              </div>
            </div>
          </div>
        </div>

        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-04/chapter-02-screen-09"/>  
                <NextBtn link="/module-04/chapter-02-screen-11" text="Go Next"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

            {/* group d */}
            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <h2 className="h2 text-white">Group D</h2>


              <p className="text-white">  Men who exhibit qualities that are opposite to those valued in Group A such as physical weakness and expressing emotions etc.
              Dominated and bullied by other men for being weak and different.
              </p>

            


              
          

            </div>
          </div>
        </div>
      </div>

      {/* group A */}

      <div className="modal fade" id="staticBackdropA" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <h2 className="h2 text-white">Group A</h2>


              <ul class="list-style-set list-circle text-white">
              <li>Enjoys power over women and other subordinate men physically,psychologically and financially</li>
              <li>Believes in being physically strong and aggressive</li>
              <li>Encourages competition, distrust and insecurity</li>
              <li>Sees kindness, compassion and expressing emotion as weakness</li>
              <li>Believes in gender roles- eg: women should do household work and caregiving and men should be breadwinners</li>
              <li>Believes that a household’s honour is in keeping their girls and women safe and protected</li>
              </ul>

            


              
          

            </div>
          </div>
        </div>
      </div>

        {/* group B */}

        <div className="modal fade" id="staticBackdropB" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <h2 className="h2 text-white">Group B</h2>
              <ul class="list-style-set text-white list-circle mt-3">
              <li>Is not overtly abusive or violent</li>
              <li>Accepts things as they are, and goes along with it</li>
              <li>Does not question or challenge if men have more power</li>
              <li>Usually comes from a privileged category belongs to upper caste,upper or middle class, majority religion, is cisgender, heterosexual and able bodied</li>
              <li>Comes across as kind and understanding</li>
              <li>Enjoys the benefits from the culture of Patriarchy and does not want to let go of the privileges Eg. Division of labour between genders </li>
              </ul>

            


              
          

            </div>
          </div>
        </div>
      </div>

        {/* group C */}

        <div className="modal fade" id="staticBackdropC" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <h2 className="h2 text-white ">Group C</h2>


              <ul class="list-style-set text-white list-circle mt-2">
              <li>Comes from an underprivileged category as they do not belong to the dominant caste, class, religion, and ability status</li>
              <li>Believes in patriarchy, but can’t enjoy it fully, marginalized because of being underprivileged</li>
              <li>Admires people who display traits and behaviours described in group A and are often violated by them</li>
              <li>Does not question or challenge these traits and behaviours</li>
              </ul>
            


              
          

            </div>
          </div>
        </div>
      </div>


       {/* group E */}

       <div className="modal fade" id="staticBackdropE" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <h2 className="h2 text-white ">Group E</h2>


              <ul class="list-style-set list-circle mt-3 text-white">
              <li>Sensitive to gender injustice and male socialization</li>
              <li>Conscious of how all genders are dehumanized by Patriarchy</li>
              <li>Does not accept the status quo</li>
              <li>Questions and challenges the gender norms at individual, family and collective levels. Eg: Shares labour and decision-making with women</li>
              <li>Counters male socialization Eg. Listens to women attentively</li>
              <li>Envisions and strives for an alternate and just world</li>
              <li>Speaks up and/or refrains from using violence </li>
              </ul>
            


              
          

            </div>
          </div>
        </div>
      </div>
      

    </>
  );
};

export default Chapter02Screen10;
