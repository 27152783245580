import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen03 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="27" star="01" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h3 mt-4">Learn</h2>
								<p className="mb-2 mt-3">After this reflection you may still disagree with the decision but you may better understand why this decision was made by your close one. Understanding the value and feeling behind a person’s decision also leads to better understanding about them as a person. Builds empathy.This also applies to you sharing about the value and feeling behind your decision/stance and creating empathy in the other person for your own self. This understanding leads to better communication and collaboration and both these qualities are key when it comes to strengthening your relationships or influencing people and becoming a good leader. </p>
								<p className="mb-2 mt-3">To ensure this, it is important to know how we communicate in difficult situations.</p>

							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="48%"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-03-screen-02" />

								<NextBtn link="/module-02/chapter-03-screen-04" text="Go Next" />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen03;
