import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BaseURL from "../../config/Baseurl";
import axios from "axios";
import HeaderHome from "../../header/HeaderHome";

const ForgotPassword = () => {
	const navigate = useNavigate();
	const [inputValue, setIputValue] = useState();
	const [inputValueErr, setinputValueErr] = useState({});

	const sendOtp = (e) => {
		e.preventDefault();
		const isValid = formValidation();

		if (isValid === true) {
			var data = JSON.stringify({
				emailMobUser: inputValue,
			});

			var config = {
				method: "post",
				url: BaseURL + "/forgotPassword",
				headers: {
					"Content-Type": "application/json",
				},
				data: data,
			};

			axios(config)
				.then(function (response) {
					if (response.data.success === true) {
						const id = response.data.data.id;
						const email = response.data.data.email;
						const email_message_id = response.data.data.email_message_id;
						const message = response.data.message;

						const forgot_password_details = {
							id: id,
							email: email,
							email_message_id: email_message_id,
							message: message,
						};
						localStorage.setItem(
							"forgot_password_details",
							JSON.stringify(forgot_password_details)
						);
						navigate("/otp");
					} else {
						// setMessage(response.data.message);
						// setType("danger");
					}
					// console.log(JSON.stringify(response.data));
				})
				.catch(function (error) {
					console.log(error);
					// setMessage(error);
					// setType("danger");
				});
		}
	};

	//   // form validation
	const formValidation = () => {
		const inputvalueErr = {};

		const inputValue1 = inputValue === undefined ? "" : inputValue;

		let isValid = true;

		// console.log(mobEmail.length);
		if (inputValue1.length === 0) {
			inputvalueErr.required = "Field Is Required";
			isValid = false;
		}

		setinputValueErr(inputvalueErr);

		return isValid;
	};

	return (
		<>
			<div className="wrapper">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<HeaderHome />
					</div>
				</div>
				<form onSubmit={sendOtp}>
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-center pt-20 mt-5">
								<div className="col-lg-5">
									<h2 className="h2 mb-5">Forgot Password</h2>
									{/* <GlobalErr/> */}
									<div className="mb-3">
										<label htmlFor="exampleInputEmail1" className="form-label">
											Email
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="Email......."
											value={inputValue}
											onChange={(e) => {
												setIputValue(e.target.value);
											}}
										/>

										{Object.keys(inputValueErr).map((key) => {
											return (
												<span className="error">{inputValueErr[key]}</span>
											);
										})}
									</div>
									<button
										className="button_su mt-3 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<span className="su_button_circle" />
										<button type="submit" className="button_su_inner">
											<span className="button_text_container">
												{" "}
												Submit <i className="bi bi-arrow-right" />
											</span>
										</button>
									</button>

									<button
										className="button_su mt-3 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<span className="su_button_circle" />
										<NavLink to="/" className="button_su_inner">
											<span className="button_text_container">
												{" "}
												Sign In <i className="bi bi-arrow-right" />
											</span>
										</NavLink>
									</button>

								</div>
								<div
									className="col-lg-3 offset-lg-1 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="100%"
											src="/assets/img/chapter1/Jhole-wali-didi.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default ForgotPassword;
