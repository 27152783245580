import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
const Chapter02Screen06n = () => {
  const navigate = useNavigate();
  const [checkedQues11, setCheckedQues11] = useState('');
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",         
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",        
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "", 
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": "",
          "ques_id": "10"
        },
        "ques11": {
          "ans": checkedQues11,
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        }
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/GenderChapter02',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-01/chapter-02-screen-06')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const checkedVal = (param,ans) => {
    if (param === '11') {
      setCheckedQues11(ans)
    }
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/GenderChapter02Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  const BtnDisabled = () => {
    if (checkedQues11 !== ''  ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  return (
    <>
      <div className="wrapper ">
        <div
          className="fixed-header"
        >
          <div className="container-fluid cf-set">
            <Header leftChapter="1/5" percentage="27" star="13" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-center pt-20 mt-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <p>
                  Reflect on your own personal experience of being judged or
                  discriminated against because you did not fulfill society’s
                  expectations based on gender norms, perceptions and practices.
                  Share in the space below 1 such experience and how did it make you
                  feel? How did you deal with it or what was your response?
                </p>
                <div className="mb-4 mt-4 positon-submit">
                  <textarea
                    className="form-control"
                    rows={6}
                    placeholder="Write Something here....."
                    onChange={(e)=>setCheckedQues11(e.target.value)}
                    data-quesId="11"
                  defaultValue={checkedQues11}
                  />
                  {/* <button
                type="button"
                className="btn btn-primary custom-btn m-auto submit-btn-set"
              >
                Submit
              </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-01/chapter-02-screen-05n1" />
                {/* <NextBtn link="/module-01/chapter-02-screen-06" text="Go Next" classtype="" /> */}
                <BtnDisabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Chapter02Screen06n