import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter02Screen04 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/5" percentage="22" star="22" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p>
									Let’s do an activity to learn about different forms of Masculinities </p>
								<p><b>Make 5 groups based on masculinities represented by men in the given below situations. Given below are the traits, characteristics and behaviours for each group. Place the men displaying similar masculinities together in one group.</b>
								</p>
								<h3 className="h3 mb-1">Group A</h3>
								<ul className="list-style-set list-circle">
									<li>
										Enjoys power over women and other subordinate men
										physically,psychologically and financially
									</li>
									<li>Believes in being physically strong and aggressive</li>
									<li>Encourages competition, distrust and insecurity</li>
									<li>
										Sees kindness, compassion and expressing emotion as weakness
									</li>
									<li>
										Believes in gender roles- eg: women should do household work
										and caregiving and men should be breadwinners
									</li>
									<li>
										Believes that a household’s honour is in keeping their girls
										and women safe and protected
									</li>
								</ul>
							</div>
							<div
								className="col-lg-4 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="38%"
										draggable="false"
										src="/assets/img/md4/md4-shap-2-3.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-02-screen-03" />

									<NextBtn link="/module-04/chapter-02-screen-05" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen04;
