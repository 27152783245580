import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter02Screen07 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/5" percentage="30" star="30" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-3">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h3 mb-1">Group E</h2>
								<ul className="list-style-set list-circle mt-3">
									<li>Sensitive to gender injustice and male socialization</li>
									<li>
										Conscious of how all genders are dehumanized by Patriarchy
									</li>
									<li>Does not accept the status quo</li>
									<li>
										Questions and challenges the gender norms at individual,
										family and collective levels. Eg: Shares labour and
										decision-making with women
									</li>
									<li>
										Counters male socialization Eg. Listens to women attentively
									</li>
									<li>Envisions and strives for an alternate and just world</li>
									<li>Speaks up and/or refrains from using violence </li>
								</ul>
							</div>
							<div
								className="col-lg-4 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md4/md4-shap-2-7.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-02-screen-06" />

									<NextBtn link="/module-04/chapter-02-screen-08" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen07;
