import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter01IntroScreen = () => {
	return (
		<div className="wrapper">
			<div
				className="fixed-header"
			>
				<div className="container-fluid cf-set">
					<Header leftChapter="0/5" percentage="0" star="0" />
				</div>
			</div>
			<div className="data-adjust">
				<div className="container-fluid cf-set">
					<div className="row align-items-top justify-content-between pt-20 mt-5">
						<div className="col-lg-8 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
							<div className="para-color-box ">
								<h3 className="h3-large mb-2">  <b>Objectives of the module</b> </h3>
								<ul className="list-style-set list-circle mt-1">
									<li>Recognise, identify and understand the human rights</li>
									<li>Understand who has power and privilege and how it impacts the access to resources and rights  </li>
									<li>Understand what leads to violence and discrimination of certain social identities</li>
									<li>Learn the connections between rights, responsibilities and duties.</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-4 mt-m-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
							<center>
								<img width="50%" draggable="false" src="/assets/img/chapter1/reading-book.png" alt="" />
							</center>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-04/score" />
								<NextBtn link="/module-03/chapter-01-screen-01" text="Go Next" classtype="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	);
};

export default Chapter01IntroScreen;
