import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen05 = () => {
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/5" percentage="52" star="32" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-5">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.1s"
							>
								<p className="text-justify">
									The system of Patriarchy is maintained through various social,
									cultural, economic and political structures and institutions-
									Family, Education, Media, Religion/Culture, Workplaces,
									Military, Law and the State. We saw in the{" "}
									{/* <b>
                    {" "}
                  
                      जज करें हैं | Breakthrough India
                    {" "}
                  </b>{" "} */}
									video in the last chapter, how family and community expected
									young women to follow gender norms like wearing long clothes
									covering their bodies, not going out, not talking to guys etc.
									to control their sexuality, hence perpetuating Patriarchy. In
									a patriarchal society boys and men are also expected to be
									physically strong, not express emotions, be the primary
									earners in the family, protectors of their family especially
									their wives and daughters and so on. We will explore more
									about this in another module about Understanding
									Masculinities. It is important to note that both men and women
									could practice and perpetuate patriarchy in a society and
									maintain the oppressive structure.  It is crucial to note that while there are set norms and expectations for individuals who fit into the gender binary definitions, gender non-binary or non-conforming and trans people are usually punished and ostracised in a patriarchal society.{" "}
								</p>

								<a
									href="https://www.youtube.com/watch?v=XJqpsdqb1k0"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>जज करें है</p>
										</div>
									</div>
								</a>

							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="52%"
										src="/assets/img/md1/md1-shap-3-6.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-03-screen-04" />

								<NextBtn
									link="/module-01/chapter-03-screen-06"
									text="Go Next"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen05;
