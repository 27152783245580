import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";


const Chapter04Screen02 = () => {

  const navigate = useNavigate();
  const [checkedQues1, setCheckedQues1] = useState([]);
  const [checkedQues1Option1, setCheckedQues1Option1] = useState('');
  const [checkedQues1Option2, setCheckedQues1Option2] = useState('');
  const [checkedQues1Option3, setCheckedQues1Option3] = useState('');
  const [checkedQues1Option4, setCheckedQues1Option4] = useState('');
  const [checkedQues1Option5, setCheckedQues1Option5] = useState('');
  const [checkedQues1Option6, setCheckedQues1Option6] = useState('');

  const [checkedQues2, setCheckedQues2] = useState([]);
  const [checkedQues2Option1, setCheckedQues2Option1] = useState('');
  const [checkedQues2Option2, setCheckedQues2Option2] = useState('');
  const [checkedQues2Option3, setCheckedQues2Option3] = useState('');
  const [checkedQues2Option4, setCheckedQues2Option4] = useState('');
  const [checkedQues2Option5, setCheckedQues2Option5] = useState('');
  const [checkedQues2Option6, setCheckedQues2Option6] = useState('');
  const [checkedQues2Option7, setCheckedQues2Option7] = useState('');

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    // alert(quesId);
    checkedVal(quesId,e.target.value)
  }
  const saveGenderBased = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    const res = e.target.value;
    const checked = e.target.checked;
    checkBoxChecked(res,checked,quesId);
    if(quesId === '1')
    {
      setCheckedQues1(checkedQues1 => [...checkedQues1, res]);
    }

    if(quesId === '2')
    {
      setCheckedQues2(checkedQues2 => [...checkedQues2, res]);
    }

  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans":  checkedQues1.join(','),       
          "ques_id": "1"
        },
        "ques2": {
          "ans": checkedQues2.join(','),         
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",         
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques10": {
          "ans":"",
          "ques_id": "10"
        },
        "ques11": {
          "ans":"",
          "ques_id": "11"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/HumanRightsChapter04',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-03/chapter-04-screen-03')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues1.length > 0 && checkedQues2.length > 0) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {
    if (param === '1') {
      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues1(ansArr)
    }

    if (param === '2') {
      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues2(ansArr)
    }

   
  }
  const checkBoxChecked = (val,checked,quesId)=>{
    if(quesId === '1')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues1Option1('checked');
        }else{
          setCheckedQues1Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues1Option2('checked');
        }else{
          setCheckedQues1Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues1Option3('checked');
        }else{
          setCheckedQues1Option3('');
        }
      }

      if(val === 'option4')
      {
        if(checked === true)
        {
          setCheckedQues1Option4('checked');
        }else{
          setCheckedQues1Option4('');
        }
      }

      if(val === 'option5')
      {
        if(checked === true)
        {
          setCheckedQues1Option5('checked');
        }else{
          setCheckedQues1Option5('');
        }
      }

      if(val === 'option6')
      {
        if(checked === true)
        {
          setCheckedQues1Option6('checked');
        }else{
          setCheckedQues1Option6('');
        }
      }



    }

    if(quesId === '2')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues2Option1('checked');
        }else{
          setCheckedQues2Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues2Option2('checked');
        }else{
          setCheckedQues2Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues2Option3('checked');
        }else{
          setCheckedQues2Option3('');
        }
      }

      if(val === 'option4')
      {
        if(checked === true)
        {
          setCheckedQues2Option4('checked');
        }else{
          setCheckedQues2Option4('');
        }
      }

      if(val === 'option5')
      {
        if(checked === true)
        {
          setCheckedQues2Option5('checked');
        }else{
          setCheckedQues2Option5('');
        }
      }

      if(val === 'option6')
      {
        if(checked === true)
        {
          setCheckedQues2Option6('checked');
        }else{
          setCheckedQues2Option6('');
        }
      }

      if(val === 'option7')
      {
        if(checked === true)
        {
          setCheckedQues2Option7('checked');
        }else{
          setCheckedQues2Option7('');
        }
      }
      



    }

  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/HumanRightsChapter04Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  
  return (
    <>
      <div className="wrapper md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="3/4" percentage="75" star="36" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between pt-20 mt-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
              
              <h4 className="mb-2 pn"> <b>Who safeguards human rights? [Note: Multiple selections possible]</b></h4> 

                <div className="d-flex ">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle" 
                    defaultValue="option1" 
                  
   
                    onClick={saveGenderBased}
                    checked={checkedQues1Option1}
                    data-quesId="1" 
                    />
                    <label className="form-check-label" htmlFor="mycirle">United Nations </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle2" 
                     defaultValue="option2" 
                  
   
                        onClick={saveGenderBased}
                        checked={checkedQues1Option2}
                        data-quesId="1" 

                     />
                    <label className="form-check-label" htmlFor="mycirle2">Governments </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle3" 
                    
                    defaultValue="option3" 
                  
   
                      onClick={saveGenderBased}
                      checked={checkedQues1Option3}
                      data-quesId="1" 
                    />
                    <label className="form-check-label" htmlFor="mycirle3"> Public Authorities </label>
                  </div>
                </div>

                <div className="d-flex ">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle4" 
                    defaultValue="option4" 
 
                  
   
                  onClick={saveGenderBased}
                  checked={checkedQues1Option4}
                  data-quesId="1" 
                    />
                    <label className="form-check-label" htmlFor="mycirle4">Institutions </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle5"
                     
                     defaultValue="option5" 
                  
   
                      onClick={saveGenderBased}
                      checked={checkedQues1Option5}
                      data-quesId="1" 
                     />
                    <label className="form-check-label" htmlFor="mycirle5">Businesses </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle6" 
        
                    defaultValue="option6" 
                  
   
                  onClick={saveGenderBased}
                  checked={checkedQues1Option6}
                  data-quesId="1" 
                    />
                    <label className="form-check-label" htmlFor="mycirle6"> Individuals </label>
                  </div>
                </div>





                <h4 className="mb-2 pn mt-2">  <b>What can you do to safeguard the human rights of people? [Note: Multiple selections possible]</b></h4> 

                <div className="d-flex ">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle7" 
                    defaultValue="option1"
                    onClick={saveGenderBased}
                    checked={checkedQues2Option1}
                    data-quesId="2" 
                     />
                    <label className="form-check-label" htmlFor="mycirle7">Ensure that I am aware of my own rights, and make sure others around me are aware of their basic rights as well. </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle8" 
                    
                    defaultValue="option2"
                    onClick={saveGenderBased}
                    checked={checkedQues2Option2}
                    data-quesId="2" 
                    />
                    <label className="form-check-label" htmlFor="mycirle8">Ensure that I don’t treat others who are different from me with disrespect</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle9" 
                    
                    defaultValue="option3"
                    onClick={saveGenderBased}
                    checked={checkedQues2Option3}
                    data-quesId="2" 
                    />
                    <label className="form-check-label" htmlFor="mycirle9"> Ensure that when I see any form of harassment and/or discrimination I am not a bystander and protest against it</label>
                  </div>
                </div>

                <div className="d-flex ">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle10"
                     
                     defaultValue="option4"
                    onClick={saveGenderBased}
                    checked={checkedQues2Option4}
                    data-quesId="2" 
                     />
                    <label className="form-check-label" htmlFor="mycirle10">Ensure that I talk to my family members about treating everyone with respect regardless of their economic, gender, social, religious etc. status.</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle11" 
   
                    defaultValue="option5"
                    onClick={saveGenderBased}
                    checked={checkedQues2Option5}
                    data-quesId="2" 
                    />
                    <label className="form-check-label" htmlFor="mycirle11">Work with organisations and individuals who work towards ensuring Human rights </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle12" 
   
                    defaultValue="option6"
                    onClick={saveGenderBased}
                    checked={checkedQues2Option6}
                    data-quesId="2" 

                     />
                    <label className="form-check-label" htmlFor="mycirle12">Work in my own community to ensure Human rights are ensured for all </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle13" 
                    
                    defaultValue="option7"
                    onClick={saveGenderBased}
                    checked={checkedQues2Option7}
                    data-quesId="2" 
                     />
                    <label className="form-check-label" htmlFor="mycirle13">Hold the authorities accountable for ensuring human rights for all </label>
                  </div>
                </div>
                

              </div>
              
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-03/chapter-04-screen-01" />  
                {/* <NextBtn link="/module-03/chapter-04-screen-03" text="Go Next"/> */}
                <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter04Screen02;
