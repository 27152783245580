import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter05Screen14 = () => {

	const navigate = useNavigate();
	const [checkedQues12Yes, setCheckedQues12Yes] = useState('');
	const [checkedQues12No, setCheckedQues12No] = useState('');
	const [checkedQues12Val, setCheckedQues12Val] = useState('');
	const [checkedQues12, setCheckedQues12] = useState('');

	const [checkedQues13Yes, setCheckedQues13Yes] = useState('');
	const [checkedQues13No, setCheckedQues13No] = useState('');
	const [checkedQues13Val, setCheckedQues13Val] = useState('');
	const [checkedQues13, setCheckedQues13] = useState('');

	const [checkedQues14, setCheckedQues14] = useState('');

	//  const Ques4 = (checkedQues4Yes !== '')?checkedQues4Val:checkedQues4Val;

	const user_details = JSON.parse(localStorage.getItem('user_details'));


	const saveRadio = (e) => {
		checkedVal('12', e.target.value)

	}
	const saveRadio13 = (e) => {
		checkedVal('13', e.target.value)

	}

	// console.log(checkedQues5);
	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": checkedQues12Val + "-" + checkedQues12,
					"ques_id": "12"
				},
				"ques13": {
					"ans": checkedQues13Val + "-" + checkedQues13,
					"ques_id": "13"
				},
				"ques14": {
					"ans": checkedQues14,
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter05',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-05-screen-16')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues12Val !== '' && checkedQues12 !== '' && checkedQues13Val !== '' && checkedQues13 !== '' && checkedQues14 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	const checkedVal = (param, ans) => {


		if (param === '12') {
			const __ans = ans.split('-')

			if (__ans[0] === 'yes') {
				setCheckedQues12Yes('checked')
				setCheckedQues12No('')
				setCheckedQues12Val(ans)
			} else {
				if (__ans[0] === 'no') {
					setCheckedQues12No('checked')
					setCheckedQues12Yes('')
					setCheckedQues12Val(ans)
				}
			}
			setCheckedQues12(__ans[1])
		}

		if (param === '13') {
			const __ans = ans.split('-')

			if (__ans[0] === 'yes') {
				setCheckedQues13Yes('checked')
				setCheckedQues13No('')
				setCheckedQues13Val(ans)
			} else {
				if (__ans[0] === 'no') {
					setCheckedQues13No('checked')
					setCheckedQues13Yes('')
					setCheckedQues13Val(ans)
				}
			}
			setCheckedQues13(__ans[1])
		}

		if (param === '14') {

			setCheckedQues14(ans)
		}



	}



	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter05Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])



	return (
		<>
			<div className="wrapper hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="98" star="84" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2">Reflect and Apply</h2>
								<p className="mb-2">Have you faced GBV in your life? Please describe </p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="ques12"
											id="ture"
											defaultValue="yes"
											onClick={saveRadio}
											checked={checkedQues12Yes}
										/>
										<label className="form-check-label" htmlFor="inlineRadio1">
											Yes
										</label>
									</div>
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="ques12"
											id="false"
											defaultValue="no"
											onClick={saveRadio}
											checked={checkedQues12No}
										/>
										<label className="form-check-label " htmlFor="inlineRadio2">
											No
										</label>
									</div>
								</div>
								<div className="mt-2 positon-submit">
									<textarea
										className="form-control"
										rows={2}
										placeholder="Write Something here....."
										defaultValue={checkedQues12}
										onChange={(e) => setCheckedQues12(e.target.value)}
									/>
									{/* <button
                    type="button"
                    className="btn btn-primary custom-btn m-auto submit-btn-set"
                  >
                    Submit
                  </button> */}
								</div>
								<p className="mb-2 mt-3">
									Have you inflicted GBV on others?  Please describe
								</p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="ques13"
											id="ture"
											defaultValue="yes"
											onClick={saveRadio13}
											checked={checkedQues13Yes}
										/>
										<label className="form-check-label" htmlFor="inlineRadio1">
											Yes
										</label>
									</div>
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="ques13"
											id="false"
											defaultValue="no"
											onClick={saveRadio13}
											checked={checkedQues13No}
										/>
										<label className="form-check-label " htmlFor="inlineRadio2">
											No
										</label>
									</div>
								</div>
								<div className="mt-2 positon-submit">
									<textarea
										className="form-control"
										rows={2}
										placeholder="Write Something here....."
										defaultValue={checkedQues13}
										onChange={(e) => setCheckedQues13(e.target.value)}
									/>
									{/* <button
                    type="button"
                    className="btn btn-primary custom-btn m-auto submit-btn-set"
                  >
                    Submit
                  </button> */}
								</div>


								<p className="mb-2 mt-3">
									What can you do to address GBV in your life? Share one step
									you can take.
								</p>
								<div className="mt-2 positon-submit">
									<textarea
										className="form-control"
										rows={2}
										placeholder="Write Something here....."
										defaultValue={checkedQues14}
										onChange={(e) => setCheckedQues14(e.target.value)}
									/>
									{/* <button
                    type="button"
                    className="btn btn-primary custom-btn m-auto submit-btn-set"
                  >
                    Submit
                  </button> */}
								</div>
							</div>


							<div
								className="col-lg-3 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img width="54%" src="/assets/img/md1/md1-shap-5-15.png" alt="" />
								</center>
							</div>






						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-05-screen-13" />
								{/* <NextBtn
                  link="/module-01/chapter-05-screen-15"
                  text="Go Next"
                /> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen14;
