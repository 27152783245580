import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";

const Chapter01Screen09 = () => {
	const navigate = useNavigate();

	const [pictureList, setPictureList] = useState([
		{ "id": "1", "name": "plan", "image": "md-5-chap-1-9.png", "defaultValue": "pen" },
		{ "id": "2", "name": "plan", "image": "md-5-chap-1-9.png", "defaultValue": "pencil" },
		{ "id": "3", "name": "plan", "image": "md-5-chap-1-9.png", "defaultValue": "pen" },
		{ "id": "4", "name": "plan", "image": "md-5-chap-1-9.png", "defaultValue": "pen" },
		{ "id": "5", "name": "plan", "image": "md-5-chap-1-9.png", "defaultValue": "pen" },
		{ "id": "6", "name": "plan", "image": "md-5-chap-1-9.png", "defaultValue": "pen" },
		{ "id": "7", "name": "plan", "image": "md-5-chap-1-9.png", "defaultValue": "pen" },
		{ "id": "8", "name": "plan", "image": "md-5-chap-1-9.png", "defaultValue": "pen" },
		{ "id": "9", "name": "plan", "image": "md-5-chap-1-9.png", "defaultValue": "pen" },
		{ "id": "10", "name": "plan", "image": "md-5-chap-1-9.png", "defaultValue": "pen" },
	])

	const [showResults, setShowResults] = useState(false)

	const saveRadio = (e) => {
		// alert(e.target.checked);
		setShowResults(true);

	}

	const ShowPopUp = () => {
		if (showResults === true) {

			return (
				<>

					<div className="alert alert-primary text-center mt-2" role="alert">
						It is estimated that between 720 and 811 million people in the world faced hunger in 2020. Beyond hunger, nearly one in three people did not have access to adequate food in 2020
						<a href="https://www.fao.org/state-of-food-security-nutrition/2021/en/#:~:text=In%202020%2C%20between%20720%20and%20811%20million%20people%20faced%20hunger&text=After%20remaining%20virtually%20unchanged%20from,8.4%20percent%20a%20year%20earlier" target="_blank" className="text-center">
							<div className="d-flex align-items-center justify-content-center mange-icon mt-3">
								<div>
									<img src="/assets/img/chapter1/list-icon.png" alt="" />
								</div>
								<div>
									<p className="text-white">The State of Food Security </p>
								</div>
							</div>
						</a>
					</div>
				</>
			)

		}
	}





	// console.log(showResults);

	const BtnDisabled = () => {
		if (showResults === true) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<NavLink to="/module-03/chapter-01-screen-10" className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</NavLink>
					</button>
				</>
			);
		} else if (showResults === false) {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next  <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper md-5-bg">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="5" star="4" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-center justify-content-center pt-20 mt-5 mt-3">

							<div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">

								<div className="row align-items-center justify-content-center select-op-new mt-4 mb-5">

									{
										pictureList.map((item, index) => {
											return (
												<>
													<div className="col-3 col-lg-1 col-sm-2 mt-2 select-pset">
														<div className="plans ">
															<label className="plan basic-plan" htmlFor={"basic" + index}>
																<input type="radio" name="plan" id={"basic" + index} onClick={saveRadio} defaultValue={item.defaultValue} />
																<div className="plan-content"><img loading="lazy" src={"/assets/img/md5/" + item.image} alt="" /></div>
															</label>
														</div>
													</div>
												</>
											)
										})
									}



								</div>


								<p className="mb-2 mt-3">Imagine that these baskets represent all the food in the world. The first basket represents the person with complete access to food, and is satiated, and the last image represents the starving/ malnourished person. Please select the basket that represents you on the scale. </p>


								<ShowPopUp />

							</div>

						</div>

					</div>
				</div>
			</div>


			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-03/chapter-01-screen-08" />
								{/* <NextBtn link="/module-03/chapter-01-screen-10" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen09;
