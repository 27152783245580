import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen03 = () => {
  return (
    <>
      <div className="wrapper bg-bd-2">
        <div
          className="fixed-header"
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="0/4" percentage="15" star="50" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
          <div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-3">
    
            <div className="col-lg-12 mt-3">

            <a href="https://inbreakthrough.org/our-story/" target="_blank">
              <div className="d-flex align-items-center mange-icon mt-2">
                <div>
                  <img src="/assets/img/chapter2/video-icon.png" alt="" />
                </div>
                <div>
                  <p>
                  Now let’s read about Breakthrough’s story here 
                  </p>
                </div>
              </div>
            </a>

            <p>We are an organisation working to create a cultural shift and make discrimination and violence against girls and women unacceptable. </p>
            <p>Experiences and stories are our tools of choice, as we endeavour to make the subject of gender-based violence mainstream. We speak to our large and diverse audience in a language they can relate to, using mediums they respond to - such as music, new media and popular culture.</p>
            <p>This new approach to problem-solving is what led to the music album Mann ke Manjeere. What started as a music video, took on a life of its own and became a movement. We have since focused our efforts on opening doors, breaking barriers, and starting honest conversations about gender, violence and discrimination, with women, men, adolescents, youth, families and communities as units, frontline health workers, government officials, influencers etc.</p>
            <p>With partners that have supported us, media platforms that have amplified our call, and messaging that has translated into tangible action on the ground, our endeavour to transform the world around us grows stronger every day. Our approach of one home, one workplace, one community at a time have helped create a positive impact on millions of women and girls.</p>
            <p>Our story is a sum total of many truths and realities that propel us to work towards creating a change in our social environments.</p>


            </div>
         
          </div>





          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-06/chapter-01-screen-03"/>  
                <NextBtn link="/module-06/chapter-01-screen-05" text="Go Next"/>
                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen03;
