import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";

const Chapter03Screen08 = () => {
	const navigate = useNavigate();
	const [checkedQues9_1, setCheckedQues9_1] = useState('');
	const [checkedQues9_2, setCheckedQues9_2] = useState('');
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",

					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				}, "ques4": {
					"ans": "",
					"ques_id": "4"
				}, "ques5": {
					"ans": "",
					"ques_id": "5"
				}, "ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",

					"ques_id": "7"
				}, "ques8": {
					"ans": "",
					"ans2": "",
					"ques_id": "8"
				},
				"ques8": {
					"ans": checkedQues9_1,
					"ans2": checkedQues9_2,
					"ques_id": "9"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-04/chapter-03-screen-09')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const checkedVal = (param, ans, ans2) => {
		if (param === '9') {
			setCheckedQues9_1(ans)
			setCheckedQues9_2(ans2)
		}
	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {

						checkedVal(result.ques_id.toString(), result.ans, result.ans2)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	const BtnDisabled = () => {
		if (checkedQues9_1 !== '' && checkedQues9_2 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="2/5" percentage="60" star="60" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between mt-4">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2 mb-1">
									What are two steps you will do to ensure meaningful
									consumption of media?{" "}
								</h2>
							</div>
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="mt-4 positon-submit">
									<textarea
										className="form-control"
										rows={2}
										placeholder="Write Something here....."
										defaultValue={checkedQues9_1}
										onChange={(e) => setCheckedQues9_1(e.target.value)}
									/>
								</div>
								<div className="mt-4 positon-submit">
									<textarea
										className="form-control"
										rows={2}
										placeholder="Write Something here....."
										defaultValue={checkedQues9_2}
										onChange={(e) => setCheckedQues9_2(e.target.value)}
									/>
								</div>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="60%"
										draggable="false"
										src="/assets/img/md4/md4-shap-3-8.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-03-screen-07" />

									{/* <NextBtn link="/module-04/chapter-03-screen-09" text="Go Next"/> */}
									<BtnDisabled />

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen08;
