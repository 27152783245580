import React from "react";

import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter01IntroScreen = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="1" star="0" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h4>
									{" "}
									<b>
										In this module you will learn the following through four
										chapters:
									</b>{" "}
								</h4>
								<div className="para-color-box">
									<p>
										<b>Chapter 1: What is My Identity?</b>
									</p>
									<ul className="list-style-set list-circle ml-1">
										<li>
											You will learn about the various aspects which form your
											identity
										</li>
									</ul>
									<p className="">
										<b>Chapter 2: Understanding Values and Decision Making</b>
									</p>
									<ul className="list-style-set list-circle ml-1">
										<li>
											You will learn to see the linkage between values and
											decision making
										</li>
										<li>
											You will learn to reflect on which value to prioritise
											when making decisions
										</li>
									</ul>
									<p className="">
										<b>Chapter 3: Understanding Effective Communication?</b>
									</p>
									<ul className="list-style-set list-circle ml-1">
										<li>
											You will be able to assess your own communication
											qualities and which qualities are important for effective
											communication
										</li>
										<li>
											You will learn the importance of necessary components
											which should be part of effective communication and be
											able to practice it
										</li>
									</ul>
									<p className="">
										<b>Chapter 4: Understanding Leadership? </b>
									</p>
									<ul className="list-style-set list-circle ml-1">
										<li>
											You will be able to reflect on and identify leadership
											qualities you admire and why
										</li>
										<li>
											You will be able to identify and reflect on your own
											leadership abilities including the gaps and make a plan to
											address them.
										</li>
									</ul>
								</div>
							</div>
							<div
								className="col-lg-3 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="60%"
										draggable="false"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.3s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/" />

									<NextBtn link="/module-02/chapter-01-screen-01" text="Go Next" />

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01IntroScreen;
