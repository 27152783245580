import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen02 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="1" star="1" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-3">
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								{/* <h3 className="h3">Highlight the first 3 values you see</h3> */}

								<p>Icebergs, which are large pieces of
									ice broken from glaciers, float in the ocean. One can only see
									the tip of the iceberg around 10 percent and most of it around
									90 percent remains submerged under water. Similarly, when we
									meet a person we only see what’s on the surface like their
									appearance, their language, their behaviour- body language,
									gestures, facial expression etc. but what we don’t see and
									what’s important is all underneath – their values, their
									perceptions, their experiences, their fears etc.
								</p>
							</div>
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										src="/assets/img/md2/deep.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">


								<PrevBtn link="/module-02/chapter-02-screen-02" />

								<NextBtn link="/module-02/chapter-02-screen-03" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
