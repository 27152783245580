import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter04Screen09 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-0">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3">Learn</h3>
								<p className="mb-2 mt-3"> In this example and some others we have seen in this module, there is a clear lack of consent and even domination of one partner over another. In terms of sexual behaviour, when sexual are unwanted, done by one person to another without agreement – it is called sexual assault. Sexual assault happens not as a result of desire or pleasure, but it is a form of power/ control over another person.  </p>




							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="75%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-4-9.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-08" />
								<NextBtn link="/module-05/chapter-04-screen-10" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>



		</>
	);
};

export default Chapter04Screen09;
