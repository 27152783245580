import React from "react";


import Header from "../../header/Header";
import NextBtn from "../../common/NextBtn";
import PrevBtn from "../../common/PrevBtn";

const Chapter01IntroScreen = () => {
	return (
		<div className="wrapper">
			<div
				className="fixed-header"

			>
				<div className="container-fluid cf-set">
					<Header leftChapter="0/5" percentage="0" star="0" />
				</div>
			</div>
			<div className="data-adjust">
				<div className="container-fluid cf-set">
					<div className="row align-items-center justify-content-between pt-20">
						<div
							className="col-lg-8 wow fadeInDown"
							data-wow-duration="1s"
							data-wow-delay="0.2s"
						>

							<div className="para-color-box p-5">
								<h3 className="h3">About Breakthrough</h3>
								<p>Breakthrough is a human rights organization working to make violence and discrimination against women and girls unacceptable. We use media, arts, technology and pop-culture to address the issues of domestic violence, sexual harassment in public spaces, early marriage and gender-based discrimination. All of these issues are rooted in and arise from gender and sexuality based discrimination that violate human rights</p>
							</div>

						</div>
						<div
							className="col-lg-3 mt-m-3 wow fadeInDown"
							data-wow-duration="1s"
							data-wow-delay="0.2s"
						>
							<center>
								<img
									width="60%"
									draggable="false"
									src="/assets/img/chapter1/reading-book.png"
									alt=""
								/>
							</center>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/" />

								<NextBtn link="/module-01/chapter-01-screen-01" text="Go Next" classtype="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	);
};

export default Chapter01IntroScreen;
