import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";

const Chapter04Screen15 = () => {

	const navigate = useNavigate();

	const [checkedQues17Option1, setCheckedQues17Option1] = useState('');
	const [checkedQues17Option2, setCheckedQues17Option2] = useState('');

	const [checkedQues17Val, setCheckedQues17Val] = useState('');

	const [checkedQues18Option1, setCheckedQues18Option1] = useState('');
	const [checkedQues18Option2, setCheckedQues18Option2] = useState('');

	const [checkedQues18Val, setCheckedQues18Val] = useState('');

	const [checkedQues19Option1, setCheckedQues19Option1] = useState('');
	const [checkedQues19Option2, setCheckedQues19Option2] = useState('');

	const [checkedQues19Val, setCheckedQues19Val] = useState('');




	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(e.target.value);
		checkedVal(quesId, e.target.value)
	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques17": {
					"ans": checkedQues17Val,
					"ques_id": "17"
				},
				"ques18": {
					"ans": checkedQues18Val,
					"ques_id": "18"
				},
				"ques19": {
					"ans": checkedQues19Val,
					"ques_id": "19"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-05/chapter-04-screen-16')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues17Val !== '' && checkedQues18Val !== '' && checkedQues19Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '17') {
			if (ans === 'option1') {
				setCheckedQues17Option1('checked')
				setCheckedQues17Option2('');

			} else if (ans === 'option2') {
				setCheckedQues17Option1('')
				setCheckedQues17Option2('checked');

			}
			setCheckedQues17Val(ans)
		}

		if (param === '18') {
			if (ans === 'option1') {
				setCheckedQues18Option1('checked')
				setCheckedQues18Option2('');

			} else if (ans === 'option2') {
				setCheckedQues18Option1('')
				setCheckedQues18Option2('checked');

			}
			setCheckedQues18Val(ans)
		}

		if (param === '19') {
			if (ans === 'option1') {
				setCheckedQues19Option1('checked')
				setCheckedQues19Option2('');

			} else if (ans === 'option2') {
				setCheckedQues19Option1('')
				setCheckedQues19Option2('checked');

			}
			setCheckedQues19Val(ans)
		}



	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-0">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>


								<a
									href="https://www.youtube.com/watch?v=bGhOWnL0BH0"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Badrinath ki dulhania funny scene|| varun dhavan gets molested by gays. </p>
										</div>
									</div>
								</a>


								<p className="mb-2 mt-3">Is the male character a victim of assault in this clip? </p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle" id="mycirle5"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues17Option1}
											data-quesId="17"

										/>
										<label className="form-check-label" htmlFor="mycirle5">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle"
											id="mycirle6"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues17Option2}
											data-quesId="17"

										/>
										<label className="form-check-label" htmlFor="mycirle6">No</label>
									</div>
								</div>

								<p className="mt-2 pn">Is there something different about how the event is shown – the music and comedy angle? </p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle2"
											id="mycirle1"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues18Option1}
											data-quesId="18"

										/>
										<label className="form-check-label" htmlFor="mycirle1">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="radio"
											name="namecircle2"
											id="mycirle2"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues18Option2}
											data-quesId="18"

										/>
										<label className="form-check-label" htmlFor="mycirle2">No</label>
									</div>
								</div>

								<p className="mt-2 pn">Due to our notions of masculinity, does it become difficult to take abuse against men seriously?  </p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3"
											id="mycirle9"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues19Option1}
											data-quesId="19"
										/>
										<label className="form-check-label" htmlFor="mycirle9">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle10"
											defaultValue="option2"
											onClick={saveRadio}
											checked={checkedQues19Option2}
											data-quesId="19"

										/>
										<label className="form-check-label" htmlFor="mycirle10">No</label>
									</div>
								</div>


								{/* <div className="para-color-box mt-3">
                  <p className="mb-2"> <b>Note:</b> While the clip helps to understand sexual assault of men. It also promotes a homophobic/ homonegative image about same-sex desiring men. Just as we discussed earlier sexual assault is not about desire or pleasure but misusing or abusing one’s power over another person. Similarly, we need to question the nature of power that people of diverse gender identities and/ or sexual orientation have over cis-gendered people.</p>
                </div>
                 */}







							</div>

							<div
								className="col-lg-2 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="83%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-15.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-14" />
								{/* <NextBtn link="/module-05/chapter-04-screen-16" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>




			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>
							<p className="text-white text-center">Sexting is the digital sharing of intimate images or videos with another person. It is a private type of self-disclosure. While it can bring partners closer, it may also carry some risks. </p>


							<div className=" d-flex justify-content-center mt-3">
								<button className="button_su m-auto" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="/module-05/chapter-03-screen-11" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen15;
