import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";

const Chapter02Screen02 = () => {

	const navigate = useNavigate();

	const [checkedQues1, setCheckedQues1] = useState([]);
	const [checkedQues1Option1, setCheckedQues1Option1] = useState('');
	const [checkedQues1Option2, setCheckedQues1Option2] = useState('');
	const [checkedQues1Option3, setCheckedQues1Option3] = useState('');
	const [checkedQues1Option4, setCheckedQues1Option4] = useState('');
	const [checkedQues1Option5, setCheckedQues1Option5] = useState('');
	const [checkedQues1Option6, setCheckedQues1Option6] = useState('');
	const [checkedQues1Option7, setCheckedQues1Option7] = useState('');
	const [checkedQues1Option8, setCheckedQues1Option8] = useState('');
	const [checkedQues1Option9, setCheckedQues1Option9] = useState('');
	const [checkedQues1Option10, setCheckedQues1Option10] = useState('');




	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;

		checkBoxChecked(res, checked, quesId);
		if (quesId === '1') {
			setCheckedQues1(checkedQues1 => [...checkedQues1, res]);

		}



	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues1.join(','),
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},


			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-05/chapter-02-screen-03')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues1.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '1') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues1(ansArr)
		}



	}
	const checkBoxChecked = (val, checked, quesId) => {
		if (quesId === '1') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues1Option1('checked');
				} else {
					setCheckedQues1Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues1Option2('checked');
				} else {
					setCheckedQues1Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues1Option3('checked');
				} else {
					setCheckedQues1Option3('');
				}
			}
			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues1Option4('checked');
				} else {
					setCheckedQues1Option4('');
				}
			}

			if (val === 'option5') {
				if (checked === true) {
					setCheckedQues1Option5('checked');
				} else {
					setCheckedQues1Option5('');
				}
			}

			if (val === 'option6') {
				if (checked === true) {
					setCheckedQues1Option6('checked');
				} else {
					setCheckedQues1Option6('');
				}
			}

			if (val === 'option7') {
				if (checked === true) {
					setCheckedQues1Option7('checked');
				} else {
					setCheckedQues1Option7('');
				}
			}

			if (val === 'option8') {
				if (checked === true) {
					setCheckedQues1Option8('checked');
				} else {
					setCheckedQues1Option8('');
				}
			}

			if (val === 'option9') {
				if (checked === true) {
					setCheckedQues1Option9('checked');
				} else {
					setCheckedQues1Option9('');
				}
			}

			if (val === 'option10') {
				if (checked === true) {
					setCheckedQues1Option10('checked');
				} else {
					setCheckedQues1Option10('');
				}
			}







		}



	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Engage  </h3>
								<p className="mb-2  mt-3"> What do we mean when we say diverse sexualities? In this video, we hear about some people’s sexuality experiences. </p>


								<a href="https://www.youtube.com/watch?v=IaDn_U8JRMM" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p> <b>Sexual Orientation </b> </p>
										</div>
									</div>
								</a>



								<p className="mb-2  mt-3"> Which of these is true, from what we have seen in the video? </p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle"
											id="mycirle1"

											defaultValue="option1"
											onClick={saveGenderBased}
											checked={checkedQues1Option1}
											data-quesId="1"
										/>
										<label className="form-check-label" htmlFor="mycirle1">A person can be attracted only to the opposite sex </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle" id="mycirle2"
											defaultValue="option2"
											onClick={saveGenderBased}
											checked={checkedQues1Option2}
											data-quesId="1"

										/>
										<label className="form-check-label" htmlFor="mycirle2">A person can be attracted to members of the same sex and build relationships with them  </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle" id="mycirle3"
											defaultValue="option3"
											onClick={saveGenderBased}
											checked={checkedQues1Option3}
											data-quesId="1"

										/>
										<label className="form-check-label" htmlFor="mycirle3">A person who is attracted to the same sex is confused or going through a phase</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle" id="mycirle4"

											defaultValue="option4"
											onClick={saveGenderBased}
											checked={checkedQues1Option4}
											data-quesId="1"
										/>
										<label className="form-check-label" htmlFor="mycirle4">A person can discover their orientation at any point in their life </label>
									</div>
								</div>




							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="70%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-2.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-01" />
								{/* <NextBtn link="/module-05/chapter-02-screen-03" text="Go Next"/> */}
								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
