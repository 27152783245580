import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
const Chapter02Screen09 = () => {

	const navigate = useNavigate();
	const [checkedQues8, setCheckedQues8] = useState('');
	const [checkedQues8_2, setCheckedQues8_2] = useState('');
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": checkedQues8,
					"ques_id": "8"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-02-screen-10')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues8 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {


		if (param === '8') {

			setCheckedQues8(ans)
		}

	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])




	return (
		<>
			<div className="wrapper md-5-bg hp">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="25" star="2" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-3">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h4 className="mb-2 mt-3"> <b> Refer to this link for the list of Fundamental Duties  </b></h4>
								<a
									href="https://doj.gov.in/sites/default/files/Fundamental-duties_0.pdf"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Text of Article 51-A PART IVA FUNDAMENTAL DUTIES 51A. Fundamental duties.—It shall be the duty of every citizen of India</p>
										</div>
									</div>
								</a>

								<p className="mb-2 mt-3">Pick any one out of the 11 duties, now think of 2 actions you can take to ensure you follow this fundamental duty.  Share about the duty, 2 actions and your experience of implementing in the space below.</p>

								<p className="mb-2 mt-3">See these links for an example of an action as part of the fundamental duty to protect and improve the natural environment including forests, lakes, rivers, wildlife and to have compassion for living creatures </p>

								<a
									href="http://commutiny.in/jagrik/never-stop-recycling"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Never stop recycling</p>
										</div>
									</div>
								</a>

								<div
									className="col-lg-10 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<div className="mt-3 positon-submit">
										<textarea className="form-control" rows={1} placeholder="Duty: Actions: Experience Sharing"
											defaultValue={checkedQues8_2}
											onChange={(e) => setCheckedQues8_2(e.target.value)}
										/>
									</div>
								</div>

								<a
									href="http://commutiny.in/jagrik/fundamental-duties-1"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Fundamental duties </p>
										</div>
									</div>
								</a>
							</div>


							<div
								className="col-lg-10 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="mt-3 positon-submit">
									<textarea className="form-control" rows={1} placeholder="Duty: Actions: Experience Sharing"
										defaultValue={checkedQues8}
										onChange={(e) => setCheckedQues8(e.target.value)}
									/>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-02-screen-08" />

									{/* <NextBtn link="/module-03/chapter-02-screen-10" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen09;
