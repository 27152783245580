import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";

const Chapter01Screen14 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/2" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Learn </h3>
								<p className="mb-2  mt-3">Let’s reflect on some of these statements. Tara’s low self-esteem and body image keep her in an unhappy relationship. Her boyfriend keeps putting her down, and she internalises the ideas on her appearance. We can see how Tara’s sense of self is affecting how she is accepting of relationships where she is criticised and disrespected. </p>
								<p className="mb-2  mt-3">Low self-esteem can lead a person to accept kinds of behaviours that they may not be comfortable with and might violate their boundaries. </p>
								<p className="mb-2  mt-3">In the discussion above, we talked about certain appearances and behaviours that are conditioned into us as attractive or correct by the surrounding influences. This is a way of stereotyping bodies. Many gender and/ or sex stereotypes impact our understanding of sexuality. </p>
							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="55%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-1-14.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-01-screen-13" />
								<button className="button_su" >
									<span className="su_button_circle desplode-circle">
									</span>
									<button className="button_su_inner" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
										<span className="button_text_container">
											Go Next <i className="bi bi-arrow-right" />
										</span>
									</button>
								</button>


							</div>
						</div>
					</div>
				</div>
			</div>



			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							<p className="text-white text-center">A stereotype is a belief that is held by anybody or a group of people about certain ways of being/ doing things which may not necessarily be true for everybody. Stereotypes are usually formed without complete knowledge about a topic, issue, individual and/or group/s </p>


							<div class=" d-flex justify-content-center mt-3">
								<button className="button_su" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="/module-05/chapter-01-screen-15" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>


		</>
	);
};

export default Chapter01Screen14;
