import React,{useEffect,useState} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter02Screen02 = () => {

  const navigate = useNavigate();
  
  const [checkedQues4, setCheckedQues4] = useState([]);
  const [checkedQues4Option1, setCheckedQues4Option1] = useState('');
  const [checkedQues4Option2, setCheckedQues4Option2] = useState('');
  const [checkedQues4Option3, setCheckedQues4Option3] = useState('');
  const [checkedQues4Option4, setCheckedQues4Option4] = useState('');


  const [checkedQues5Option1, setCheckedQues5Option1] = useState('');
  const [checkedQues5Option2, setCheckedQues5Option2] = useState('');
  const [checkedQues5Option3, setCheckedQues5Option3] = useState('');
  const [checkedQues5Option4, setCheckedQues5Option4] = useState('');
  const [checkedQues5Val, setCheckedQues5Val] = useState('');


  // const [checkedQues6, setCheckedQues6] = useState([]);
  const [checkedQues6Option1, setCheckedQues6Option1] = useState('');
  const [checkedQues6Option2, setCheckedQues6Option2] = useState('');
  const [checkedQues6Option3, setCheckedQues6Option3] = useState('');
  const [checkedQues6Option4, setCheckedQues6Option4] = useState('');
  const [checkedQues6Val, setCheckedQues6Val] = useState('');
 




  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    
    checkedVal(quesId,e.target.value)
   
  }

  const saveGenderBased = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    const res = e.target.value;
    const checked = e.target.checked;

   

    
    checkBoxChecked(res,checked,quesId);
    if(quesId === '4')
    {
      setCheckedQues4(checkedQues4 => [...checkedQues4, res]);

    }

    // if(quesId === '6')
    // {
    //   setCheckedQues6(checkedQues6 => [...checkedQues6, res]);

    // }


   

  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques4": {
          "ans":  checkedQues4.join(','),       
          "ques_id": "4"
        },
        "ques5": {
          "ans": checkedQues5Val,         
          "ques_id": "5"
        },
        "ques6": {
          "ans":  checkedQues6Val,  
          "ques_id": "6"
        },
       
       
        
      
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/ApproachChapter03',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {

        if(response.status === "Token is Expired")
        {
          window.localStorage.clear();
          navigate('/login')
        }
        if(response.data.message === "Token Invalid")
        {
          window.localStorage.clear();
          navigate('/login')
        }
        
        if(response.data.success === true)
        {
          navigate('/module-06/chapter-03-screen-06')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues4.length > 0 && checkedQues5Val !=='' && checkedQues6Val !== '') {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>

         
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {
    if (param === '4') {
      
      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues4(ansArr)
    }

    if (param === '5') {
      
      if (ans === 'option1') {
        setCheckedQues5Option1('checked')
        setCheckedQues5Option2('')
        setCheckedQues5Option3('')
        setCheckedQues5Option4('')
      } else if(ans === 'option2') {
        setCheckedQues5Option1('')
        setCheckedQues5Option2('checked')
        setCheckedQues5Option3('')
        setCheckedQues5Option4('')
      }else if(ans === 'option3') {
        setCheckedQues5Option1('')
        setCheckedQues5Option2('')
        setCheckedQues5Option3('checked')
        setCheckedQues5Option4('')
      }else if(ans === 'option4') {
        setCheckedQues5Option1('')
        setCheckedQues5Option2('')
        setCheckedQues5Option3('')
        setCheckedQues5Option4('checked')
      }
      setCheckedQues5Val(ans)
    }

    if (param === '6') {
      
      if (ans === 'option1') {
        setCheckedQues6Option1('checked')
        setCheckedQues6Option2('')
        setCheckedQues6Option3('')
        setCheckedQues6Option4('')
      } else if(ans === 'option2') {
        setCheckedQues6Option1('')
        setCheckedQues6Option2('checked')
        setCheckedQues6Option3('')
        setCheckedQues6Option4('')
      }else if(ans === 'option3') {
        setCheckedQues6Option1('')
        setCheckedQues6Option2('')
        setCheckedQues6Option3('checked')
        setCheckedQues6Option4('')
      }else if(ans === 'option4') {
        setCheckedQues6Option1('')
        setCheckedQues6Option2('')
        setCheckedQues6Option3('')
        setCheckedQues6Option4('checked')
      }
      setCheckedQues6Val(ans)
    }


    // if (param === '6') {
    //   const ansArr = ans.split(',');
    //   ansArr.map((el)=>{
    //     const checked = true;
    //     checkBoxChecked(el,checked,param);
    //   })
    //   setCheckedQues6(ansArr)
    // }




   
  }
  const checkBoxChecked = (val,checked,quesId)=>{
    if(quesId === '4')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues4Option1('checked');
        }else{
          setCheckedQues4Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues4Option2('checked');
        }else{
          setCheckedQues4Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues4Option3('checked');
        }else{
          setCheckedQues4Option3('');
        }
      }
      if(val === 'option4')
      {
        if(checked === true)
        {
          setCheckedQues4Option4('checked');
        }else{
          setCheckedQues4Option4('');
        }
      }

     

    
    

    }

    if(quesId === '6')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues6Option1('checked');
        }else{
          setCheckedQues6Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues6Option2('checked');
        }else{
          setCheckedQues6Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues6Option3('checked');
        }else{
          setCheckedQues6Option3('');
        }
      }
      if(val === 'option4')
      {
        if(checked === true)
        {
          setCheckedQues6Option4('checked');
        }else{
          setCheckedQues6Option4('');
        }
      }

      

    
    

    }


  
   
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/approachChapter03Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])



  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"
        
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="2/4" percentage="65" star="50" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
          <div className="row align-items-top justify-content-between pt-20 mt-m-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <p className="mb-2 mt-3 pn"><span><b>3.</b></span><b>To address and challenge patriarchy cultural norms that are accepted and not questioned, Breakthrough worked through following approaches. Select all that apply</b> </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                      name="namecircle"
                      id="mycirle5"
                      defaultValue="option1"
                         onClick={saveGenderBased}
                         checked={checkedQues4Option1}
                         data-quesId="4" 
                       />
                    <label className="form-check-label" htmlFor="mycirle1"> Engagement with adolescents girls and boys </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                     name="namecircle"
                      id="mycirle2" 
                      defaultValue="option2"
                         onClick={saveGenderBased}
                         checked={checkedQues4Option2}
                         data-quesId="4"  

                      />
                    <label className="form-check-label" htmlFor="mycirle2"> Community mobilization and engagement</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                     name="namecircle" id="mycirle3"
                     defaultValue="option3"
                         onClick={saveGenderBased}
                         checked={checkedQues4Option3}
                         data-quesId="4" 

                      />
                    <label className="form-check-label" htmlFor="mycirle3"> Intergenerational dialogue</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" 
                    type="checkbox" 
                    name="namecircle" 
                    defaultValue="option4"
                         onClick={saveGenderBased}
                         checked={checkedQues4Option4}
                         data-quesId="4" 

                    />
                    <label className="form-check-label" htmlFor="mycirle4"> Creating alternative norms</label>
                  </div>
                </div>




                <p className="mb-2 mt-3 pn"><span><b>4.</b></span><b> Breakthrough’s programme on adolescents empowerment is called ............(select the right answer)</b> </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio"
                      name="namecircle34" id="mycirle124" 
                      defaultValue="option1"
                         onClick={saveRadio}
                         checked={checkedQues5Option1}
                         data-quesId="5" 
                        />
                    <label className="form-check-label" htmlFor="mycirle9"> De Taali</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio"
                    name="namecircle34" id="mycirle124" 
                     defaultValue="option2"
                         onClick={saveRadio}
                         checked={checkedQues5Option2}
                         data-quesId="5" 

                     />
                    <label className="form-check-label" htmlFor="mycirle10"> Taaron ki Toli </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio" 
                    name="namecircle34" id="mycirle124" 
                    defaultValue="option3"
                         onClick={saveRadio}
                         checked={checkedQues5Option3}
                         data-quesId="5" 
                     />
                    <label className="form-check-label" htmlFor="mycirle11"> Team Change Leader</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio"
                     name="namecircle34" id="mycirle124" 
                     defaultValue="option4"
                         onClick={saveRadio}
                         checked={checkedQues5Option4}
                         data-quesId="5" 

                     />
                    <label className="form-check-label" htmlFor="mycirle12"> Jano, Jago, Jagao</label>
                  </div>
                </div>



                <p className="mb-2 mt-3 pn"><span><b>5.</b></span><b>What alternative norms were created  (select the one/s which are not true)</b> </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check"
                     type="radio" 
                     name="namecircle2"
                      id="mycirle5"
                      defaultValue="option1"
                         onClick={saveRadio}
                         checked={checkedQues6Option1}
                         data-quesId="6" 

                       />
                    <label className="form-check-label" htmlFor="mycirle5"> Girls started playing sports which was considered a space for boys and men</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio"
                     name="namecircle2"
                      id="mycirle6" 
                      defaultValue="option2" 
                      
                         onClick={saveRadio}
                         checked={checkedQues6Option2}
                         data-quesId="6" 
                      />
                    <label className="form-check-label" htmlFor="mycirle6"> Girls stopped talking about their empowerment through sports</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio"
                     name="namecircle2" 
                     id="mycirle7" 
                     defaultValue="option3"
                         onClick={saveRadio}
                         checked={checkedQues6Option3}
                         data-quesId="6" 
                      />
                    <label className="form-check-label" htmlFor="mycirle7"> Adolescents started identifying their issues and raising in their families and communities</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" 
                    type="radio"
                     name="namecircle2"
                      id="mycirle8" 
                      defaultValue="option4"
                         onClick={saveRadio}
                         checked={checkedQues6Option4}
                         data-quesId="6" 
                       />
                    <label className="form-check-label" htmlFor="mycirle8"> Menstruation health and hygiene as an issue started getting discussed at community level</label>
                  </div>
                </div>


               



       




              </div>

             
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
               
                <PrevBtn link="/module-06/chapter-03-screen-04"/>  
                {/* <NextBtn link="/module-06/chapter-03-screen-06" text="Go Next"/> */}
                <BtnDisabled/>
                 

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen02;
