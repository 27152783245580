import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
const Chapter03Screen04 = () => {
  const navigate = useNavigate();
  const [checkedQues3Always, setCheckedQues3Always] = useState('');
  const [checkedQues3Often, setCheckedQues3Often] = useState('');
  const [checkedQues3Rarely, setCheckedQues3Rarely] = useState('');
  const [checkedQues3Never, setCheckedQues3Never] = useState('');
  const [checkedQues3Val, setCheckedQues3Val] = useState('');
  const [checkedQues4Always, setCheckedQues4Always] = useState('');
  const [checkedQues4Often, setCheckedQues4Often] = useState('');
  const [checkedQues4Rarely, setCheckedQues4Rarely] = useState('');
  const [checkedQues4Never, setCheckedQues4Never] = useState('');
  const [checkedQues4Val, setCheckedQues4Val] = useState('');
  const [checkedQues5Always, setCheckedQues5Always] = useState('');
  const [checkedQues5Often, setCheckedQues5Often] = useState('');
  const [checkedQues5Rarely, setCheckedQues5Rarely] = useState('');
  const [checkedQues5Never, setCheckedQues5Never] = useState('');
  const [checkedQues5Val, setCheckedQues5Val] = useState('');
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    checkedVal(quesId,e.target.value)
  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",         
          "ques_id": "2"
        },
        "ques3": {
          "ans": checkedQues3Val,        
          "ques_id": "3"
        },
        "ques4": {
          "ans": checkedQues4Val,
          "ques_id": "4"
        },
        "ques5": {
          "ans": checkedQues5Val,
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "", 
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": "",
          "ques_id": "10"
        },
        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        },
        "ques13": {
          "ans": "",
          "ques_id": "13"
        },
        "ques14": {
          "ans": "",
          "ques_id": "14"
        }
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/SelfChapter03',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-02/chapter-03-screen-05')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const checkedVal = (param,ans) => {
    if (param === '3') {
      if (ans === 'Always') {
        setCheckedQues3Always('checked')
        setCheckedQues3Often('')
        setCheckedQues3Rarely('')
        setCheckedQues3Never('')
      } else if (ans === 'Often/sometimes') {
        setCheckedQues3Always('')
        setCheckedQues3Often('checked')
        setCheckedQues3Rarely('')
        setCheckedQues3Never('')
        }else if(ans === 'Rarely')
        {
          setCheckedQues3Always('')
          setCheckedQues3Often('')
          setCheckedQues3Rarely('checked')
          setCheckedQues3Never('')
        }else if(ans === 'Never')
        {
          setCheckedQues3Always('')
          setCheckedQues3Often('')
          setCheckedQues3Rarely('')
          setCheckedQues3Never('checked')
        }
        setCheckedQues3Val(ans)
    }
    if (param === '4') {
      if (ans === 'Always') {
        setCheckedQues4Always('checked')
        setCheckedQues4Often('')
        setCheckedQues4Rarely('')
        setCheckedQues4Never('')
      } else if (ans === 'Often/sometimes') {
        setCheckedQues4Always('')
        setCheckedQues4Often('checked')
        setCheckedQues4Rarely('')
        setCheckedQues4Never('')
        }else if(ans === 'Rarely')
        {
          setCheckedQues4Always('')
          setCheckedQues4Often('')
          setCheckedQues4Rarely('checked')
          setCheckedQues4Never('')
        }else if(ans === 'Never')
        {
          setCheckedQues4Always('')
          setCheckedQues4Often('')
          setCheckedQues4Rarely('')
          setCheckedQues4Never('checked')
        }
        setCheckedQues4Val(ans)
    }
    if (param === '5') {
      if (ans === 'Always') {
        setCheckedQues5Always('checked')
        setCheckedQues5Often('')
        setCheckedQues5Rarely('')
        setCheckedQues5Never('')
      } else if (ans === 'Often/sometimes') {
        setCheckedQues5Always('')
        setCheckedQues5Often('checked')
        setCheckedQues5Rarely('')
        setCheckedQues5Never('')
        }else if(ans === 'Rarely')
        {
          setCheckedQues5Always('')
          setCheckedQues5Often('')
          setCheckedQues5Rarely('checked')
          setCheckedQues5Never('')
        }else if(ans === 'Never')
        {
          setCheckedQues5Always('')
          setCheckedQues5Often('')
          setCheckedQues5Rarely('')
          setCheckedQues5Never('checked')
        }
        setCheckedQues5Val(ans)
    }
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfChapter03Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  const BtnDisabled = () => {
    if (checkedQues3Val !== '' && checkedQues4Val !== '' && checkedQues5Val !== '' ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  return (
    <>
      <div className="wrapper bg-bd-2">
        <div
          className="fixed-header"
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="2/4" percentage="30" star="40" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between mt-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                

                <h2 className="h3 mt-4">Engage</h2>
                <p className="mb-2 mt-2"> Let’s take this Quiz</p>
              </div>
              <div
                className="col-lg-7 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <p className="mb-2 mt-3 pn"> <span>1.</span> I express myself clearly and objectively when I am angry without losing my temper.</p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="m"
                    id=""
                    defaultValue="Always"
                    onClick={saveRadio}
                    data-quesId="3"
                    checked={checkedQues3Always}
                  />
                  <label className="form-check-label" htmlFor="">
                    Always
                  </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="m"
                    id=""
                    defaultValue="Often/sometimes"
                    onClick={saveRadio}
                    data-quesId="3"
                    checked={checkedQues3Often}
                  />
                  <label className="form-check-label" htmlFor="">
                    Often/sometimes
                  </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="m"
                    id=""
                    defaultValue="Rarely"
                    onClick={saveRadio}
                    data-quesId="3"
                    checked={checkedQues3Rarely}
                  />
                  <label className="form-check-label" htmlFor="">
                    Rarely
                  </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="m"
                    id=""
                    defaultValue="Never"
                    onClick={saveRadio}
                    data-quesId="3"
                    checked={checkedQues3Never}
                  />
                  <label className="form-check-label" htmlFor="">
                    Never
                  </label>
                  </div>
                </div>
                <p className="mb-2 mt-3 pn"> <span>2.</span>
                  In an argument, I'm generally able to listen to and
                  understand what another person is communicating.
                </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="a"
                    id=""
                    defaultValue="Always"
                    onClick={saveRadio}
                    data-quesId="4"
                    checked={checkedQues4Always}
                  />
                  <label className="form-check-label" htmlFor="">
                    Always
                  </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="a"
                    id=""
                    defaultValue="Often/sometimes"
                    onClick={saveRadio}
                    data-quesId="4"
                    checked={checkedQues4Often}
                  />
                  <label className="form-check-label" htmlFor="">
                    Often/sometimes
                  </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="a"
                    id=""
                    defaultValue="Rarely"
                    onClick={saveRadio}
                    data-quesId="4"
                    checked={checkedQues4Rarely}
                  />
                  <label className="form-check-label" htmlFor="">
                    Rarely
                  </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="a"
                    id=""
                    defaultValue="Never"
                    onClick={saveRadio}
                    data-quesId="4"
                    checked={checkedQues4Never}
                  />
                  <label className="form-check-label" htmlFor="">
                    Never
                  </label>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-5 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <p className="mb-2 mt-3 pn"> <span>3.</span>
                  I am good at gauging how others are feeling.
                </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="b"
                    id=""
                    defaultValue="Always"
                    onClick={saveRadio}
                    data-quesId="5"
                    checked={checkedQues5Always}
                  />
                  <label className="form-check-label" htmlFor="">
                    Always
                  </label>
                </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="b"
                    id=""
                    defaultValue="Often/sometimes"
                    onClick={saveRadio}
                    data-quesId="5"
                    checked={checkedQues5Often}
                  />
                  <label className="form-check-label" htmlFor="">
                    Often/sometimes
                  </label>
                </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="b"
                    id=""
                    defaultValue="Rarely"
                    onClick={saveRadio}
                    data-quesId="5"
                    checked={checkedQues5Rarely}
                  />
                  <label className="form-check-label" htmlFor="">
                    Rarely
                  </label>
                </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="b"
                    id=""
                    defaultValue="Never"
                    onClick={saveRadio}
                    data-quesId="5"
                    checked={checkedQues5Never}
                  />
                  <label className="form-check-label" htmlFor="">
                    Never
                  </label>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
              <PrevBtn link="/module-02/chapter-03-screen-03"/>                  
                {/* <NextBtn link="/module-02/chapter-03-screen-05" text="Go Next"/> */}
                <BtnDisabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Chapter03Screen04;
