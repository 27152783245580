import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter04Screen11 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="70" star="70" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="">
									As you can see in the video for many boys and men staring,
									stalking, making obscene calls, flashing, touching everything
									that comes under the legal definition of sexual harassment are
									completely normal and treated with impunity. They are in fact
									ways for them to win over girls/women or have fun. When their
									unsolicited actions and calls are refused it could lead to
									violent reaction from their end. Many acid attack incidents
									stem from their inability to accept refusal. For such crimes
									many times the victims are blamed in the society by their
									families, communities, law enforcers and politicians
								</p>
								<a
									href="https://hindi.feminisminindia.com/2021/09/08/what-is-victim-blaming-in-hindi/"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p className="red">क्या होती है विक्टिम ब्लेमिंग?</p>
										</div>
									</div>
								</a>
								<p className="mt-4">
									This socialization coupled with systemic and structural
									enabling and normalising of violent behaviours through boys
									and men leads to death of humanity in them and creates immense
									pressure and burden on some cis and trans men who do not fit
									the society’s ideals of hegemonic masculinity. As a result
									they face many adverse repercussions.{" "}
								</p>
								<a
									href="https://www.instagram.com/p/CY_q5zOoFsQ/?utm_medium=copy_link"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/instagram.png" alt="" />
										</div>
										<div>
											<p className="red">Instagram- The Logical Indian</p>
										</div>
									</div>
								</a>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md4/md4-shap-4-11.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-04-screen-10" />

									<NextBtn link="/module-04/chapter-04-screen-13" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen11;
