import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter03Screen27 = () => {
	const navigate = useNavigate();
	const [checkedQues13Yes, setCheckedQues13Yes] = useState('');
	const [checkedQues13No, setCheckedQues13No] = useState('');
	const [checkedQues13Val, setCheckedQues13Val] = useState('');
	const [checkedQues14Yes, setCheckedQues14Yes] = useState('');
	const [checkedQues14No, setCheckedQues14No] = useState('');
	const [checkedQues14Val, setCheckedQues14Val] = useState('');


	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showModalText, setShowModalText] = useState();
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		checkedVal(quesId, e.target.value)

	}


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques13": {
					"ans": checkedQues13Val,
					"ques_id": "13"
				},
				"ques14": {
					"ans": checkedQues14Val,
					"ques_id": "14"
				},


			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-05/chapter-03-screen-28')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans) => {
		if (param === '13') {

			if (ans === 'yes') {
				setCheckedQues13Yes('checked')
				setCheckedQues13No('')
			} else {
				if (ans === 'no') {
					setCheckedQues13No('checked')
					setCheckedQues13Yes('')
				}
			}
			setCheckedQues13Val(ans)
		}
		if (param === '14') {
			if (ans === 'yes') {
				setCheckedQues14Yes('checked')
				setCheckedQues14No('')
			} else {
				if (ans === 'no') {
					setCheckedQues14No('checked')
					setCheckedQues14Yes(''
					)
				}
			}
			setCheckedQues14Val(ans)
		}




	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues13Val !== '' && checkedQues14Val !== ''
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<p className="mb-2  mt-3">Think of the following questions while you do this exercise</p>


								<p className="mb-2  mt-3">Are there different standards for sexuality for men/ boys and women/ girls?  </p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle7"
											onClick={saveRadio}
											defaultValue="yes"
											data-quesId="13"
											checked={checkedQues13Yes}

										/>
										<label className="form-check-label" htmlFor="mycirle7">Yes </label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle8"
											onClick={saveRadio}
											defaultValue="no"
											data-quesId="13"
											checked={checkedQues13No}

										/>
										<label className="form-check-label" htmlFor="mycirle8">No </label>
									</div>
								</div>

								<p className="mb-2  mt-3">	Are terms used for women more negative and derogatory while for men they are aggressive and positive?  </p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle5" id="mycirle1"
											onClick={saveRadio}
											defaultValue="yes"
											data-quesId="14"
											checked={checkedQues14Yes}
										/>
										<label className="form-check-label" htmlFor="mycirle1">Yes </label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle5" id="mycirle2"
											onClick={saveRadio}
											defaultValue="no"
											data-quesId="14"
											checked={checkedQues14No}
										/>
										<label className="form-check-label" htmlFor="mycirle2">No </label>
									</div>
								</div>





							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="42%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-3-20.png"
										alt=""
									/>
								</center>
							</div>




						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-26" />
								{/* <NextBtn link="/module-05/chapter-03-screen-28" text="Go Next"/> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>






		</>
	);
};

export default Chapter03Screen27;
