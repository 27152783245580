import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter03Screen33 = () => {
  const navigate = useNavigate();
  const [checkedQues15Option1, setCheckedQues15Option1] = useState("");
  const [checkedQues15Option2, setCheckedQues15Option2] = useState("");
  const [checkedQues15Option3, setCheckedQues15Option3] = useState("");
  const [checkedQues15Option4, setCheckedQues15Option4] = useState("");
  const [checkedQues15Val, setCheckedQues15Val] = useState("");

  const [checkedQues16, setCheckedQues16] = useState([]);
  const [option1Checked, setOption1Checked] = useState("");
  const [option2Checked, setOption2Checked] = useState("");
  const [option3Checked, setOption3Checked] = useState("");
  const [option4Checked, setOption4Checked] = useState("");

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const saveRadio = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    // alert(quesId);
    checkedVal(quesId, e.target.value);
  };
  const saveGenderBased = (e) => {
    const res = e.target.value;
    const checked = e.target.checked;
    checkBoxChecked(res, checked);
    setCheckedQues16((checkedQues16) => [...checkedQues16, res]);
  };
  const saveAns = () => {
    var data = JSON.stringify({
      user_id: user_details.user_id,
      answers: {
        ques1: {
          ans: "",
          ques_id: "1",
        },

        ques15: {
          ans: checkedQues15Val,
          ques_id: "15",
        },
        ques16: {
          ans: checkedQues16.join(","),
          ques_id: "16",
        },
      },
    });
    var config = {
      method: "post",
      url: BaseURL + "/SexualityChapter03",
      headers: {
        Authorization: `Bearer ${user_details.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.status === "Token is Expired") {
          window.localStorage.clear();
          navigate("/login");
        }
        if (response.data.message === "Token Invalid") {
          window.localStorage.clear();
          navigate("/login");
        }

        if (response.data.success === true) {
          navigate("/module-05/chapter-03-screen-34");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const BtnDisabled = () => {
    if (checkedQues16.length > 0 && checkedQues15Val !== "") {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param, ans) => {
    if (param === "16") {
      const ansArr = ans.split(",");
      ansArr.map((el) => {
        const checked = true;
        checkBoxChecked(el, checked);
      });
      setCheckedQues16(ansArr);
    }
    if (param === "15") {
      if (ans === "option1") {
        setCheckedQues15Option1("checked");
        setCheckedQues15Option2("");
        setCheckedQues15Option3("");
        setCheckedQues15Option4("");
      } else if (ans === "option2") {
        setCheckedQues15Option1("");
        setCheckedQues15Option2("checked");
        setCheckedQues15Option3("");
        setCheckedQues15Option4("");
      } else if (ans === "option3") {
        setCheckedQues15Option1("");
        setCheckedQues15Option2("");
        setCheckedQues15Option3("checked");
        setCheckedQues15Option4("");
      } else if (ans === "option4") {
        setCheckedQues15Option1("");
        setCheckedQues15Option2("");
        setCheckedQues15Option3("");
        setCheckedQues15Option4("checked");
      }
      setCheckedQues15Val(ans);
    }
  };
  const checkBoxChecked = (val, checked) => {
    if (val === "option1") {
      if (checked === true) {
        setOption1Checked("checked");
      } else {
        setOption1Checked("");
      }
    }
    if (val === "option2") {
      if (checked === true) {
        setOption2Checked("checked");
      } else {
        setOption2Checked("");
      }
    }
    if (val === "option3") {
      if (checked === true) {
        setOption3Checked("checked");
      } else {
        setOption3Checked("");
      }
    }
    if (val === "option4") {
      if (checked === true) {
        setOption4Checked("checked");
      } else {
        setOption4Checked("");
      }
    }
  };
  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_details.user_id,
      ques_id: "",
    });
    var config = {
      method: "post",
      url: BaseURL + "/sexualityChapter03Result",
      headers: {
        Authorization: `Bearer ${user_details.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(), result.ans);
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="2/4" percentage="50" star="50" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-1">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                {/* <h3 className="h3 mt-3">Learn:  </h3> */}

                <p className="mb-2 mt-3 pn">
                  <span>
                    <b>1.</b>
                  </span>{" "}
                  <b>
                    Pick a sentence that demonstrates Rita’s boss has power over
                    her{" "}
                  </b>{" "}
                </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="namecircle2"
                      id="mycirle5"
                      defaultValue="option1"
                      onClick={saveRadio}
                      data-quesId="15"
                      checked={checkedQues15Option1}
                    />
                    <label className="form-check-label" htmlFor="mycirle5">
                      {" "}
                      There were very few people at India Gate,probably because
                      it was close to Republic Day celebrations and traffic was
                      diverted
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="namecircle2"
                      id="mycirle6"
                      defaultValue="option2"
                      onClick={saveRadio}
                      data-quesId="15"
                      checked={checkedQues15Option2}
                    />
                    <label className="form-check-label" htmlFor="mycirle6">
                      He was always bullying them and exerting his dominance.
                      All the girls stayed quiet because he was their boss
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="namecircle2"
                      id="mycirle7"
                      defaultValue="option3"
                      onClick={saveRadio}
                      data-quesId="15"
                      checked={checkedQues15Option3}
                    />
                    <label className="form-check-label" htmlFor="mycirle7">
                      {" "}
                      They didn’t want the ride, but he kept pestering them{" "}
                    </label>
                  </div>
                </div>
                {/* <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio" 
                      name="namecircle2" id="mycirle8"
                      defaultValue="option4"
                      onClick={saveRadio}
                      data-quesId="15"
                      checked={checkedQues15Option4}
                        />
                      <label className="form-check-label" htmlFor="mycirle8">They didn’t want the ride, but he kept pestering them</label>
                    </div>
                  </div> */}

                <p className="mb-2 mt-3 pn">
                  <span>
                    <b>2.</b>
                  </span>{" "}
                  <b>
                    {" "}
                    Pick sentences where there is a violation of body and rights{" "}
                  </b>{" "}
                </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle1"
                      defaultValue="option1"
                      onClick={saveGenderBased}
                      checked={option1Checked}
                    />
                    <label className="form-check-label" htmlFor="mycirle1">
                      Rita’s boss began to touch and kiss Rita and soon was able
                      to overpower her{" "}
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle2"
                      defaultValue="option2"
                      onClick={saveGenderBased}
                      checked={option2Checked}
                    />
                    <label className="form-check-label" htmlFor="mycirle2">
                      {" "}
                      He held her down even more forcefully, while touching her
                      despite her protests{" "}
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle3"
                      defaultValue="option3"
                      onClick={saveGenderBased}
                      checked={option3Checked}
                    />
                    <label className="form-check-label" htmlFor="mycirle3">
                      Rita’s boss parked the car in a dark place, under a big
                      tree where they were hidden from sight.{" "}
                    </label>
                  </div>
                </div>
              </div>
              {/* 
               <div
                className="col-lg-4 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="40%"
                    draggable="false"
                    src="/assets/img/md4/md4-shap-2-3.png"
                    alt=""
                  />
                </center>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-05/chapter-03-screen-32" />
                {/* <NextBtn link="/module-05/chapter-03-screen-34" text="Go Next"/> */}
                <BtnDisabled />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen33;
