import React,{useEffect,useState} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter02Screen02 = () => {

  const navigate = useNavigate();
  
 

  const [checkedQues2Option1, setCheckedQues2Option1] = useState('');
  const [checkedQues2Option2, setCheckedQues2Option2] = useState('');
 
  const [checkedQues2Val, setCheckedQues2Val] = useState('');

  const [checkedQues3, setCheckedQues3] = useState([]);
  const [checkedQues3Option1, setCheckedQues3Option1] = useState('');
  const [checkedQues3Option2, setCheckedQues3Option2] = useState('');
  const [checkedQues3Option3, setCheckedQues3Option3] = useState('');
  const [checkedQues3Option4, setCheckedQues3Option4] = useState('');








  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    
    checkedVal(quesId,e.target.value)
   
  }

  const saveGenderBased = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    const res = e.target.value;
    const checked = e.target.checked;

   

    
    checkBoxChecked(res,checked,quesId);
    if(quesId === '3')
    {
      setCheckedQues3(checkedQues3 => [...checkedQues3, res]);

    }

  


   

  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        
        "ques2": {
          "ans": checkedQues2Val,         
          "ques_id": "2"
        },
        "ques3": {
          "ans":  checkedQues3.join(','),  
          "ques_id": "3"
        },
       
       
        
      
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/ApproachChapter04',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {

        if(response.status === "Token is Expired")
        {
          window.localStorage.clear();
          navigate('/login')
        }
        if(response.data.message === "Token Invalid")
        {
          window.localStorage.clear();
          navigate('/login')
        }
        
        if(response.data.success === true)
        {
          navigate('/module-06/chapter-04-screen-04')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues2Val !=='' && checkedQues3.length > 0) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>

         
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {
   

    if (param === '2') {
      
      if (ans === 'option1') {
        setCheckedQues2Option1('checked')
        setCheckedQues2Option2('')
      
      } else if(ans === 'option2') {
        setCheckedQues2Option1('')
        setCheckedQues2Option2('checked')
       
      }
      setCheckedQues2Val(ans)
    }

    if (param === '3') {
      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues3(ansArr)
    }




   
  }
  const checkBoxChecked = (val,checked,quesId)=>{
    if(quesId === '3')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues3Option1('checked');
        }else{
          setCheckedQues3Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues3Option2('checked');
        }else{
          setCheckedQues3Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues3Option3('checked');
        }else{
          setCheckedQues3Option3('');
        }
      }
      if(val === 'option4')
      {
        if(checked === true)
        {
          setCheckedQues3Option4('checked');
        }else{
          setCheckedQues3Option4('');
        }
      }

     

    
    

    }

   
  
   
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/approachChapter04Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])



  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"
        
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="3/4" percentage="78" star="50" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
          <div className="row align-items-top justify-content-between pt-20 mr-3 mt-m-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <h3 className="h3">Quiz</h3>

                <p className="mb-2 mt-3 pn"><span><b>1</b></span><b> Why does Breakthrough work with youth (Mark the true one)</b> </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio"
                     name="namecircle3"
                      id="mycirle9" 
                      defaultValue="option1"
                         onClick={saveRadio}
                         checked={checkedQues2Option1}
                         data-quesId="2" 

                      />
                    <label className="form-check-label" htmlFor="mycirle9"> Youth are the architects of tomorrow</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio" 
                    name="namecircle3"
                     id="mycirle10"
                     defaultValue="option2"
                         onClick={saveRadio}
                         checked={checkedQues2Option2}
                         data-quesId="2" 

                      />
                    <label className="form-check-label" htmlFor="mycirle10"> Youth are lost and need constructive engagement </label>
                  </div>
                </div>
               





                <p className="mb-2 mt-3 pn"><span><b>2.</b></span><b>Which of the given below statement/s are true when it comes to Breakthrough’s work with youth</b> </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                     name="namecircle"
                      id="mycirle1"
                      defaultValue="option1"
                         onClick={saveGenderBased}
                         checked={checkedQues3Option1}
                         data-quesId="3" 
                        />
                    <label className="form-check-label" htmlFor="mycirle1"> We are engaging the youth to become our partners in this journey to a tomorrow that is free of gender-based violence and discrimination</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                     name="namecircle"
                      id="mycirle2" 
                      defaultValue="option2"
                         onClick={saveGenderBased}
                         checked={checkedQues3Option2}
                         data-quesId="3" 
                       />
                    <label className="form-check-label" htmlFor="mycirle2"> We work with youth to ensure they are responsible in their actions and listen to their families</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                     name="namecircle" 
                     id="mycirle3" 
                     defaultValue="option3"
                         onClick={saveGenderBased}
                         checked={checkedQues3Option3}
                         data-quesId="3" 

                     />
                    <label className="form-check-label" htmlFor="mycirle3"> Youth is an integral part of the work we do and the impact we hope to achieve. </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                     name="namecircle" 
                     id="mycirle4"
                     defaultValue="option4"
                         onClick={saveGenderBased}
                         checked={checkedQues3Option4}
                         data-quesId="3" 

                      />
                    <label className="form-check-label" htmlFor="mycirle4"> We engage with youth through interactions, conversations, innovative media trainings and skill development sessions  </label>
                  </div>
                </div>





                


               



              </div>

             
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
               
                <PrevBtn link="/module-06/chapter-04-screen-02"/>  
                {/* <NextBtn link="/module-06/chapter-04-screen-04" text="Go Next"/> */}
                 <BtnDisabled/>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen02;
