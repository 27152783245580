import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter01Screen08 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="5" star="5" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">

							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2 mb-2">Additional Resources </h2>
								<a
									href="https://www.youtube.com/watch?v=8ogW9LggBHE"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>
												Indian Men Talk About Love, Sex &amp; Relationships{" "}
												<br /> <b>#TribeTalk Part 2/4</b>
											</p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2019/10/16/men-made-by-society-hindi/"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p> पुरुष होते नहीं बल्कि समाज में बनाए जाते हैं | फेमिनिज़म इन इंडिया</p>
										</div>
									</div>
								</a>

							</div>
							<div
								className="col-lg-4 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="50%"
										draggable="false"
										src="/assets/img/md4/md4-shap-1-8.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-01-screen-07" />

									<NextBtn link="/module-04/chapter-02-screen-01" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen08;
