import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";

const Chapter01Screen11 = () => {

	const navigate = useNavigate();

	const [checkedQues8, setCheckedQues8] = useState([]);
	const [checkedQues8Option1, setCheckedQues8Option1] = useState('');
	const [checkedQues8Option2, setCheckedQues8Option2] = useState('');
	const [checkedQues8Option3, setCheckedQues8Option3] = useState('');
	const [checkedQues8Option4, setCheckedQues8Option4] = useState('');
	const [checkedQues8Option5, setCheckedQues8Option5] = useState('');

	const [checkedQues9Yes, setCheckedQues9Yes] = useState('');
	const [checkedQues9No, setCheckedQues9No] = useState('');
	const [checkedQues9Val, setCheckedQues9Val] = useState('');


	const [checkedQues10, setCheckedQues10] = useState([]);
	const [checkedQues10Option1, setCheckedQues10Option1] = useState('');
	const [checkedQues10Option2, setCheckedQues10Option2] = useState('');
	const [checkedQues10Option3, setCheckedQues10Option3] = useState('');
	const [checkedQues10Option4, setCheckedQues10Option4] = useState('');
	const [checkedQues10Option5, setCheckedQues10Option5] = useState('');




	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		checkedVal(quesId, e.target.value)

	}

	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;




		checkBoxChecked(res, checked, quesId);
		if (quesId === '8') {
			setCheckedQues8(checkedQues8 => [...checkedQues8, res]);

		}

		if (quesId === '10') {
			setCheckedQues10(checkedQues10 => [...checkedQues10, res]);

		}




	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques8": {
					"ans": checkedQues8.join(','),
					"ques_id": "8"
				},
				"ques9": {
					"ans": checkedQues9Val,
					"ques_id": "9"
				},
				"ques10": {
					"ans": checkedQues10.join(','),
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				}



			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-05/chapter-01-screen-12')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues8.length > 0 && checkedQues9Val !== '' && checkedQues10.length > 0) {
			return (
				<>


					<button className="button_su" >
						<span className="su_button_circle desplode-circle">
						</span>
						<button className="button_su_inner" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '8') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues8(ansArr)
		}

		if (param === '9') {

			if (ans === 'yes') {
				setCheckedQues9Yes('checked')
				setCheckedQues9No('')
			} else {
				if (ans === 'no') {
					setCheckedQues9No('checked')
					setCheckedQues9Yes('')
				}
			}
			setCheckedQues9Val(ans)
		}

		if (param === '10') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues10(ansArr)
		}





	}
	const checkBoxChecked = (val, checked, quesId) => {
		if (quesId === '8') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues8Option1('checked');
				} else {
					setCheckedQues8Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues8Option2('checked');
				} else {
					setCheckedQues8Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues8Option3('checked');
				} else {
					setCheckedQues8Option3('');
				}
			}
			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues8Option4('checked');
				} else {
					setCheckedQues8Option4('');
				}
			}

			if (val === 'option5') {
				if (checked === true) {
					setCheckedQues8Option5('checked');
				} else {
					setCheckedQues8Option5('');
				}
			}




		}

		if (quesId === '10') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues10Option1('checked');
				} else {
					setCheckedQues10Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues10Option2('checked');
				} else {
					setCheckedQues10Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues10Option3('checked');
				} else {
					setCheckedQues10Option3('');
				}
			}
			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues10Option4('checked');
				} else {
					setCheckedQues10Option4('');
				}
			}

			if (val === 'option5') {
				if (checked === true) {
					setCheckedQues10Option5('checked');
				} else {
					setCheckedQues10Option5('');
				}
			}




		}




	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/2" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-2  mt-3">How does Dinesh feel about posting his photo online? </p>


								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle1"
											defaultValue="option1"
											onClick={saveGenderBased}
											checked={checkedQues8Option1}
											data-quesId="8"
										/>
										<label className="form-check-label" htmlFor="mycirle1">Doubtful </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle"
											id="mycirle2"
											defaultValue="option2"
											onClick={saveGenderBased}
											checked={checkedQues8Option2}
											data-quesId="8"
										/>
										<label className="form-check-label" htmlFor="mycirle2">Insecure </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle3"
											defaultValue="option3"
											onClick={saveGenderBased}
											checked={checkedQues8Option3}
											data-quesId="8"
										/>
										<label className="form-check-label" htmlFor="mycirle3">Worried  </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle4"
											defaultValue="option4"
											onClick={saveGenderBased}
											checked={checkedQues8Option4}
											data-quesId="8"
										/>
										<label className="form-check-label" htmlFor="mycirle4">Pressured </label>
									</div>
								</div>

								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle4"
											defaultValue="option5"
											onClick={saveGenderBased}
											checked={checkedQues8Option5}
											data-quesId="8"
										/>
										<label className="form-check-label" htmlFor="mycirle4">Negative </label>
									</div>
								</div>


								<p className="mb-2 mt-3">Does he get affected by what other people think of his photos? </p>
								<div className="d-flex mt-3">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle" id="mycirle5"
											onClick={saveRadio}
											defaultValue="yes"
											data-quesId="9"
											checked={checkedQues9Yes}
										/>
										<label className="form-check-label" htmlFor="mycirle5">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="radio"
											name="namecircle"
											id="mycirle6"

											onClick={saveRadio}
											defaultValue="no"
											data-quesId="9"
											checked={checkedQues9No}

										/>
										<label className="form-check-label" htmlFor="mycirle6">No</label>
									</div>
								</div>




								<p className="mb-2  mt-3">How does Fahad feel when he sees Dinesh’s photo? </p>


								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle7"
											defaultValue="option1"
											onClick={saveGenderBased}
											checked={checkedQues10Option1}
											data-quesId="10"

										/>
										<label className="form-check-label" htmlFor="mycirle7">Injecure </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle"
											id="mycirle8"
											defaultValue="option2"
											onClick={saveGenderBased}
											checked={checkedQues10Option2}
											data-quesId="10"
										/>
										<label className="form-check-label" htmlFor="mycirle8">Jealous </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle9"
											defaultValue="option3"
											onClick={saveGenderBased}
											checked={checkedQues10Option3}
											data-quesId="10"
										/>
										<label className="form-check-label" htmlFor="mycirle9">Envious  </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle10"
											defaultValue="option4"
											onClick={saveGenderBased}
											checked={checkedQues10Option4}
											data-quesId="10"
										/>
										<label className="form-check-label" htmlFor="mycirle10">Dejected </label>
									</div>
								</div>

								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle11"
											defaultValue="option5"
											onClick={saveGenderBased}
											checked={checkedQues10Option5}
											data-quesId="10"
										/>
										<label className="form-check-label" htmlFor="mycirle11">Negative </label>
									</div>
								</div>


							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="85%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-1-11.png"
										alt=""
									/>
								</center>
							</div>





						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-01-screen-10" />
								{/* <NextBtn link="/module-05/chapter-01-screen-12" text="Go Next"/> */}

								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>





			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							<p className="text-white text-text-left">The notions we have of our body and the way we feel about this is called body image!
							</p>

							<ul className="list-text-whtie">
								<li>	The way we see ourselves </li>
								<li>	The way we feel about how we look  </li>
								<li>	Our thoughts and beliefs of how we should look   </li>
								<li>	The things we do in relation to how we feel we look </li>
							</ul>

							<div class=" d-flex justify-content-center mt-3">
								<button className="button_su" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<button onClick={saveAns} className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </button>
								</button>
							</div>




						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen11;
