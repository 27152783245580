import React, { useState } from 'react'
import { useNavigate,NavLink } from "react-router-dom";
import PrevBtn from "../../../common/PrevBtn";
import Header from '../../../header/Header';
import { useDrop } from "react-dnd";
// import Image from './Image';
import Chapter04Screen03_1 from './Chapter04Screen03_1';
import axios from 'axios';
import BaseURL from '../../../config/Baseurl';
import { useEffect } from 'react';
import NextBtn from '../../../common/NextBtn'
import { useDrag } from "react-dnd";

const Chapter04Screen02 = () => {

	const navigate = useNavigate();
	const [PictureList, setPictureList] = useState([
		{ "id": "1", "name": "Vaginal Sex" },
		{ "id": "2", "name": "Masturbation" },
		{ "id": "3",  "name": "Being a Virgin" },
		{ "id": "4",  "name": "Sexual Health awareness" },
		{ "id": "5",  "name": "Kissing" },
		{ "id": "6",  "name": "Orgasm" },
		{ "id": "7",  "name": "Using contraceptives" },
		
	]);

 


	const [consquesncesArray, setConsquesncesArray] = useState([]);
	const [responsibilitesArray, setResponsibilitesArray] = useState([]);
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const [{ isOver }, consdrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addSamarArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const [{ isShasOver }, respdrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addSahasArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));


	// Add To Array Samar
	const addSamarArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setConsquesncesArray((consquesncesArray) => [...consquesncesArray, picture_List[0]]);
	};

	// Add To sahas Array
	const addSahasArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setResponsibilitesArray((responsibilitesArray) => [...responsibilitesArray, picture_List[0]]);
	};


	const onItemRemoveSamar = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = consquesncesArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setConsquesncesArray(consquesncesArray =>
			consquesncesArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};

	const onItemRemoveSahas = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = responsibilitesArray.filter((item) => item.id === id);
		console.log(_item);
		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setResponsibilitesArray(responsibilitesArray =>
			responsibilitesArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};


	const saveAns = () => {
  
		var data = JSON.stringify({
		  "user_id": user_details.user_id,
		  "answers": {
			"ques48": {
				"ans": JSON.stringify(responsibilitesArray), // as sexual activity
				"ans2": JSON.stringify(consquesncesArray),      // as sexual behaviour 
			  "ques_id": "48"
			},
			
		  }
		});
		var config = {
		  method: 'post',
		  url: BaseURL +'/SexualityChapter04',
		  headers: {
			'Authorization': `Bearer ${user_details.token}`,
			'Content-Type': 'application/json'
		  },
		  data: data
		};
		axios(config)
		  .then(function (response) {

			if(response.status === "Token is Expired")
			{
			  window.localStorage.clear();
			  navigate('/login')
			}
			if(response.data.message === "Token Invalid")
			{
			  window.localStorage.clear();
			  navigate('/login')
			}
			
			if(response.data.success === true)
			{
				navigate('/module-05/chapter-04-screen-03');
			}
		  })
		  .catch(function (error) {
			console.log(error);
		  });
	  }

	useEffect(() => {
	
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			
			"ques_id": "48"
		});

		var config = {
			method: 'post',
			url: BaseURL+'/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
        console.log(response.data);
				if(response.data.success === true)
				{
					const ans = JSON.parse(response.data.data.chapter.ans)
					const ans2 = JSON.parse(response.data.data.chapter.ans2)
					setConsquesncesArray(ans);
					setResponsibilitesArray(ans2);
					if(ans.length > 0){
					ans.map((elem)=>{

						setPictureList(current =>
							current.filter(PictureList => {							 
							  return PictureList.id !== elem.id;
							}),
						  );

						
					});
				}

				if(ans2.length > 0){
					ans2.map((elem)=>{

						setPictureList(current =>
							current.filter(PictureList => {							 
							  return PictureList.id !== elem.id;
							}),
						  );

						
					});
				}

					
				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);

	
	const BtnDisabled = () => {
		if (responsibilitesArray.length !== 0 && consquesncesArray.length !== 0 ) {
		  return (
			<>
			  
        <button className="button_su" >
              <span className="su_button_circle desplode-circle">
              </span>
              <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
			</>
		  );
		} else {
		  return (
			<>
			  <button className="button_su disabled-btn" type="button" disabled="">
				<span className="su_button_circle"></span>
				<span className="button_su_inner">
				  <span className="button_text_container">
					{" "}
					Go Next <i className="bi bi-arrow-right" />
				  </span>{" "}
				</span>
			  </button>
			</>
		  );
		}
	  };


  return (
    <>
      <div className="wrapper bg-bd-2">
        <div
          className="fixed-header"
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="3/4" percentage="75" star="50" />
          </div>
        </div>

        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 ">

              
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                
                <h3 className="h3">Engage</h3>
                <p className="mb-2  mt-2"> Drag the given below words/statements into the box you think fits best!</p>
                <table className="table table-bordered table-dark table-red table-padding table-white mt-1">
                  <tbody>
                    <tr>
                      <td className="td-green">Sexual Activity </td>
                      <td className="td-red">Sexual Behaviour</td>
                    </tr>
                    <tr className="drag-table table-width-set">
                      <td>
                        <div className="dragarea" ref={consdrop}>
                        <ul >
                        
                        {consquesncesArray.map((e) => {
                          
												return (
													<>
														<li>
															
															{e.name}
                              <button
                                  onClick={onItemRemoveSamar}
																data-id={e.id}
																type="button"
																className="btn-close"
																aria-label="Close"
                              ></button>
														</li>

													</>
												)
                          

											})}
                      </ul>
                        </div>
                      </td>
                      <td>
                      <div className="dragarea" ref={respdrop}>
                      <ul>
                        
                        {responsibilitesArray.map((e) => {
                          
												return (
													<>
														<li>
															
															{e.name}
                              <button
                                  onClick={onItemRemoveSahas}
																data-id={e.id}
																type="button"
																className="btn-close"
																aria-label="Close"
                              ></button>
														</li>

													</>
												)
                          

											})}
                      </ul>
                      </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

                

              <div
                className="col-lg-6 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <ul className="list-style-set list-cursor list-circle mt-3 mb-3">
                {
                  PictureList.map((e)=>{
                    if(e.id < 10){
                     
                    return (
                   
                      <Chapter04Screen03_1 key={e.id} id={e.id} imageName={e.name}/>
                     
                  
                    )
                    }
                  })
                }
                 
                 
                </ul>
              </div>


              <div
                className="col-lg-6 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <ul className="list-style-set list-circle mt-2 mb-3">
                {
                  PictureList.map((e)=>{
                    if(e.id > 9){
                    return (
                      <Chapter04Screen03_1 key={e.id} id={e.id} imageName={e.name}/>
                    )
                    }
                  })
                }
                </ul>
              </div>

              
              <p className="mb-2  mt-2"> In the final chapter of this sexuality module, we turn our attention to sexual activities and behaviours. We think of the risks that come with being sexually active, our responsibilities and the sexual rights we have.  </p> 

              
             
            </div>
          </div>
        </div>

       
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
               
                <PrevBtn link="/module-05/chapter-04-screen-01"/>  
                {/* <NextBtn link="/module-05/chapter-02-screen-03" text="Go Next"/> */}

				<BtnDisabled />
    

              </div>
            </div>
          </div>
        </div>
      </div>




   

    </>
  );
};

export default Chapter04Screen02;
