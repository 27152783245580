import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
// import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter05Screen07N = () => {

	const navigate = useNavigate();
	const [checkedQues7Yes, setCheckedQues7Yes] = useState('');
	const [checkedQues7No, setCheckedQues7No] = useState('');
	const [checkedQues7Val, setCheckedQues7Val] = useState('');


	const [checkedQues6, setCheckedQues6] = useState([]);


	const [earlyChecked, setEarlyChecked] = useState('');

	const [domesticChecked, setDomesticChecked] = useState('');
	const [traffickingChecked, setTraffickingChecked] = useState('');
	const [dowryChecked, setDowryChecked] = useState('');
	const [femicideChecked, setFemicideChecked] = useState('');
	const [femaleChecked, setFemaleChecked] = useState('');
	const [genderChecked, setGenderChecked] = useState('');
	const [onlineChecked, setOnlineChecked] = useState('');
	const [maltreatmentChecked, setMaltreatmentChecked] = useState('');
	const [restrictionsChecked, setRestrictionChecked] = useState('');



	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveGenderBased = (e) => {
		const res = e.target.value;
		const checked = e.target.checked;


		checkBoxChecked(res, checked);
		setCheckedQues6(checkedQues6 => [...checkedQues6, res]);
	}

	const saveRadio = (e) => {
		checkedVal('7', e.target.value)

	}

	// console.log(checkedQues5);
	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": checkedQues6.join(','),
					"ques_id": "6"
				},
				"ques7": {
					"ans": checkedQues7Val,
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter05',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-05-screen-08')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues6 !== '' && checkedQues7Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	const checkedVal = (param, ans) => {


		if (param === '7') {
			if (ans === 'yes') {
				setCheckedQues7Yes('checked')
				setCheckedQues7No('')
				setCheckedQues7Val(ans)
			} else {
				if (ans === 'no') {
					setCheckedQues7No('checked')
					setCheckedQues7Yes('')
					setCheckedQues7Val(ans)
				}
			}
		}

		if (param === '6') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked);
			})
			setCheckedQues6(ansArr)
		}


	}

	const checkBoxChecked = (val, checked) => {
		if (val === 'Early and Forced marriage') {
			if (checked === true) {
				setEarlyChecked('checked');

			} else {
				setEarlyChecked('');
			}
		}

		if (val === 'Domestic Violence') {
			if (checked === true) {
				setDomesticChecked('checked');
			} else {
				setDomesticChecked('');
			}
		}

		if (val === 'Trafficking') {
			if (checked === true) {
				setTraffickingChecked('checked');
			} else {
				setTraffickingChecked('');
			}
		}


		if (val === 'Dowry') {
			if (checked === true) {
				setDowryChecked('checked');
			} else {
				setDowryChecked('');
			}
		}
		if (val === 'Female Genital Mutilation') {
			if (checked === true) {
				setFemaleChecked('checked');
			} else {
				setFemaleChecked('');
			}
		}

		if (val === 'Femicide') {
			if (checked === true) {
				setFemicideChecked('checked');
			} else {
				setFemicideChecked('');
			}
		}

		if (val === 'Gender biased sex selection') {
			if (checked === true) {
				setGenderChecked('checked');
			} else {
				setGenderChecked('');
			}
		}

		if (val === 'Online or Digital Violence') {
			if (checked === true) {

				setOnlineChecked('checked');
			} else {
				setOnlineChecked('');
			}
		}

		if (val === 'Maltreatment and discrimination') {
			if (checked === true) {

				setMaltreatmentChecked('checked');
			} else {
				setMaltreatmentChecked('');
			}
		}



		if (val === 'Restrictions on mobility and sexuality') {
			if (checked === true) {

				setRestrictionChecked('checked');
			} else {
				setRestrictionChecked('');
			}
		}



	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter05Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="88" star="68" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>


								<p className="">
									What types of Gender based discrimination and violence did she face? Select all that apply
								</p>

								<div className="checkbox-design mt-1">
									<form>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle1"
													defaultValue="Early and Forced marriage"
													onClick={saveGenderBased}
													checked={earlyChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle1">
													Early and Forced marriage
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle2"
													defaultValue="Domestic Violence"
													onClick={saveGenderBased}
													checked={domesticChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle2">
													Domestic Violence
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle3"
													defaultValue="Trafficking"
													onClick={saveGenderBased}
													checked={traffickingChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle3">
													Trafficking
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle4"
													defaultValue="Dowry"
													onClick={saveGenderBased}
													checked={dowryChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle4">
													Dowry
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle5"
													defaultValue="Female Genital Mutilation"
													onClick={saveGenderBased}
													checked={femaleChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle5">
													Female Genital Mutilation
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle6"
													defaultValue="Femicide"
													onClick={saveGenderBased}
													checked={femicideChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle6">
													Femicide
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle8"
													id="mycirle7"
													defaultValue="Gender biased sex selection"
													onClick={saveGenderBased}
													checked={genderChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle7">
													Gender biased sex selection
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle8"
													defaultValue="Online or Digital Violence"
													onClick={saveGenderBased}
													checked={onlineChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle8">
													Online or Digital Violence
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle9"
													defaultValue="Maltreatment and discrimination"
													onClick={saveGenderBased}
													checked={maltreatmentChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle9">
													Maltreatment and discrimination
												</label>
											</div>
										</div>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="checkbox"
													name="namecircle"
													id="mycirle10"
													defaultValue="Restrictions on mobility and sexuality"
													onClick={saveGenderBased}
													checked={restrictionsChecked}
												/>
												<label className="form-check-label" htmlFor="mycirle10">
													Restrictions on mobility and sexuality
												</label>
											</div>
										</div>


									</form>
								</div>

								<p className="mb-2">
									Do you think the different types of gender based discrimination and violence she faced are connected?
								</p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="ques7"
											id="ture"
											defaultValue="yes"
											onClick={saveRadio}
											checked={checkedQues7Yes}
										/>
										<label className="form-check-label" htmlFor="ture">
											Yes
										</label>
									</div>
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="ques7"
											id="false"
											defaultValue="no"
											onClick={saveRadio}
											checked={checkedQues7No}
										/>
										<label className="form-check-label " htmlFor="false">
											No
										</label>
									</div>
								</div>

							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img width="53%" src="/assets/img/md1/md1-shap-5-07n.png" alt="" />
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-05-screen-07" />
								{/* <NextBtn
                    link="/module-01/chapter-05-screen-08"
                    text="Go Next"
                  /> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen07N;
