import React,{useEffect} from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
// import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import { useState } from "react";


const Chapter05Screen17 = () => {
  const navigate = useNavigate();
 

  const [checkedQues10, setCheckedQues10] = useState([]);
  const [checkedQues10Option1, setCheckedQues10Option1] = useState('');
  const [checkedQues10Option2, setCheckedQues10Option2] = useState('');
  const [checkedQues10Option3, setCheckedQues10Option3] = useState('');
  const [checkedQues10Option4, setCheckedQues10Option4] = useState('');

  const [checkedQues11, setCheckedQues11] = useState([]);
  const [checkedQues11Option1, setCheckedQues11Option1] = useState('');
  const [checkedQues11Option2, setCheckedQues11Option2] = useState('');
  const [checkedQues11Option3, setCheckedQues11Option3] = useState('');
  const [checkedQues11Option4, setCheckedQues11Option4] = useState('');


  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const saveGenderBased = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    const res = e.target.value;
    const checked = e.target.checked;
    
    checkBoxChecked(res,checked,quesId);
    if(quesId === '10')
    {
      setCheckedQues10(checkedQues10 => [...checkedQues10, res]);

    }

    if(quesId === '11')
    {
      setCheckedQues11(checkedQues11 => [...checkedQues11, res]);

    }

  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans":  "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",         
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",         
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques10": {
          "ans":checkedQues10.join(','),
          "ques_id": "10"
        },
        "ques11": {
          "ans":checkedQues11.join(','),
          "ques_id": "11"
        },
      
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/MasculFinalQuiz',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-04/chapter-05-screen-18')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues10.length > 0 && checkedQues11.length > 0  ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {
    if (param === '10') {
      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues10(ansArr)
    }

    if (param === '11') {
      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues11(ansArr)
    }

   
  }
  const checkBoxChecked = (val,checked,quesId)=>{
    if(quesId === '10')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues10Option1('checked');
        }else{
          setCheckedQues10Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues10Option2('checked');
        }else{
          setCheckedQues10Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues10Option3('checked');
        }else{
          setCheckedQues10Option3('');
        }
      }
      if(val === 'option4')
      {
        if(checked === true)
        {
          setCheckedQues10Option4('checked');
        }else{
          setCheckedQues10Option4('');
        }
      }
    }

    if(quesId === '11')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues11Option1('checked');
        }else{
          setCheckedQues11Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues11Option2('checked');
        }else{
          setCheckedQues11Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues11Option3('checked');
        }else{
          setCheckedQues11Option3('');
        }
      }
      if(val === 'option4')
      {
        if(checked === true)
        {
          setCheckedQues11Option4('checked');
        }else{
          setCheckedQues11Option4('');
        }
      }
    }

   
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/MasculFinalQuizResult',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])




  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="4/5" percentage="98" star="98" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between ">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h4 className="mb-2 mt-2 pn">
                  {" "}
                  <span>
                    <b>10.</b>{" "}
                  </span>{" "}
                  <b>
                    Many men like Babu (from the case story) feel entitled
                    about being violent towards their wives, children and other
                    subordinates because (select all that apply){" "}
                  </b>
                </h4>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle1"
                     
                      defaultValue="option1"
                      onClick={saveGenderBased}
                      checked={checkedQues10Option1}
                      data-quesId="10"
                    />
                    <label className="form-check-label" htmlFor="mycirle1">
                      they had to apologise for their mistakes to other men who
                      have more power over them which makes them feel less in
                      their manlihood
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle2"
                      defaultValue="option2"
                      onClick={saveGenderBased}
                      checked={checkedQues10Option2}
                      data-quesId="10"
                    />
                    <label className="form-check-label" htmlFor="mycirle2">
                      they treat them like their property
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle3"
                      defaultValue="option3"
                      onClick={saveGenderBased}
                      checked={checkedQues10Option3}
                      data-quesId="10"
                    />
                    <label className="form-check-label" htmlFor="mycirle3">
                      they feel powerless and have no other alternative
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle4"
                      defaultValue="option4"
                      onClick={saveGenderBased}
                      checked={checkedQues10Option4}
                      data-quesId="10"
                    />
                    <label className="form-check-label" htmlFor="mycirle4">
                      they have more power over them due to their gender and
                      relationship status{" "}
                    </label>
                  </div>
                </div>
                <h4 className="mb-2 mt-2 pn">
                  {" "}
                  <span>
                    <b>11.</b>{" "}
                  </span>{" "}
                  <b>
                    The pressure of masculinity which boys and men are largely
                    socialised into, leads to
                  </b>
                </h4>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle2"
                      id="mycirle5"
                      defaultValue="option1"
                      onClick={saveGenderBased}
                      checked={checkedQues11Option1}
                      data-quesId="11"
                    />
                    <label className="form-check-label" htmlFor="mycirle5">
                      Normalising violent behaviours by boys and men{" "}
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle2"
                      id="mycirle6"
                      defaultValue="option2"
                      onClick={saveGenderBased}
                      checked={checkedQues11Option2}
                      data-quesId="11"
                    />
                    <label className="form-check-label" htmlFor="mycirle6">
                      {" "}
                      Immense pressure and burden on men who do not fit the
                      society’s ideals of being ‘A Man
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle2"
                      id="mycirle7"
                      defaultValue="option3"
                      onClick={saveGenderBased}
                      checked={checkedQues11Option3}
                      data-quesId="11"
                    />
                    <label className="form-check-label" htmlFor="mycirle7">
                      Stigmatizes conversation around mental health for boys and
                      men
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle2"
                      id="mycirle8"
                      defaultValue="option4"
                      onClick={saveGenderBased}
                      checked={checkedQues11Option4}
                      data-quesId="11"
                    />
                    <label className="form-check-label" htmlFor="mycirle8">
                      Aggressive competition between boys and men
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-04/chapter-05-screen-16"/>                  
                
                {/* <NextBtn link="/module-04/chapter-05-screen-18" text="Go Next"/> */}
                <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content modal-bg-blue">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close close-set"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bi bi-x-lg" />
                </button>
                <p className="text-white text-center">
                  Kamla Bhasin - Wikipedia
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter05Screen17;
