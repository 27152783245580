import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter02Screen14 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2 hp">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/5" percentage="45" star="45" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4 mt-m-3">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2 mb-3">Additional Resources </h2>

								<a
									href="http://www.raewynconnell.net/p/masculinities_20.html"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p> Raewyn Connell (Masculinities)</p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2020/09/24/home-is-the-place-where-we-can-unlearn-toxic-masculinity-hindi/"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p> कैसे हमारे घर और परिवार हिंसक मर्दानगी को बढ़ावा देते हैं</p>
										</div>
									</div>
								</a>

							</div>
							<div
								className="col-lg-5 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="89%"
										draggable="false"
										src="/assets/img/md4/md4-shap-2-14.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-02-screen-13" />

									<NextBtn link="/module-04/chapter-03-screen-01" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen14;
