import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter02Screen02 = () => {
	const navigate = useNavigate();
	const [checkedQues1, setCheckedQues1] = useState('');
	const [checkedQues1_1, setCheckedQues1_1] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));




	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues1,
					"ans2": checkedQues1_1,
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				}



			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/ApproachChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-06/chapter-03-screen-04')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans, ans2) => {


		if (param === '1') {

			setCheckedQues1(ans)
			setCheckedQues1_1(ans2)
		}






	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/approachChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans, result.ans2)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues1 !== '' && checkedQues1_1 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="58" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20  mt-m-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Adolescent Empowerment</h3>

								<p className="mb-2 mt-1"> What are the 2 gender issues that you think adolescents in your community are facing.</p>
								<div className="mb-0 mt-2">
									<textarea className="form-control" rows="1" defaultValue={checkedQues1} onChange={(e) => setCheckedQues1(e.target.value)} placeholder=".."></textarea>
								</div>
								<div className="mt-3">
									<textarea className="form-control" rows="1" defaultValue={checkedQues1_1} onChange={(e) => setCheckedQues1_1(e.target.value)} placeholder=".."></textarea>
								</div>



								<p className="mb-2 mt-3"> To understand why and how Breakthrough works with adolescents let’s watch a film</p>

								<a href="https://www.youtube.com/watch?v=VCpfVFpQo3s&list=PLbyiiPziC3QruFIevDyTJdEVWtn5K7hoQ" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>
												Why Working With Teenagers Is A Game Changer
											</p>
										</div>
									</div>
								</a>

								<p className="mb-2 mt-3">Read more about our work on <a className="display-inline" href="https://inbreakthrough.org/focus-area/adolescent-empowerment/" target="_blank">Adolescent Empowerment - Breakthrough</a> </p>

								{/* (From 2nd page onwards)(Hindi translation to be kept here) */}


							</div>

							<div className="col-lg-3 mt-m-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">
								<center>
									<img
										width="65%"
										src="/assets/img/md6/md-60chap-1-3.png"
										alt=""
									/>
								</center>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-06/chapter-03-screen-02" />
								{/* <NextBtn link="/module-06/chapter-02-screen-03" text="Go Next"/> */}

								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
