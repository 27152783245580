import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import PrevBtn from "../../../common/PrevBtn";
import Header from '../../../header/Header';
import { useDrop } from "react-dnd";
// import Image from './Image';

import Chapter02Screen15_1 from './Chapter02Screen15_1';
import axios from 'axios';
import BaseURL from '../../../config/Baseurl';
import { useEffect } from 'react';
import NextBtn from '../../../common/NextBtn'
import { useDrag } from "react-dnd";

const Chapter02Screen15 = () => {
	const navigate = useNavigate();



	const [PictureList, setPictureList] = useState([
		{ "id": "1", "name": "Discriminated against, faces threat of violence due to identity" },
		{ "id": "2", "name": "Threatened, mocked, or asked to leave public spaces due to identity" },
		{ "id": "3", "name": "Not protected adequately by laws or society in an event this happens" },
		{ "id": "4", "name": "Threatened, mocked, or asked to leave public spaces" },
		{ "id": "5", "name": "Threatened, mocked, and uncomfortable " },

	]);




	const [heteroSexualWomanArray, setHeteroSexualWomanArray] = useState([]);
	const [gayArray, setGayArray] = useState([]);
	const [transwomanArray, setTranswomanArray] = useState([]);
	const [wearingShortArray, setWearingShortArray] = useState([]);
	const [coupleArray, setCoupleArray] = useState([]);

	const [strangerArray, setStrangerArray] = useState([]);

	// stranger

	const [{ isStrangerOver }, strangerdrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addStrangerArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addStrangerArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setStrangerArray((strangerArray) => [...strangerArray, picture_List[0]]);
	};

	const onItemRemoveStranger = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = strangerArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setStrangerArray(meArray =>
			strangerArray.filter(stranger => {
				return stranger.id !== id;
			}),
		);
	};


	// couple

	const [{ isCoupleOver }, coupledrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addCoupleArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addCoupleArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setCoupleArray((coupleArray) => [...coupleArray, picture_List[0]]);
	};

	const onItemRemoveCouple = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = coupleArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setCoupleArray(coupleArray =>
			coupleArray.filter(coupleArray => {
				return coupleArray.id !== id;
			}),
		);
	};


	// wearingshort

	const [{ isWearingShortOver }, wearingShortdrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addWearingShortArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addWearingShortArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setWearingShortArray((wearingShortArray) => [...wearingShortArray, picture_List[0]]);
	};

	const onItemRemoveWearingShort = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = wearingShortArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setWearingShortArray(wearingShortArray =>
			wearingShortArray.filter(wearingShortArray => {
				return wearingShortArray.id !== id;
			}),
		);
	};


	// for HeterosexualWoman
	const [{ isHeteroSexualWomanOver }, heteroSexualWomandrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addHeteroSexualWomanArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addHeteroSexualWomanArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setHeteroSexualWomanArray((heteroSexualWomanArray) => [...heteroSexualWomanArray, picture_List[0]]);
	};

	const onItemRemoveHeteroSexualWoman = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = heteroSexualWomanArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setHeteroSexualWomanArray(heteroSexualWomanArray =>
			heteroSexualWomanArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};

	//  for gay


	const [{ isGayOver }, gaydrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addGayArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addGayArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setGayArray((gayArray) => [...gayArray, picture_List[0]]);
	};

	const onItemRemoveGay = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = gayArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setGayArray(gayArray =>
			gayArray.filter(gayArray => {
				return gayArray.id !== id;
			}),
		);
	};

	//  for friends


	const [{ isTransWomanOver }, transwomandrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addTranswomanArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addTranswomanArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setTranswomanArray((transwomanArray) => [...transwomanArray, picture_List[0]]);
	};

	const onItemRemoveTranswoman = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = transwomanArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setTranswomanArray(transwomanArray =>
			transwomanArray.filter(transwomanArray => {
				return transwomanArray.id !== id;
			}),
		);
	};



	const user_details = JSON.parse(localStorage.getItem('user_details'));




	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques4": {
					"ans": JSON.stringify(heteroSexualWomanArray),
					"ans2": JSON.stringify(gayArray),
					"ans3": JSON.stringify(transwomanArray),
					"ans4": JSON.stringify(wearingShortArray),
					"ans5": JSON.stringify(coupleArray),
					"ques_id": "4"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-05/chapter-02-screen-17');
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,

			"ques_id": "4"
		});

		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				if (response.data.success === true) {

					console.log(response.data.data);
					const ans = JSON.parse(response.data.data.chapter.ans)
					const ans2 = JSON.parse(response.data.data.chapter.ans2)
					const ans3 = JSON.parse(response.data.data.chapter.ans3)

					const ans4 = JSON.parse(response.data.data.chapter.ans4)
					const ans5 = JSON.parse(response.data.data.chapter.ans5)
					// console.log(ans3);
					setHeteroSexualWomanArray(ans);
					setGayArray(ans2);
					setTranswomanArray(ans3);
					setWearingShortArray(ans4);
					setCoupleArray(ans5);


					if (ans.length > 0) {
						ans.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans2.length > 0) {
						ans2.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}


				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);


	const BtnDisabled = () => {
		if (heteroSexualWomanArray.length !== 0 && gayArray.length !== 0 && transwomanArray.length !== 0 && wearingShortArray.length !== 0 && coupleArray.length !== 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};






	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3 mt-3">Engage: </h3>
								<p className="mt-1">Consider these situations and the statements. Match the situations with the statements for which of these could this happen.? You can choose multiple situations for each statement. </p>
								<p className="mt-2 mb-3">We have done one for you as an example  </p>

								<table className="table table-bordered table-dark table-red hand-cursor">

									<tbody>
										<tr>
											<td>ID</td>
											<td>Statements</td>
											<td>Situtation</td>
										</tr>
										<tr>
											<td>1</td>
											<td> A cisgender heterosexual man and his wife dine at a restaurant together. They are physically affectionate with each other. </td>
											<td>
												<ul>
													<li>Threatened, mocked, or asked to leave public space</li>
													<li> Not protected adequately by laws or society in an event this happens </li>
												</ul>

											</td>
										</tr>

										<tr>
											<td>2</td>
											<td>A cisgender heterosexual woman is walking on the street by herself at midnight</td>
											<td ref={heteroSexualWomandrop}>

												<ul>

													{heteroSexualWomanArray.map((e) => {

														return (
															<>

																<li>
																	<button
																		onClick={onItemRemoveHeteroSexualWoman}
																		data-id={e.id}
																		type="button"
																		className="btn-close"
																		aria-label="Close"
																	/>  {e.name}
																</li>



															</>
														)


													})}


												</ul>

											</td>
										</tr>

										<tr>
											<td>3</td>
											<td>A gay person goes to the cinema with their male partner. They are holding hands.</td>
											<td ref={gaydrop}>

												<ul>

													{gayArray.map((e) => {

														return (
															<>

																<li>
																	<button
																		onClick={onItemRemoveGay}
																		data-id={e.id}
																		type="button"
																		className="btn-close"
																		aria-label="Close"
																	/>  {e.name}
																</li>



															</>
														)


													})}


												</ul>

											</td>
										</tr>

										<tr>
											<td>4</td>
											<td>A transwoman uses the ladies bathroom in a mall. </td>
											<td ref={transwomandrop}>

												<ul>

													{transwomanArray.map((e) => {

														return (
															<>

																<li>
																	<button
																		onClick={onItemRemoveTranswoman}
																		data-id={e.id}
																		type="button"
																		className="btn-close"
																		aria-label="Close"
																	/>  {e.name}
																</li>



															</>
														)


													})}


												</ul>

											</td>
										</tr>

										<tr>
											<td>5</td>
											<td>A woman wearing short skirt and crop top visits a temple to offer her prayers </td>
											<td ref={wearingShortdrop}>

												<ul>

													{wearingShortArray.map((e) => {

														return (
															<>

																<li>
																	<button
																		onClick={onItemRemoveWearingShort}
																		data-id={e.id}
																		type="button"
																		className="btn-close"
																		aria-label="Close"
																	/>  {e.name}
																</li>



															</>
														)


													})}


												</ul>

											</td>
										</tr>

										<tr>
											<td>6</td>
											<td>A heterosexual couple who has been married for 10 years and has chosen to be child free are invited to a god-bharai in their extended family. </td>
											<td ref={coupledrop}>

												<ul>

													{coupleArray.map((e) => {

														return (
															<>

																<li>
																	<button
																		onClick={onItemRemoveCouple}
																		data-id={e.id}
																		type="button"
																		className="btn-close"
																		aria-label="Close"
																	/>  {e.name}
																</li>



															</>
														)


													})}


												</ul>

											</td>
										</tr>




									</tbody>
								</table>

								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<ul className="list-style-set list-cursor list-circle mt-3 mb-3">

										<p className="mb-2  mt-3"><b>Statements:</b></p>
										{
											PictureList.map((e, index) => {
												return (
													<>
														<Chapter02Screen15_1 key={e.id} id={e.id} imageName={e.name} />
													</>
												)
											})
										}
									</ul>
								</div>

							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="60%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-15.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-14" />
								{/* <NextBtn link="/module-05/chapter-02-screen-16" text="Go Next"/> */}

								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen15;
