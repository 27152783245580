import React, { useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Chapter05Screen10 = () => {
	const navigate = useNavigate();
	const [ans, setAns] = useState('');
	const [ans2, setAns2] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));


	const saveAns = () => {

		var FormData = require('form-data');
		// var fs = require('fs');
		var data = new FormData();
		data.append('user_id', user_details.user_id);
		data.append('answers[ques4][ans]', ans);
		data.append('answers[ques4][ans2]', ans2);
		data.append('answers[ques4][ques_id]', '4');



		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter05',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				// ...data.getHeaders()
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-04/chapter-05-screen-11')
				}
				console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter05Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans, result.ans2)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	const checkedVal = (param, ans, ans2) => {

		if (param === '4') {
			setAns(ans)
			setAns2(ans2)


		}


	}





	const BtnDisabled = () => {
		if (ans !== '' && ans2 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<>
				<div className="wrapper bg-bd-2">
					<div className="fixed-header">
						<div className="container-fluid cf-set">
							<Header leftChapter="4/5" percentage="80" star="80" />
						</div>
					</div>
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between mt-4">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h4 className=" mb-1">
										<b>Another example </b>
									</h4>
									<a href="https://www.youtube.com/watch?v=NpdAk5demtQ" target="_blank">
										<div className="d-flex align-items-center mange-icon">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>
													{" "}
													Masculinities in India <br />
													<b>Prashant</b>{" "}
												</p>
											</div>
										</div>
									</a>
									<h4 className="mt-2 mb-3">
										<b>
											What are 2 steps you will take to inspire inclusive and
											gender equitable masculinity
										</b>
									</h4>
									<div className="row">
										<div className="col-lg-9">
											<div className=" positon-submit">
												<textarea
													className="form-control"
													rows={1}
													placeholder="Write Something here....."
													defaultValue={ans}
													onChange={(e) => setAns(e.target.value)}
												/>
											</div>
											<div className=" positon-submit mt-4">
												<textarea
													className="form-control"
													rows={1}
													placeholder="Write Something here....."
													defaultValue={ans2}
													onChange={(e) => setAns2(e.target.value)}
												/>
											</div>
										</div>
									</div>
									<p className="mt-4">
										Please note that transformation is not easy, it is a process
										and one may face many challenges and hurdles. There can also
										be backlash, therefore, it is important to have a support
										system in place and know of individuals, institutions and
										organisations which can be reached out for support.
									</p>
								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="100%"
											draggable="false"
											src="/assets/img/md4/md4-shap-5-10.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-05-screen-08" />

									{/* <NextBtn link="/module-04/chapter-05-screen-11" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
};

export default Chapter05Screen10;
