import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Module04IntroScreen = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="0" star="0" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-4">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h4>
									{" "}
									<b>Understanding Breakthrough’s Approach</b>
								</h4>
								<div className="para-color-box mt-4">
									<p>
										<b>
											Objectives of the module:
										</b>
									</p>
									<ul className="list-style-set list-circle mt-1">
										<li>To learn about Breakthrough’s journey, important milestones  </li>
										<li>To understand Breakthrough’s vision, mission and theory of change, and how it translates in the work  </li>
										<li>To understand who we work with, why and how we work with these stakeholders </li>
										<li>To learn about the impact of Breakthrough’s work</li>
									</ul>
								</div>
							</div>
							<div
								className="col-lg-3 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="60%"
										draggable="false"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.3s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/score" />

									<NextBtn link="/module-06/chapter-01-screen-01" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Module04IntroScreen;
