import React from "react";
import { useDrag } from "react-dnd";
import { NavLink } from 'react-router-dom';

function Chapter04Screen13_1({ id, url,imageName }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "image",
    item: { id: id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
   <>
        <li
        ref={drag}
           draggable="false"
         
            id={id}
          
            // style={{ border: isDragging ? "5px solid pink" : "0px" }}
            
        >{imageName}</li>
   </>
  );
}

export default Chapter04Screen13_1;
