import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter01Screen05 = () => {

	const navigate = useNavigate();
	const [checkedQues2Yes, setCheckedQues2Yes] = useState('');
	const [checkedQues2No, setCheckedQues2No] = useState('');
	const [checkedQues2Val, setCheckedQues2Val] = useState('');
	const [checkedQues3Yes, setCheckedQues3Yes] = useState('');
	const [checkedQues3No, setCheckedQues3No] = useState('');
	const [checkedQues3Val, setCheckedQues3Val] = useState('');

	const [checkedQues4Yes, setCheckedQues4Yes] = useState('');
	const [checkedQues4No, setCheckedQues4No] = useState('');
	const [checkedQues4Val, setCheckedQues4Val] = useState('');




	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showModalText, setShowModalText] = useState();
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		if (quesId === '2') {
			setShowModalText('A person’s sexuality develops throughout their life, from childhood and teen years into adulthood. Even before one is born people start asking and/ or assigning gender and/ or sexual identities. You can watch the film again in case you want')
		}


		if (quesId === '3') {
			setShowModalText('Sexuality is a broad term covering multiple aspects of our lives as you would have seen in the WHO’s draft definition.')
		}

		if (quesId === '4') {
			setShowModalText('Yes, this is true! We will discuss these influences in more detail later in the chapter')
		}


		setShow(true);
		checkedVal(quesId, e.target.value)

	}


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": checkedQues2Val,
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3Val,
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4Val,
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-01-screen-06')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans) => {
		if (param === '2') {

			if (ans === 'yes') {
				setCheckedQues2Yes('checked')
				setCheckedQues2No('')
			} else {
				if (ans === 'no') {
					setCheckedQues2No('checked')
					setCheckedQues2Yes('')
				}
			}
			setCheckedQues2Val(ans)
		}
		if (param === '3') {
			if (ans === 'yes') {
				setCheckedQues3Yes('checked')
				setCheckedQues3No('')
			} else {
				if (ans === 'no') {
					setCheckedQues3No('checked')
					setCheckedQues3Yes(''
					)
				}
			}
			setCheckedQues3Val(ans)
		}




		if (param === '4') {
			if (ans === 'yes') {
				setCheckedQues4Yes('checked')
				setCheckedQues4No('')

			} else {
				if (ans === 'no') {
					setCheckedQues4No('checked')
					setCheckedQues4Yes('')
				}
			}
			setCheckedQues4Val(ans)
		}







	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues2Val !== '' && checkedQues3Val !== '' && checkedQues4Val !== ''
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="0" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								{/* <h3 className="h3">Learn:  </h3> */}
								<p className="mb-2  mt-3"> <b> Let us look at this video to understand this better. </b> </p>


								<a href="https://www.youtube.com/watch?v=ztVAqPmYlU0 ">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p> <b>What is sexuality: </b> </p>
										</div>
									</div>
								</a>

								<p className="mb-2  mt-3"> From what you have seen, are these statements true or false?  </p>



								<p className="mb-2 mt-3">A.  A person’s sexuality starts before they are born</p>
								<div className="d-flex mt-3">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="radio"
											name="namecircle"
											id="mycirle2"
											onClick={saveRadio}
											defaultValue="yes"
											data-quesId="2"
											checked={checkedQues2Yes}

										/>
										<label className="form-check-label" htmlFor="mycirle2">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="radio"
											name="namecircle"
											id="mycirle3"
											onClick={saveRadio}
											defaultValue="no"
											data-quesId="2"
											checked={checkedQues2No}
										/>
										<label className="form-check-label" htmlFor="mycirle3">No</label>
									</div>
								</div>



								<p className="mb-2 mt-3">B. Sexuality is linked to sexual activity and behaviour only.</p>
								<div className="d-flex mt-3">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle2"
											id="mycirle2"
											onClick={saveRadio}
											defaultValue="yes"
											data-quesId="3"
											checked={checkedQues3Yes}

										/>
										<label className="form-check-label" htmlFor="mycirle2">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle2"
											id="mycirle3"
											onClick={saveRadio}
											defaultValue="no"
											data-quesId="3"
											checked={checkedQues3No}
										/>
										<label className="form-check-label" htmlFor="mycirle3">No</label>
									</div>
								</div>



								<p className="mb-2 mt-3">C. Our sexual attitudes and behaviour are influenced by many things in our lives.</p>
								<div className="d-flex mt-3">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="radio" name="namecircle3"
											id="mycirle2"
											onClick={saveRadio}
											defaultValue="yes"
											data-quesId="4"
											checked={checkedQues4Yes}
										/>
										<label className="form-check-label" htmlFor="mycirle2">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3"
											id="mycirle3"
											onClick={saveRadio}
											defaultValue="no"
											data-quesId="4"
											checked={checkedQues4No}
										/>
										<label className="form-check-label" htmlFor="mycirle3">No</label>
									</div>
								</div>






							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="90%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-1-5.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-01-screen-04" />
								{/* <NextBtn link="/module-05/chapter-01-screen-06" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Modal */}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					{/* <Modal.Title>Women are physically weak :</Modal.Title> */}
				</Modal.Header>

				<Modal.Body>
					<p>{showModalText}</p>
				</Modal.Body>
			</Modal>

		</>
	);
};

export default Chapter01Screen05;
