import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";


const Chapter01Screen02 = () => {

	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="1" star="1" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-3">
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2 mb-1">Learn</h2>
								<p className="mb-2 mt-3">
									Masculinity is a social construct that has been defining who,
									what and how a man should be. It is typically a set of
									attributes, behaviours, and roles associated with boys and
									men. This is part of the social conditioning linked to the
									gender/sex you are assigned at birth. For example: Boys are
									taught to not cry, be strong, protect their sisters, play
									sports, build their muscles etc. All of these are considered
									part of building their masculinity which is more acceptable in
									the society. Similarly, femininity for girls and women defines
									who, what and how they should be. For example: Girls should
									not talk loudly or be outspoken, they should be delicate and
									lean, not have body hair etc.
								</p>
							</div>
							<div
								className="col-lg-5 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="95%"
										draggable="false"
										src="/assets/img/md4/md4-shap-1-2.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-01-screen-02-First" />
									<NextBtn link="/module-04/chapter-01-screen-04" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen02;
