import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter04Screen06 = () => {
	const navigate = useNavigate();
	const [checkedQues7_1, setCheckedQues7_1] = useState('');
	const [checkedQues7_2, setCheckedQues7_2] = useState('');
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",

					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": checkedQues7_1,
					"ans2": checkedQues7_2,
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-04/chapter-04-screen-07')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const checkedVal = (param, ans, ans2) => {
		if (param === '7') {
			setCheckedQues7_1(ans)
			setCheckedQues7_2(ans2)
		}
	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {

						checkedVal(result.ques_id.toString(), result.ans, result.ans2)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	const BtnDisabled = () => {
		if (checkedQues7_1 !== '' && checkedQues7_2 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper page-auto bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="65" star="65" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 ">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3 mb-1">
									{" "}
									<b>Share about how hegemonic/patriarchal masculinity behaviours have impacted your life. Share any 2 examples in the space below</b>{" "}
								</h3>
							</div>
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="mt-3 positon-submit">
									<textarea
										className="form-control"
										rows={1}
										placeholder="Write Something here....."
										defaultValue={checkedQues7_1}
										onChange={(e) => setCheckedQues7_1(e.target.value)}
									/>
								</div>
								<div className="mt-4 positon-submit">
									<textarea
										className="form-control"
										rows={1}
										placeholder="Write Something here....."
										defaultValue={checkedQues7_2}
										onChange={(e) => setCheckedQues7_2(e.target.value)}
									/>
								</div>

								<p className="mt-2">Babu works with a call center in Lucknow. One evening just when he is about to leave for home, his team leader calls him and asks him to immediately rework on a report that he had sent earlier in the day. Babu gets very upset that his team leader asked him for this work when he is about to leave for home. This was happening for the third time this week. After work he decided to go and meet his friends at a bar and informs at home that he will have dinner out. He gets drunk and is driving his bike to get back home. On his way home he meets a cop. The cop asks Babu for his license and pay fine of Rs.2000 for drunk driving. He apologises, assures the cop he won't do it again. After paying a bribe of Rs. 250 the cop lets him go. Once Babu reaches home his elder brother opens door and scolds him for being drunk and late. Babu again apologises and assures his brother that this won't happen ever again. He goes to check the fridge for food and finds there is nothing. He goes to his wife-Roma, to ask for food who is upseet that Babu has come home so late she is also angry that he is drunk. When Roma questions him, Babu shuts back at her and tells her that it's none of her business. He also tells her that her only job is to give him food when he requires it. Roma says since she wasn't sure if he would be home for dinner, there was no food kept aside for him. Babu gets angry and pulls her up by her arms and starts to use abusive language. Roma cries out. On hearing Roma cry, their 5 year old son comes crying and asks father to stop. In that fit of anger Babu hits his son too. </p>





							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="84%"
										draggable="false"
										src="/assets/img/md4/md4-shap-4-6.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">

									<PrevBtn link="/module-04/chapter-04-screen-05" />

									{/* <NextBtn link="/module-04/chapter-04-screen-07" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen06;
