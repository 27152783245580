import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen20 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3 mt-3">Learn:  </h3>
								<p className="mb-2  mt-3">Understanding our values, communicating effectively, setting boundaries – are all parts of exercising our rights and expressing our sexuality. In this context,<b> consent i.e. permission or voluntary agreement for something to happen/ to do something</b> becomes most important in sexual relationships</p>
								<p className="mb-2  mt-3">Any kind of coercive behaviour, like pestering, intimidating, manipulating, controlling means lack of consent. If communication is confusing or unclear, it is not consent. If there is any uncertainty that someone is agreeing to do something, it is not consent. Consent has to be direct, it cannot be assumed. It can be withdrawn at any point from any partner.  </p>
								<p className="mb-2  mt-3">Do the consent quiz to learn more!  </p>

							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="55%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-3-20.png"
										alt=""
									/>
								</center>
							</div>




						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-18" />
								<NextBtn link="/module-05/chapter-03-screen-21" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>


		</>
	);
};

export default Chapter03Screen20;
