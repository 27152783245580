import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter01Screen10 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/2" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4 mt-m-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>



								<h3 className="h3 ">Engage </h3>
								<a href="https://www.youtube.com/watch?v=ztVAqPmYlU0" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p> Recall the sexuality definition and the video that we watched  <br /><b>What is sexuality: </b> </p>
										</div>
									</div>
								</a>


								<p className="mb-2  mt-3"> <b>Dinesh, Mansi and Fahad are classmates in college. They are friends with each other on social media and regularly see each other’s posts. </b> </p>

								<ul class="list-style-set list-circle mt-1">
									<li>	Dinesh has been trying to get the perfect picture for hours. He is struggling and feels unattractive. After deleting many pictures, he finally takes one he is happy with and posts it online! He feels nervous about the reactions of his friends and keeps checking his phone. </li>
									<li>	Fahad looks at Dinesh’s photo. He doesn’t like or comment. He thinks “Dinesh always looks better than me, he is just showing off. I wish I also looked better. I wish I had more confidence to post my own photos online.” </li>
									<li>	Mansi is Dinesh’s friend. She thinks it would be funny to make a joke about Dinesh’s photo. Soon other friends join in. Dinesh feels hurt and thinks he won’t post photos of himself anymore. He deletes the photo.</li>
								</ul>

							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="90%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-17.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>







				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-01-screen-09" />
								<NextBtn link="/module-05/chapter-01-screen-11" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen10;
