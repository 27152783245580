import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter02Screen08 = () => {
  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="1/5" percentage="33" star="33" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between pt-20 mt-5 mt-m-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                
                <div className="d-flex ">
                <label className="form-check-label" htmlFor="mycirle">
                
                  Nandan is a migrant, dalit construction worker who works long
                  hours to earn for his family. He admires the dashing Bollywood
                  heroes, but his world is far away from theirs. He is
                  frequently abused by his supervisors and is always worried
                  about losing his job. His wife is his only friend. &nbsp;
                  <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="a"
                      id="mycirle"
                      data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                    />
                    </label>

                 
                </div>

               
                <div className="d-flex ">
                <label className="form-check-label" htmlFor="mycirle">
                
                Kunal is a General Manager of Sales at an MNC. He drives his
                  team hard to reach their targets. When anyone is behind
                  target, he shouts and threatens them with their job. He
                  rewards his team with sex parties and booze weekends. There
                  are no women in his team; as he sees it, women can’t work in
                  this tough and aggressive environment.  &nbsp;
                  <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="a"
                      id="mycirle"
                      data-bs-toggle="modal" data-bs-target="#staticBackdropA"
                      
                      
                    />
                    </label>

                 
                </div>

                <div className="d-flex ">
                <label className="form-check-label" htmlFor="mycirle">
                
                Aadhar is a 14 year old middle class boy. He has a happy life
                  going to school, playing football and eating good food. He is
                  thankful he wasn't born a girl like his sister who has to help
                  her mother and get harassed by boys.  &nbsp;
                  <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="a"
                      id="mycirle"
                      data-bs-toggle="modal" data-bs-target="#staticBackdropB"
                      
                    />
                    </label>

                 
                </div>


                <div className="d-flex ">
                <label className="form-check-label" htmlFor="mycirle">
                
                Nadeem is a doctor. He was deeply influenced by his female
                  friends in college.Listening to them, he became conscious of
                  injustices women faced. He started changing his behaviour – he
                  stopped interrupting women when they spoke, he listened to
                  women and men respectfully. He gently challenged his male
                  colleagues when they treated women unfairly.  &nbsp;
                  <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="a"
                      id="mycirle"
                      data-bs-toggle="modal" data-bs-target="#staticBackdropE"
                    />
                    </label>

                 
                </div>

               
               
              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-04/chapter-02-screen-07"/>                  
                
                <NextBtn link="/module-04/chapter-02-screen-09" text="Go Next"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

{/* group d */}
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <h2 className="h2 text-white">Group D</h2>



              <ul class="list-style-set list-circle text-white">
                <li>Men who exhibit qualities that are opposite to those valued in Group A such as physical weakness and expressing emotions etc.</li>
                <li>Dominated and bullied by other men for being weak and different.</li>
              </ul>

            


              
          

            </div>
          </div>
        </div>
      </div>

      {/* group A */}

      <div className="modal fade" id="staticBackdropA" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <h2 className="h2 text-white">Group A</h2>
              <ul class="list-style-set list-circle text-white">
              <li>Enjoys power over women and other subordinate men physically,psychologically and financially</li>
              <li>Believes in being physically strong and aggressive</li>
              <li>Encourages competition, distrust and insecurity</li>
              <li>Sees kindness, compassion and expressing emotion as weakness</li>
              <li>Believes in gender roles- eg: women should do household work and caregiving and men should be breadwinners</li>
              <li>Believes that a household’s honour is in keeping their girls and women safe and protected</li>
              </ul>

            


              
          

            </div>
          </div>
        </div>
      </div>

        {/* group B */}

        <div className="modal fade" id="staticBackdropB" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <h2 className="h2 text-white">Group B</h2>


              <ul class="list-style-set text-white list-circle mt-3">
              <li>Is not overtly abusive or violent</li>
              <li>Accepts things as they are, and goes along with it</li>
              <li>Does not question or challenge if men have more power</li>
              <li>Usually comes from a privileged category belongs to upper caste,upper or middle class, majority religion, is cisgender, heterosexual and able bodied</li>
              <li>Comes across as kind and understanding</li>
              <li>Enjoys the benefits from the culture of Patriarchy and does not want to let go of the privileges Eg. Division of labour between genders </li>
              </ul>

            


              
          

            </div>
          </div>
        </div>
      </div>

        {/* group E */}

        <div className="modal fade" id="staticBackdropE" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <h2 className="h2 text-white ">Group E</h2>
              <ul class="list-style-set list-circle mt-3 text-white">
              <li>Sensitive to gender injustice and male socialization</li>
              <li>Conscious of how all genders are dehumanized by Patriarchy</li>
              <li>Does not accept the status quo</li>
              <li>Questions and challenges the gender norms at individual, family and collective levels. Eg: Shares labour and decision-making with women</li>
              <li>Counters male socialization Eg. Listens to women attentively</li>
              <li>Envisions and strives for an alternate and just world</li>
              <li>Speaks up and/or refrains from using violence </li>
              </ul>
            


              
          

            </div>
          </div>
        </div>
      </div>



    </>
  );
};

export default Chapter02Screen08;
