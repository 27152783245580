import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter02Screen13 = () => {
  const navigate = useNavigate();
  const [checkedQues4, setCheckedQues4] = useState('');
  const [checkedQues5, setCheckedQues5] = useState('');
  const [checkedQues6, setCheckedQues6] = useState('');
  const [checkedQues7, setCheckedQues7] = useState('');
  const [checkedQues8, setCheckedQues8] = useState('');
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",         
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",        
          "ques_id": "3"
        },
        "ques4": {
          "ans": checkedQues4,
          "ques_id": "4"
          },
          "ques5": {
          "ans": checkedQues5,
          "ques_id": "5"
          },
          "ques6": {
          "ans": checkedQues6,
          "ques_id": "6"
          },
          "ques7": {
          "ans": checkedQues7,
          "ques_id": "7"
          },
          "ques8": {
          "ans": checkedQues8,
          "ques_id": "8"
          }
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/MasculChapter02',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-04/chapter-02-screen-14')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const checkedVal = (param,ans) => {
    if (param === '4') {
      setCheckedQues4(ans)
    }

    if (param === '5') {
      setCheckedQues5(ans)
    }

    if (param === '6') {
      setCheckedQues6(ans)
    }

    if (param === '7') {
      setCheckedQues7(ans)
    }
    if (param === '8') {
      setCheckedQues8(ans)
    }

  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/MasculChapter02Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  const BtnDisabled = () => {
    if (checkedQues4 !== '' && checkedQues5 !== '' && checkedQues6!== '' && checkedQues7 !== '' && checkedQues8 !== '' ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="1/5" percentage="42" star="42" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between ">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
              
                <p> Think about the behaviours and situations where men in your own life displayed the above mentioned behaviors of  different Masculinities. Share the situation below:{" "}
                </p>
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="row align-items-center mt-2">
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        <p className="pn">
                          {" "}
                          <span>1.</span> Hegemonic-
                        </p>{" "}
                      </label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control c"
                        placeholder=""
                        defaultValue={checkedQues4}
                        onChange={(e)=>setCheckedQues4(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mt-2">
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        <p className="pn">
                          {" "}
                          <span>2.</span> Complicit-{" "}
                        </p>{" "}
                      </label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        defaultValue={checkedQues5}
                        onChange={(e)=>setCheckedQues5(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mt-2">
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        <p className="pn">
                          {" "}
                          <span>3.</span> Marginalised-
                        </p>{" "}
                      </label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control "
                        placeholder=""
                        defaultValue={checkedQues6}
                        onChange={(e)=>setCheckedQues6(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mt-2">
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        <p className="pn">
                          {" "}
                          <span>4.</span> Subordinated-
                        </p>{" "}
                      </label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control "
                        placeholder=""
                        defaultValue={checkedQues7}
                        onChange={(e)=>setCheckedQues7(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row align-items-center mt-2">
                    <div className="col-lg-4">
                      <label className="col-form-label">
                        <p className="pn">
                          {" "}
                          <span>5.</span>Inclusive &amp; gender equitable-
                        </p>{" "}
                      </label>
                    </div>
                    <div className="col-lg-8">
                      <input
                        type="text"
                        className="form-control "
                        placeholder=""
                        defaultValue={checkedQues8}
                        onChange={(e)=>setCheckedQues8(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-04/chapter-02-screen-12"/>                  
                
                {/* <NextBtn link="/module-04/chapter-02-screen-14" text="Go Next"/> */}
                <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen13;
