import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen09 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3">Learn: </h3>
								<p className="mb-2  mt-3">This exercise gives us a little glimpse into our sexuality values. Every person has their own set of values and preferences. What is normal and acceptable, even desirable for one person, may not be for another. Our ideas of limits and boundaries result from our personal values.    </p>
								<p className="mb-2  mt-3">Recall chapter 1 where we studied self-image and self-esteem. Our sexual behaviour and preferences are also closely linked to how we feel about ourselves.   </p>
								<p className="mb-2  mt-3">Let’s do an activity to understand this better.   </p>




							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="80%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-3-9.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-06" />
								<NextBtn link="/module-05/chapter-03-screen-10" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen09;
