import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter04Screen10 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-0">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3">Reflect </h3>
								<p className="mb-2 mt-3"> Due to our cultural attitudes to sex, there are several myths about sexual assault that are believed. Things such as why it happens, who is to blame etc. are norms communicated to us as part of our socialization. Let’s look at some such statements, and see if they are a myth or a fact!  </p>




							</div>

							<div
								className="col-lg-2 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="80%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-15.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-09" />
								<NextBtn link="/module-05/chapter-04-screen-11" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>



		</>
	);
};

export default Chapter04Screen10;
