import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen09 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="22" star="22" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between mt-3">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2 mb-1">Additional Resources </h2>
							</div>
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<a href="https://personalvalu.es/" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Personal Values Assessment - Find your top 5 values</p>
										</div>
									</div>
								</a>

								<a href="https://www.youtube.com/watch?v=te_h08znkz4" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Decision Focus 04 - Clear Values #decisionmaking</p>
										</div>
									</div>
								</a>

								<a href="https://www.youtube.com/watch?v=ye7TVMl-_TQ" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>The Iceberg Model</p>
										</div>
									</div>
								</a>

								<a href="https://daretolead.brenebrown.com/wp-content/uploads/2019/02/Values.pdf" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-3 ">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Values</p>
										</div>
									</div>
								</a>

							</div>
							<div
								className="col-lg-4 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img width="54%" src="/assets/img/md2/md2-shap-2-9.png" alt="" />
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-02-screen-08" />

								<NextBtn link="/module-02/chapter-03-screen-01" text="Go Next" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen09;
