import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen31 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3 mt-3">Learn  </h3>

								<p className="mb-2  mt-3">There is no single definition of power, it takes different forms in different times. Broadly, power is the ability to do, act or exert influence and authority in a particular way we want. Unequal power dynamics between men and women affect women’s position in their sexual and intimate relationships. In any male-female equation, when men command power over women it can quickly slip into domination, control and violence.  </p>

							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="38%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-3-20.png"
										alt=""
									/>
								</center>
							</div>




						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-30" />
								<NextBtn link="/module-05/chapter-03-screen-32" text="Go Next" />

							</div>
						</div>
					</div>
				</div>
			</div>


		</>
	);
};

export default Chapter03Screen31;
