import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter04Screen11 = () => {

	const navigate = useNavigate();

	const [checkedQues1, setCheckedQues1] = useState([]);
	const [hinduChecked, setHinduChecked] = useState('');
	const [femaleChecked, setFemaleChecked] = useState('');
	const [athleteChecked, setAthleteChecked] = useState('');
	const [NameAksaChecked, setNameAksaChecked] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveGenderBased = (e) => {
		const res = e.target.value;
		const checked = e.target.checked;


		checkBoxChecked(res, checked);
		setCheckedQues1(checkedQues1 => [...checkedQues1, res]);
	}
	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues1.join(','),
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfFinalQuiz',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-02/chapter-04-screen-12')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues1.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	const checkedVal = (param, ans) => {


		if (param === '1') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked);
			})
			setCheckedQues1(ansArr)
		}


	}

	const checkBoxChecked = (val, checked) => {
		if (val === 'Hindu') {
			if (checked === true) {
				setHinduChecked('checked');

			} else {
				setHinduChecked('');
			}
		}

		if (val === 'Female') {
			if (checked === true) {
				setFemaleChecked('checked');
			} else {
				setFemaleChecked('');
			}
		}

		if (val === 'Athlete') {
			if (checked === true) {
				setAthleteChecked('checked');
			} else {
				setAthleteChecked('');
			}
		}

		if (val === 'Name-Aksa') {
			if (checked === true) {
				setNameAksaChecked('checked');
			} else {
				setNameAksaChecked('');
			}
		}



	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfFinalQuizResult',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="4/4" percentage="90" star="90" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 ">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h2 className="h2">Quiz for the Module</h2>
								<p className="mb-2 mt-3 pn">
									<span>
										<b>1.</b>
									</span>
									<b>
										{" "}
										Which of the following are usually the identities given to a
										person <br />
										when they are born select all that apply.
									</b>{" "}
								</p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle1"
											defaultValue="Hindu"
											onClick={saveGenderBased}
											checked={hinduChecked}
										/>
										<label className="form-check-label" htmlFor="mycirle1">
											Hindu{" "}
										</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle2"
											defaultValue="Female"
											onClick={saveGenderBased}
											checked={femaleChecked}
										/>
										<label className="form-check-label" htmlFor="mycirle2">
											Female{" "}
										</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle3"
											defaultValue="Athlete"
											onClick={saveGenderBased}
											checked={athleteChecked}
										/>
										<label className="form-check-label" htmlFor="mycirle3">
											Athlete
										</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle4"
											defaultValue="Name-Aksa"
											onClick={saveGenderBased}
											checked={NameAksaChecked}
										/>
										<label className="form-check-label" htmlFor="mycirle4">
											Name-Aksa{" "}
										</label>
									</div>
								</div>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="80%"
										draggable="false"
										src="/assets/img/md2/md2-shap-4-11.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-02/chapter-04-screen-10" />

									{/* <NextBtn
                    link="/module-02/chapter-04-screen-12"
                    text="Go Next"
                  /> */}

									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen11;
