import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter04Screen08 = () => {
  const navigate = useNavigate();

  const [checkedQues10, setCheckedQues10] = useState([]);
  const [checkedQues10Option1, setCheckedQues10Option1] = useState('');
  const [checkedQues10Option2, setCheckedQues10Option2] = useState('');
  const [checkedQues10Option3, setCheckedQues10Option3] = useState('');
  const [checkedQues10Option4, setCheckedQues10Option4] = useState('');
  const [checkedQues10Option5, setCheckedQues10Option5] = useState('');
  const [checkedQues10Val, setCheckedQues10Val] = useState('');

  const [checkedQues11Yes, setCheckedQues11Yes] = useState('');
  const [checkedQues11No, setCheckedQues11No] = useState('');
 
  const [checkedQues11Val, setCheckedQues11Val] = useState('');


  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
  
    checkedVal(quesId,e.target.value)
  }

  const saveGenderBased = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    const res = e.target.value;
    const checked = e.target.checked;
    // alert(quesId);
    if(quesId === '10')
    {
      checkBoxChecked(res,checked,quesId);
      setCheckedQues10(checkedQues10 => [...checkedQues10, res]);
    }

   

    
  }


  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans":  "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",         
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",         
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans":"",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": checkedQues10.join(','),
          "ques_id": "10"
        },
        "ques11": {
          "ans": checkedQues11Val,
          "ques_id": "11"
        }

      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/MasculChapter04',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-04/chapter-04-screen-09')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues10.length >0 && checkedQues11Val !== '') {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {

    if (param === '10') {
      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues10(ansArr)
    }

    // if (param === '10') {
    //   // alert(ans);
    //   if (ans === 'option1') {
    //     setCheckedQues10Option1('checked')
    //     setCheckedQues10Option2('');
    //     setCheckedQues10Option3('')
    //     setCheckedQues10Option4('')
    //     setCheckedQues10Option5('')
    //   } else if (ans === 'option2') {
    //     setCheckedQues10Option1('')
    //     setCheckedQues10Option2('checked');
    //     setCheckedQues10Option3('')
    //     setCheckedQues10Option4('')
    //     setCheckedQues10Option5('')
    //     }else if (ans === 'option3') {
    //       setCheckedQues10Option1('')
    //       setCheckedQues10Option2('');
    //       setCheckedQues10Option3('checked')
    //       setCheckedQues10Option4('')
    //       setCheckedQues10Option5('')
    //       }else if (ans === 'option4') {
    //         setCheckedQues10Option1('')
    //         setCheckedQues10Option2('');
    //         setCheckedQues10Option3('')
    //         setCheckedQues10Option4('checked')
    //         setCheckedQues10Option5('')
    //         }else if (ans === 'option5') {
    //           setCheckedQues10Option1('')
    //           setCheckedQues10Option2('');
    //           setCheckedQues10Option3('')
    //           setCheckedQues10Option4('')
    //           setCheckedQues10Option5('checked')
    //           }
    //     setCheckedQues10Val(ans)
    // }

    if (param === '11') {
      if (ans === 'yes') {
        setCheckedQues11Yes('checked')
        setCheckedQues11No('');
       
      } else if (ans === 'no') {
        setCheckedQues11Yes('')
        setCheckedQues11No('checked');
       
        }
        setCheckedQues11Val(ans)
    }
  }


  const checkBoxChecked = (val,checked,param)=>{

    if(param === '10')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues10Option1('checked');
        }else{
          setCheckedQues10Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues10Option2('checked');
        }else{
          setCheckedQues10Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues10Option3('checked');
        }else{
          setCheckedQues10Option3('');
        }
      }
      if(val === 'option4')
      {
        if(checked === true)
        {
          setCheckedQues10Option4('checked');
        }else{
          setCheckedQues10Option4('');
        }
      }
  
      if(val === 'option5')
      {
        if(checked === true)
        {
          setCheckedQues10Option5('checked');
        }else{
          setCheckedQues10Option5('');
        }
      }
    }

   
  

  }

 
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/MasculChapter04Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="3/5" percentage="65" star="65" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-4">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <p className="pn">
                  {" "}
                  <span>
                    <b>3.</b>
                  </span>{" "}
                  <b>
                    Why was Babu not violent towards his boss, the cop and his
                    brother?{" "}
                  </b>
                </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle1"
                      defaultValue="option1"
                      onClick={saveGenderBased}
                      data-quesId="10"
                      checked={checkedQues10Option1}
                    />
                    <label className="form-check-label" htmlFor="mycirle1">
                      Because he respects them
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle2"
                      defaultValue="option2"
                      onClick={saveGenderBased}
                      data-quesId="10"
                      checked={checkedQues10Option2}
                    />
                    <label className="form-check-label" htmlFor="mycirle2">
                      Because he realised his mistake and he was sorry
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle3"
                      defaultValue="option3"
                      onClick={saveGenderBased}
                      data-quesId="10"
                      checked={checkedQues10Option3}
                    />
                    <label className="form-check-label" htmlFor="mycirle3">
                      Because he is scared of them
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check ">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirle4"
                      defaultValue="option4"
                      onClick={saveGenderBased}
                      data-quesId="10"
                      checked={checkedQues10Option4}
                    />
                    <label
                      className="form-check-label over-content"
                      htmlFor="mycirle4"
                    >
                      Because they have more power over him- his team leader
                      power of position, his elder brother power of age in their
                      relationship, cop has power given by the state
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="checkbox"
                      name="namecircle"
                      id="mycirleextra"
                      defaultValue="option5"
                      onClick={saveRadio}
                      data-quesId="10"
                      checked={checkedQues10Option5}
                    />
                    <label className="form-check-label" htmlFor="mycirleextra">
                      Because he feared consequences
                    </label>
                  </div>
                </div>
                <p className="mt-4 pn">
                  {" "}
                  <span>
                    <b>4.</b>
                  </span>{" "}
                  <b>
                    {" "}
                    Do you think Babu had a valid reason for hitting his wife
                    and son?{" "}
                  </b>
                </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="namecircle2"
                      id="mycirle5"
                      defaultValue="yes"
                      onClick={saveRadio}
                      data-quesId="11"
                      checked={checkedQues11Yes}
                    />
                    <label className="form-check-label" htmlFor="mycirle5">
                      Yes
                    </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="namecircle2"
                      id="mycirle6"
                      defaultValue="no"
                      onClick={saveRadio}
                      data-quesId="11"
                      checked={checkedQues11No}
                    />
                    <label className="form-check-label" htmlFor="mycirle6">
                      No{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-04/chapter-04-screen-07"/>                  
                
                {/* <NextBtn link="/module-04/chapter-04-screen-09" text="Go Next"/> */}
                <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content modal-bg-blue">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close close-set"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bi bi-x-lg" />
                </button>
                <p className="text-white text-center">
                  Kamla Bhasin - Wikipedia
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter04Screen08;
