import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate, NavLink } from "react-router-dom";

const Chapter02Screen04 = () => {

	const navigate = useNavigate();
	const [checkedQues6, setCheckedQues6] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));




	const saveAns = () => {

		// alert();
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {

				"ques6": {
					"ans": checkedQues6,
					"ques_id": "6"
				},


			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-02-screen-05')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans) => {


		if (param === '6') {

			setCheckedQues6(ans)
		}




	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])




	const BtnDisabled = () => {
		if (checkedQues6 !== '') {
			return (
				<>


					<button className="button_su" >
						<span className="su_button_circle desplode-circle">
						</span>
						<button onClick={saveAns} className="button_su_inner" >
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20">




							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>


								<p className="mb-2  mt-3"> <b>Here are terms that we learnt from the video. This is list is not exclusive</b> </p>

								<ul class="list-style-set list-circle mt-1">
									<li> <b>Heterosexuality –</b>	I feel romantic and sexual attraction to people of the opposite sex and gender. </li>
									<li> <b>Homosexuality – </b>	I feel romantic and sexual attraction to members of my gender and sex.  </li>
									<li> <b>Lesbian –  </b>	I am a female, who feels emotional and sexual feelings towards other females.  </li>
									<li> <b>Gay –   </b> I am a male, who feels emotional and sexual feelings for other males.  </li>
									<li> <b>Bisexual –  </b>I feel sexual and romantic attraction to more than just one sex or gender.  </li>
									<li> <b>Pansexual – </b>I feel attraction to people of all genders and sex. Sometimes I also feel that gender does not play any role in sexual attraction in my experiences.  </li>
									<li> <b>Asexuality – </b>I feel no sexual attraction towards others, irrespective of their gender and sex. I have a low desire to engage in sexual and physical activities with other people.  </li>
								</ul>
								<p className="mb-2  mt-3"> Once again, let’s think of which of these 7 sexualities/sexual oreitnation would find their sexual orientation most easily accepted in their family or community?  </p>

								<div class="mt-4 positon-submit">
									<textarea class="form-control"
										rows="2"
										placeholder="Write Something here....."

										onChange={(e) => setCheckedQues6(e.target.value)}
										data-quesId="6"
										defaultValue={checkedQues6}
									></textarea>
								</div>

							</div>

							<div
								className="col-lg-4 mt-5 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/here.jpg"
										alt=""
									/>
								</center>
							</div>







						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-03" />
								{/* <NextBtn link="/module-05/chapter-02-screen-05" text="Go Next"/> */}
								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen04;
