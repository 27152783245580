import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";

import { useNavigate } from "react-router-dom";
const Chapter01Screen02 = () => {
	const navigate = useNavigate();
	// const [checkedQues2Yes, setCheckedQues2Yes] = useState('');
	// const [checkedQues2No, setCheckedQues2No] = useState('');
	// const [checkedQues2Val, setCheckedQues2Val] = useState('');
	const [checkedQues2, setCheckedQues2] = useState('');
	const [checkedQues7, setCheckedQues7] = useState('');
	const [checkedQues15, setCheckedQues15] = useState('');

	const [checkedQues3Yes, setCheckedQues3Yes] = useState('');
	const [checkedQues3No, setCheckedQues3No] = useState('');
	const [checkedQues3Val, setCheckedQues3Val] = useState('');
	const [checkedQues4, setCheckedQues4] = useState('');
	const [checkedQues5, setCheckedQues5] = useState('');
	const [checkedQues6Yes, setCheckedQues6Yes] = useState('');
	const [checkedQues6No, setCheckedQues6No] = useState('');
	const [checkedQues6Val, setCheckedQues6Val] = useState('');
	const user_details = JSON.parse(localStorage.getItem('user_details'));



	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		checkedVal(quesId, e.target.value)

	}


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": checkedQues2,

					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3Val,
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4,
					"ques_id": "4"
				},
				"ques5": {
					"ans": checkedQues5,
					"ques_id": "5"
				},
				"ques6": {
					"ans": checkedQues6Val,
					"ques_id": "6"
				},
				"ques15": {
					"ans": checkedQues15,
					"ques_id": "15"
				},
				"ques7": {
					"ans": checkedQues7,
					"ques_id": "7"
				}

			}

		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-01-screen-04')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans) => {

		if (param === '3') {
			if (ans === 'yes') {
				setCheckedQues3Yes('checked')
				setCheckedQues3No('')
			} else {
				if (ans === 'no') {
					setCheckedQues3No('checked')
					setCheckedQues3Yes(''
					)
				}
			}
			setCheckedQues3Val(ans)
		}


		if (param === '2') {

			setCheckedQues2(ans)
		}

		if (param === '7') {

			setCheckedQues7(ans)
		}


		if (param === '15') {

			setCheckedQues15(ans)
		}


		if (param === '4') {

			setCheckedQues4(ans)
		}

		if (param === '5') {

			setCheckedQues5(ans)
		}

		if (param === '6') {
			if (ans === 'yes') {
				setCheckedQues6Yes('checked')
				setCheckedQues6No('')

			} else {
				if (ans === 'no') {
					setCheckedQues6No('checked')
					setCheckedQues6Yes('')
				}
			}
			setCheckedQues6Val(ans)
		}


	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues2 !== '' && checkedQues3Val !== '' && checkedQues4 !== '' &&
			checkedQues5 !== '' && checkedQues6Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper page-auto">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="1" star="1" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-top justify-content-between pt-20 mt-0">

							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3 mt-3">Reflect</h3>
								<p className=""><b> There are no right or wrong answers here but we could do scoring for attempting each question</b></p>
								<p className="mb-0"> Why did you choose these objects for Samar?</p>

								<div className="d-flex">
									<input
										type="text"
										className="form-control"
										placeholder="Type your Answer......."
										onChange={(e) => setCheckedQues2(e.target.value)}
										data-quesId="2"
										value={checkedQues2}
									/>



								</div>

								<p className="mb-0"> Why did you choose these objects for Sahas?</p>

								<div className="d-flex">
									<input
										type="text"
										className="form-control"
										placeholder="Type your Answer......."
										onChange={(e) => setCheckedQues15(e.target.value)}
										data-quesId="15"
										value={checkedQues15}
									/>



								</div>





								<p className="mb-0">
									Did you think about their gender when choosing?
								</p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="ques2"
											id="inlineRadio1"
											defaultValue="yes"
											onClick={saveRadio}
											data-quesId="3"
											checked={checkedQues3Yes}
										/>
										<label className="form-check-label" htmlFor="inlineRadio1">
											Yes
										</label>
									</div>
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="ques2"
											id="inlineRadio2"
											defaultValue="no"
											onClick={saveRadio}
											data-quesId="3"
											checked={checkedQues3No}
										/>
										<label className="form-check-label " htmlFor="inlineRadio2">
											No
										</label>
									</div>
								</div>

								<div className="">

									<p className="mb-0"> Was it difficult or easy to know their gender ?</p>
									<input
										type="text"
										className="form-control"
										placeholder="Type your Answer......."
										onChange={(e) => setCheckedQues4(e.target.value)}
										data-quesId="4"
										value={checkedQues4}
									/>
								</div>
								<div className="">

									<p className="mb-0"> Why was it difficult or easy?</p>
									<input
										type="text"
										className="form-control"
										placeholder="Type your Answer......."
										onChange={(e) => setCheckedQues5(e.target.value)}
										data-quesId="5"
										value={checkedQues5}
									/>
								</div>

								<p className="mb-0">
									Did you think about what they may need or want for their trip
									based on their gender?
								</p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="ques6"
											defaultValue="yes"
											onClick={saveRadio}
											data-quesId="6"
											id="inlineRadio4"
											checked={checkedQues6Yes}
										/>
										<label className="form-check-label" htmlFor="inlineRadio4">
											Yes
										</label>
									</div>
									<div className="form-check form-check-inline">
										<input
											className="form-check-input custom-check"
											type="radio"
											name="ques6"
											defaultValue="no"
											onClick={saveRadio}
											data-quesId="6"
											id="inlineRadio3"
											checked={checkedQues6No}
										/>
										<label className="form-check-label " htmlFor="inlineRadio3">
											No
										</label>
									</div>

								</div>
								<p className="mb-0"> Why do you think that happened?Please share any other reflections in the space below
								</p>
								<div className="d-flex">
									<input
										type="text"
										className="form-control"
										placeholder="Type your Answer......."
										onChange={(e) => setCheckedQues7(e.target.value)}
										data-quesId="7"
										value={checkedQues7}
									/>
								</div>

							</div>
							<div
								className="col-lg-4 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="90%"
										className="mb-2"
										draggable="false"
										src="/assets/img/md1/md1-shap-2.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-01-screen-022" />
								{/* <NextBtn link="/module-01/chapter-01-screen-03" text="Go Next" classtype="" /> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Chapter01Screen02;
