import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter04Screen35 = () => {
	return (
		<>
			<div className="wrapper page-auto bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-0">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Additional Resources: </h3>

								<ul className="list-style-set list-circle hand-cursor mt-3 mb-3">

									<li data-bs-toggle="modal" data-bs-target="#staticBackdrop">	Note – A Sexual Bill of Rights </li>
									<li data-bs-toggle="modal" data-bs-target="#staticBackdrop2">	Exercise – Am I ready for sex? </li>
									<li data-bs-toggle="modal" data-bs-target="#staticBackdrop3">	Note – How well do we know condoms? </li>
									{/* <li data-bs-toggle="modal" data-bs-target="#staticBackdrop4">	  Safer sex  </li> */}

									<li>
										Safer sex
										<a
											href="http://agentsofishq.com/aoi-tutions-with-bitti-sex-pregnancy-and-abortion-mybodymychoice/"
											target="_blank"
											rel="noreferrer"
										>
											<div className="d-flex align-items-center mange-icon mt-2">
												<div>
													<img src="/assets/img/chapter1/list-icon.png" alt="" />
												</div>
												<div>
													<p>AOI Tutions with Bitti: Sex, Pregnancy and Abortion! #MyBodyMyChoice</p>
												</div>
											</div>
										</a>

										<a
											href="https://hindi.feminisminindia.com/2021/05/31/video-on-women-and-sexually-transmitted-diseases-hindi/"
											target="_blank"
											rel="noreferrer"
										>
											<div className="d-flex align-items-center mange-icon mt-2">
												<div>
													<img src="/assets/img/chapter1/list-icon.png" alt="" />
												</div>
												<div>
													<p>जानें, महिलाओं को होने वाले इन चार यौन संचारित रोगों के बारे में</p>
												</div>
											</div>
										</a>

										<a
											href="http://agentsofishq.com/hi/the-stages-of-consent/"
											target="_blank"
											rel="noreferrer"
										>
											<div className="d-flex align-items-center mange-icon mt-2">
												<div>
													<img src="/assets/img/chapter1/list-icon.png" alt="" />
												</div>
												<div>
													<p>सहमति के स्तर</p>
												</div>
											</div>
										</a>
									</li>

									<li>
										Women and sex
										<a
											href="http://agentsofishq.com/12-common-questions-about-stis/"
											target="_blank"
											rel="noreferrer"
										>
											<div className="d-flex align-items-center mange-icon mt-2">
												<div>
													<img src="/assets/img/chapter1/list-icon.png" alt="" />
												</div>
												<div>
													<p>12 Common Questions About Sexually Transmitted Infections - Dr. Suchitra Dalvie To Our Rescue! </p>
												</div>
											</div>
										</a>

										<a
											href="https://hindi.feminisminindia.com/2021/08/06/what-is-safe-sex-video-hindi/"
											target="_blank"
											rel="noreferrer"
										>
											<div className="d-flex align-items-center mange-icon mt-2">
												<div>
													<img src="/assets/img/chapter1/list-icon.png" alt="" />
												</div>
												<div>
													<p>जानें, क्या होता है ‘सेफ़ सेक्स?’</p>
												</div>
											</div>
										</a>
									</li>


								</ul>

							</div>

							<div
								className="col-lg-2 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="90%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-15.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-34" />
								<NextBtn link="/module-05/chapter-04-screen-44" text="Go Next" />



							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							{/* <p className="text-white text-center">We often associate sexuality only with our bodies and sexual relationships. In reality, sexuality is a broad concept that includes many aspects of our lives. Let’s get into this!</p> */}

							<h3 className="h3 text-white">Note – A Sexual Bill of Rights  </h3>
							<p className="text-white">Sexual rights are fundamental rights that cannot be taken away from us. </p>

							<ul className="list-style-set list-circle  text-white">
								<li>The right to <b>sexual freedom </b> and for us to explore our full sexual potential, without any form of sexual coercion, force, or abuse. </li>
								<li>The right to <b>sexual autonomy, integrity and safety of the body.</b> We make independent decisions on our bodies and sexual lives’ based on personal and social values. </li>
								<li>The right to sexual privacy and intimacy behaviour as long as it does not infringe upon another’s rights.</li>
								<li>The right to <b>sexual equity.</b>  Freedom from all forms of discrimination regardless of sex, gender, orientation, race, class, religion, disability, caste and other identities. </li>
								<li>The right to <b>sexual pleasure,</b> which is a source of individual’s well-being.  </li>
								<li>The right to emotional sexual expression, which involves sexuality, which is more than pleasure or sex. Individuals have a right to express sexuality through communication, touch, love and emotional expression.  </li>


								<li>The right to sexually associate freely. This means the possibility to marry or not, to divorce, and to form and be in other kinds of responsible sexual relationships. </li>
								<li>The right to make <b>free and responsible reproductive choices.</b> Whether we have children or not, the number and spacing and full access to birth control.  </li>
								<li>The right to <b> sexual information.</b> </li>
								<li>The right to <b> comprehensive sexuality education.</b> This is a lifelong process from birth and should involve the entire social ecosystem. </li>

								<li>The right to <b>sexual health care.</b>  Easy, accessible and equitable sexual health information and treatment of all sexual concerns, problems and disorders.  </li>



							</ul>

							<p className="text-white">It is important to constantly remind ourselves of our rights. We can do this through easy affirmations such as: I have a right to my own body! I have a right to say no! I have a right to experience sexual pleasure! I have a right to sexually assertive! I have the right to ask questions! I have the right to my sexual preference! Ultimately, I have a right to control my own sexuality!</p>
							<p className="text-white"><b>Condoms aren’t sexy and ruin the sexual experience -  </b>Ensuring that you feel safe and protected during sex is in fact a way to make sex more comfortable and therefore more fun for everyone (without the consequences we discussed above).  </p>
							<p className="text-white"><b>It is embarrassing to buy a condom –  </b>Many aspects of sexual activity have been judged and moralised in our society. It is important that we ensure we challenge these to make our sexual experience focused on safety and affirmative practices for ourselves and our partners.  </p>
							<p className="text-white"><b>Condoms are more a man’s thing:  </b>Condoms are for the protection of both men and women.  Condoms protect from pregnancy, sexually transmitted infections, and even cervical cancer.   </p>




						</div>
					</div>
				</div>
			</div>

			{/* 2 */}

			<div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							{/* <p className="text-white text-center">We often associate sexuality only with our bodies and sexual relationships. In reality, sexuality is a broad concept that includes many aspects of our lives. Let’s get into this!</p> */}

							<h3 className="h3 text-white">Exercise – Am I ready for sex? </h3>
							<p className="mb-2 mt-3 text-white">It is completely okay to engage in intimate physical relationships with whomever one likes/ desires. However, the decision should be taken when we feel completely ready. </p>
							<p className="mb-2 mt-3 text-white">These are some helpful questions to ask, if we have any moments of doubt, worry or discomfort.  </p>

							<ul className="list-style-set list-circle  mt-3 mb-3 text-white">
								<li>	Am I feeling pressured into sexual activity by my partner, my friends or TV/ movies/ media?  </li>
								<li>	Will sexual activity now fit with my personal beliefs and values?  </li>
								<li>	Will I feel guilty if I engage in sexual activity?  </li>
								<li>	Do I want sexual activity to prove that I am sexually attractive? </li>
								<li>	Am I afraid that my reputation will be hurt if I have/ do not have sex? </li>
								<li>	Do I want to engage in sex with my partner so that we become closer emotionally and physically? </li>

								<li>	Do we (partner and you) have the same expectations from the relationship and/ or sexual activity?  </li>
								<li>	Can I talk to my partner about sexually transmitted infections and how we can protect ourselves?  </li>
								<li>	If applicable: Can I talk to my partner about birth control and can we share the responsibility for birth control?  </li>






							</ul>

							<p className="mb-2 mt-3 text-white">You can add your own questions to this list! </p>

						</div>
					</div>
				</div>
			</div>

			{/* 3 */}

			<div className="modal fade" id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							{/* <p className="text-white text-center">We often associate sexuality only with our bodies and sexual relationships. In reality, sexuality is a broad concept that includes many aspects of our lives. Let’s get into this!</p> */}

							<h3 className="h3 text-white">Note: How well do we know condoms?</h3>
							<p className="mb-2 mt-3 text-white">Condoms are the most widely available, accessible and affordable means of barrier contraception available to most of us. In this note, we learn a little of how they work and the “myths” of condom use! </p>
							<p className="mb-2 mt-3 text-white">Condoms act as a physical barrier during sexual activity – they prevent the male sperm from entering the female reproductive tract. It also acts as a barrier for infections and hence, prevents transmission of STIs and HIV between partners if used correctly and consistently.  </p>

							<h3 className="h3 text-white">Characteristics of Condoms  </h3>


							<ul className="list-style-set list-circle  mt-3 mb-3 text-white">
								<li>	Prevent both pregnancy and STIs/HIV (when used consistently and correctly </li>
								<li>	Requires motivation to use consistently and correctly </li>
								<li>	Requires partner’s cooperation </li>
								<li>	Has virtually no side effects</li>
							</ul>

							<p className="mb-2 mt-3 text-white">Let’s discuss some myths around condom use and how to burst them!  </p>
							<p className="mb-2 mt-3 text-white"><b>Condoms reduce pleasure –</b> When a condom is worn properly and there is lubrication, there is very little difference in the pleasure. This argument should not be used as a means of coercion against condom use.  </p>
							<p className="mb-2 mt-3 text-white"><b>Condoms are not reliable - </b>Condoms are a reliable method of birth control. They can also be effective in preventing the spread of sexually transmitted infections including HIV.  </p>



						</div>
					</div>
				</div>
			</div>

			{/* 4 */}

			<div className="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							<p className="text-white text-center">We often associate sexuality only with our bodies and sexual relationships. In reality, sexuality is a broad concept that includes many aspects of our lives. Let’s get into this!</p>


						</div>
					</div>
				</div>
			</div>

			{/* 5 */}

			<div className="modal fade" id="staticBackdrop5" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							<p className="text-white text-center">We often associate sexuality only with our bodies and sexual relationships. In reality, sexuality is a broad concept that includes many aspects of our lives. Let’s get into this!</p>


						</div>
					</div>
				</div>
			</div>






		</>
	);
};

export default Chapter04Screen35;
