import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import axios from "axios";

import BaseURL from "../../config/Baseurl";

import HeaderHome from "../../header/HeaderHome";

const Login = () => {
	const navigate = useNavigate();

	const [email, setEmail] = useState();
	const [password, setPassword] = useState();
	const [emailErr, setmobEmailErr] = useState({});
	const [passwordErr, setPasswordErr] = useState({});
	const [globalErr, setGlobalErr] = useState();

	const loginForm = (e) => {
		e.preventDefault();

		const isValid = formValidation();

		if (isValid === true) {
			const regex = /(<([^>]+)>)/gi;
			const __mobEmail = email.replace(regex, "");
			const __password = password.replace(regex, "");

			const data = JSON.stringify({
				email: __mobEmail,
				password: __password,
			});

			// console.log(data);

			const config = {
				method: "post",
				url: BaseURL + "/login",
				headers: {
					"Content-Type": "application/json",
				},

				data: data,
			};

			axios(config)
				.then(function (response1) {
					// console.log(response1);

					if (response1.data.success === true) {
						const token = response1.data.token;
						// console.log(token);

						const data1 = JSON.stringify({
							token: token,
						});

						const config1 = {
							method: "post",
							url: BaseURL + "/get_user",
							headers: {
								"Content-Type": "application/json",
							},
							data: data1,
						};

						// console.log(config1);

						axios(config1)
							.then(function (response) {

								const userId = response.data.data.id;
								const name = response.data.data.name;
								// console.log(response);


								const user_unique_id = response.data.data.user_unique_id;

								const userData = {
									token: token,
									user_id: userId,
									name: name,

									user_unique_id: user_unique_id,
								};

								// console.log(userData);
								localStorage.setItem("user_details", JSON.stringify(userData));

								navigate("/home");
							})
							.catch(function (error) {
								console.log(error);
							});

						//   navigate("/board-screen");
					} else {

						setGlobalErr(response1.data.message);
						// console.log(response1.data.message);
					}
				})
				.catch(function (error) {
					// setMessage('Invalid Credintials');
					// setType("danger");
				});
		}
	};

	//   // form validation
	const formValidation = () => {
		const emailErr = {};
		const passwordErr = {};

		const mobEmail1 = email === undefined ? "" : email;
		const password1 = password === undefined ? "" : password;

		let isValid = true;

		// console.log(mobEmail.length);
		if (mobEmail1.length === 0) {
			emailErr.required = "Email Is Required";
			isValid = false;
		}

		if (password1.length === 0) {
			passwordErr.required = "Password Is Required";
			isValid = false;
		}

		setmobEmailErr(emailErr);

		setPasswordErr(passwordErr);

		return isValid;
	};

	const GlobalErr = () => {

		if (globalErr !== '') {
			return (
				<>
					<span className="error">{globalErr}</span>
				</>
			)
		}

	}

	return (
		<>
			<div className="wrapper">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<HeaderHome />
					</div>
				</div>
				<form onSubmit={loginForm}>
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-center pt-20 mt-4">

								{/* <div className="col-lg-8 order-2 order-sm-2 order-md-2 order-lg-1 wow fadeInDown"  data-wow-duration="1s"  data-wow-delay="0.2s">
                  <div className="para-color-box p-4">
                    <h3 className="h3 red">About Breakthrough</h3>
                    <p>Breakthrough is a human rights organization working to make violence and discrimination against women and girls unacceptable. We use media, arts, technology and pop-culture to address the issues of domestic violence, sexual harassment in public spaces, early marriage and gender-based discrimination. All of these issues are rooted in and arise from gender and sexuality based discrimination that violate human rights</p>

                    <h3 className="h3 red mt-4">About the Course</h3>
                    <p>This course aims to enable learners’ build a basic understanding about Self, Gender, Sexuality and Human Rights. You will be able to identify the systems and structures which exist in our society and in your lives, and the role they play in maintaining the status quo. You will also learn about Breakthrough’s approach of working with people, systems, structures and institutions to address Gender based discrimination and violence in order to create a gender equal and just world.</p>
                  </div>
                </div> */}

								<div className="col-lg-4 mt-m-3 order-1  order-sm-1 order-md-1 order-lg-2  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
									<h2 className="h2 mb-2">Sign In </h2>
									<GlobalErr />
									<div className="mb-3">
										<label htmlFor="exampleInputEmail1" className="form-label">
											Email or Mobile
										</label>
										<input
											type="text"
											className="form-control"
											placeholder="Email Or Mobile......."
											value={email}
											onChange={(e) => {
												setEmail(e.target.value);
											}}
										/>

										{Object.keys(emailErr).map((key) => {
											return <span className="error">{emailErr[key]}</span>;
										})}
									</div>
									<div className="mb-3 mt-4">
										<label htmlFor="exampleInputEmail1" className="form-label">
											Password
										</label>
										<input
											type="password"
											className="form-control"
											placeholder="********"
											value={password}
											onChange={(e) => {
												setPassword(e.target.value);
											}}
										/>
										{Object.keys(passwordErr).map((key) => {
											return <span className="error">{passwordErr[key]}</span>;
										})}
									</div>

									<button
										className="button_su mt-3 "
									>
										<span className="su_button_circle" />
										<button type="submit" className="button_su_inner">
											<span className="button_text_container">
												{" "}
												Sign In <i className="bi bi-arrow-right" />
											</span>
										</button>
									</button>

									<button
										className="button_su mt-3"
									>
										<span className="su_button_circle" />
										<NavLink to="/register" className="button_su_inner">
											<span className="button_text_container">
												{" "}
												Sign Up <i className="bi bi-arrow-right" />
											</span>
										</NavLink >
									</button>
									<div className="mt-3">
										<NavLink to="/forgot-password" className="button_text_container">
											Forgot Password
										</NavLink>
									</div>


								</div>

								<div
									className="col-lg-3 offset-md-2 mt-4 order-2 order-sm-2 order-md-2 order-lg-2  wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="70%"
											src="/assets/img/chapter1/reading-book.png"
											alt=""
										/>
									</center>
								</div>

							</div>
						</div>
					</div>
				</form>
			</div>

			<div
				className="button-fixed termsc wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-center">

								<ul className="list-style-set list-circle mt-1">
									<li> <NavLink to="/terms-and-conditions">Terms & Conditions </NavLink>	</li>
									<li> <NavLink to="/privacy-policy">Privacy Policy </NavLink>	</li>

								</ul>


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
