import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";


const Chapter03Screen05 = () => {
  const navigate = useNavigate();
  const [checkedQues7_1, setCheckedQues7_1] = useState('');
  const [checkedQues7_2, setCheckedQues7_2] = useState('');
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",  
          
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",        
          "ques_id": "3"
        }, "ques4": {
          "ans": "",        
          "ques_id": "4"
        }, "ques5": {
          "ans": "",        
          "ques_id": "5"
        }, "ques6": {
          "ans": "",        
          "ques_id": "6"
        },
        "ques7": {
          "ans": checkedQues7_1,  
          "ans2":checkedQues7_2,       
          "ques_id": "7"
        }, "ques8": {
          "ans": "",        
          "ques_id": "8"
        }
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/MasculChapter03',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-04/chapter-03-screen-06')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const checkedVal = (param,ans,ans2) => {
    if (param === '7') {
      setCheckedQues7_1(ans)
      setCheckedQues7_2(ans2)
    }
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/MasculChapter03Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            
            checkedVal(result.ques_id.toString(),result.ans,result.ans2)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  const BtnDisabled = () => {
    if (checkedQues7_1 !== '' && checkedQues7_2 !==''  ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };


  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="2/5" percentage="57" star="57" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <p className="mt-3">
                  Bollywood has represented multiple masculinities through
                  various characters over the years. However, many popular films
                  which are widely watched by the audience have shown characters
                  displaying hegemonic masculinity by promoting violence,
                  reinforcing importance on physical strength, drinking and
                  being abusive to their partners, not expressing their emotions
                  and being angry, promoting the role of men as protectors,
                  bread winners and owners of women amongst other things. When
                  characters like Kabir Singh who are projected as being deeply
                  in love and ready to do anything for love are idealized in
                  films, it creates a very problematic narrative where men who
                  are abusive, reckless and destructive and who think they own
                  women can get away with everything by calling it ‘love’.
                </p>
                <h3 className="mt-3">
                  {" "}
                  <b>
                    Think about 2 films you liked and reflect on what different
                    masculinities were shown in the film through the characters.
                    Share about the films, characters and masculinities
                    represented in the space below.
                  </b>
                </h3>
              </div>
              <div
                className="col-lg-6 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <form>
                  <div className=" mt-4 positon-submit">
                    <textarea
                      className="form-control"
                      rows={1}
                      placeholder="Write Something here....."
                      defaultValue={checkedQues7_1}
                      onChange={(e)=>setCheckedQues7_1(e.target.value)}
                    />
                  </div>
                  <div className="mt-4 positon-submit">
                    <textarea
                      className="form-control"
                      rows={1}
                      placeholder="Write Something here....."
                      defaultValue={checkedQues7_2}
                      onChange={(e)=>setCheckedQues7_2(e.target.value)}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-04/chapter-03-screen-04"/>                  
                
                {/* <NextBtn link="/module-04/chapter-03-screen-06" text="Go Next"/> */}
                <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen05;
