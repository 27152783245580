import React, { useState } from "react";
import axios from "axios";
import BaseURL from "../../config/Baseurl";

import { NavLink, useNavigate } from "react-router-dom";
import HeaderHome from "../../header/HeaderHome";

const ResetPassword = () => {
	const navigate = useNavigate();
	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();
	const [passwordErr, setPasswordErr] = useState({});
	const [confirmPasswordErr, setConfirmPasswordErr] = useState({});
	const [globalErr, setGlobalErr] = useState();
	const [messageType, setMessageType] = useState();

	const changePassword = (e) => {

		e.preventDefault();

		const forgetdetails = JSON.parse(
			localStorage.getItem("forgot_password_details")
		);
		const isValid = formValidation();

		if (isValid === true) {
			var data = JSON.stringify({
				user_id: forgetdetails.id,
				password: password,
				password_confirmation: confirmPassword,
			});

			var config = {
				method: "post",
				url: BaseURL + "/resetPassword",
				headers: {
					"Content-Type": "application/json",
				},
				data: data,
			};

			axios(config)
				.then(function (response) {
					if (response.data.success === true) {
						localStorage.removeItem("forgot_password_details");
						setGlobalErr(response.data.message);
						setMessageType('success');
						navigate('/login');
					} else {
						setGlobalErr(response.data.message);
						setMessageType('error');
					}
					// console.log(JSON.stringify(response.data));
				})
				.catch(function (error) {
					setGlobalErr(error);
					setMessageType('error');
				});
		}
	};

	//   // form validation
	const formValidation = () => {
		const passwordErr = {};
		const confirmPasswordErr = {};

		const password1 = password === undefined ? "" : password;
		const confirmPassword1 =
			confirmPassword === undefined ? "" : confirmPassword;

		let isValid = true;

		// console.log(mobEmail.length);
		if (password1.length === 0) {
			passwordErr.required = "Password Is Required";
			isValid = false;
		}

		if (confirmPassword1.length === 0) {
			confirmPasswordErr.required = "Confirm Password Is Required";
			isValid = false;
		}

		if (password !== confirmPassword) {
			confirmPasswordErr.required = "Confirm Password Does Not Match";
			isValid = false;
		}

		setPasswordErr(passwordErr);
		setConfirmPasswordErr(confirmPasswordErr);

		return isValid;
	};

	const GlobalErr = () => {

		if (globalErr !== '') {
			return (
				<>
					<span className={messageType}>{globalErr}</span>
				</>
			)
		}

	}

	return (
		<>
			<div className="wrapper">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<HeaderHome />
					</div>
				</div>
				<form onSubmit={changePassword}>
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-center pt-20">
								<div className="col-lg-5">
									<h2 className="h2 mb-5">Change Password </h2>
									<GlobalErr />
									<div className="mb-3">
										<label htmlFor="exampleInputEmail1" className="form-label">
											Enter New Password
										</label>
										<input
											type="password"
											className="form-control"
											placeholder="Password......."
											value={password}
											onChange={(e) => {
												setPassword(e.target.value);
											}}
										/>

										{Object.keys(passwordErr).map((key) => {
											return <span className="error">{passwordErr[key]}</span>;
										})}
									</div>
									<div className="mb-3 mt-4">
										<label htmlFor="exampleInputEmail1" className="form-label">
											Confirm New Password
										</label>
										<input
											type="password"
											className="form-control"
											placeholder="********"
											value={confirmPassword}
											onChange={(e) => {
												setConfirmPassword(e.target.value);
											}}
										/>
										{Object.keys(confirmPasswordErr).map((key) => {
											return (
												<span className="error">{confirmPasswordErr[key]}</span>
											);
										})}
									</div>
									<button
										className="button_su mt-3 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<span className="su_button_circle" />
										<button type="submit" className="button_su_inner">
											<span className="button_text_container">
												{" "}
												Submit <i className="bi bi-arrow-right" />
											</span>
										</button>
									</button>

								</div>
								<div
									className="col-lg-3 offset-lg-1 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="100%"
											src="/assets/img/chapter1/Jhole-wali-didi.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default ResetPassword;
