import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Chapter03Screen06 = () => {
	const navigate = useNavigate();
	const [checkedQues14, setCheckedQues14] = useState('');
	const [checkedQues14_2, setCheckedQues14_2] = useState('');
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": checkedQues14,
					"ques_id": "14"
				},
				"ques15": {
					"ans": checkedQues14_2,
					"ques_id": "15"
				},
				"ques16": {
					"ans": "",
					"ques_id": "16"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-03-screen-07')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const checkedVal = (param, ans) => {
		if (param === '14') {
			setCheckedQues14(ans)
		}

		if (param === '15') {
			setCheckedQues14_2(ans)
		}

	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	const BtnDisabled = () => {
		if (checkedQues14 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/5" percentage="54" star="34" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.1s"
							>
								<h2 className="h2">Reflect and share</h2>
								<p>
									Share similar examples of how other institutions perpetuate
									Patriarchy. You can pick any 2 institutions and share one
									example each. You can include links of
									articles/blogs/videos/stories along with your reflection.
									(word limit- 200 words){" "}
								</p>
								<div className="mb-4 mt-4 positon-submit">
									<CKEditor
										editor={ClassicEditor}
										data={checkedQues14}
										onReady={editor => {
											// You can store the "editor" and use when it is needed.
											console.log('Editor is ready to use!', editor);
										}}
										onChange={(event, editor) => {
											const data = editor.getData();
											setCheckedQues14(data)
											console.log({ event, editor, data });
										}}
										onBlur={(event, editor) => {
											console.log('Blur.', editor);
										}}
										onFocus={(event, editor) => {
											console.log('Focus.', editor);
										}}
									/>

									{/* <textarea
                    className="form-control"
                    rows={3}
                    placeholder="Write Something here....."
                    onChange={(e)=>setCheckedQues14(e.target.value)}
                    data-quesId="15"
                  defaultValue={checkedQues14}
                  /> */}

									<div className="mt-4">
										<CKEditor

											editor={ClassicEditor}
											data={checkedQues14_2}
											onReady={editor => {
												// You can store the "editor" and use when it is needed.
												console.log('Editor is ready to use!', editor);
											}}
											onChange={(event, editor) => {
												const data = editor.getData();
												setCheckedQues14_2(data)
												console.log({ event, editor, data });
											}}
											onBlur={(event, editor) => {
												console.log('Blur.', editor);
											}}
											onFocus={(event, editor) => {
												console.log('Focus.', editor);
											}}
										/>
									</div>


									{/* <textarea
                    className="form-control mt-4"
                    rows={3}
                    placeholder="Write Something here....."
                    onChange={(e)=>setCheckedQues14_2(e.target.value)}
                    data-quesId="15"
                  defaultValue={checkedQues14_2}
                  /> */}

								</div>
							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="52%"
										src="/assets/img/md1/md1-shap-3-6.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-03-screen-05" />
								{/* <NextBtn
                  link="/module-01/chapter-03-screen-07"
                  text="Go Next"
                /> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Chapter03Screen06;
