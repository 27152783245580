import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import BaseURL, { AdminUrl } from "../../../config/Baseurl";
import axios from "axios";
import { useNavigate, NavLink } from "react-router-dom";

const Chapter04Screen22 = () => {

	const navigate = useNavigate();


	const [checkedQues47, setCheckedQues47] = useState();

	const [checkedQues46, setCheckedQues46] = useState([]);
	const [option1Checked, setOption1Checked] = useState('');
	const [option2Checked, setOption2Checked] = useState('');
	const [option3Checked, setOption3Checked] = useState('');
	const [option4Checked, setOption4Checked] = useState('');
	const [option5Checked, setOption5Checked] = useState('');
	const [option6Checked, setOption6Checked] = useState('');
	const [option7Checked, setOption7Checked] = useState('');
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);
		checkedVal(quesId, e.target.value)
	}
	const saveGenderBased = (e) => {
		const res = e.target.value;
		const checked = e.target.checked;
		checkBoxChecked(res, checked);
		setCheckedQues46(checkedQues46 => [...checkedQues46, res]);
	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques46": {
					"ans": checkedQues46.join(','),
					"ques_id": "46"
				},
				"ques47": {
					"ans": checkedQues47,
					"ques_id": "47"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-04-screen-23')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues46.length > 0 && checkedQues47 !== '') {
			return (
				<>
					<button className="button_su" >
						<span className="su_button_circle desplode-circle">
						</span>
						<button className="button_su_inner" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '46') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked);
			})
			setCheckedQues46(ansArr)
		}

		if (param === '47') {

			setCheckedQues47(ans)
		}


	}
	const checkBoxChecked = (val, checked) => {
		if (val === 'option1') {
			if (checked === true) {
				setOption1Checked('checked');
			} else {
				setOption1Checked('');
			}
		}
		if (val === 'option2') {
			if (checked === true) {
				setOption2Checked('checked');
			} else {
				setOption2Checked('');
			}
		}
		if (val === 'option3') {
			if (checked === true) {
				setOption3Checked('checked');
			} else {
				setOption3Checked('');
			}
		}
		if (val === 'option4') {
			if (checked === true) {
				setOption4Checked('checked');
			} else {
				setOption4Checked('');
			}
		}

		if (val === 'option5') {
			if (checked === true) {
				setOption5Checked('checked');
			} else {
				setOption5Checked('');
			}
		}

		if (val === 'option6') {
			if (checked === true) {
				setOption6Checked('checked');
			} else {
				setOption6Checked('');
			}
		}

		if (val === 'option7') {
			if (checked === true) {
				setOption7Checked('checked');
			} else {
				setOption7Checked('');
			}
		}


	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-0">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3">Learn:  </h3>
								<p className="mb-2 mt-3">We have now come to the end of our learning on sexuality! Let us summarise quickly some of the things we learnt:  </p>

								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle"
											id="mycirle2"
											defaultValue="option1"
											onClick={saveGenderBased}
											checked={option1Checked}

										/>
										<label className="form-check-label" htmlFor="mycirle2">It is a broad concept covering many aspects and dimensions of our life and self </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle"
											id="mycirle3"
											defaultValue="option2"
											onClick={saveGenderBased}
											checked={option2Checked}

										/>
										<label className="form-check-label" htmlFor="mycirle3">	It is influenced by things around us, norms, institutions, media, the environment we live in </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle"
											id="mycirle4"
											defaultValue="option3"
											onClick={saveGenderBased}
											checked={option3Checked}
										/>
										<label className="form-check-label" htmlFor="mycirle4">	There are many kinds of sexual identities whose sexual experiences are all unique </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle"
											id="mycirle5"
											defaultValue="option4"
											onClick={saveGenderBased}
											checked={option4Checked}

										/>
										<label className="form-check-label" htmlFor="mycirle5">	There are societal ideals on what sexual behaviour is acceptable and what is not </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="checkbox"
											name="namecircle"
											id="mycirle6"
											defaultValue="option5"
											onClick={saveGenderBased}
											checked={option5Checked}

										/>
										<label className="form-check-label" htmlFor="mycirle6">	Pleasure, desire, boundaries and consent are very important to our experiences of sexuality  </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle"
											id="mycirle7"
											defaultValue="option6"
											onClick={saveGenderBased}
											checked={option6Checked}
										/>
										<label className="form-check-label" htmlFor="mycirle7">There are many kinds of safer sex and communication behaviours to be mindful of if we are sexually active </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle"
											id="mycirle8"
											defaultValue="option7"
											onClick={saveGenderBased}
											checked={option7Checked}
										/>
										<label className="form-check-label" htmlFor="mycirle8">And so much more! </label>
									</div>
								</div>


								<p className="mb-2 mt-3">When the topic of sexuality is this vast and has so many parts, how do we make our own meaningful choices, access comprehensive information and enjoy an affirmative sexuality experience? </p>
								<div className="mb-3 mt-4">
									<textarea className="form-control" rows={2} placeholder="Write Something here....."
										defaultValue={checkedQues47}
										onChange={(e) => setCheckedQues47(e.target.value)}
									/>
								</div>
								<p className="mb-2 mt-3">A <b>comprehensive sexuality education</b>  can help!  </p>

							</div>
							<div
								className="col-lg-2 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="82%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-15.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-20" />
								{/* <NextBtn link="/module-05/chapter-01-screen-06" text="Go Next"/> */}

								<button className="button_su">
									<span className="su_button_circle desplode-circle">
									</span>
									{/* <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </a> */}
									<BtnDisabled />
								</button>



							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>
							<p className="text-white text-center">The word “comprehensive” is used to suggest a positive view of sexuality focused on well-being and safety, not morality and norms</p>


							<div className=" d-flex justify-content-center mt-3">
								<button className="button_su m-auto" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									{/* <NavLink to="/module-05/chapter-04-screen-23"  className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </NavLink> */}
									<button onClick={saveAns} className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </button>
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter04Screen22;
