import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter04Screen06 = () => {
	const navigate = useNavigate();
	const [checkedQues4_1, setCheckedQues4_1] = useState('');
	const [checkedQues4_2, setCheckedQues4_2] = useState('');
	const [checkedQues4_3, setCheckedQues4_3] = useState('');
	const [checkedQues4_4, setCheckedQues4_4] = useState('');
	const [checkedQues4_5, setCheckedQues4_5] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": checkedQues4_1 + "," + checkedQues4_2 + "," + checkedQues4_3 + "," + checkedQues4_4 + "," + checkedQues4_5,
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-04-screen-07')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": "7"
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter.ans;
					const res = results.split(",");
					res.map((elem, i) => {
						console.log(i);
						if (i === 0) {
							setCheckedQues4_1(elem)
						}
						if (i === 1) {
							setCheckedQues4_2(elem)
						}
						if (i === 2) {
							setCheckedQues4_3(elem)
						}
						if (i === 3) {
							setCheckedQues4_4(elem)
						}
						if (i === 4) {
							setCheckedQues4_5(elem)
						}
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {
		if (checkedQues4_1 !== '' && checkedQues4_2 !== '' || checkedQues4_3 !== '' ||
			checkedQues4_4 !== '' || checkedQues4_5 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="70" star="48" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2">Reflect and Share</h2>
								<p className="">
									Share examples of any 2 of the media given below where you
									have seen Patriarchal mindset being challenged.
								</p>
								<ul className="list-style-set list-input mt-3 mb-3">
									<li>
										Film{" "}
										<input
											type="text"
											placeholder=""
											className="input-without-style form-control"
											onChange={(e) => { setCheckedQues4_1(e.target.value) }}
											data-quesId="7"
											defaultValue={checkedQues4_1}

										/>
									</li>
									<li>
										TV or online streaming platform show{" "}
										<input
											type="text"
											placeholder=""
											className="input-without-style form-control"
											onChange={(e) => { setCheckedQues4_2(e.target.value) }}
											data-quesId="7"
											defaultValue={checkedQues4_2}
										/>
									</li>
									<li>
										News- Print/ Digital{" "}
										<input
											type="text"
											placeholder=""
											className="input-without-style form-control"
											onChange={(e) => { setCheckedQues4_3(e.target.value) }}
											data-quesId="7"
											defaultValue={checkedQues4_3}
										/>
									</li>
									<li>
										Advertisement{" "}
										<input
											type="text"
											placeholder=""
											className="input-without-style form-control"
											onChange={(e) => { setCheckedQues4_4(e.target.value) }}
											data-quesId="7"
											defaultValue={checkedQues4_4}
										/>
									</li>
									<li>
										Podcast/ Radio show{" "}
										<input
											type="text"
											placeholder=""
											className="input-without-style form-control"
											onChange={(e) => { setCheckedQues4_5(e.target.value) }}
											data-quesId="7"
											defaultValue={checkedQues4_5}
										/>
									</li>
								</ul>
								<div className="d-flex justify-content-start mt-4">

								</div>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="76%"
										src="/assets/img/md1/md1-shap-4-6.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-04-screen-05n" />
								{/* <NextBtn
                  link="/module-01/chapter-04-screen-07"
                  text="Go Next"
                /> */}

								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen06;
