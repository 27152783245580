import React, { useEffect, useState } from "react";

import HeaderHome from "../../header/HeaderHome";
import NextBtn from "../../common/NextBtn";
import PrevBtn from "../../common/PrevBtn";

// import ReactDOM from "react-dom/client";
import axios from "axios";
import BaseURL from "../../config/Baseurl";

const TermsAndConditions = () => {

  const [privacy,setPrivacy] = useState();

  useEffect(() => {
    var data = JSON.stringify({
      "type": "privacy"
    });
    
    var config = {
      method: 'post',
      url: BaseURL+'/getPage',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    axios(config)
    .then(function (response) {
      if(response.data.success === true)
      {
        setPrivacy(response.data.data.content);
      }
      console.log(response.data.data.content);
    })
    .catch(function (error) {
      console.log(error);
    });
    
  }, [])


  return (
    <>
      <div className="wrapper termsof-conditions-bg">
        <div
          className="fixed-header"
        
        >
          <div className="container-fluid cf-set">
          {/* <Header leftChapter="1/2" percentage="50" star="50" /> */}
          <HeaderHome/>
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-4">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
              <div className="the-content" dangerouslySetInnerHTML={{__html: privacy}}></div>
               </div>

              
             
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default TermsAndConditions;
