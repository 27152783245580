import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter01Screen12 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/2" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Learn </h3>
								<p className="mb-2  mt-3">Our body image (mental picture notions) and self-esteem (how we feel about it ourselves) make up our sense of self. In this day and age a lot of emphasis and attention is placed on our physical appearance, pressure to look perfect, have an “ideal” physique, etc. which can also extend to how our bodies are expected to function e.g. it’s assumed that women who have ‘child-bearing hips’ give birth to healthy children. Sometimes, our focus might shift to adhering to these images ideals rather than what makes us comfortable or what we may find adequate. Thus, it’s important to remember that all bodies don’t look the same. Bodies have different shapes and sizes, different abilities, and some people may choose to undergo changes to align their gender identity with their bodies. </p>
								<p className="mb-2  mt-3">We may not like every part of our body, but it’s good to remember that our worth and sense of self can be independent of our body. What does this look like? Let’s see some examples.  </p>

								<a href="https://www.youtube.com/watch?v=MfYSpuKq_-8" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p><b>Amaze: </b> Disability and Sexuality  </p>
										</div>
									</div>
								</a>
								<a href="https://www.youtube.com/watch?v=Ue41QYld3dU" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p><b>Harnam Kaur  </b>  </p>
										</div>
									</div>
								</a>
								<a href="https://drive.google.com/file/d/1e2ACot6REy7SZoIGK9c8pBmgvRu81ro-/view?usp=sharing" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p><b>Transman (Hindi) </b>  </p>
										</div>
									</div>
								</a>





							</div>

							{/* <div
                className="col-lg-4 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="40%"
                    draggable="false"
                    src="/assets/img/md4/md4-shap-2-3.png"
                    alt=""
                  />
                </center>
              </div> */}



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-01-screen-11" />
								<NextBtn link="/module-05/chapter-01-screen-13" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen12;
