import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { NavLink } from "react-router-dom";

const Chapter05Screen08 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="80" star="80" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-5">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-5 mt-3">
									Example: 13 young men and boys participated as youth leaders
									in a programme called <a className="display-inline" href="https://theypfoundation.org/programmes/mwb/" target="_blank">  Mardon Wali Baat</a> who developed innovative communication materials on masculinity and gender
									using their own photos and developed postcards and posters.
									These posters were utilized in community engagement efforts
									that reached out to more than 500 young people across campus
									and public sites in Lucknow city to challenge dominant
									conceptions of masculinity and address gender based violence.
								</p>
							</div>




							<div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
								<div className="carousel-inner">
									<div className="carousel-item active">
										<div className="row">
											<div
												className="col-lg-4 mt-4 wow fadeInDown"
												data-wow-duration="1s"
												data-wow-delay="0.2s"
											>
												<center>
													<img
														width="100%"
														draggable="false"
														src="/assets/img/md4/md5-poster1.png"
														alt=""
													/>
												</center>
											</div>
											<div
												className="col-lg-4 mt-4 wow fadeInDown"
												data-wow-duration="1s"
												data-wow-delay="0.2s"
											>
												<center>
													<img
														width="100%"
														draggable="false"
														src="/assets/img/md4/md5-poster2.png"
														alt=""
													/>
												</center>
											</div>
											<div
												className="col-lg-4 mt-4 wow fadeInDown"
												data-wow-duration="1s"
												data-wow-delay="0.2s"
											>
												<center>
													<img
														width="100%"
														draggable="false"
														src="/assets/img/md4/md5-poster3.png"
														alt=""
													/>
												</center>
											</div>
										</div>
									</div>
									<div className="carousel-item">
										<div className="row">
											<div
												className="col-lg-4 mt-4 wow fadeInDown"
												data-wow-duration="1s"
												data-wow-delay="0.2s"
											>
												<center>
													<img
														width="100%"
														draggable="false"
														src="/assets/img/md4/md5-poster4.png"
														alt=""
													/>
												</center>
											</div>

											<div
												className="col-lg-4 mt-4 wow fadeInDown"
												data-wow-duration="1s"
												data-wow-delay="0.2s"
											>
												<center>
													<img
														width="100%"
														draggable="false"
														src="/assets/img/md4/md5-poster5.png"
														alt=""
													/>
												</center>
											</div>
											<div
												className="col-lg-4 mt-4 wow fadeInDown"
												data-wow-duration="1s"
												data-wow-delay="0.2s"
											>
												<center>
													<img
														width="100%"
														draggable="false"
														src="/assets/img/md4/md5-poster6.png"
														alt=""
													/>
												</center>
											</div>

										</div>
									</div>
									<div className="carousel-item">
										<div className="row">
											<div
												className="col-lg-4 mt-4 wow fadeInDown"
												data-wow-duration="1s"
												data-wow-delay="0.2s"
											>
												<center>
													<img
														width="100%"
														draggable="false"
														src="/assets/img/md4/md5-poster7.png"
														alt=""
													/>
												</center>
											</div>

											<div
												className="col-lg-4 mt-4 wow fadeInDown"
												data-wow-duration="1s"
												data-wow-delay="0.2s"
											>
												<center>
													<img
														width="100%"
														draggable="false"
														src="/assets/img/md4/md5-poster8.png"
														alt=""
													/>
												</center>
											</div>

											<div
												className="col-lg-4 mt-4 wow fadeInDown"
												data-wow-duration="1s"
												data-wow-delay="0.2s"
											>
												<center>
													<img
														width="100%"
														draggable="false"
														src="/assets/img/md4/md5-poster1.png"
														alt=""
													/>
												</center>
											</div>


										</div>
									</div>
								</div>
								<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
									<span className="carousel-control-prev-icon" aria-hidden="true"><i class="bi bi-chevron-left"></i> </span>
									<span className="visually-hidden">Previous</span>
								</button>
								<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
									<span className="carousel-control-next-icon" aria-hidden="true"> <i class="bi bi-chevron-right"></i></span>
									<span className="visually-hidden">Next</span>
								</button>
							</div>
















						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-05-screen-06" />

									<NextBtn link="/module-04/chapter-05-screen-10" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen08;
