import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen17 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3 mt-3">Engage </h3>
								<p className="mb-2  mt-3">Why are gay rights important? </p>

								<a
									href="https://www.youtube.com/watch?v=1wsG9eYsl7Q"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>LGBT Rights - Why They Matter </p>
										</div>
									</div>
								</a>
								<p className="mb-2  mt-3">Section 377 has been used to harass same sex desiring people, break them up, extort and blackmail gay men or verbally and physically abuse them. A law like this considers sodomy and homosexuality as the same thing.  </p>
								<p className="mb-2  mt-3">In our society, there is a huge amount of stigma around diverse sexual practices and behaviour. Homophobia or homonegativity runs deep. This includes considering any sexual intercourse that is not for the purpose of ‘procreation’ especially among and by homosexual or same-sex desiring people as “wrong” or “dirty”, and deemed unnatural. These negative beliefs and actions can affect the physical and mental health of people with diverse gender identities who may not identify as cis-gender, their access to legal and health services, treatment in society and more.  </p>


							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="65%"
										draggable="false"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-17" />
								<NextBtn link="/module-05/chapter-02-screen-19" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen17;
