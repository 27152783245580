import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen06 = () => {
  return (
    <>
      <>
        <div className="wrapper">
          <div
            className="fixed-header"
          
          >
            <div className="container-fluid cf-set">
              <Header leftChapter="1/5" percentage="26" star="13" />
            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20 mt-3">
                <div
                  className="col-lg-7 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <h1 className="h1 text-left">Learn </h1>
                  <p className="text-left mb-3">
                    So why are we judged and discriminated against if we do not
                    follow the gender norms and expectations of society? The
                    system of Patriarchy ensures that gender norms and
                    expectations are constantly followed in society by girls and
                    boys, women and men by only adhering to the behaviours,
                    expressions, roles and responsibilities which are prescribed
                    for their respective gender. Any deviation from it results
                    in judgement, discrimination and violence because these
                    norms, practices and narratives are important to maintaining
                    the system of Patrirachy. We will learn more about it in the
                    next chapter but before that let’s watch a video to see how
                    these girls broke the gender norms in their family and
                    community.{" "}
                  </p>
                  {/* <p className="text-left mb-3">
                    <a
                      href="https://www.youtube.com/watch?v=HFPjypCj7MM&t=2s"
                      target="_blank"
                    >
                      <b>The Murailapur Champions | Breakthrough India</b>
                    </a>
                  </p> */}
                </div>
                <div
                  className="col-lg-5 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                 

                  <iframe
                    width="100%"
                    height="315"
                    src="https://www.youtube.com/embed/HFPjypCj7MM"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                  <PrevBtn link="/module-01/chapter-02-screen-06n" />

                  <NextBtn
                    link="/module-01/chapter-02-screen-07"
                    text="Go Next"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Chapter02Screen06;
