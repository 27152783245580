import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen02 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="1" star="1" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Engage</h3>
								<p className="mb-2 mt-3">Highlight the first 3 values you see:</p>


								<div className="table">
									<div className="tr">
										<div className="td">Love</div>
										<div className="td">Peace</div>
										<div className="td">Freedom</div>
										<div className="td">Courage</div>
										<div className="td">Authenticity</div>
									</div>

									<div className="tr">
										<div className="td">Accountability</div>
										<div className="td">Justice</div>
										<div className="td">Gratitude</div>
										<div className="td">Forgiveness</div>
										<div className="td">Compassion</div>
									</div>

									<div className="tr">
										<div className="td">Commitment</div>
										<div className="td">Creativity</div>
										<div className="td">Integrity</div>
										<div className="td">Equality</div>
										<div className="td">Dignity</div>
									</div>

									<div className="tr">
										<div className="td">Inclusion</div>
										<div className="td">Trust</div>
										<div className="td">Joy</div>
										<div className="td">Diversity</div>
										<div className="td">Respect</div>
									</div>

									<div className="tr">
										<div className="td">Patience</div>
										<div className="td">Leadership</div>
										<div className="td">Well-Being</div>
										<div className="td">Truth</div>
										<div className="td">Wealth</div>
									</div>
									<div className="tr">
										<div className="td">Safety</div>
										<div className="td">Success</div>
										<div className="td">Kindness</div>
										<div className="td">Faith</div>
										<div className="td">Leisure</div>
									</div>

								</div>



							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="82%"
										src="/assets/img/md2/md2-shap-2-7.png"
										alt=""
									/>
								</center>
							</div>





						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">


								<PrevBtn link="/module-02/chapter-02-screen-01" />
								<NextBtn link="/module-02/chapter-02-screen-02n" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
