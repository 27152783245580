import React,{useState,useEffect} from "react";

import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";

const Chapter03Screen11 = () => {

 
  return (
    <>
      <div className="wrapper page-auto md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="1/4" percentage="25" star="2" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
              <h4 className="h3 mb-2 mt-3"><b>Summary</b></h4> 
              </div>
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <ul className="without-list list-style-circle ml-1 mt-1">
                  <li>A cisgender, heterosexual, upper class, Hindu man is most likely to be ahead of all other identities because of the intersection of multiple privileged identities  </li>
                  <li>There is no level playing field - eg: Caste system in India, Gender based violence, Communalism</li>
                  <li>Certain identities have more power and privileges historically and they are the ones who are largely in decision making positions in the society which often leads to securing all the resources for themselves hence violating the rights of others.</li>
                  <li>Whereas the intersections of identities with historically less power and privileges end up struggling to ensure their basic rights which often deprives them from growing beyond their struggles, leave alone make it to the decision making positions. This constant cycle leads to further rise in inequality.</li>
                  <li>Therefore, equality cannot come without equity and justice</li>
                  <li>Recognising one’s own privilege is a hard thing but it is more than necessary to ensure that affirmative actions can be taken for identities without privileges, to create a level playing field /equitable environment for everyone’s growth. For example: Caste based reservation in educational institutions, reservation for girls and women in  public transport, accessible infrastructure for persons with disabilities, reservation in jobs for transgender people. </li>
                  <li>Shifting the narrative- stereotypes, prejudices and stigma attached to certain identities through awareness, perspective building and behaviour change work is also critical to ensuring access to rights and resources  equally for all identities.   </li>

                </ul>

              </div>

            
           
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-03/chapter-03-screen-10"/>  
                <NextBtn link="/module-03/chapter-03-screen-11n" text="Go Next"/>
                {/* <BtnDisabled/> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen11;
