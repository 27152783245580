import React from "react";
import { NavLink } from "react-router-dom";

const NextBtn = (props) => {
  return (
    <>
      <button
      
        className={`button_su ${props.classType}`}
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <span className="su_button_circle" />
        <NavLink to={props.link} className="button_su_inner">
          <span className="button_text_container">
            {" "}
            {props.text} <i className="bi bi-arrow-right" />
          </span>
        </NavLink>
      </button>
    </>
  );
};

export default NextBtn;
