import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen02 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2 hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="55" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mr-3 mt-m-3">
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-2 mt-1">As discussed in the previous chapter, Breakthrough works extensively with young people through a deep transformation model that helps them develop a stronger identity, the confidence to make their own life decisions and embark on a journey of being gender equality champions. We ensure that they have an enabling environment to do so by working with their families and community at large and key stakeholders like frontline workers, PRI members, school management, state authorities etc.</p>
								<h3 className="h3">Breakthrough is currently working in Delhi, Haryana, Jharkhand, Odisha, Punjab, Uttar Pradesh and engages with young people, community, government and the ecosystem through </h3>

								<ul className="list-style-set list-circle mt-2">
									<li>Deep transformation models</li>
									<li>360 degree campaigns</li>
									<li>Advocacy within and outside India</li>
								</ul>

							</div>

							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="90%"
										draggable="false"
										src="/assets/img/md6/map.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-06/chapter-03-screen-01" />
								<NextBtn link="/module-06/chapter-03-screen-03" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
