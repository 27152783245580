import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter02Screen03 = () => {
	const navigate = useNavigate();
	const [checkedQues2_1, setCheckedQues2_1] = useState('');
	const [checkedQues2_2, setCheckedQues2_2] = useState('');
	const [checkedQues2_3, setCheckedQues2_3] = useState('');
	const [checkedQues2, setCheckedQues2] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));




	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": checkedQues2,
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues2_1,
					"ans2": checkedQues2_2,
					"ans3": checkedQues2_3,
					"ques_id": "3"
				},




			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/ApproachChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-06/chapter-02-screen-04')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans, ans2, ans3) => {

		if (param === '2') {

			setCheckedQues2(ans)
		}

		if (param === '3') {

			setCheckedQues2_1(ans)
			setCheckedQues2_2(ans2)
			setCheckedQues2_3(ans3)
		}




	}


	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/approachChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans, result.ans2, result.ans3)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues2 !== '' && checkedQues2_1 !== '' && checkedQues2_2 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="40" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div
							className="col-lg-9 wow fadeInDown"
							data-wow-duration="1s"
							data-wow-delay="0.2s"
						>
							<h4 className="h4">
								What is your vision for the world when it comes to gender and how can that be achieved? Share in 50 words in the space below
							</h4>

							<div className="mb-2">
								<textarea className="form-control" rows="1" defaultValue={checkedQues2} onChange={(e) => setCheckedQues2(e.target.value)} placeholder=".."></textarea>
							</div>
						</div>

						<div className="row align-items-top justify-content-between pt-20 mt-2">



							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-2 mt-2">Breakthrough aims to bring change in the world by building a generation of gender sensitive adolescents and young adults. We will learn how this will happen but before that let’s hear from you…</p>
								<h4 className="h4"> Think about one change you have been able to actively bring in yourself or your family or community in the last 5 years. What are the 3 steps you took to bring that change? Share in the space below</h4>

								<div className="mb-4 mt-3">
									<textarea className="form-control" defaultValue={checkedQues2_1} onChange={(e) => setCheckedQues2_1(e.target.value)} rows="1" placeholder=".."></textarea>
								</div>
								<div className="mb-4 mt-3">
									<textarea className="form-control" defaultValue={checkedQues2_2} onChange={(e) => setCheckedQues2_2(e.target.value)} rows="1" placeholder=".."></textarea>
								</div>
								<div className="mb-4 mt-3">
									<textarea className="form-control" defaultValue={checkedQues2_3} onChange={(e) => setCheckedQues2_3(e.target.value)} rows="1" placeholder=".."></textarea>
								</div>

							</div>


							<div className="col-lg-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">
								<center>
									<img width="50%" draggable="false" src="/assets/img/md6/md-60chap-2-3.png" alt="" />
								</center>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-06/chapter-02-screen-02" />
								{/* <NextBtn link="/module-06/chapter-02-screen-04" text="Go Next"/> */}
								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen03;
