import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../config/Baseurl";


import swal from "sweetalert";


const ChangePassword = () => {
	const navigate = useNavigate();
	const [oldPassword, setOldPassword] = useState();
	const [newPassword, setNewPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();
	const previous_link = JSON.parse(localStorage.getItem('previous_link'));

	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const updatePassword = (e) => {
		e.preventDefault();

		var data = JSON.stringify({
			currentPassword: oldPassword,
			password: newPassword,
			confirmPassword: confirmPassword,
			user_id: user_details.user_id,
		});

		var config = {
			method: "post",
			url: BaseURL + "/changePassword",
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		};

		axios(config)
			.then(function (response) {

				console.log(response);
				const msg = response.data.message;

				if(msg === 'Old Password Does Not Match' || "The password and confirm password must match.")
				{
					swal(
						msg,
						"",
						"error"
					);
				}

				if(msg === 'Password Change Successfully')
				{
					swal(
						msg,
						"",
						"success"
					);	
				}
				

				// console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				console.log(error);
			});
	};



	const logout = () => {
		localStorage.removeItem('user_details')
		navigate('/')

	}

	const LoginMenu = () => {
		if (user_details === null || user_details === '' || user_details === '') {
			return (
				<>
					<ul className="nav nav-pills header-ul">
						<li className="nav-item">
							<NavLink to="/register" className="header-btn">
								{" "}
								<img src="/assets/img/icon/profile.png" alt="" /> Sign up
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to="/login" className="header-btn btn-gray-bg">
								{" "}
								<img src="/assets/img/icon/login.png" alt="" /> Login
							</NavLink>
						</li>
					</ul>
				</>
			);
		} else {

			return (
				<>
					<ul className="nav nav-pills header-ul">
						<li className="nav-item">
							<button onClick={logout} className="header-btn">
								{" "}
								<img src="/assets/img/icon/login.png" alt="" /> Logout
							</button>
						</li>
						<li className="nav-item">
							<NavLink to="/profile" className="header-btn btn-gray-bg">
								{" "}
								<img src="/assets/img/icon/profile.png" alt="" /> Profile
							</NavLink>
						</li>
					</ul>
				</>
			);



		}
	};


	return (
		<>
			<div className="wrapper ">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<header className="d-flex flex-wrap justify-content-center align-items-center">
							<NavLink
								to="/"
								className="d-flex align-items-center mb-3 mb-md-0 me-md-auto  text-decoration-none"
							>
								<div className="home-icon">
									<i className="bi bi-house-door-fill" />
								</div>
							</NavLink>
							<LoginMenu />
						</header>
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-center pt-20 mt-5">
							<div className="col-lg-5">
								<form method="post" onSubmit={updatePassword}>

									<h2 className="h2 mb-5">Change Password</h2>
									<div className="mb-3">
										<label htmlFor="exampleInputEmail1" className="form-label">
											Enter Old password
										</label>
										<input
											type="password"
											className="form-control"
											placeholder="********"
											onChange={(e) => setOldPassword(e.target.value)}
											required
										/>
									</div>

									<div className="mb-3">
										<label htmlFor="exampleInputEmail1" className="form-label">
											Enter New password
										</label>
										<input
											type="password"
											className="form-control"
											placeholder="********"
											onChange={(e) => setNewPassword(e.target.value)}
											required
										/>
									</div>
									<div className="mb-3 mt-4">
										<label htmlFor="exampleInputEmail1" className="form-label">
											Confirm new password
										</label>
										<input
											type="password"
											className="form-control"
											placeholder="********"
											onChange={(e) => setConfirmPassword(e.target.value)}
											required
										/>
									</div>

									<div className="row mt-5 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s">
										<div className="col-lg-12">
											<div className=" d-flex justify-content-center">

												<NavLink to={previous_link} class="button_su undefined" data-wow-duration="1s" data-wow-delay="0.2s"><span class="su_button_circle"></span><button className="button_su_inner" ><span className="button_text_container"> <i class="bi bi-arrow-left"></i> Back </span></button></NavLink>
												<button type="submit" class="button_su undefined" data-wow-duration="1s" data-wow-delay="0.2s"><span class="su_button_circle"></span><button className="button_su_inner" ><span className="button_text_container"> Submit </span></button></button>

											</div>
										</div>
									</div>

									{/* <button
            className="button_su mt-3 mb-3 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >

            <span className="su_button_circle" />
            <button type="submit"  className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Submit <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button> */}
								</form>
								{/* <NavLink to={previous_link} class="button_su undefined" data-wow-duration="1s" data-wow-delay="0.2s"><span class="su_button_circle"></span><button className="button_su_inner" ><span className="button_text_container"> <i class="bi bi-arrow-left"></i> Back </span></button></NavLink> */}
							</div>

							<div
								className="col-lg-3 offset-lg-1 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										src="/assets/img/chapter1/Jhole-wali-didi.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	)
}

export default ChangePassword
