import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter01Screen13 = () => {
	const navigate = useNavigate();
	const [checkedQues13Like, setCheckedQues13Like] = useState('');
	const [checkedQues13unLike, setCheckedQues13unLike] = useState('');
	const [checkedQues13Val, setCheckedQues13Val] = useState('');

	const [checkedQues14Like, setCheckedQues14Like] = useState('');
	const [checkedQues14unLike, setCheckedQues14unLike] = useState('');
	const [checkedQues14Val, setCheckedQues14Val] = useState('');


	const [checkedQues15Like, setCheckedQues15Like] = useState('');
	const [checkedQues15unLike, setCheckedQues15unLike] = useState('');
	const [checkedQues15Val, setCheckedQues15Val] = useState('');


	const [checkedQues16Like, setCheckedQues16Like] = useState('');
	const [checkedQues16unLike, setCheckedQues16unLike] = useState('');
	const [checkedQues16Val, setCheckedQues16Val] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);
		checkedVal(quesId, e.target.value)

	}


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {

				"ques13": {
					"ans": checkedQues13Val,
					"ques_id": "13"
				},
				"ques14": {
					"ans": checkedQues14Val,
					"ques_id": "14"
				},
				"ques15": {
					"ans": checkedQues15Val,
					"ques_id": "15"
				},
				"ques16": {
					"ans": checkedQues16Val,
					"ques_id": "16"
				},


			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-05/chapter-01-screen-14')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans) => {

		if (param === '13') {

			if (ans === 'like') {
				setCheckedQues13Like('checked')
				setCheckedQues13unLike('')
			} else {
				if (ans === 'unlike') {
					setCheckedQues13unLike('checked')
					setCheckedQues13Like('')
				}
			}
			setCheckedQues13Val(ans)
		}

		if (param === '14') {

			if (ans === 'like') {
				setCheckedQues14Like('checked')
				setCheckedQues14unLike('')
			} else {
				if (ans === 'unlike') {
					setCheckedQues14unLike('checked')
					setCheckedQues14Like('')
				}
			}
			setCheckedQues14Val(ans)
		}

		if (param === '15') {

			if (ans === 'like') {
				setCheckedQues15Like('checked')
				setCheckedQues15unLike('')
			} else {
				if (ans === 'unlike') {
					setCheckedQues15unLike('checked')
					setCheckedQues15Like('')
				}
			}
			setCheckedQues15Val(ans)
		}

		if (param === '16') {

			if (ans === 'like') {
				setCheckedQues16Like('checked')
				setCheckedQues16unLike('')
			} else {
				if (ans === 'unlike') {
					setCheckedQues16unLike('checked')
					setCheckedQues16Like('')
				}
			}
			setCheckedQues16Val(ans)
		}




	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues13Val !== '' && checkedQues14Val !== '' && checkedQues15Val !== '' && checkedQues16Val !== ''
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper page-auto bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/2" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Engage</h3>
								<p className="mb-2  mt-3">Some scenarios are given below, which of these show a self-assuring body image? Place a thumbs up/ thumbs down by the statement. </p>

								<ul class="list-style-set list-circle mt-1">
									<li>	Reema’s brother tells her she looks fat in a dress, but she feels good about her body and wears it anyway. </li>

									<div className="plans thumb-design">
										<div className="d-flex ">


											<div>
												<label className="plan basic-plan" htmlFor="basic23">
													<input type="radio" name="plan" id="basic23"
														onClick={saveRadio}
														defaultValue="like"
														data-quesId="13"
														checked={checkedQues13Like}

													/>
													<div className="plan-content">
														<img loading="lazy" src="/assets/img/md5/thumbs-top.png" alt="" />
													</div>
												</label>
											</div>

											<div>
												<label className="plan complete-plan" htmlFor="complete">
													<input type="radio" id="complete"
														name="plan"
														onClick={saveRadio}
														defaultValue="unlike"
														data-quesId="13"
														checked={checkedQues13unLike}

													/>
													<div className="plan-content">
														<img loading="lazy" src="/assets/img/md5/thumbs-down.png" alt="" />
													</div>
												</label>
											</div>
										</div>
									</div>


									<li>Mohit’s friends in the gym make fun of him for being so thin and call him “sukda”. Now he goes only when no one is there. </li>

									<div className="plans thumb-design">
										<div className="d-flex ">
											<div>
												<label className="plan basic-plan" htmlFor="basic2">
													<input type="radio" name="plan2" id="basic2"
														onClick={saveRadio}
														defaultValue="like"
														data-quesId="14"
														checked={checkedQues14Like}

													/>
													<div className="plan-content">
														<img loading="lazy" src="/assets/img/md5/thumbs-top.png" alt="" />
													</div>
												</label>
											</div>
											<div>
												<label className="plan complete-plan" htmlFor="complete2">
													<input type="radio" id="complete2" name="plan2"

														onClick={saveRadio}
														defaultValue="unlike"
														data-quesId="14"
														checked={checkedQues14unLike}

													/>
													<div className="plan-content">
														<img loading="lazy" src="/assets/img/md5/thumbs-down.png" alt="" />
													</div>
												</label>
											</div>
										</div>
									</div>
									<li> Tara’s boyfriend keeps criticizing her looks it makes her feel sad. She starts to believe what he says and feels unworthy. She doesn’t tell him to stop because she is just glad that she still has a boyfriend.  </li>

									<div className="plans thumb-design">
										<div className="d-flex ">
											<div>
												<label className="plan basic-plan" htmlFor="basic3">
													<input type="radio" name="plan3" id="basic3"
														onClick={saveRadio}
														defaultValue="like"
														data-quesId="15"
														checked={checkedQues15Like}

													/>
													<div className="plan-content">
														<img loading="lazy" src="/assets/img/md5/thumbs-top.png" alt="" />
													</div>
												</label>
											</div>
											<div>
												<label className="plan complete-plan" htmlFor="complete3">
													<input type="radio" id="complete3" name="plan3"

														onClick={saveRadio}
														defaultValue="unlike"
														data-quesId="15"
														checked={checkedQues15unLike}

													/>
													<div className="plan-content">
														<img loading="lazy" src="/assets/img/md5/thumbs-down.png" alt="" />
													</div>
												</label>
											</div>
										</div>
									</div>

									<li>Afreen’s mother keeps telling her that she is putting on too much weight for a 13 year old. Her aunt noticed her being upset, and assured her that this happens during puberty.This makes Afreen feel more confident about herself. </li>

									<div className="plans thumb-design">
										<div className="d-flex ">
											<div>
												<label className="plan basic-plan" htmlFor="basic4">
													<input type="radio" name="plan4" id="basic4"
														onClick={saveRadio}
														defaultValue="like"
														data-quesId="16"
														checked={checkedQues16Like}

													/>
													<div className="plan-content">
														<img loading="lazy" src="/assets/img/md5/thumbs-top.png" alt="" />
													</div>
												</label>
											</div>
											<div>
												<label className="plan complete-plan" htmlFor="complete4">
													<input type="radio" id="complete4"
														name="plan4"
														onClick={saveRadio}
														defaultValue="unlike"
														data-quesId="16"
														checked={checkedQues16unLike}

													/>
													<div className="plan-content">
														<img loading="lazy" src="/assets/img/md5/thumbs-down.png" alt="" />
													</div>
												</label>
											</div>
										</div>
									</div>
								</ul>

							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-1-13.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-01-screen-12" />
								{/* <NextBtn link="/module-05/chapter-01-screen-14" text="Go Next"/> */}
								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen13;
