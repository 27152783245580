import React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";

const Home = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const user_details = JSON.parse(localStorage.getItem("user_details"));

	const logout = () => {
		localStorage.removeItem('user_details')
		navigate('/')

	}

	const goToProfile = () => {
		const pathdata = location.pathname
		localStorage.setItem('previous_link', JSON.stringify(pathdata));
		navigate("/profile")
	}


	const LoginMenu = () => {
		if (user_details === null || user_details === '' || user_details === '') {
			return (
				<>
					<ul className="nav nav-pills header-ul">
						<li className="nav-item">
							<NavLink to="/register" className="header-btn">
								{" "}
								<i class="bi bi-people"></i> Sign up
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to="/login" className="header-btn btn-gray-bg">
								{" "}
								<i class="bi bi-box-arrow-in-right"></i> Login
							</NavLink>
						</li>
					</ul>
				</>
			);
		} else {

			return (
				<>
					<ul className="nav nav-pills header-ul">
						<li className="nav-item">
							<button onClick={logout} className="header-btn">
								{" "}
								<i class="bi bi-box-arrow-right"></i> Logout
							</button>
						</li>
						<li className="nav-item">
							<button onClick={goToProfile} className="header-btn btn-gray-bg">
								{" "}
								<i class="bi bi-person-fill"></i> Profile
							</button>
						</li>

						<li className="nav-item">
							<NavLink to="/home" className="header-btn">
								{" "}
								<i class="bi bi-person-fill"></i> Dashboard
							</NavLink>
						</li>

					</ul>
				</>
			);



		}
	};

	const Module1 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink
						to="/module-01/chapter-01-intro-screen"
						className="home-box-btn"
					>
						{" "}
						Module 1
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 1{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module2 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink to="/module-02/chapter-01-intro-screen" className="home-box-btn">
						{" "}
						Module 2{" "}
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 2{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module3 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink to="/module-03/chapter-01-intro-screen" className="home-box-btn">
						{" "}
						Module 3{" "}
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 3{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module4 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink to="/module-04/module-04-intro-screen" className="home-box-btn">
						{" "}
						Module 4
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 4{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module5 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink to="/module-05/module-05-intro-screen" className="home-box-btn">
						{" "}
						Module 5
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 5{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module6 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink
						to="/module-06/module-06-intro-screen"
						className="home-box-btn"
					>
						{" "}
						Module 6
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/module-06/module-06-intro-screen" className="home-box-btn">
						{" "}
						Module 6
					</NavLink>
				</>
			)
		}
	}


	return (
		<>
			<div className="wrapper page-auto">
				<div className="fixed-header   black-header">
					<div className="container-fluid cf-set">
						<header className="d-flex flex-wrap justify-content-center align-items-center">
							<NavLink
								to="/"
								className="d-flex align-items-center mb-md-0 me-md-auto  text-decoration-none"
							>
								<img
									className="brand-logo"
									src="/assets/img/logo-black.svg"
									alt=""
								/>
							</NavLink>
							<LoginMenu />
						</header>
					</div>
				</div>

				<div className="data-adjust ">
					<div className="container-fluid cf-set">
						<div className="row align-items-top  mt-3">
							<div className="col-lg-9  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">

								<h3 className="h3 mt-2"> Team Change Leader (TCL)</h3>
								<p>Working to build a brighter, braver and more gender equitable tomorrow.</p>

								<div className="row mt-3">

									<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3">
										<div className="home-box light-yellow-bg">
											<div className="price">Language</div>
											<div className="price-content"><i class="bi bi-translate"></i> English</div>
										</div>
									</div>
									<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3">
										<div className="home-box light-pink-bg">
											<div className="price">Duration</div>
											<div className="price-content"><i class="bi bi-alarm"></i> 30 Hours</div>
										</div>
									</div>

									<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3">
										<div className="home-box green-bg">
											<div className="price">Certificate</div>
											<div className="price-content"><i class="bi bi-patch-check"></i> Yes</div>
										</div>
									</div>
								
								</div>

								<div className="mt-4">
									<ul className="nav nav-pills mb-3 list-set" id="pills-tab" role="tablist">
										<li className="nav-item" role="presentation">
											<button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">About</button>
										</li>
										<li className="nav-item" role="presentation">
											<button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Curriculum</button>
										</li>
										<li className="nav-item" role="presentation">
											<button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">How it works</button>
										</li>
										<li className="nav-item" role="presentation">
											<button className="nav-link" id="pills-contact-tab2" data-bs-toggle="pill" data-bs-target="#pills-contact2" type="button" role="tab" aria-controls="pills-contact2" aria-selected="false">FAQs</button>
										</li>
									</ul>
									<div className="tab-content" id="pills-tabContent">

									<div className="tab-pane fade " id="pills-contact2" role="tab" aria-labelledby="pills-contact-tab2">
											
									<p className="red"><b> Q. How do I access the courses?</b></p>
									<p className="text-justify">After login/Sign up you can access the course. Please fill correct details while sign up.</p>

											
									<p className="red"><b> Q. How long do I have access the course?</b></p>
									<p className="text-justify">After registration you can access the course till the portal is alive. Also if you want to delete your account you have to send us request and we will delete your account in seven working days.</p>


									<p className="red"><b>Q. Do I have to complete a course in one go?</b></p>
									<p className="text-justify">Yes, you can visit it at any time, wherever you are. Each course is made up of a number of individual modules which enables you to complete it in your own time and at times convenient to you. If you wish to return to the course at the point you last left it at, please ensure you use the same credentials to access the course and do not clear your cache in between visits.</p>

									<p className="red"><b>Q. Is my registered course transferable?</b></p>
									<p className="text-justify">No. Your course cannot be transferred to anyone else.</p>

									<p className="red"><b>Q. What if I forget my login details?</b></p>
									<p className="text-justify">If you forget your login details please go to the forgot password and create new password.</p>

									<p className="red"><b>Q. I have registered the course online but cannot access the course?</b></p>
									<p className="text-justify">Please contact the Breakthrough support team on: contact@inbreakthrough.org.</p>

									<p className="red"><b>Q. Can I download or print a copy of the course material?</b></p>
									<p className="text-justify">Although it is allowed under our Terms and Conditions to have a personal printed copy of the material, the software that we use for our TCL does not have a download or print function, so there is no easy way to obtain this. For this reason, once you have registered individual TCL courses with us you have indefinite access to them so that you may return to the information and review it at any time.</p>


									<p className="red"><b>Q. Do I receive a certificate?</b></p>
									<p className="text-justify">Yes. You will get the certificate after completing the full course.</p>


										

											



										</div>

										<div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
											<h3 className="red">About Breakthrough</h3>
											<p className="text-justify">Breakthrough is a human rights organization working to make violence and discrimination against women and girls unacceptable. We use media, arts, technology and pop-culture to address the issues of domestic violence, sexual harassment in public spaces, early marriage and gender-based discrimination. All of these issues are rooted in and arise from gender and sexuality based discrimination that violate human rights</p>
											<h3 className="red">About the Course</h3>
											<p className="text-justify">This course aims to enable learners to start to build understanding about Self, Gender, Sexuality, Masculinity and Human Rights. As a learner, you will be able to identify the systems and structures which exist in our society and in your lives, and the role they play in maintaining the status quo. The course will shed light on some ways in which this can be challenged to bring change through examples. You will also learn about Breakthrough’s approach of working with people, systems, structures and institutions to address gender based discrimination and violence in order to create a gender equitable and just world. </p>

											<h3 className="red mt-3">Learning Objectives:</h3>
											<p>After successful completion of this course learners will be able to:</p>
											<ul className="list-style-set list-circle">
												<li>Develop a deeper understanding of their self and how it connects to their decisions and actions in the society</li>
												<li>Develop an understanding of Gender, Sexuality, Masculinity and Human Rights, recognise how it plays out in their own lives and in society</li>
												<li>Identify and Analyse how the structures and systems perpetuate gender based discrimination and violence</li>
												<li>Learn about Breakthrough’s approach of working with young people, communities and the larger ecosystem for gender equity and justice.</li>
												<li>Plan and organise actions against gender based violence and discrimination, and for gender equity and justice</li>
											</ul>




										</div>
										<div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
											<h3 className="red mt-3">Curriculum</h3>
											<p>It consists of 6 modules with different chapters covering various aspects of the module.</p>

											<p className="red"><b> Module 1: Understanding Self</b></p>
											<ul className="list-style-set list-circle">
												<li>What is my Identity?</li>
												<li>Understanding Values and Decision making </li>
												<li>Understanding Effective communication</li>
												<li>Understanding Leadership</li>
											</ul>

											<p className="red"><b> Module 2:  Understanding Gender</b></p>
											<ul className="list-style-set list-circle">
												<li>What is Gender?</li>
												<li>What is Gender Socialisation? </li>
												<li>What is Patriarchy? </li>
												<li>Gender and Media</li>
												<li>What is Gender Based Violence? </li>
											</ul>

											<p className="red"><b>Module 3: Understanding Sexuality</b></p>
											<ul className="list-style-set list-circle">
												<li>What is Sexuality?</li>
												<li>Sexual and Gender Diversity</li>
												<li>Expressions of Sexuality </li>
												<li>Understanding Risks and Rights</li>
											</ul>

											<p className="red"><b>Module 4: Understanding Masculinities</b></p>
											<ul className="list-style-set list-circle">
												<li>What is Masculinity?</li>
												<li>Understanding Multiple Masculinities</li>
												<li>Role of Media in creating notions of Masculinities</li>
												<li>Impact of Hegemonic/Patriarchal Masculinity on society</li>
												<li>Need of Inclusive and gender equitable masculinities</li>
											</ul>

											<p className="red"><b>Module 5: Understanding Human Rights</b></p>
											<ul className="list-style-set list-circle">
												<li>What are Human Rights?</li>
												<li>Fundamental Duties and Responsibilities</li>
												<li>Understanding Intersectionality</li>
												<li>How can we ensure Human Rights for all?</li>
											</ul>

											<p className="red"><b>Module 6: Understanding Breakthrough’s Approach</b></p>
											<ul className="list-style-set list-circle">
												<li>Breakthrough’s Journey</li>
												<li>Vision, Mission and Theory of Change</li>
												<li>Programs and Impact (Part I)</li>
												<li>Programs and Impact (Part II)</li>
											</ul>



										</div>
										<div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">

											<div className="row row-cols-2 row-cols-lg-4">
												<div className="col d-flex">
													<div class="serviceBox2">
														<div class="service-icon">Step 1</div>
														<h3 class="title">Create an account</h3>
														<p class="description">
															Sign up using your email id  and set your password. Once you have signed up you can always sign back in using the same credentials.
														</p>
													</div>
												</div>

												<div className="col d-flex">
													<div class="serviceBox2">
														<div class="service-icon">Step 2</div>
														<h3 class="title">Start</h3>
														<p class="description">
															Start your journey by clicking on Module 1
														</p>
													</div>
												</div>

												<div className="col d-flex">
													<div class="serviceBox2">
														<div class="service-icon">Step 3</div>
														<h3 class="title">Engage and earn stars</h3>
														<p class="description">
															Complete each module by engaging with all the content, activities, quizzes, videos and articles. For each completed activity you will earn stars. In case of quizzes you will earn stars on the right answers. The cumulative of these stars will reflect in your overall score for the module.
														</p>
													</div>
												</div>

												<div className="col d-flex">
													<div class="serviceBox2">
														<div class="service-icon">Step 4:</div>
														<h3 class="title">Completion and Certificate</h3>
														<p class="description">
															After finishing all the modules, you will receive a course completion certificate.
														</p>
													</div>
												</div>

												{/* <div className="col">
                          <div class="serviceBox2">
                              <div class="service-icon"><i class="fa fa-globe"></i></div>
                              <h3 class="title">Web Design</h3>
                              <p class="description">
                                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium consequuntur, deleniti eaque excepturi.
                              </p>
                          </div>
                        </div> */}


											</div>

											<div className="tab-pane fade" id="pills-contact2" role="tabpanel" aria-labelledby="pills-contact-tab2">
												......sadfasdfs
											</div>

											

										</div>
									</div>
								</div>




								{/* <div className="para-color-box p-4">
                  <h3 className="h3 red">About Breakthrough</h3>
                  <p>Breakthrough is a human rights organization working to make violence and discrimination against women and girls unacceptable. We use media, arts, technology and pop-culture to address the issues of domestic violence, sexual harassment in public spaces, early marriage and gender-based discrimination. All of these issues are rooted in and arise from gender and sexuality based discrimination that violate human rights</p>

                  <h3 className="h3 red mt-4">About the Course</h3>
                  <p>This course aims to enable learners’ build a basic understanding about Self, Gender, Sexuality and Human Rights. You will be able to identify the systems and structures which exist in our society and in your lives, and the role they play in maintaining the status quo. You will also learn about Breakthrough’s approach of working with people, systems, structures and institutions to address Gender based discrimination and violence in order to create a gender equal and just world.</p>
                </div> */}
							</div>

							<div className="col-lg-3 mt-5 mt-m-3 order-1  order-sm-1 order-md-1 order-lg-2  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
								<center>
									<img
										width="70%"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>




						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;
