import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen05 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">


							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3">Share: </h3>

								<p className="mb-2  mt-3">We experience pleasure right from childhood from many things in our daily lives. Many of these are non-sexual in nature. As we grow up, ideas of pleasure extend to sexual activities and intimacy.  </p>
								<p className="mb-2  mt-3">Can you give us some examples of what pleasure would be in these situations? The first is done as an example.  </p>

								<ul className="list-style-set list-input mt-3 mb-3">
									<li> <b>As a child, I found eating an ice cream on a hot day pleasurable.</b>  </li>
									<li> Experiencing pleasure with friends doing  <input type="text" placeholder className="input-without-style form-control" /></li>
									<li> Sexual behaviour that is pleasurable in an intimate relationship  <input type="text" placeholder className="input-without-style form-control" /></li>
									<li> Emotional behaviour that is desirable in an intimate relationship  <input type="text" placeholder className="input-without-style form-control" /></li>
								</ul>






							</div>

							{/* <div
                className="col-lg-10 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="100%"
                    draggable="false"
                    src="/assets/img/md5/chap02-2/4.jpg"
                    alt=""
                  />
                </center>
              </div>

                */}



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-04" />
								<NextBtn link="/module-05/chapter-03-screen-06" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen05;
