import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen32 = () => {
  return (
    <>
      <div className="wrapper page-auto bg-bd-2">
        <div
          className="fixed-header"
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="2/4" percentage="50" star="50" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <h3 className="h3 ">Engage </h3>
               
                <p className="mb-2  mt-3">Read this case study. Examine the relationship dynamics between Rita and her boss based on the questions below! </p>
                <p className="mb-2  mt-3">Rita works in a garment factory and was on her feet the entire day.  Although, she was physically strong and a hockey player when in college, now Rita would get tired at the end of the day.  </p>
                <p className="mb-2  mt-3">Rita’s boss was a thin and tiny person. The girls all thought he was a bit of a creep. He was always bullying them and exerting his dominance. All the girls stayed quiet because he was their boss.  </p>
                <p className="mb-2  mt-3">One evening when everyone had worked late, the boss told Rita and her workmates that he would give them a lift home. They did not want the ride, but he kept pestering them. After dropping off the others, he told Rita he would show her the view from India Gate. She did not want to go, but she did not want to be rude.   </p>
                <p className="mb-2  mt-3">There were very few people at India Gate. Rita’s boss parked the car in a dark place, under a big tree where they were hidden from sight.  </p>
                <p className="mb-2  mt-3">Rita’s’ boss began to touch and kiss Rita and soon was able to overpower her. Rita was very shocked and could not react at first. He held her down even more forcefully, while touching her despite her protests. Rita was cowered against the door, trying to get out. Finally, she managed to open the lock and ran out of the car.   </p>

                <p className="mb-2 mb-7 mt-3">As she caught an auto home, she was overcome with guilt. She was worried about what her family and friends would think. They would blame her for working late and say, “We know men will do what they want”. She felt alone and ashamed. She did not tell anyone what had happened. She quit her job. She was worried her boss would do the same again and she had no way out.   </p>

               </div>


              
             
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
               
                <PrevBtn link="/module-05/chapter-03-screen-31"/>  
                <NextBtn link="/module-05/chapter-03-screen-33" text="Go Next"/>



              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default Chapter03Screen32;
