import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
const Chapter03Screen05 = () => {
  const navigate = useNavigate();
  const [checkedQues6Always, setCheckedQues6Always] = useState('');
  const [checkedQues6Often, setCheckedQues6Often] = useState('');
  const [checkedQues6Rarely, setCheckedQues6Rarely] = useState('');
  const [checkedQues6Never, setCheckedQues6Never] = useState('');
  const [checkedQues6Val, setCheckedQues6Val] = useState('');
  const [checkedQues7Always, setCheckedQues7Always] = useState('');
  const [checkedQues7Often, setCheckedQues7Often] = useState('');
  const [checkedQues7Rarely, setCheckedQues7Rarely] = useState('');
  const [checkedQues7Never, setCheckedQues7Never] = useState('');
  const [checkedQues7Val, setCheckedQues7Val] = useState('');
  const [checkedQues8Always, setCheckedQues8Always] = useState('');
  const [checkedQues8Often, setCheckedQues8Often] = useState('');
  const [checkedQues8Rarely, setCheckedQues8Rarely] = useState('');
  const [checkedQues8Never, setCheckedQues8Never] = useState('');
  const [checkedQues8Val, setCheckedQues8Val] = useState('');
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    checkedVal(quesId,e.target.value)
  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",         
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",        
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": checkedQues6Val,
          "ques_id": "6"
        },
        "ques7": {
          "ans": checkedQues7Val, 
          "ques_id": "7"
        },
        "ques8": {
          "ans": checkedQues8Val,
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": "",
          "ques_id": "10"
        },
        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        },
        "ques13": {
          "ans": "",
          "ques_id": "13"
        },
        "ques14": {
          "ans": "",
          "ques_id": "14"
        }
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/SelfChapter03',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-02/chapter-03-screen-06')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const checkedVal = (param,ans) => {
    if (param === '6') {
      if (ans === 'Always') {
        setCheckedQues6Always('checked')
        setCheckedQues6Often('')
        setCheckedQues6Rarely('')
        setCheckedQues6Never('')
      } else if (ans === 'Often/sometimes') {
        setCheckedQues6Always('')
        setCheckedQues6Often('checked')
        setCheckedQues6Rarely('')
        setCheckedQues6Never('')
        }else if(ans === 'Rarely')
        {
          setCheckedQues6Always('')
          setCheckedQues6Often('')
          setCheckedQues6Rarely('checked')
          setCheckedQues6Never('')
        }else if(ans === 'Never')
        {
          setCheckedQues6Always('')
          setCheckedQues6Often('')
          setCheckedQues6Rarely('')
          setCheckedQues6Never('checked')
        }
        setCheckedQues6Val(ans)
    }
    if (param === '7') {
      if (ans === 'Always') {
        setCheckedQues7Always('checked')
        setCheckedQues7Often('')
        setCheckedQues7Rarely('')
        setCheckedQues7Never('')
      } else if (ans === 'Often/sometimes') {
        setCheckedQues7Always('')
        setCheckedQues7Often('checked')
        setCheckedQues7Rarely('')
        setCheckedQues7Never('')
        }else if(ans === 'Rarely')
        {
          setCheckedQues7Always('')
          setCheckedQues7Often('')
          setCheckedQues7Rarely('checked')
          setCheckedQues7Never('')
        }else if(ans === 'Never')
        {
          setCheckedQues7Always('')
          setCheckedQues7Often('')
          setCheckedQues7Rarely('')
          setCheckedQues7Never('checked')
        }
        setCheckedQues7Val(ans)
    }
    if (param === '8') {
      if (ans === 'Always') {
        setCheckedQues8Always('checked')
        setCheckedQues8Often('')
        setCheckedQues8Rarely('')
        setCheckedQues8Never('')
      } else if (ans === 'Often/sometimes') {
        setCheckedQues8Always('')
        setCheckedQues8Often('checked')
        setCheckedQues8Rarely('')
        setCheckedQues8Never('')
        }else if(ans === 'Rarely')
        {
          setCheckedQues8Always('')
          setCheckedQues8Often('')
          setCheckedQues8Rarely('checked')
          setCheckedQues8Never('')
        }else if(ans === 'Never')
        {
          setCheckedQues8Always('')
          setCheckedQues8Often('')
          setCheckedQues8Rarely('')
          setCheckedQues8Never('checked')
        }
        setCheckedQues8Val(ans)
    }
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfChapter03Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  const BtnDisabled = () => {
    if (checkedQues6Val !== '' && checkedQues7Val !== '' && checkedQues8Val !== '' ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  return (
    <>
      <div className="wrapper bg-bd-2">
        <div
          className="fixed-header"
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="2/4" percentage="32" star="32" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-3">
              <div
                className="col-lg-7 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
             <p className="mb-2 mt-3 pn"> <span>4.</span>
                  I don’t get defensive and/ or offensive in challenging
                  conversations or arguments
                </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="m"
                    id=""
                    defaultValue="Always"
                    onClick={saveRadio}
                    data-quesId="6"
                    checked={checkedQues6Always}
                  />
                  <label className="form-check-label" htmlFor="">
                    Always
                  </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="m"
                    id=""
                    defaultValue="Often/sometimes"
                    onClick={saveRadio}
                    data-quesId="6"
                    checked={checkedQues6Often}
                  />
                  <label className="form-check-label" htmlFor="">
                    Often/sometimes
                  </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="m"
                    id=""
                    defaultValue="Rarely"
                    onClick={saveRadio}
                    data-quesId="6"
                    checked={checkedQues6Rarely}
                  />
                  <label className="form-check-label" htmlFor="">
                    Rarely
                  </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="m"
                    id=""
                    defaultValue="Never"
                    onClick={saveRadio}
                    data-quesId="6"
                    checked={checkedQues6Never}
                  />
                  <label className="form-check-label" htmlFor="">
                    Never
                  </label>
                </div>
                </div>
                <p className="mb-2 mt-3 pn"> <span>5.</span>
                   I am willing to consider that I might be wrong about my
                  understanding or perceptions
                </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="a"
                    id=""
                    defaultValue="Always"
                    onClick={saveRadio}
                    data-quesId="7"
                    checked={checkedQues7Always}
                  />
                  <label className="form-check-label" htmlFor="">
                    Always
                  </label>
                </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="a"
                    id=""
                    defaultValue="Often/sometimes"
                    onClick={saveRadio}
                    data-quesId="7"
                    checked={checkedQues7Often}
                  />
                  <label className="form-check-label" htmlFor="">
                    Often/sometimes
                  </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="a"
                    id=""
                    defaultValue="Rarely"
                    onClick={saveRadio}
                    data-quesId="7"
                    checked={checkedQues7Rarely}
                  />
                  <label className="form-check-label" htmlFor="">
                    Rarely
                  </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="a"
                    id=""
                    defaultValue="Never"
                    onClick={saveRadio}
                    data-quesId="7"
                    checked={checkedQues7Never}
                  />
                  <label className="form-check-label" htmlFor="">
                    Never
                  </label>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-5 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
              <p className="mb-2 mt-3 pn"> <span>6.</span>
                   I am willing to apologise to others when I’ve been wrong or
                  when I hurt someone
                </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="b"
                    id=""
                    defaultValue="Always"
                    onClick={saveRadio}
                    data-quesId="8"
                    checked={checkedQues8Always}
                  />
                  <label className="form-check-label" htmlFor="">
                    Always
                  </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="b"
                    id=""
                    defaultValue="Often/sometimes"
                    onClick={saveRadio}
                    data-quesId="8"
                    checked={checkedQues8Often}
                  />
                  <label className="form-check-label" htmlFor="">
                    Often/sometimes
                  </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="b"
                    id=""
                    defaultValue="Rarely"
                    onClick={saveRadio}
                    data-quesId="8"
                    checked={checkedQues8Rarely}
                  />
                  <label className="form-check-label" htmlFor="">
                    Rarely
                  </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="b"
                    id=""
                    defaultValue="Never"
                    onClick={saveRadio}
                    data-quesId="8"
                    checked={checkedQues8Never}
                  />
                  <label className="form-check-label" htmlFor="">
                    Never
                  </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
              <PrevBtn link="/module-02/chapter-03-screen-04"/>                  
                {/* <NextBtn link="/module-02/chapter-03-screen-06" text="Go Next"/> */}
                <BtnDisabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Chapter03Screen05;
