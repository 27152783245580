import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter04Screen33 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-0">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Learn  </h3>
								<p className="mb-2 mt-3">What is important for us to remember is that there can be many reasons someone might choose to have an abortion. An unintended pregnancy could be the result of contraception failure; a person may be pregnant due to coercive sexual activity; the foetus or the person who is pregnant could be in danger; or simply a person could experience a change in life that does not make a pregnancy feasible.    </p>
								<p className="mb-2 mt-3">No matter the circumstance or the reason, the right to make this choice/ decision lies solely in the hands of the person going through it, in whatever way they think is best.  </p>
							</div>

							<div
								className="col-lg-2 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="90%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-15.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-32" />
								<NextBtn link="/module-05/chapter-04-screen-34" text="Go Next" />



							</div>
						</div>
					</div>
				</div>
			</div>




		</>
	);
};

export default Chapter04Screen33;
