import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter04Screen13 = () => {
	const navigate = useNavigate();
	const [checkedQues13_1, setCheckedQues13_1] = useState('');
	const [checkedQues13_2, setCheckedQues13_2] = useState('');
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",

					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				}, "ques4": {
					"ans": "",
					"ques_id": "4"
				}, "ques5": {
					"ans": "",
					"ques_id": "5"
				}, "ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ans2": "",
					"ques_id": "7"
				}, "ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques13": {
					"ans": checkedQues13_1,
					"ans2": checkedQues13_2,
					"ques_id": "13"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-04/chapter-04-screen-14')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const checkedVal = (param, ans, ans2) => {
		if (param === '13') {
			setCheckedQues13_1(ans)
			setCheckedQues13_2(ans2)
		}
	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {

						checkedVal(result.ques_id.toString(), result.ans, result.ans2)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	const BtnDisabled = () => {
		if (checkedQues13_1 !== '' && checkedQues13_2 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="71" star="71" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 ">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="">
									It is important to note that while there are disadvantages-
									pressure and burden of patriarchal and hegemonic masculine
									ideals, there are advantages in the form of much power and
									many privileges to boys and men, therefore, while they can be
									victims of Patriarchy they are also the perpetrators. This
									makes their role critical in changing the system and their own
									masculine ideals for an equitable and inclusive society.
								</p>
							</div>
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h4 className="mb-1">
									<b>The next chapter will address how to deal with this.</b>{" "}
								</h4>
								<h4 className="mb-1">
									<b>
										Share about 2 hegemonic patriarchal masculine ideals you
										would want boys and men to be rid of ?
									</b>{" "}
								</h4>
								<div className="mt-4 positon-submit">
									<textarea
										className="form-control"
										rows={2}
										placeholder="Write Something here....."
										defaultValue={checkedQues13_1}
										onChange={(e) => setCheckedQues13_1(e.target.value)}
									/>
								</div>
								<div className="mt-4 positon-submit">
									<textarea
										className="form-control"
										rows={2}
										placeholder="Write Something here....."
										defaultValue={checkedQues13_2}
										onChange={(e) => setCheckedQues13_2(e.target.value)}
									/>
								</div>
							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="70%"
										draggable="false"
										src="/assets/img/md4/md4-shap-4-13.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-04-screen-11" />

									{/* <NextBtn link="/module-04/chapter-04-screen-14" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen13;
