import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../config/Baseurl";
import PrevBtn from "../../common/PrevBtn";


const Score = () => {
	const navigate = useNavigate();
	const user_details = JSON.parse(localStorage.getItem("user_details"));
	const [stars, setStars] = useState([]);


	const AddModule = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"module_no": "1"
		});

		var config = {
			method: 'post',
			url: BaseURL + '/addUnlockModule',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-01-intro-screen');


				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"activity_type": "self"
		});
		var config = {
			method: 'post',
			url: BaseURL + '/getScore',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					setStars(response.data.data.totalStar)
					// console.log(response.data.data.totalStar);


				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])



	const logout = () => {
		localStorage.removeItem('user_details')
		navigate('/')

	}

	const LoginMenu = () => {
		if (user_details === null || user_details === '' || user_details === '') {
			return (
				<>
					<ul className="nav nav-pills header-ul">
						<li className="nav-item">
							<NavLink to="/register" className="header-btn">
								{" "}
								<img src="/assets/img/icon/profile.png" alt="" /> Sign up
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to="/login" className="header-btn btn-gray-bg">
								{" "}
								<img src="/assets/img/icon/login.png" alt="" /> Login
							</NavLink>
						</li>
					</ul>
				</>
			);
		} else {

			return (
				<>
					<ul className="nav nav-pills header-ul">
						<li className="nav-item">
							<button onClick={logout} className="header-btn">
								{" "}
								<img src="/assets/img/icon/login.png" alt="" /> Logout
							</button>
						</li>
						<li className="nav-item">
							<NavLink to="/profile" className="header-btn btn-gray-bg">
								{" "}
								<img src="/assets/img/icon/profile.png" alt="" /> Profile
							</NavLink>
						</li>
					</ul>
				</>
			);



		}
	};

	const ScoreBar = () => {
		if (stars.user_percent < 50) {
			return (
				<>
					<div className="red-light-box">
						<div className="img-nox-red d-flex justify-content-between align-items-center">
							<div>you scored {stars.user_percent}% </div> <div className="start-number">{stars.user_star}</div>
						</div>
						<img className="red-light-img"
							src="/assets/img/red-light.png"
							alt="" draggable="false"
						/>
					</div>
					<div className="opacity-set">
						<div className="red-light-box yellow-light">
							<div className="img-nox-red d-flex justify-content-between align-items-center">
								<div> </div> <div className="start-number"></div>
							</div>
							<img className="red-light-img"
								src="/assets/img/yellow-img.png"
								alt="" draggable="false"
							/>
						</div>
					</div>

					<div className="opacity-set">
						<div className="red-light-box green-light">
							<div className="img-nox-red d-flex justify-content-between align-items-center">
								<div></div> <div className="start-number"></div>
							</div>
							<img className="red-light-img"
								src="/assets/img/green-img.png"
								alt="" draggable="false"
							/>
						</div>
					</div>

				</>
			)
		} else if (stars.user_percent >= 50 && stars.user_percent <= 74) {
			return (
				<>
					<div className="red-light-box opacity-set">
						<div className="img-nox-red d-flex justify-content-between align-items-center">
							<div></div> <div className="start-number"></div>
						</div>
						<img className="red-light-img"
							src="/assets/img/red-light.png"
							alt="" draggable="false"
						/>
					</div>
					<div className="">
						<div className="red-light-box yellow-light">
							<div className="img-nox-red d-flex justify-content-between align-items-center">
								<div>you scored {stars.user_percent}% </div> <div className="start-number">{stars.user_star}</div>
							</div>
							<img className="red-light-img"
								src="/assets/img/yellow-img.png"
								alt="" draggable="false"
							/>
						</div>
					</div>

					<div className="opacity-set">
						<div className="red-light-box green-light">
							<div className="img-nox-red d-flex justify-content-between align-items-center">
								<div></div> <div className="start-number"></div>
							</div>
							<img className="red-light-img"
								src="/assets/img/green-img.png"
								alt="" draggable="false"
							/>
						</div>
					</div>

				</>
			)
		} else if (stars.user_percent >= 75) {
			return (
				<>
					<div className="red-light-box opacity-set">
						<div className="img-nox-red d-flex justify-content-between align-items-center">
							<div></div> <div className="start-number"></div>
						</div>
						<img className="red-light-img"
							src="/assets/img/red-light.png"
							alt="" draggable="false"
						/>
					</div>
					<div className="opacity-set">
						<div className="red-light-box yellow-light">
							<div className="img-nox-red d-flex justify-content-between align-items-center">
								<div></div> <div className="start-number"></div>
							</div>
							<img className="red-light-img"
								src="/assets/img/yellow-img.png"
								alt="" draggable="false"
							/>
						</div>
					</div>

					<div className="">
						<div className="red-light-box green-light">
							<div className="img-nox-red d-flex justify-content-between align-items-center">
								<div>you scored  {(stars.user_percent>100)?100:stars.user_percent}% </div> <div className="start-number">{stars.user_star}</div>
							</div>
							<img className="red-light-img"
								src="/assets/img/green-img.png"
								alt="" draggable="false"
							/>
						</div>
					</div>

				</>
			)
		}
	}




	return (
		<>
			<div className="wrapper">
				<div className="fixed-header black-header">
					<div className="container-fluid cf-set">
						<header className="d-flex flex-wrap justify-content-center align-items-center">
							<NavLink
								to="/"
								className="d-flex align-items-center mb-3 mb-md-0 me-md-auto  text-decoration-none"
							>
								<img
									className="brand-logo"
									src="/assets/img/logo-black.svg"
									alt=""
								/>
							</NavLink>
							<LoginMenu />
						</header>
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-center pt-20">
							<div
								className="col-lg-7  wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<ScoreBar />
								<div className=" d-flex justify-content-start mt-5">
									{/* <NavLink  to="/" className="button_su_inner">
                    <span className="button_text_container">
                      {" "}
                      NEXT <i className="bi bi-arrow-right" />
                    </span>
                  </NavLink> */}
									<PrevBtn link="/module-02/chapter-04-screen-14" />
									<button className="button_su_inner" onClick={AddModule}>
										<span className="button_text_container">
											{" "}
											NEXT <i className="bi bi-arrow-right" />
										</span>
									</button>
								</div>


							</div>

							<div
								className="col-lg-3 mt-5 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<img
									width="70%"
									src="/assets/img/jhole-wali-didi.png"
									alt=""
								/>

							</div>






						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Score;
