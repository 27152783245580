import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL,{AdminUrl} from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
const Chapter01Screen18 = () => {

  const [imageUrl,setImageUrl] = useState();

 
  const [images, setImages] = useState([]);


  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const onImageChange = async(e) => {

   
    setImages([...e.target.files]);

   
    
    const questId = '17';
  
    const user_id = user_details.user_id;
    const files = e.target.files;
    const formData = new FormData();


    formData.append("image1", files[0]);
    formData.append("user_id", user_id);
    formData.append("ques_id", questId);

    // console.log(user_details.user_id);

    fetch(BaseURL + "/sexualityImageUploadCh1", {
      method: "POST",
      body: formData,
    }).then((res) => {

      res.json().then((result) => {

        
      });
    });

    // console.log(questId);
  };


  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": "17"
    });
    var config = {
      method: 'post',
      url: BaseURL + '/sexualityChapter01Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;

          setImageUrl(results.ans2);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"
        
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="1/4" percentage="0" star="50" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-2">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
               
               <h3 className="h3 mt-3">Share  </h3>
                <p className="mb-2  mt-3">Let’s see if what we said above is true with a game. Pick any form of media (a TV show or film) which has characters your age. Think of how their life is shown on the screen. How is this similar or different to your experiences? What is not shown? Upload a photo for each of the aspects of your life and the one seen in media given below  </p>
               
                <table className="table table-bordered table-dark table-red table-padding">
                  <tbody>
                    <tr>
                      <td>NAME: ...............</td>
                      <td>TV / FILM: ......................</td>
                   
                    </tr>
                    <tr className="color-dark-red">
                      <td >My day at college/ work/ home </td>
                      <td>TV day at college/ work / home</td>
                    </tr>

                    <tr className="color-dark-red">
                      <td >My friends  </td>
                      <td>TV friends </td>
                    </tr>

                    <tr className="color-dark-red">
                      <td>My parents / or other family members </td>
                      <td>TV parents / or other family members  </td>
                    </tr>

                    <tr className="color-dark-red">
                      <td>My relationships </td>
                      <td>TV relationships </td>
                    </tr>
                    <tr className="color-dark-red">
                      <td>My sexual experience </td>
                      <td>TV sexual experience  </td>
                    </tr>
                    
                
                  </tbody>
                </table>




                {/* <div className="box">
                  <form>
                    <input id="demo1" className="demo1"  type="file" onChange={onImageChange}  placeholder="Select Files" name="demo1[]" />
                  </form>
                </div> */}

                <div class="file-input">
                        <input type="file" name="file-input" id="file-input" class="file-input__input" onChange={onImageChange} />
                        <label class="file-input__label" for="file-input">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" class="svg-inline--fa fa-upload fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
                          </svg>
                          <span>Upload file</span></label>
                          <a href={AdminUrl+"/public/mascul/"+imageUrl} target="__blank">{imageUrl}</a>
                      </div>

                

            
            
               </div>

             
              
             
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
               
                <PrevBtn link="/module-05/chapter-01-screen-17"/>  
                <NextBtn link="/module-05/chapter-01-screen-18n" text="Go Next"/>
                 

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter01Screen18;
