import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";

const Chapter02Screen07 = () => {

	const navigate = useNavigate();

	const [checkedQues2, setCheckedQues2] = useState([]);
	const [checkedQues2Option1, setCheckedQues2Option1] = useState('');
	const [checkedQues2Option2, setCheckedQues2Option2] = useState('');
	const [checkedQues2Option3, setCheckedQues2Option3] = useState('');
	const [checkedQues2Option4, setCheckedQues2Option4] = useState('');
	const [checkedQues2Option5, setCheckedQues2Option5] = useState('');
	const [checkedQues2Option6, setCheckedQues2Option6] = useState('');
	const [checkedQues2Option7, setCheckedQues2Option7] = useState('');
	const [checkedQues2Option8, setCheckedQues2Option8] = useState('');
	const [checkedQues2Option9, setCheckedQues2Option9] = useState('');
	const [checkedQues2Option10, setCheckedQues2Option10] = useState('');




	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;

		checkBoxChecked(res, checked, quesId);
		if (quesId === '2') {
			setCheckedQues2(checkedQues2 => [...checkedQues2, res]);

		}



	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": checkedQues2.join(','),
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},


			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-05/chapter-02-screen-08')
				}
				// navigate('/module-05/chapter-02-screen-08')
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues2.length > 0) {
			return (
				<>
					{/* <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button> */}

					<button className="button_su" >
						<span className="su_button_circle desplode-circle">
						</span>
						<button className="button_su_inner" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>




				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>


				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '2') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues2(ansArr)
		}



	}
	const checkBoxChecked = (val, checked, quesId) => {
		if (quesId === '2') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues2Option1('checked');
				} else {
					setCheckedQues2Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues2Option2('checked');
				} else {
					setCheckedQues2Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues2Option3('checked');
				} else {
					setCheckedQues2Option3('');
				}
			}
			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues2Option4('checked');
				} else {
					setCheckedQues2Option4('');
				}
			}

			if (val === 'option5') {
				if (checked === true) {
					setCheckedQues2Option5('checked');
				} else {
					setCheckedQues2Option5('');
				}
			}

			if (val === 'option6') {
				if (checked === true) {
					setCheckedQues2Option6('checked');
				} else {
					setCheckedQues2Option6('');
				}
			}

			if (val === 'option7') {
				if (checked === true) {
					setCheckedQues2Option7('checked');
				} else {
					setCheckedQues2Option7('');
				}
			}

			if (val === 'option8') {
				if (checked === true) {
					setCheckedQues2Option8('checked');
				} else {
					setCheckedQues2Option8('');
				}
			}

			if (val === 'option9') {
				if (checked === true) {
					setCheckedQues2Option9('checked');
				} else {
					setCheckedQues2Option9('');
				}
			}

			if (val === 'option10') {
				if (checked === true) {
					setCheckedQues2Option10('checked');
				} else {
					setCheckedQues2Option10('');
				}
			}







		}



	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])



	return (
		<>
			<div className="wrapper page-auto bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-1 ">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Engage </h3>
								<p className="mb-2  mt-3"> <b>What are some of the cultural norms you have heard of that make it hard to accept diverse sexualities?</b> </p>


								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle" id="mycirle1"

											defaultValue="option1"
											onClick={saveGenderBased}
											checked={checkedQues2Option1}
											data-quesId="2"
										/>
										<label className="form-check-label" htmlFor="mycirle1">We/ everyone must always be attracted to the opposite sex.</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle" id="mycirle2"

											defaultValue="option2"
											onClick={saveGenderBased}
											checked={checkedQues2Option2}
											data-quesId="2"
										/>
										<label className="form-check-label" htmlFor="mycirle2">People attracted to the same sex, gender or multiple genders need to be “cured” </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle" id="mycirle3"
											defaultValue="option3"
											onClick={saveGenderBased}
											checked={checkedQues2Option3}
											data-quesId="2"

										/>
										<label className="form-check-label" htmlFor="mycirle3">Homosexuality is “unnatural” </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle" id="mycirle4"
											defaultValue="option4"
											onClick={saveGenderBased}
											checked={checkedQues2Option4}
											data-quesId="2"
										/>
										<label className="form-check-label" htmlFor="mycirle4">Homosexuality might be acceptable in the West, but it is not a part of Indian culture/ society </label>
									</div>
								</div>



								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle" id="mycirle5"
											defaultValue="option5"
											onClick={saveGenderBased}
											checked={checkedQues2Option5}
											data-quesId="2"
										/>
										<label className="form-check-label" htmlFor="mycirle5">Homosexuality is illegal </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle" id="mycirle6"
											defaultValue="option6"
											onClick={saveGenderBased}
											checked={checkedQues2Option6}
											data-quesId="2"

										/>
										<label className="form-check-label" htmlFor="mycirle6">Marrying and having children is everyone’s responsibility and is the ‘normal’ thing to do  </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle" id="mycirle7"
											defaultValue="option7"
											onClick={saveGenderBased}
											checked={checkedQues2Option7}
											data-quesId="2"
										/>
										<label className="form-check-label" htmlFor="mycirle7">	If we don’t have children then who will take care of us in our old age</label>
									</div>
								</div>



								<p className="mb-2  mt-3"> These cultural values and attitudes lead to many forms of discrimination against
									<b> LGBTQIA+</b> individuals. <b>LGBTQIA+</b> people suffer lower educational outcomes; they are more likely to be unemployed; they have a lack of access to health services, they are subjected to homophobia and constant stigma and lack essential freedom and rights. Until recently, our Indian laws criminalized homosexuality. </p>
								<p className="mt-3"> Our gender and sexual identities intersect with many other identities that we hold, for example, the religion we are born into, caste, class, physical ability and more. Various identities come together to determine the rights and acceptance given to different sexual experiences/identities/orientations. Some identity intersections are validated and considered acceptable in society, while others are discriminated against and marginalised. For more on intersectional identities, we can refer to the human rights module  </p>

								<NavLink to="/module-03/chapter-01-intro-screen" target="_blank">Hyperlink</NavLink>

								<p className="mb-5"></p>

							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-1-16.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-06" />
								{/* <NextBtn link="/module-05/chapter-02-screen-08" text="Go Next"/> */}

								{/* <button className="button_su">
                  <span className="su_button_circle desplode-circle">
                  </span>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </a>
                </button> */}
								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>



			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							<p className="text-white text-center">Currently, homosexuality is illegal in 67 countries around the world.
							</p>

							<div class=" d-flex justify-content-center mt-3">
								<button className="button_su" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<button onClick={saveAns} className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </button>
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen07;
