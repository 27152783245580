import React from "react";


import Header from "../../header/Header";
import NextBtn from "../../common/NextBtn";
import PrevBtn from "../../common/PrevBtn";

const Chapter01IntroScreen = () => {
	return (
		<div className="wrapper">
			<div
				className="fixed-header"

			>
				<div className="container-fluid cf-set">
					<Header leftChapter="0/5" percentage="0" star="0" />
				</div>
			</div>
			<div className="data-adjust">
				<div className="container-fluid cf-set">
					<div className="row align-items-center justify-content-between pt-20">
						<div
							className="col-lg-8 wow fadeInDown"
							data-wow-duration="1s"
							data-wow-delay="0.2s"
						>

							<div className="para-color-box p-5">
								<h3 className="h3">About the Course</h3>
								<p>This course aims to enable learners’ build a basic understanding about Self, Gender, Sexuality and Human Rights. You will be able to identify the systems and structures which exist in our society and in your lives, and the role they play in maintaining the status quo. You will also learn about Breakthrough’s approach of working with people, systems, structures and institutions to address Gender based discrimination and violence in order to create a gender equal and just world.</p>
							</div>

						</div>
						<div
							className="col-lg-3 mt-m-3 wow fadeInDown"
							data-wow-duration="1s"
							data-wow-delay="0.2s"
						>
							<center>
								<img
									width="60%"
									draggable="false"
									src="/assets/img/chapter1/reading-book.png"
									alt=""
								/>
							</center>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/" />

								<NextBtn link="/module-01/chapter-01-screen-01" text="Go Next" classtype="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	);
};

export default Chapter01IntroScreen;
