import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter05Screen04 = () => {
	return (
		<>
			<>
				<div className="wrapper">
					<div
						className="fixed-header"

					>
						<div className="container-fluid cf-set">
							<Header leftChapter="4/5" percentage="83" star="64" />
						</div>
					</div>
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-4">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mb-4">
										What is Gender-based Violence? UNWomen defines Gender-based
										violence (GBV) as harmful acts directed at an individual or
										a group of individuals based on their gender. It is rooted
										in gender inequality, the abuse of power and harmful norms.
										The term is primarily used to underscore the fact that
										structural, gender-based power differentials place women and
										girls at risk for multiple forms of violence. While women
										and girls suffer disproportionately from GBV, men and boys
										can also be targeted. The term is also sometimes used to
										describe targeted violence against LGBTQI+ populations, when
										referencing violence related to norms of
										masculinity/femininity and/or gender norms. <br />
										GBV can be physical, sexual, verbal, emotional,
										psychological, economic and structural. Different types of
										GBV are Child, Early and Forced marriage, Domestic Violence,
										Dowry, Female Genital Mutilation, Femicide, Gender biased
										sex selection, Gender based discrimination, Sexual Violence-
										Harassment, Assault or Rape, Human Trafficking, Online or
										Digital Violence etc.
									</p>
								</div>
								<div className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s">
									<center>
										<img width="53%" src="/assets/img/md1/md1-shap-5-04.png" alt="" />
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.3s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-01/chapter-05-screen-03" />
									<NextBtn
										link="/module-01/chapter-05-screen-05"
										text="Go Next"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
};

export default Chapter05Screen04;
