import React from "react";

import NextBtn from "../../../common/NextBtn";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";

const Chapter01Screen01 = () => {
	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="0" star="0" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-center pt-20 ">
							<div className="col-lg-9">
								<h1
									className="what-gender wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									{" "}
									What are  <br /> Human Rights?
								</h1>

								<PrevBtn link="/module-03/chapter-01-intro-screen" />
								<NextBtn link="/module-03/chapter-01-screen-02" text="Let’s Start" classType="mt-5 mt-m-3 wow fadeInDown" />

							</div>
							<div
								className="col-lg-3 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="73%"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen01;
