import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter03Screen12 = () => {
	return (
		<>
			<div className="wrapper md-5-bg hp">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="36" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3 mt-m-3">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h4 className="mb-2 h3 mt-3"> <b> Additional Resources  </b></h4>
							</div>
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>



								<a
									href="https://drive.google.com/file/d/1w7yo6ljyS0pnvEO-BOrE7Aohmaa9n5Jf/view"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Social Identity Wheel Handout Revised.pdf</p>
										</div>
									</div>
								</a>



								<a
									href="https://www.youtube.com/watch?v=ViDtnfQ9FHc"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Kimberle Crenshaw piece-to-camera Kimberlé Crenshaw: What is Intersectionality?</p>
										</div>
									</div>
								</a>

								<a
									href="https://www.youtube.com/watch?v=z0hiZwWpWnA&t=183s"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>What is Intersectionality? </p>
										</div>
									</div>
								</a>
								<a
									href="https://feminisminindia.com/2020/07/28/video-intersectional-feminism-101/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Video: Intersectional Feminism 101 </p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2022/03/01/writing-with-fire-khabar-lahariya-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>खबर लहरिया: मेनस्ट्रीम मीडिया को चुनौती देती ये रिपोर्टर्स कर रही हैं शोषित-वंचितों की पत्रकारिता <b>| फेमिनिज़म इन इंडिया </b></p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2022/01/25/marriage-strike-a-trend-by-indian-men-in-support-of-marital-rape-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>#MarriageStrike : भारतीय मर्दों ने चलाया एक ट्रेंड मैरिटल रेप के बचाव में<b>|  | फेमिनिज़म इन इंडिया</b></p>
										</div>
									</div>
								</a>


							</div>
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<a
									href="https://hindi.feminisminindia.com/2022/01/07/bully-bai-step-to-oppress-muslim-women-voice-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>बुल्ली बाई ऐप: आवाज़ उठाती मुस्लिम महिलाओं पर एक और हमला <b>|  | फेमिनिज़म इन इंडिया</b></p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2021/12/24/five-indian-trans-women-who-made-headlines-in2021-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon ">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>जानिए उन ट्रांस महिलाओं के बारे में जो साल 2021 में सुर्खियों में रहीं <b>|  | फेमिनिज़म इन इंडिया</b></p>
										</div>
									</div>
								</a>

								<a
									href="https://www.womankind.org.uk/intersectionality-101-what-is-it-and-why-is-it-important/#:~:text=Intersectionality%20is%20the%20acknowledgement%20that,orientation%2C%20physical%20ability%2C%20etc"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Intersectionality 101: what is it and why is it important? - <br /> <b>Womankind Worldwide </b> </p>
										</div>
									</div>
								</a>

								<a
									href="https://achievebrowncounty.org/2021/05/defining-equity-equality-and-justice/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Defining equity equality and justice</p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2021/12/03/world-disability-day-disabled-children-education-has-many-barriers-in-india-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>भारत में विकलांग बच्चों की शिक्षा को कोविड ने और चुनौतीपूर्ण बनाया <b>| फेमिनिज़म इन इंडिया</b></p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2021/12/13/consent-sex-and-privacy-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>सेक्सुअल कंसेंट और प्राइवेसी कैसे आपस में जुड़े हुए हैं <b> | फेमिनिज़म इन इंडिया</b></p>
										</div>
									</div>
								</a>



							</div>


							{/* <div
                className="col-lg-4 mt-m-3 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="45%"
                    draggable="false"
                    src="/assets/img/md3/md3-shap-1-10.png"
                    alt=""
                  />
                </center>
              </div> */}




						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-03-screen-11n" />
									<NextBtn link="/module-03/chapter-04-screen-01" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen12;
