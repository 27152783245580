import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter04Screen08 = () => {

	const navigate = useNavigate();

	const [checkedQues12, setCheckedQues12] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": checkedQues12,
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-04-screen-09')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}



	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": "12"
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					setCheckedQues12(results.ans)
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	const BtnDisabled = () => {
		if (checkedQues12 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="74" star="52" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-3">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-4">
									In case you haven’t seen one or more of these films, think
									about the films you have seen and whether they pass the
									Bechdel-Wallace test. Share in the space below. Think about a
									total of 3 films you have seen including the ones mentioned
									above for the test.{" "}
								</p>
								<div className="mb-4 positon-submit">
									<textarea
										className="form-control"
										rows={2}
										placeholder="Write Something here....."
										onChange={(e) => setCheckedQues12(e.target.value)}
										data-quesId="12"
										defaultValue={checkedQues12}
									/>
									{/* <button
                    type="button"
                    className="btn btn-primary custom-btn m-auto submit-btn-set"
                  >
                    Submit
                  </button> */}
								</div>
								<p className="mb-0">
									Here’s another example of how we can challenge Patriarchal
									content in popular songs{" "}
								</p>

								{/* <a
                  href="https://www.youtube.com/watch?v=V68Jy8fZcpc"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="d-flex align-items-center mange-icon mt-3">
                    <div>
                      <img src="/assets/img/chapter2/video-icon.png" alt="" />
                    </div>
                    <div>
                      <p>Akshara Centre- Gaana Rewrite</p>
                    </div>
                  </div>
                </a> */}
								

								<a href="https://www.facebook.com/AksharaCentre/videos/1419145204822528/" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p> Gaana Rewrite | Breakthrough{" "}</p>
										</div>
									</div>
								</a>

								<p className="mb-0">
									Rewrite the lyrics of one song which you feel promotes a
									regressive patriarchal mindset. Please mention the original
									song in the space below as well. For example, Breakthrough
									rewrote feminist lyrics to the song- Urvashi Urvashi by<br></br> AR Rahman Urvashi Feminist Remix | Breakthrough

								</p>
							</div>

							<div
								className="col-lg-5 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.1s"
							>

								<iframe width="100%" height="360" src="https://www.youtube.com/embed/V68Jy8fZcpc" title="Urvashi Feminist Remix | Breakthrough" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>






						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-04-screen-07n" />
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen08;
