import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter02Screen09 = () => {
  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="1/5" percentage="36" star="36" />
          </div>
        </div>
        

        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between pt-20 mt-5 mt-m-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                
                <div className="d-flex ">
                <label className="form-check-label" htmlFor="mycirle">
                
                Akash is 22 years old. He leads a group of bikers who race on the streets of Bangalore. He worships Hrithik Roshan and Salman Khan, tough Bollywood stars who save pretty young women from bad people. He himself rarely speaks to women; he thinks they are silly. &nbsp;


                  <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="a"
                      id="mycirle"
                      data-bs-toggle="modal" data-bs-target="#staticBackdropA"
                      
                      
                    />
                    </label>

                 
                </div>

               
                <div className="d-flex ">
                <label className="form-check-label" htmlFor="mycirle">
                
                Naveen is a professor of English and reads widely. He is usually gentle with his wife; but she knows he can get angry if she serves his food a little late or not to his taste. He looks down on other men who treat women badly. He admires women for their stamina - his wife works at a bank and also does the house work so well! &nbsp;
                  <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="a"
                      id="mycirle"
                      data-bs-toggle="modal" data-bs-target="#staticBackdropB"
                      
                      
                    />
                    </label>

                 
                </div>

                <div className="d-flex ">
                <label className="form-check-label" htmlFor="mycirle">
                
                Kevin is a young man who is homesexual. He is gentle and effeminate. He was bullied a lot in school for being like a ‘girl’. Now his partner dominates him; he often shouts at him and sometimes even beats him. &nbsp;
                  <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="a"
                      id="mycirle"
                      data-bs-toggle="modal" data-bs-target="#staticBackdropC"
                      
                    />
                    </label>

                 
                </div>


                <div className="d-flex ">
                <label className="form-check-label" htmlFor="mycirle">
                
                Krishna is a dominant-caste farmer with a small landholding. He and his family work on the farm. He sells the produce and manages the money. He takes care of his wife, buys her gifts on festival days. He knows his son will soon get a good dowry. &nbsp;
                  <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="a"
                      id="mycirle"
                      data-bs-toggle="modal" data-bs-target="#staticBackdropB"
                      
                      
                    />
                    </label>

                 
                </div>

               
               
              </div>
            </div>
          </div>
        </div>
        
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-04/chapter-02-screen-08"/>                  
                
                <NextBtn link="/module-04/chapter-02-screen-10" text="Go Next"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* group d */}
      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <h2 className="h2 text-white ">Group D</h2>


              <p className="text-white">  Men who exhibit qualities that are opposite to those valued in Group A such as physical weakness and expressing emotions etc.
              Dominated and bullied by other men for being weak and different.
              </p>

            


              
          

            </div>
          </div>
        </div>
      </div>

      {/* group A */}

      <div className="modal fade" id="staticBackdropA" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>
              <h2 className="h2 text-white ">Group A</h2>
              <ul class="list-style-set list-circle text-white">
              <li>Enjoys power over women and other subordinate men physically,psychologically and financially</li>
              <li>Believes in being physically strong and aggressive</li>
              <li>Encourages competition, distrust and insecurity</li>
              <li>Sees kindness, compassion and expressing emotion as weakness</li>
              <li>Believes in gender roles- eg: women should do household work and caregiving and men should be breadwinners</li>
              <li>Believes that a household’s honour is in keeping their girls and women safe and protected</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

        {/* group B */}

        <div className="modal fade" id="staticBackdropB" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <h2 className="h2 text-white">Group B</h2>


              <ul class="list-style-set text-white list-circle mt-3">
              <li>Is not overtly abusive or violent</li>
              <li>Accepts things as they are, and goes along with it</li>
              <li>Does not question or challenge if men have more power</li>
              <li>Usually comes from a privileged category belongs to upper caste,upper or middle class, majority religion, is cisgender, heterosexual and able bodied</li>
              <li>Comes across as kind and understanding</li>
              <li>Enjoys the benefits from the culture of Patriarchy and does not want to let go of the privileges Eg. Division of labour between genders </li>
              </ul>

            


              
          

            </div>
          </div>
        </div>
      </div>

        {/* group C */}

        <div className="modal fade" id="staticBackdropC" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <h2 className="h2 text-white ">Group C</h2>


              <ul class="list-style-set text-white list-circle mt-2">
              <li>Comes from an underprivileged category as they do not belong to the dominant caste, class, religion, and ability status</li>
              <li>Believes in patriarchy, but can’t enjoy it fully, marginalized because of being underprivileged</li>
              <li>Admires people who display traits and behaviours described in group A and are often violated by them</li>
              <li>Does not question or challenge these traits and behaviours</li>
              </ul>
            


              
          

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen09;
