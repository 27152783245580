import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen17 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-1">


							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>


								<h3 className="h3 mt-3">Learn  </h3>
								<p className="mb-2  mt-3">The most effective way of setting boundaries in intimate relationships is through communication. When partners communicate their wants and limits assertively, honestly and openly, it strengthens the trust and relationship. What does it mean to be assertive in communication? Let us see!  </p>



								<table className="table table-bordered table-dark table-red table-padding mt-4 text-center">
									<tbody>
										<tr>
											<td className="td-blue width-20">PASSIVE</td>
											<td className="td-green">ASSERTIVE </td>
											<td className="td-blue">AGGRESSIVE </td>
										</tr>
										<tr>
											<td className="td-blue width-20">  Giving in and saying yes  <br /> Putting others’ feelings and concerns <br /> before our own <br /> Not voicing our  feelings/ wants adequately</td>
											<td className="td-green"> Standing up for our rights while respecting others  <br /> Asking for what we want in a straightforward manner  <br /> Expressing our emotions and listening to others’ </td>
											<td className="td-blue"> Thinking of ourselves first at others’ expense  <br /> Dominating others  <br />Using threats, intimidation or force to get our way </td>
										</tr>


									</tbody>
								</table>

								<p className="mb-2  mt-3">When we are assertive, we can stand up for how we feel and express what we want. In practice, this also means respecting others.  </p>

								{/* <p className="mb-2  mt-3">We may have seen or know of, in real life or in films/TV, cases where individuals are in relationships that do not have the characteristics we discussed above! Relationships between partners are often disrespectful, toxic, unequal and even abusive.  </p> */}
							</div>

							{/* <div
                className="col-lg-10 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="100%"
                    draggable="false"
                    src="/assets/img/md5/chap02-2/4.jpg"
                    alt=""
                  />
                </center>
              </div>

                */}



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-16" />
								<NextBtn link="/module-05/chapter-03-screen-18" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen17;
