import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Module04IntroScreen = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="0" star="0" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-4">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h4>
									{" "}
									<b> Usage of Language:</b>
								</h4>
								<div className="para-color-box mt-2">

									<p className="mb-2 mt-3">The language around gender and sexuality is complex and constantly evolving. For example, the words natural/unnatural, normal/abnormal, healthy/unhealthy, etc are subjective and cultural. In the context of gender and sexuality, society has often used words like unnatural, abnormal, unhealthy, etc. to dismiss gender identities and sexualities that do not fit “norms as prescribed” by society at large. We will explore these aspects in this module. </p>
									<p className="mb-2 mt-3">We will refrain from using this kind of language and encourage you to do the same since the usage of  it promotes and perpetuates stereotypes that further discriminate against and marginalise people who don’t fit the perceptions of “normal and/ or natural”.</p>
									<p className="mb-2 mt-3">Moreover, we actively need to question what we consider natural, normal, and healthy. Are the spectacles we wear, cars we drive, computers we use natural? There is no fixed definition of these words. What one person may consider normal may not be for someone else. It may also change according to cities, culture, different groups of people, and time. </p>
								</div>
							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="60%"
										draggable="false"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.3s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-01/score" />

									<NextBtn link="/module-05/chapter-01-screen-01" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Module04IntroScreen;
