import React, { useState, useEffect } from "react";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Header from "../../../header/Header";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter01Screen16 = () => {

	const navigate = useNavigate();
	const [checkedQues12_1, setCheckedQues12_1] = useState('');
	const [checkedQues12_2, setCheckedQues12_2] = useState('');
	const [checkedQues12_3, setCheckedQues12_3] = useState('');
	const [checkedQues12_4, setCheckedQues12_4] = useState('');
	const [checkedQues12_5, setCheckedQues12_5] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques12": {
					"ans": checkedQues12_3 + "," + checkedQues12_4 + "," + checkedQues12_5,
					"ques_id": "12"
				}




			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-05/chapter-01-screen-17')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": "12"
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {


				if (response.data.success === true) {
					const results = response.data.data.chapter.ans;
					const res = results.split(",");
					res.map((elem, i) => {
						console.log(i);

						if (i === 0) {
							setCheckedQues12_3(elem)
						}
						if (i === 1) {
							setCheckedQues12_4(elem)
						}
						if (i === 2) {
							setCheckedQues12_5(elem)
						}
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	const BtnDisabled = () => {
		if (checkedQues12_3 !== '' || checkedQues12_4 !== '' || checkedQues12_5 !== ''
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/2" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								{/* <h3 className="h3">Share:  </h3> */}
								<p className="mb-2  mt-3">Stereotypes and norms around relationships also influence us and our interactions in relationships. Let’s think of at least 3 examples of this you have heard of, two are given:  </p>
								<ul className="list-style-set list-input mt-3 mb-3">
									<li>1. Men must pay for everything in a relationship
										{/* <input type="text" placeholder 
                  onChange={(e)=>{setCheckedQues12_1(e.target.value)}}
                      data-quesId="12"
                      defaultValue={checkedQues12_1}
                      className="input-without-style form-control" /> */}
									</li>
									<li>2. Women should not make the first move in relationships
										{/* <input type="text" 
                  onChange={(e)=>{setCheckedQues12_2(e.target.value)}}
                      data-quesId="12"
                      defaultValue={checkedQues12_2}
                      placeholder className="input-without-style form-control" /> */}
									</li>
									<li>3. <input type="text"
										onChange={(e) => { setCheckedQues12_3(e.target.value) }}
										data-quesId="12"
										defaultValue={checkedQues12_3}
										placeholder className="input-without-style form-control" /></li>
									<li>4. <input type="text"
										onChange={(e) => { setCheckedQues12_4(e.target.value) }}
										data-quesId="12"
										defaultValue={checkedQues12_4}
										placeholder className="input-without-style form-control" /></li>
									<li>5. <input type="text"
										onChange={(e) => { setCheckedQues12_5(e.target.value) }}
										data-quesId="12"
										defaultValue={checkedQues12_5}
										placeholder className="input-without-style form-control" /></li>
								</ul>



							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="67%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-1-16.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-01-screen-15" />
								{/* <NextBtn link="/module-05/chapter-01-screen-17" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen16;
