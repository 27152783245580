import React from 'react'
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter05Screen16 = () => {
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="100" star="90" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2">Additional Resources </h2>


								<a href="https://www.youtube.com/watch?v=gsEyZ47df3o" target="_blank">
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>What Is Bystander Intervention? | Breakthrough India - YouTube </p>
										</div>
									</div>
								</a>

								<a href="https://www.youtube.com/watch?v=8zk8JyaPoXo" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Decoding Bystander Behaviour | Breakthrough India</p>
										</div>
									</div>
								</a>

								<p className="mb-2 mt-3">
									<b>Detailed report can be found here in both English and Hindi</b>{" "}
								</p>



								<a href="https://www.youtube.com/watch?v=E5U2udidWl8" target="_blank">
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Episode 3: घरेलू हिंसा को रोके कानून की मदद से :{" "}</p>
										</div>
									</div>
								</a>

								<a href="https://inbreakthrough.org/bystander-report/" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Decoding Bystander Behaviour | Breakthrough India</p>
										</div>
									</div>
								</a>

								<a href="https://creaworld.org/wp-content/resources/brochures-and-leaflets/hindi-brochures/Know-Your-Rights-hindi.pdf" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>HINDI VIOLENCE BRO-FINAL (creaworld.org)</p>
										</div>
									</div>
								</a>


								<a href="https://www.youtube.com/watch?v=_uO7dWscZV0&feature=youtu.be" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Soch Badlein Baat Badlegi - Hindi, cricket- Swayam:{" "}</p>
										</div>
									</div>
								</a>

								<a href="https://www.youtube.com/watch?v=3FLvz7PZl5U" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Satyamev Jayate S3 | Unsung Heroes | Ep on LGBT Issues | Part of the family:</p>
										</div>
									</div>
								</a>


							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img width="78%" src="/assets/img/md1/md1-shap-5-16.png" alt="" />
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-05-screen-15" />
								<NextBtn
									link="/module-01/module-01-finalquiz-screen-01"
									text="Go Next"
								/>

							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	)
}

export default Chapter05Screen16