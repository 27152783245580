import React from "react";
import { NavLink } from "react-router-dom";

const PrevBtn = (props) => {
  return (
    <>
      <button className="button_su ">
        <span className="su_button_circle"></span>
        <NavLink to={props.link} className="button_su_inner">
          <span className="button_text_container">
            <i className="bi bi-arrow-left" /> Previous{" "}
          </span>{" "}
        </NavLink>
      </button>
    </>
  );
};

export default PrevBtn;
