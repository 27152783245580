import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen29 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3 mt-3">Learn </h3>
								<p className="mb-2  mt-3">Girls and women desire pleasure, feel attraction, and want sexual and physical gratification in their own right. We have to challenge the idea wanting sex or engaging in physical relationships by women is “bad” and fight the stigma attached to it. This will make it possible for women to form, articulate and experience their own desires and pleasures. Desire and pleasure has to be understood from the lens of consent, equality and mutuality. </p>

							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="73%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-3.png"
										alt=""
									/>
								</center>
							</div>




						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-28" />
								<NextBtn link="/module-05/chapter-03-screen-30" text="Go Next" />

							</div>
						</div>
					</div>
				</div>
			</div>




		</>
	);
};

export default Chapter03Screen29;
