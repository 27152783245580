import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen12 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-1">


							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>


								<table className="table table-bordered table-text-color-black table-dark table-red table-padding mt-4 text-center">
									<tbody>
										<tr>
											<td className="td-yellow width-20">Feelings</td>
											<td className="td-green">Partners feel safe, comfortable and strong enough to say how they feel </td>
											<td className="td-red"> One person feels uncomfortable or is afraid to say how they feel </td>
										</tr>
										<tr>
											<td className="td-yellow width-20">Communication</td>
											<td className="td-green"> Partners listen to each other’s point of view and make decisions together</td>
											<td className="td-red"> One person ignores the other and does not respect their opinion</td>
										</tr>
										<tr>
											<td className="td-yellow width-20">Disagreements</td>
											<td className="td-green"> Partners have an equal say when they disagree and try to work out a solution together</td>
											<td className="td-red">Disagreements turn into fights, yelling, criticism and harsh words</td>
										</tr>
										<tr>
											<td className="td-yellow width-20">Arguments</td>
											<td className="td-green">Partners try not to talk harshly and respect differences</td>
											<td className="td-red">Arguments escalate as either partners belittle and talk to each other harshly</td>
										</tr>
										<tr>
											<td className="td-yellow width-20">Intimacy</td>
											<td className="td-green">Partners are honest about how they feel about intimacy with each other No one is pressured to do anything they do not want to do</td>
											<td className="td-red">One partner’s needs and wants and more important than the others One partner goes along with things they may not be comfortable with</td>
										</tr>

									</tbody>
								</table>

								<p className="mb-2  mt-3">We may have seen or know of, in real life or in films/TV, cases where individuals are in relationships that do not have the characteristics we discussed above! Relationships between partners are often disrespectful, toxic, unequal and even abusive.  </p>
							</div>

							{/* <div
                className="col-lg-10 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="100%"
                    draggable="false"
                    src="/assets/img/md5/chap02-2/4.jpg"
                    alt=""
                  />
                </center>
              </div>

                */}



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-11" />
								<NextBtn link="/module-05/chapter-03-screen-13" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen12;
