import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";

const Chapter01Screen07 = () => {
	const navigate = useNavigate();
	const [checkedQues4, setCheckedQues4] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4,
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-02/chapter-01-screen-06')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const checkedVal = (param, ans) => {
		if (param === '4') {
			setCheckedQues4(ans)
		}




	}


	const BtnDisabled = () => {
		if (checkedQues4 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper md-5-bg">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="5" star="4" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-top justify-content-center pt-20 mt-5 mt-m-3">
							<div className="col-lg-7 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">


								<ul className="without-list list-number ml-1 mt-1">
									<li>Right to Free Movement In and Out of the Country</li>
									<li>Right to Asylum in Other Countries from Persecution</li>
									<li>Right to a Nationality and the Freedom to Change It</li>
									<li>Right to Marriage and Family</li>
									<li>Right to Own Property</li>
									<li>Freedom of Belief and Religion </li>
									<li>Freedom of Opinion and Information</li>
									<li>Right to Peaceful Assembly and Association</li>
									<li>Right to Participate in Government and in Free Elections</li>
									<li>Right to Social Security </li>
									<li>Right to Desirable Work and to Join Trade Unions</li>
									<li>Right to Rest and Leisure</li>
									<li>Right to Adequate Living Standard</li>
									<li>Right to Education</li>
									<li>Right to Participate in the Cultural Life of Community</li>
									<li>Right to a Social Order that Articulates this Document</li>
									<li>Community Duties Essential to Free and Full Development</li>
									<li>Freedom from State or Personal Interference in the above Rights</li>
								</ul>





							</div>
							<div className="col-lg-4 mt-m-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
								<center>
									<img width="60%" src="/assets/img/md3/md3-shap-3-7.png" alt="" />
								</center>
							</div>
						</div>

					</div>
				</div>
			</div>


			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-03/chapter-01-screen-06" />

								<NextBtn link="/module-03/chapter-01-screen-08" text="Go Next" />
								{/* <BtnDisabled/> */}

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen07;
