import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import PrevBtn from "../../../common/PrevBtn";
import Header from '../../../header/Header';
import { useDrop } from "react-dnd";
// import Image from './Image';
import Chapter04Screen13_1 from './Chapter04Screen13_1';
import axios from 'axios';
import BaseURL from '../../../config/Baseurl';
import { useEffect } from 'react';
import NextBtn from '../../../common/NextBtn'
import { useDrag } from "react-dnd";

const Chapter04Screen13 = () => {

	const navigate = useNavigate();
	const [PictureList, setPictureList] = useState([
		{ "id": "1", "name": "When you refuse to do certain sexual acts, your partner puts you down and threatens to break up." },
		{ "id": "2", "name": "You are afraid of your partner so you avoid saying/ doing things that could make them angry." },
		{ "id": "3", "name": "You feel safe being completely honest and open about your sexual feelings with your partner." },
		{ "id": "4", "name": "Your partner threatens to hurt themselves during arguments." },
		{ "id": "5", "name": "Your partner and you decide together how you spend your time and what you do." },
		{ "id": "6", "name": "Your partner gets jealous of you spending time with friends and makes you feel guilty." },
		{ "id": "7", "name": "You and your partner are protected against STIs/ unplanned pregnancies." },

	]);




	const [affirmingArray, setAffirmingArray] = useState([]);
	const [abusiveArray, setAbusiveArray] = useState([]);
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const [{ isOver }, affirmdrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addSamarArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const [{ isShasOver }, abusivedrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addSahasArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));


	// Add To Array Samar
	const addSamarArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setAffirmingArray((affirmingArray) => [...affirmingArray, picture_List[0]]);
	};

	// Add To sahas Array
	const addSahasArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setAbusiveArray((abusiveArray) => [...abusiveArray, picture_List[0]]);
	};


	const onItemRemoveAffirming = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = affirmingArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setAffirmingArray(affirmingArray =>
			affirmingArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};

	const onItemRemoveAbusive = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = abusiveArray.filter((item) => item.id === id);
		console.log(_item);
		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setAbusiveArray(abusiveArray =>
			abusiveArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques13": {
					"ans": JSON.stringify(affirmingArray),
					"ans2": JSON.stringify(abusiveArray),
					"ques_id": "13"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-05/chapter-04-screen-14');
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,

			"ques_id": "13"
		});

		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				console.log(response.data);
				if (response.data.success === true) {
					const ans = JSON.parse(response.data.data.chapter.ans)
					const ans2 = JSON.parse(response.data.data.chapter.ans2)
					setAffirmingArray(ans);
					setAbusiveArray(ans2);
					if (ans.length > 0) {
						ans.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans2.length > 0) {
						ans2.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}


				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);


	const BtnDisabled = () => {
		if (abusiveArray.length !== 0 && affirmingArray.length !== 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper bg-bd-2 hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 ">


							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<p className="mb-2 ">Based on all that we have learnt, can we identify which of these relationship behaviours are abusive? Which are affirming and positive? Drag the statement into the box you think fits best!  </p>
								<table className="table table-bordered table-white table-dark table-red table-padding mt-1">
									<tbody>
										<tr>
											<td className="td-green">AFFIRMING </td>
											<td className="td-red">ABUSIVE </td>
										</tr>
										<tr className="drag-table table-width-set">
											<td>
												<div className="dragarea" ref={affirmdrop}>
													<ul>

														{affirmingArray.map((e) => {

															return (
																<>
																	<li>

																		{e.name}
																		<button
																			onClick={onItemRemoveAffirming}
																			data-id={e.id}
																			type="button"
																			className="btn-close"
																			aria-label="Close"
																		></button>
																	</li>

																</>
															)


														})}
													</ul>
												</div>
											</td>
											<td>
												<div className="dragarea " ref={abusivedrop}>
													<ul>

														{abusiveArray.map((e) => {

															return (
																<>
																	<li>

																		{e.name}
																		<button
																			onClick={onItemRemoveAbusive}
																			data-id={e.id}
																			type="button"
																			className="btn-close"
																			aria-label="Close"
																		></button>
																	</li>

																</>
															)


														})}
													</ul>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>



							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<ul className="list-style-set list-circle mb-1">
									{
										PictureList.map((e) => {


											return (

												<Chapter04Screen13_1 key={e.id} id={e.id} imageName={e.name} />


											)

										})
									}


								</ul>
							</div>



							{/* <div
                className="col-lg-10 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="100%"
                    draggable="false"
                    src="/assets/img/md5/chap02-2/4.jpg"
                    alt=""
                  />
                </center>
              </div>

                */}



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-12" />
								{/* <NextBtn link="/module-05/chapter-04-screen-14" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen13;
