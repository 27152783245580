import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";


const Chapter01Screen03 = () => {

	const navigate = useNavigate();
	const [checkedQues2_1, setCheckedQues2_1] = useState('');
	const [checkedQues2_2, setCheckedQues2_2] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "given-" + checkedQues2_1 + ",chosen-" + checkedQues2_2,
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-02/chapter-01-screen-04')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const checkedVal = (param, ans) => {
		if (param === '2') {
			const __ans = ans.split(',');
			const given = __ans[0].split('-');
			const chosen = __ans[1].split('-');

			setCheckedQues2_1(given[1])
			setCheckedQues2_2(chosen[1])
		}




	}


	const BtnDisabled = () => {
		if (checkedQues2_1 !== '' && checkedQues2_2 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="2" star="1" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-top justify-content-center pt-20 mt-5 mt-m-3">
							<div className="col-lg-8 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.1s', animationName: 'fadeInDown' }}>


								<h2 className="h3 mt-2">What are Human Rights?</h2>
								<p className="mt-3">Human rights are rights we have simply because we exist as human beings - they are not granted by any state. These universal rights are inherent to us all, regardless of nationality, sex, national or ethnic origin, color, religion, language, or any other status. They range from the most fundamental - the right to life - to those that make life worth living, such as the rights to food, education, work, health, and liberty.  Human rights are Universal and Inalienable, Indivisible and Interdependent, Equal and Non- discriminatory </p>
								<p><b>Universal:</b> This means that rights are not a privilege that some people have, but something all people are entitled to regardless of where they live, what caste, religious, class, gender identity, sexual orientation/ identity, ability status they belong to</p>

							</div>
							<div className="col-lg-4 mt-m-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
								<center>
									<img width="100%" src="/assets/img/md3/md3-shap-3-3.png" alt="" />
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-01-screen-02" />
									<NextBtn link="/module-03/chapter-01-screen-04" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen03;



