import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
const Chapter02Screen05 = () => {
  const navigate = useNavigate();
  const [checkedQues5_1, setCheckedQues5_1] = useState('');
  const [checkedQues5_2, setCheckedQues5_2] = useState('');
  const [checkedQues5Val, setCheckedQues5Val] = useState('');
  const [checkedQues6_1, setCheckedQues6_1] = useState('');
  const [checkedQues6_2, setCheckedQues6_2] = useState('');
  const [checkedQues6Val, setCheckedQues6Val] = useState('');
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    checkedVal(quesId,e.target.value)
  }
  // console.log(checkedQues5);
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",         
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",         
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans":checkedQues5Val,
          "ques_id": "5"
        },
        "ques6": {
          "ans":  checkedQues6Val,
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": "",
          "ques_id": "10"
        },
        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        },
        "ques13": {
          "ans": "",
          "ques_id": "13"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/SelfChapter02',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-02/chapter-02-screen-06')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues5Val !== '' && checkedQues6Val !== '') {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {
    if (param === '5') {
      if (ans === '1') {
        setCheckedQues5_1('checked')
        setCheckedQues5_2('')
      } else {
        if (ans === '2') {
          setCheckedQues5_2('checked')
          setCheckedQues5_1('')
        }
      }
      setCheckedQues5Val(ans)
    }
    if (param === '6') {
      if (ans === '1') {
        setCheckedQues6_1('checked')
        setCheckedQues6_2('')
      } else {
        if (ans === '2') {
          setCheckedQues6_2('checked')
          setCheckedQues6_1('')
        }
      }
      setCheckedQues6Val(ans)
    }
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfChapter02Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          
          results.map((result) => {
           
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  return (
    <>
      <div className="wrapper bg-bd-2">
        <div
          className="fixed-header"
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="1/4" percentage="13" star="13" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between pt-20 mt-4">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h4>
                  Let’s see what you will do in some of the situations given
                  below- <br />
                  you can choose only one option.
                </h4>
                <p className="mb-2 mt-3 pn">
                <span><b>1.</b> </span>
                  <b>
                    Your best friend is stalking someone from their class.
                    They get offended on being told what to do by others. What
                    will you do?
                  </b>{" "}
                </p>
                <div className="wiout-border-table without-bg">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>a. You will tell them that they shouldn’t do it</td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="m"
                                id="inlineRadio1"
                                defaultValue="1"
                                checked={checkedQues5_1}
                                onClick={saveRadio}
                                data-quesId="5"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          b. You value your friendship with them a lot and do
                          not want to offend them so you will not say anything
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="m"
                                id="inlineRadio1"
                                defaultValue="2"
                                checked={checkedQues5_2}
                                onClick={saveRadio}
                                data-quesId="5"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="mb-2 mt-3 pn">
                <span><b>2.</b> </span>
                  <b>
                    You have a friend’s birthday party which involves a night
                    stay, they really want you to be at the party but your
                    parents have not allowed you to go. A friend of yours
                    offered to help you sneak out of the house at night but
                    there is a risk that your parents might find out and punish
                    you. What will you do?
                  </b>{" "}
                </p>
                <div className="wiout-border-table without-bg">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          a. You will sneak out with your friend at night to be
                          at the party and sneak back in early before your
                          parents wake up
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="b"
                                id=""
                                defaultValue="1"
                                checked={checkedQues6_1}
                                onClick={saveRadio}
                                data-quesId="6"
                              />
                              <label className="form-check-label" htmlFor="" />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          b. You will honour your parents decision and not go to
                          the party
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="b"
                                id=""
                                defaultValue="2"
                                checked={checkedQues6_2}
                                onClick={saveRadio}
                                data-quesId="6"
                              />
                              <label className="form-check-label" htmlFor="" />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
              <PrevBtn link="/module-02/chapter-02-screen-04"/>                  
                {/* <NextBtn link="/module-02/chapter-02-screen-06" text="Go Next"/> */}
                <BtnDisabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Chapter02Screen05;
