import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import BaseURL from "../../../config/Baseurl";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Chapter03Screen03 = () => {
	const navigate = useNavigate();
	const [checkedQues6Opt1, setCheckedQues6Opt1] = useState('');
	const [checkedQues6Opt2, setCheckedQues6Opt2] = useState('');
	const [checkedQues6Opt3, setCheckedQues6Opt3] = useState('');
	const [checkedQues6Val, setCheckedQues6Val] = useState('');

	const [checkedQues7Opt1, setCheckedQues7Opt1] = useState('');
	const [checkedQues7Opt2, setCheckedQues7Opt2] = useState('');
	const [checkedQues7Opt3, setCheckedQues7Opt3] = useState('');

	const [checkedQues8Opt1, setCheckedQues8Opt1] = useState('');
	const [checkedQues8Opt2, setCheckedQues8Opt2] = useState('');
	const [checkedQues8Opt3, setCheckedQues8Opt3] = useState('');
	const [checkedQues8Val, setCheckedQues8Val] = useState('');
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		checkedVal(quesId, e.target.value)
	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": checkedQues6Val,
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": checkedQues8Val,
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-03-screen-03n4')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	// sdaSD


	const checkedVal = (param, ans) => {
		if (param === '6') {

			if (ans === 'option1') {
				setCheckedQues6Opt1('checked')
				setCheckedQues6Opt2('')
				setCheckedQues6Opt3('')
			} else if (ans === 'option2') {

				setCheckedQues6Opt1('')
				setCheckedQues6Opt2('checked')
				setCheckedQues6Opt3('')

			} else if (ans === 'option3') {
				setCheckedQues6Opt1('')
				setCheckedQues6Opt2('')
				setCheckedQues6Opt3('checked')

			}

			setCheckedQues6Val(ans)
		}

		// if (param === '7') {

		//   if (ans === 'option1') {
		//     setCheckedQues7Opt1('checked')
		//     setCheckedQues7Opt2('')
		//     setCheckedQues7Opt3('')
		//   } else if(ans === 'option2') {

		//     setCheckedQues7Opt1('')
		//     setCheckedQues7Opt2('checked')
		//     setCheckedQues7Opt3('')

		//   } else if(ans === 'option3') {
		//     setCheckedQues7Opt1('')
		//     setCheckedQues7Opt2('')
		//     setCheckedQues7Opt3('checked')

		//   }
		// }

		if (param === '8') {

			if (ans === 'option1') {
				setCheckedQues8Opt1('checked')
				setCheckedQues8Opt2('')
				setCheckedQues8Opt3('')
			} else if (ans === 'option2') {

				setCheckedQues8Opt1('')
				setCheckedQues8Opt2('checked')
				setCheckedQues8Opt3('')

			} else if (ans === 'option3') {
				setCheckedQues8Opt1('')
				setCheckedQues8Opt2('')
				setCheckedQues8Opt3('checked')

			}
			setCheckedQues8Val(ans)
		}






	}


	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	const BtnDisabled = () => {
		if (checkedQues6Val !== '' && checkedQues8Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper hp">
				<div
					className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="2/5" percentage="44" star="24" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.1s"
							>
								<h2 className="h2">Learn</h2>
								{/* <p className="mt-3"> </p> */}
								<p>
									Jyoti’s father being the decision maker in the family and
									supporting the son to pursue his aspiration but not daughter
									(initially) is rooted in Patriarchy.
								</p>
								<hr></hr>
								<h4 className="h3 mt-3">Let’s watch a video to understand what is Patriarchy</h4>
								<a
									href="https://www.youtube.com/watch?v=tZzPCSnHq9I"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-1">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>आसान भाषा में समझिए पितृसत्ता (Patriarchy) क्या है? I  फेमिनिज़म इन इंडिया{" "}</p>
										</div>
									</div>
								</a>

								{/* <p><b>Please mark which of the following statements are true.</b></p>
                <div className="wiout-border-table without-bg table-padding-0">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <p className="pn "> <span>1.&nbsp;&nbsp;a.</span> Patriarchy is a social system where men have more
                                power and privileges than women</p>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques1option1"
                                id="ture"                     
                               
                                onClick={saveRadio}
                                checked={checkedQues6Opt1}
                                defaultValue="option1"
                                data-quesId="6"
                                
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mycirle1"
                              ></label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <p className="pn ml-1" > <span>b.</span> Patriarchy is a social system where both women and men
                          have equal power and privileges</p>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques1option2"
                                id="ques1option2"
                                defaultValue="option2"
                                data-quesId="6"
                                onClick={saveRadio}
                                checked={checkedQues6Opt2}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mycirle1"
                              ></label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <p className="pn ml-1"> <span>c.</span> Patriarchy is a social system where women have more
                          power and privileges than men</p>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques1option3"
                                id="mycirle1"
                                defaultValue="option3"
                                data-quesId="6"
                                onClick={saveRadio}
                                checked={checkedQues6Opt3}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mycirle1"
                              ></label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="padding-set-table">
                        <p className="pn"> <span>2.&nbsp;&nbsp;a.</span> In a patriarchal society men are responsible for
                          household work, childcare and taking care of elders</p>
                        </td>
                        <td className="padding-set-table">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques7option1"
                                id="ques7option1"
                                defaultValue="option1"
                                data-quesId="8"
                                onClick={saveRadio}
                                checked={checkedQues8Opt1}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mycirle1"
                              ></label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <p className="pn ml-1"> <span>b. </span>In a patriarchal society women are responsible for
                          household work, childcare and taking care of elders</p>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques7option2"
                                id="ques7option2"
                                defaultValue="option2"
                                data-quesId="8"
                                onClick={saveRadio}
                                checked={checkedQues8Opt2}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mycirle1"
                              ></label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <p className="pn ml-1"> <span>c. </span> In a patriarchal society both women and men are
                          responsible for household work, childcare and taking
                          care of elders{" "}</p>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                id="ques7option3"
                                defaultValue="option3"
                                data-quesId="8"
                                onClick={saveRadio}
                                checked={checkedQues8Opt3}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="mycirle1"
                              ></label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div> */}




							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="83%"
										src="/assets/img/md1/md1-shap-3-5.png"
										alt=""
									/>
								</center>
							</div>








						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-03-screen-02n" />

								<NextBtn
									link="/module-01/chapter-03-screen-03n4"
									text="Go Next"
								/>
								{/* <BtnDisabled/> */}

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen03;
