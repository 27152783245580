import React, { useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import { useState } from "react";


const Chapter05Screen05 = () => {
	const navigate = useNavigate();
	const [ans, setAns] = useState('');
	const [ans2, setAns2] = useState('');
	const [ans3, setAns3] = useState('');
	const [ans4, setAns4] = useState('');
	const [ans5, setAns5] = useState('');
	// const [ans6,setAns6] = useState('');
	const [images, setImages] = useState();
	const user_details = JSON.parse(localStorage.getItem('user_details'));


	const saveAns = () => {
		// console.log(images);
		var FormData = require('form-data');
		// var fs = require('fs');
		var data = new FormData();
		data.append('user_id', user_details.user_id);
		data.append('answers[ques3][ans]', ans);
		data.append('answers[ques3][ans2]', ans2);
		data.append('answers[ques3][ques_id]', '3');
		data.append('answers[ques3][ans3]', ans3);
		data.append('answers[ques3][ans4]', ans4);
		data.append('answers[ques3][ans5]', ans5);
		if (images != undefined) {

			data.append('answers[ques3][ans6]', images[0]);
		}


		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter05',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				// ...data.getHeaders()
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-04/chapter-05-screen-06')
				}
				console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter05Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans, result.ans2, result.ans3, result.ans4, result.ans5, result.ans6)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	const checkedVal = (param, ans, ans2, ans3, ans4, ans5, ans6) => {

		if (param === '3') {
			setAns(ans)
			setAns2(ans2)
			setAns3(ans3)
			setAns4(ans4)
			setAns5(ans5)
			setImages(ans6)

		}


	}





	const BtnDisabled = () => {
		if (ans !== '' && ans2 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="75" star="75" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between ">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className=" mb-1">
									{" "}
									<b>
										What are 5 messages you have heard which you think
										contribute to gender equitable and inclusive masculinity.
										(You can upload your own chart if you can think of more)
									</b>{" "}
								</h2>
							</div>
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="mt-3 positon-submit">
									<textarea
										className="form-control"
										rows={1}
										placeholder="Write Something here....."
										defaultValue={ans}
										onChange={(e) => setAns(e.target.value)}
									/>
								</div>
								<div className="mt-3 positon-submit">
									<textarea
										className="form-control"
										rows={1}
										placeholder="Write Something here....."
										defaultValue={ans2}
										onChange={(e) => setAns2(e.target.value)}
									/>
								</div>
								<div className="mt-3 positon-submit">
									<textarea
										className="form-control"
										rows={1}
										placeholder="Write Something here....."
										defaultValue={ans3}
										onChange={(e) => setAns3(e.target.value)}
									/>
								</div>
								<div className="mt-3 positon-submit">
									<textarea
										className="form-control"
										rows={1}
										placeholder="Write Something here....."
										defaultValue={ans4}
										onChange={(e) => setAns4(e.target.value)}
									/>
								</div>
								<div className="mt-3 positon-submit">
									<textarea
										className="form-control"
										rows={1}
										placeholder="Write Something here....."
										defaultValue={ans5}
										onChange={(e) => setAns5(e.target.value)}
									/>
								</div>
								<div className="mt-4">
									<input type="file"
										onChange={(e) => setImages(e.target.files)}
									/>
								</div>
							</div>
							<div
								className="col-lg-5 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="67%"
										draggable="false"
										src="/assets/img/md4/md4-shap-5-5.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-05-screen-04" />

									{/* <NextBtn link="/module-04/chapter-05-screen-06" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen05;
