import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import BaseURL, { AdminUrl } from "../../../config/Baseurl";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Chapter02Screen17 = () => {
  const navigate = useNavigate();
  const [checkedQues8, setCheckedQues8] = useState();

  const [images, setImages] = useState([]);
  const [imageurl1, setImageUrl1] = useState();

  const user_details = JSON.parse(localStorage.getItem("user_details"));

  const onImageChange1 = async (e) => {
    const imageno = e.target.getAttribute("data-image");

    setImages([...e.target.files]);

    const questId = "8";

    const user_details = JSON.parse(localStorage.getItem("user_details"));
    const user_id = user_details.user_id;
    const files = e.target.files;
    const formData = new FormData();

    formData.append("image1", files[0]);

    formData.append("user_id", user_id);
    formData.append("ques_id", questId);

    // console.log(user_details.user_id);

    fetch(BaseURL + "/sexualityImageUploadCh2", {
      method: "POST",
      body: formData,
    }).then((res) => {
      res.json().then((result) => {});
    });

    // console.log(questId);
  };

  const saveAns = () => {
    var data = JSON.stringify({
      user_id: user_details.user_id,
      answers: {
        ques8: {
          ans: checkedQues8,
          ques_id: "8",
        },
      },
    });
    var config = {
      method: "post",
      url: BaseURL + "/SexualityChapter02",
      headers: {
        Authorization: `Bearer ${user_details.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate("/module-05/chapter-02-screen-21");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const checkedVal = (param, ans, ans2, ans3, ans4, ans5) => {
    if (param === "8") {
      setCheckedQues8(ans);
    }
  };
  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_details.user_id,
      ques_id: "",
    });
    var config = {
      method: "post",
      url: BaseURL + "/sexualityChapter02Result",
      headers: {
        Authorization: `Bearer ${user_details.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            if (result.ques_id === 8) {
              setImageUrl1(result.ans2);
            }

            checkedVal(result.ques_id.toString(), result.ans);
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const BtnDisabled = () => {
    if (checkedQues8 !== "") {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button
              className="button_su_inner"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="2/4" percentage="50" star="50" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-3">
              <div
                className="col-lg-8 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h3 className="h3 mt-3">Share</h3>

                {/* <p className="mb-2  mt-3">Based on all we have learnt so far, what are some ways that we can increase awareness on <b>LGBTQIA+</b> in our family or community? </p>  */}
                <p className="mb-2  mt-3">
                  Based on all we have learnt so far, what are some ways that we
                  can increase awareness on <b>LGBTQ+</b> in our family or
                  community? Discuss with your siblings, friends, or anyone you
                  feel comfortable with discussing sexuality. Upload a photo!{" "}
                </p>

                {/* <ul className="list-style-set list-circle mt-2">
                  <li>	Read-up more about the topic</li>
                  <li>	Attend lectures, talks etc. in your school, or college</li>
                  <li>	Talk to a trusted adult e.g. parent, teacher, relative who has been working on these issues </li>
                  <li>College Youth Engagement</li>
                </ul> */}

                <div className="row">
                  <div className="col-lg-9">
                    <div className="mb-4 positon-submit">
                      <textarea
                        className="form-control "
                        rows={1}
                        placeholder="Write Something here....."
                        onChange={(e) => setCheckedQues8(e.target.value)}
                        data-quesId="9"
                        defaultValue={checkedQues8}
                      />
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div class="file-input">
                      <input
                        type="file"
                        name="file-input"
                        id="file-input5"
                        class="file-input__input"
                        data-image="image5"
                        onChange={onImageChange1}
                      />
                      <label class="file-input__label" for="file-input5">
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="upload"
                          class="svg-inline--fa fa-upload fa-w-16"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                          ></path>
                        </svg>
                        <span>Upload file</span>
                      </label>
                      <a
                        target="__blank"
                        href={AdminUrl + "/public/mascul/" + imageurl1}
                      >
                        {imageurl1}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="95%"
                    draggable="false"
                    src="/assets/img/md5/md-5-chap-2-17.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-05/chapter-02-screen-19" />
                {/* <NextBtn link="/module-05/chapter-02-screen-21" text="Go Next"/> */}

                <BtnDisabled />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close close-set"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="bi bi-x-lg" />
              </button>

              <p className="text-white text-center">
                talking to elder relatives about sexual identities, looking at
                media messaging on diverse sexualities, joining protests and
                movements, staging plays/ discussions in our college and
                neighbourhoods
              </p>

              <div class=" d-flex justify-content-center mt-3">
                <button
                  className="button_su"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="su_button_circle desplode-circle"></span>
                  <button onClick={saveAns} className="button_su_inner">
                    <span className="button_text_container">
                      {" "}
                      Go Next <i className="bi bi-arrow-right" />
                    </span>{" "}
                  </button>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen17;
