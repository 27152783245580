import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
// import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter02Screen04 = () => {

	const navigate = useNavigate();
	const [checkedQues1, setCheckedQues1] = useState('');
	const [penChecked, setPenChecked] = useState('');
	const [pencilChecked, setPencilChecked] = useState('');
	const [penPencilChecked, setPenPencilChecked] = useState('');
	const [butterChecked, setButterChecked] = useState('');
	const [jamChecked, setJamChecked] = useState('');
	const [butterJamChecked, setButterJamChecked] = useState('');
	const [smartChecked, setSmartChecked] = useState('');
	const [lapChecked, setLapChecked] = useState('');
	const [smartLapChecked, setSmartLapChecked] = useState('');

	const [breadChecked, setBreadChecked] = useState('');
	const [riceChecked, setRiceChecked] = useState('');
	const [breadRiceChecked, setBreadRiceChecked] = useState('');

	const [karaokeDanceChecked, setKaraokeDanceChecked] = useState('');
	const [karaokeChecked, setKaraokeChecked] = useState('');
	const [danceChecked, setDanceChecked] = useState('');

	const [redBlackChecked, setRedBlackChecked] = useState('');
	const [redChecked, setRedChecked] = useState('');
	const [blackChecked, setBlackChecked] = useState('');


	const [checkedQues14Yes, setCheckedQues14Yes] = useState('');
	const [checkedQues14No, setCheckedQues14No] = useState('');
	const [checkedQues14Val, setCheckedQues14Val] = useState('');

	const [checkedQues15Yes, setCheckedQues15Yes] = useState('');
	const [checkedQues15No, setCheckedQues15No] = useState('');
	const [checkedQues15Val, setCheckedQues15Val] = useState('');







	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const itemVal = e.target.value;
		if (quesId === '14' || quesId === '15') {
			checkedVal(quesId, itemVal);
		}
		if (quesId === '2_1') {
			setPenPencilChecked(itemVal);
			checkedVal(quesId, itemVal)
		}

		if (quesId === '2_2') {
			setButterJamChecked(itemVal);
			checkedVal(quesId, itemVal)
		}

		if (quesId === '2_3') {
			setSmartLapChecked(itemVal);
			checkedVal(quesId, itemVal)
		}

		if (quesId === '2_4') {
			setBreadRiceChecked(itemVal);
			checkedVal(quesId, itemVal)
		}

		if (quesId === '2_5') {
			setKaraokeDanceChecked(itemVal);
			checkedVal(quesId, itemVal)
		}

		if (quesId === '2_6') {
			setRedBlackChecked(itemVal);
			checkedVal(quesId, itemVal)
		}



	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues1,
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ans2": breadRiceChecked + "," + karaokeDanceChecked + "," + redBlackChecked,
					"ques_id": "2"
				},
				"ques14": {
					"ans": checkedQues14Val,
					"ques_id": "14"
				},
				"ques15": {
					"ans": checkedQues15Val,
					"ques_id": "15"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				console.log(response);
				if (response.data.success === true) {
					navigate('/module-02/chapter-02-screen-05')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (breadRiceChecked !== '' && karaokeDanceChecked !== '' && redBlackChecked !== '' && checkedQues14Val !== '' && checkedQues15Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {

		if (param === '14') {
			if (ans === 'yes') {
				setCheckedQues14Yes('checked')
				setCheckedQues14No('')
			} else {
				if (ans === 'no') {
					setCheckedQues14No('checked')
					setCheckedQues14Yes(''
					)
				}
			}
			setCheckedQues14Val(ans)
		}

		if (param === '15') {
			if (ans === 'easy') {
				setCheckedQues15Yes('checked')
				setCheckedQues15No('')
			} else {
				if (ans === 'difficuilt') {
					setCheckedQues15No('checked')
					setCheckedQues15Yes(''
					)
				}
			}
			setCheckedQues15Val(ans)
		}



		if (param === '1') {

			setCheckedQues1(ans)
		}

		if (ans === 'pen') {

			setPenChecked('checked');
			setPencilChecked('');
		}
		if (ans === 'pencil') {

			setPencilChecked('checked');
			setPenChecked('');
		}

		if (ans === 'butter') {

			setButterChecked('checked')
			setJamChecked('');
		}

		if (ans === 'jam') {

			setJamChecked('checked');
			setButterChecked('');
		}

		if (ans === 'smartphone') {

			setSmartChecked('checked');
			setLapChecked('')
		}

		if (ans === 'laptop') {

			setLapChecked('checked');
			setSmartChecked('');
		}

		if (ans === 'flatbread') {

			setBreadChecked('checked');
			setRiceChecked('');
		}

		if (ans === 'rice') {

			setRiceChecked('checked');
			setBreadChecked('');
		}

		if (ans === 'karaoke') {

			setKaraokeChecked('checked');
			setDanceChecked('');
		}

		if (ans === 'dance') {

			setDanceChecked('checked');
			setKaraokeChecked('');
		}

		if (ans === 'circle-red') {

			setRedChecked('checked');
			setBlackChecked('');
		}

		if (ans === 'black-circle') {

			setBlackChecked('checked');
			setRedChecked('');
		}











	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {

						// checkedVal(result.ques_id.toString(), result.ans)
						if (result.ques_id !== 2) {

							checkedVal(result.ques_id.toString(), result.ans)
						} else {

							const ___ans = result.ans2;
							console.log(___ans);
							const res_ans = ___ans.split(',');
							res_ans.map((rel) => {

								// alert(rel);

								if (rel === 'flatbread' || rel === 'rice') {
									setBreadRiceChecked(rel);
								}

								if (rel === 'karaoke' || rel === 'dance') {
									setKaraokeDanceChecked(rel);
								}

								if (rel === 'circle-red' || rel === 'black-circle') {
									setRedBlackChecked(rel);
								}


								checkedVal(result.ques_id.toString(), rel)
							})
							// console.log(___ans);
						}

					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])



	return (

		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="5" star="5" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20  mt-m-3">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<div className="row">
									<div
										className="col-lg-4 col-md-6 col-sm-6"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<div className="plans">
											<div className="d-flex align-items-center justify-content-sm-center justify-content-center">
												<div>
													<label className="plan basic-plan" htmlFor="basic">
														<input type="radio" name="plan" id="basic"
															defaultValue="flatbread"
															onClick={saveRadio}
															checked={breadChecked}
															data-quesId="2_4"
														/>
														<div className="plan-content">
															<img loading="lazy" src="/assets/img/md2/flatbread.png" alt="" />

														</div>
														<span>Flatbread</span>
													</label>
												</div>
												<div className="or-text">or</div>
												<div>
													<label className="plan complete-plan" htmlFor="complete">
														<input type="radio" id="complete" name="plan"
															defaultValue="rice"
															onClick={saveRadio}
															checked={riceChecked}
															data-quesId="2_4"
														/>
														<div className="plan-content">
															<img loading="lazy" src="/assets/img/md2/rice.png" alt="" />
														</div>
														<span>Rice</span>
													</label>
												</div>
											</div>
										</div>

									</div>
									<div
										className="col-lg-4 col-md-6 col-sm-6"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>

										<div className="plans">
											<div className="d-flex align-items-center justify-content-center">
												<div>
													<label className="plan basic-plan" htmlFor="basic2">
														<input type="radio" name="plan2" id="basic2"
															defaultValue="karaoke"
															onClick={saveRadio}
															checked={karaokeChecked}
															data-quesId="2_5"
														/>
														<div className="plan-content">
															<img loading="lazy" src="/assets/img/md2/karaoke.png" alt="" />

														</div>
														<span>Karaoke</span>
													</label>
												</div>
												<div className="or-text">or</div>
												<div>
													<label className="plan complete-plan" htmlFor="complete2">
														<input type="radio" id="complete2" name="plan2"
															defaultValue="dance"
															onClick={saveRadio}
															checked={danceChecked}
															data-quesId="2_5"
														/>
														<div className="plan-content">
															<img loading="lazy" src="/assets/img/md2/dance.png" alt="" />
														</div>
														<span>Dance</span>
													</label>
												</div>
											</div>
										</div>

									</div>
									<div
										className="col-lg-4 col-md-6 col-sm-6"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>

										<div className="plans">
											<div className="d-flex align-items-center justify-content-sm-center justify-content-center">
												<div>
													<label className="plan basic-plan" htmlFor="basic3">
														<input type="radio" name="plan3" id="basic3"
															defaultValue="circle-red"
															onClick={saveRadio}
															checked={redChecked}
															data-quesId="2_6"
														/>
														<div className="plan-content">
															<img loading="lazy" src="/assets/img/md2/circle-red.png" alt="" />

														</div>
														<span>Circle Red</span>
													</label>
												</div>
												<div className="or-text">or</div>
												<div>
													<label className="plan complete-plan" htmlFor="complete3">
														<input type="radio" id="complete3" name="plan3"
															defaultValue="black-circle"
															onClick={saveRadio}
															checked={blackChecked}
															data-quesId="2_6"
														/>
														<div className="plan-content">
															<img loading="lazy" src="/assets/img/md2/black-circle.png" alt="" />

														</div>
														<span>Black Circle</span>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>


								<div className="row ">
									<div
										className="col-lg-6 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<p className="mb-2 mt-3">
											Do you know the reasons behind each option you picked?
										</p>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="ques14"
													id="inlineRadio1"
													defaultValue="yes"
													onClick={saveRadio}
													data-quesId="14"
													checked={checkedQues14Yes}
												/>
												<label className="form-check-label" htmlFor="inlineRadio1">
													Yes
												</label>
											</div>
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="ques14"
													id="inlineRadio2"
													defaultValue="no"
													onClick={saveRadio}
													data-quesId="14"
													checked={checkedQues14No}
												/>
												<label className="form-check-label " htmlFor="inlineRadio2">
													No
												</label>
											</div>
										</div>


									</div>
									<div
										className="col-lg-6 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<p className="mb-2 mt-3">
											Is it easy or difficult to think about the reason?
										</p>

										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="ques15"
													id="inlineRadio1"
													defaultValue="easy"
													onClick={saveRadio}
													data-quesId="15"
													checked={checkedQues15Yes}
												/>
												<label className="form-check-label" htmlFor="inlineRadio1">
													Easy
												</label>
											</div>
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="ques15"
													id="inlineRadio2"
													defaultValue="difficuilt"
													onClick={saveRadio}
													data-quesId="15"
													checked={checkedQues15No}
												/>
												<label className="form-check-label " htmlFor="inlineRadio2">
													Difficuilt
												</label>
											</div>
										</div>
									</div>
								</div>




								<h2 className="h3 mt-4">Learn</h2>
								<p className=" mt-2"> Sometimes it is easy to know and choose what we like and want, when presented with simple things in life even then it could be difficult to pinpoint why we made that choice or decision. However, life is complex and we are oftentimes presented with complex choices and decisions. What’s important is to understand what makes us make those decisions and choices and therefore, starting this reflection with even small things can help with knowing the reasons behind important choices and decisions.</p>
								<p className=" mt-3"> For example in the above activity someone could have picked rice over roti because they are allergic to wheat in roti which means that they prioritised their health. However, if a person really likes roti and not rice then it will be difficult for them to choose because if they choose roti which they like they will enjoy eating but suffer due to their allergy and if they choose rice they will not enjoy eating.   </p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-02-screen-03" />

								{/* <NextBtn link="/module-02/chapter-02-screen-05" text="Go Next"/> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	);
};

export default Chapter02Screen04;
