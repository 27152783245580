import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";

const Chapter01Screen15 = () => {


	return (
		<>
			<div className="wrapper md-5-bg">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="5" star="4" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-top justify-content-center pt-20 mt-5 mt-m-3">
							<div className="col-lg-8 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.1s', animationName: 'fadeInDown' }}>
								<p>These statistics make it clear that everybody is not ensured of their human rights and while we see progress in access to certain human rights over the years some others like freedom and inequality have declined. The progress is also limited to certain economic classes. The growing inequality will ultimately lead to less and less access to human rights. </p>

								<p className="mt-3">One extremely common human rights violation that people face is violence. Often it is marginalised groups and those that have less power and privilege. Economic, social, educational, caste, class, religion and other aspects of our identities affect our access to information about and the resources itself. </p>

								<p className="mt-3">Just like any other plant or tree the human rights tree needs nourishment. Let us create a human rights tree. In our tree the roots are the acts, schemes, systems, mechanisms, institutions etc. which will help us to provide nourishment to our tree so that we can get fruits and leaves. The fruits and leaves are the Rights. The exercise below is a way for us to understand the connection between laws, policies, systems etc. which help us to get rights. In turn, these rights enable us to live a life of dignity, respect, equality and justice. </p>



							</div>
							<div className="col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.2s', animationName: 'fadeInDown' }}>
								<center>
									<img width="60%" src="/assets/img/md3/md3-shap-3-7.png" alt="" />
								</center>
							</div>
						</div>

					</div>
				</div>
			</div>


			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-03/chapter-01-screen-14" />

								<NextBtn link="/module-03/chapter-01-screen-16" text="Go Next" />
								{/* <BtnDisabled/> */}

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen15;
