import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";

const Chapter02Screen03 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Learn  </h3>
								<p className="mb-2  mt-3"> <b>LGBTQIA+ </b> is an umbrella term for individuals with diverse sexual and gender identities. <b>LGBTQIA+ </b> includes a range of identities – lesbian, gay, bisexual, transgender, queer and more. We add a (+) sign to stress on the evolving and fluid aspect of sexuality. Categories and identities are not strictly defined / can be added and substituted as our understanding and people’s experiences evolve.  </p>
								<p className="mb-2  mt-3"> We know from the gender module that sex that is assigned based on biological organs, may or may not match a person’s gender experience </p>


								{/* <a href="https://docs.google.com/document/d/1ddjf1mfdLcsc5qmRibVe7ngF9lHU-Hxe/edit?usp=sharing&ouid=113329703917573513465&rtpof=true&sd=true" target="_blank">
                  <div className="d-flex align-items-center mange-icon mt-3">
                    <div>
                      <img src="/assets/img/chapter1/list-icon.png" alt="" />
                    </div>
                    <div>
                      <p> Just as we learnt about gender diversity and different kinds of identities, let’s look at what we mean by sexual diversity.  </p>
                    </div>
                  </div>
                </a> */}



								<p className="mb-2  mt-3"> A person’s feelings of attraction, sexual desires and intimate connection to another person is their sexual orientation. Just like gender identity, these are diverse and fluid. </p>


								<a href="https://docs.google.com/document/d/1ddjf1mfdLcsc5qmRibVe7ngF9lHU-Hxe/edit?usp=sharing&ouid=113329703917573513465&rtpof=true&sd=true" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p> Just as we learnt about gender diversity and different kinds of identities, let’s look at what we mean by sexual diversity.  </p>
										</div>
									</div>
								</a>




							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="90%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-3.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-02" />
								{/* <NextBtn link="/module-05/chapter-02-screen-04" text="Go Next"/> */}

								<button className="button_su" >
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>


							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							<p className="text-white text-left">In the context of sexuality, it is important to use language that is respectful to the individuals and community we are addressing. For instance, our use of pronouns like “he/she” should not be based on our own assumption of someone’s gender. Instead it might be better to use pronouns that a person identifies with to create inclusive spaces for people of all genders and sexualities. For more information on pronouns, we can look at this page -    <a href="https://pronouns.org" className="text-white" target="_blank">https://pronouns.org/ </a>
							</p>

							<div class=" d-flex justify-content-center mt-3">
								<button className="button_su" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="/module-05/chapter-02-screen-04" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>




						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter02Screen03;
