import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import PrevBtn from "../../../common/PrevBtn";
import Header from '../../../header/Header';
import { useDrop } from "react-dnd";
import Image from './Image';
import axios from 'axios';
import BaseURL from '../../../config/Baseurl';
import { useEffect } from 'react';
import NextBtn from '../../../common/NextBtn';



const Chapter01Screen02 = () => {

	const navigate = useNavigate();
	const [PictureList, setPictureList] = useState([

		{ "id": "1", "image": "muscle.png", "name": "muscles" },
		{ "id": "2", "image": "bullet.png", "name": "bullet" },
		{ "id": "3", "image": "long-hair.png", "name": "Long hair" },
		{ "id": "4", "image": "tear.png", "name": "Tears" },
		{ "id": "5", "image": "flowers.png", "name": "Flowers" },
		{ "id": "6", "image": "moustache.png", "name": "Moustache" },
		{ "id": "7", "image": "chocolate.png", "name": "Chocolate" },
		{ "id": "8", "image": "broom.png", "name": "Broom" },
		{ "id": "9", "image": "hammer.png", "name": "Hammer" },
		{ "id": "10", "image": "cigarette.png", "name": "Cigarette" },
		{ "id": "11", "image": "soft-toys.png", "name": "Soft Toys" },

	]);

	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const [masculArray, setMasculArray] = useState([]);



	const [{ isShasOver }, sahasdrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addmasculArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));



	// Add To sahas Array
	const addmasculArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setMasculArray((masculArray) => [...masculArray, picture_List[0]]);
	};



	const onItemRemoveSahas = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = masculArray.filter((item) => item.id === id);
		console.log(_item);
		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setMasculArray(masculArray =>
			masculArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};

	const BtnDisabled = () => {
		if (masculArray.length !== 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques4": {
					"ans": JSON.stringify(masculArray),

					"ques_id": "4"
				},


			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-04/chapter-01-screen-02');
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var axios = require('axios');
		var data = JSON.stringify({
			"user_id": user_details.user_id,

			"ques_id": "4"
		});

		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const ans = JSON.parse(response.data.data.chapter.ans)

					setMasculArray(ans);

					if (ans.length > 0) {
						ans.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}




				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);





	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="1" star="1" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2 mt-m-3">
							<div
								className="col-lg-7  wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
								ref={sahasdrop}
							>
								<h3 className="h3 text-center">Which of the given below images can be part of the term ‘Masculinity’. Drag and drop them in the center</h3>
								<div className="masculinity-box" >
									<h3>Masculinity</h3>
								</div>



								<div className="close-box-design d-flex align-items-center  mt-3">
									<ul className="ul-p-0">

										{masculArray.map((e) => {
											return (
												<>
													<li>
														<button
															onClick={onItemRemoveSahas}
															data-id={e.id}
															type="button"
															className="btn-close"
															aria-label="Close"
														/>
														<img src={"/assets/img/md4/" + e.image} id={e.id} alt="" />

													</li>

												</>
											)

										})}


									</ul>
								</div>





								<div className="mt-5">
									<div className="row align-items-center justify-content-center">

										{
											PictureList.map((e) => {
												return (
													<Image key={e.id} url={e.image} id={e.id} imageName={e.name} />
												)
											})
										}


									</div>


								</div>








							</div>

							<div
								className="col-lg-5 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md4/md4-shap-1-2.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-01-screen-01" />

									{/* <NextBtn link="/module-04/chapter-01-screen-02" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen02;
