import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen38 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h2 className="h2 mt-3">Additional Resources </h2>

								<a
									href="https://www.youtube.com/watch?v=QmggWPI6lZY"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>	Sex in Indian society  </p>
										</div>
									</div>
								</a>

								<a
									href="https://www.youtube.com/watch?v=iP0GbnSnr5w"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>	Sexual needs in Indian women  </p>
										</div>
									</div>
								</a>

								<a
									href="https://agentsofishq.com/a-lavni-about-consent-the-amorous-adventures-of-megha-and-shakku/"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>	The story of consent  </p>
										</div>
									</div>
								</a>

								<a
									href="https://www.youtube.com/watch?v=ElkOgzolU5k"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>	Parents and children discuss sex – Netflix India  </p>
										</div>
									</div>
								</a>

								<a
									href="https://www.youtube.com/watch?v=URHxxbCFxj4"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Taboo around female sexuality  </p>
										</div>
									</div>
								</a>

								<a
									href="https://agentsofishq.com/"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>For many resources on sex and pleasure visit  <span className="red">https://agentsofishq.com/</span> </p>
										</div>
									</div>
								</a>





							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="42%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-15.png"
										alt=""
									/>
								</center>
							</div>




						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-37" />
								<NextBtn link="/module-05/chapter-04-screen-01" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter03Screen38;
