import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";


const Chapter02Screen02 = () => {

	return (
		<>
			<div className="wrapper page-auto md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="25" star="1" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3 mt-m-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<p className="mb-2 mt-3">Human rights are part of our everyday lives, and each one of us is responsible for their protection and promotion. Though governments and international laws are responsible and have an important role in supporting human rights globally, there is much that we can also do locally, everyday, with one another. Together, we can build a culture that respects all human rights for all people. We can ensure that each of us lives up to our full potential, enriching our own lives and the world around us.  To understand how rights are related to responsibilities, please read the excerpt from a news article and answer the questions that follow: </p>
								<p className="mb-2 mt-3"><b>Mohali:</b>  It is not just the Aussie players that get under the skin of Harbhajan Singh. On Friday night, after the game between Mumbai Indians and Kings XI Punjab, Harbhajan reportedly slapped S. Sreesanth. The aggressive pacer was seen crying and being consoled by his teammates and the Mohali team owner Preity Zinta. </p>
								<p className="mb-2 mt-3">Kings XI Punjab skipper Yuvraj Singh told the media that "the act" was unacceptable but did not confirm if Harbhajan slapped Sreesanth. However, he said that Harbhajan apologised to Sreesanth after the incident took place. </p>
								<p className="mb-2 mt-3">"That was unacceptable behaviour. But Harbhajan later came to the dressing and spoke with Sree and am sure apologised," Yuvraj said. </p>
								<p className="mb-2 mt-3">The coach of Kings XI Punjab, Tom Moody, said the matter must be left with the authorities to deal with. </p>



								<p className="mb-2 mt-3">"I had a long chat with Sree. This was unacceptable. I don't know what the ramifications will be, but I am sure officials will deal with it in their own fashion," he said.{" "} </p>
								<p className="mb-2 mt-3">When CNN-IBN’s Nishant Arora spoke with the two players, both played down the incident. "He didn't slap me. May be the handshake was in the wrong place," Sreesanth said. However, he did let it slip that he was a "little hurt".{" "} </p>
								<p className="mb-2 mt-3">It was just a misunderstanding, a part of the game. After all, we all play together and share the same dressing room. So I did not take it too seriously and have not much to complain about him (Harbhajan). We all know Bhajji and need not take it seriously. I was a little hurt but am fine with it," he said.{" "} </p>
								<p className="mb-2 mt-3">He also said Harbhajan apologised after he broke down on the field.{" "} </p>
								<p className="mb-2 mt-3">"I just cried because, I don’t know. I am fine, he spoke with me, apologised. But there was no need because end of the day, we play for the same team and we have to go together a long way. Whatever happens in IPL should not be taken too seriously as end of the day it's just entertainment. Whatever happened shouldn't have happened but it’s okay,” he said.{" "} </p>


							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md3/md3-shap-2-3.png"
										alt=""
									/>
								</center>
							</div>

						</div>






					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-02-screen-01" />
									<NextBtn link="/module-03/chapter-02-screen-04" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
