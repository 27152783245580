import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter04Screen09 = () => {
  return (
    <>
      <div className="wrapper">
        <div
          className="fixed-header"
       
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="3/5" percentage="76" star="54" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-center pt-20 mt-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >
                <h3 className="h3">The original lyrics are:</h3>
              </div>
              <div
                className="col-lg-6 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >
                <ul className="without-list">
                  <li>उर्वशी, उर्वशी, टेक इट ईज़ी उर्वशी</li>
                  <li>उंगली जैसी दुबली को नहीं चाहिए फार्मसी</li>
                  <li>उर्वशी, उर्वशी... </li>
                  <li>जीत का मंत्र है, टेक इट ईज़ी पॉलिसी </li>
                  <li>उर्वशी, उर्वशी... </li>
                  <li>जानती हो, हिंदी में, प्यार के कितने अक्षर </li>
                  <li>बोल दो मेरी ख़ातिर, प्यार के दो ही अक्षर </li>
                  <li>जीत का मंत्र... </li>
                  <li>चित्रहार में बिजली उड़ गई, टेक इट ईज़ी पॉलिसी </li>
                  <li>पढ़ने पर भी फेल हो गए, टेक इट ईज़ी पॉलिसी </li>
                  <li>बाप ने बोला, अम्मा का दुश्मन, टेक इट ईज़ी पॉलिसी </li>
                  <li>पाप करे और गंगा नहाये, टेक इट ईज़ी पॉलिसी</li>
                  <li>उर्वशी, उर्वशी, टेक इट ईज़ी उर्वशी</li>
                  <li>जानती हो, इतना तो, बदन में लाखों नाड़ी</li>
                  <li>बता दो मुझ को इतना, कहाँ है प्यार की नाड़ी</li>
                  <li>जीत का मंत्र...</li>
                </ul>
              </div>
              <div
                className="col-lg-6  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >
                <ul className="without-list mt-m-3">
                  <li>नज़र के मिल जाने से ही, शील तो भंग नहीं होता</li>
                  <li>बिल्लियाँ ना शाकाहारी, हर कोई राम नहीं होता </li>
                  <li>भलाई कभी औरतों की, क्रांति के बिना नहीं होगी</li>
                  <li>ज़माना बदल गया प्यारे, पुरानी बात नहीं होगी </li>
                  <li>फ़िलम देखने वो नहीं आई, टेक इट ईज़ी पॉलिसी</li>
                  <li>बगल सीट पर बुड्ढी हो तो, टेक इट ईज़ी पॉलिसी</li>
                  <li>संडे को भी छुट्टी न हो, टेक इट ईज़ी पॉलिसी</li>
                  <li>प्यार करे और नोट भी मांगे, टेक इट ईज़ी पॉलिसी </li>
                  <li>उर्वशी, उर्वशी... </li>
                  <li>अगर लड़की को अंधेरे में, आँख मारी तो होगा क्या </li>
                  <li>अगर आज़ादी न हो तो, स्वर्ग मिलने से होगा क्या </li>
                  <li>क्लास में फिगर नहीं हो तो, वहाँ पढ़ने से होगा क्या </li>
                  <li>बीस की उमर का जो है खेल, साठ में खेल के होगा क्या</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-01/chapter-04-screen-08" />
                <NextBtn
                  link="/module-01/chapter-04-screen-10"
                  text="Go Next"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter04Screen09;
