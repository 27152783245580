import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen24 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								{/* Yahan Par Popup Rahega */}

								<p className="mb-2 mt-3 pn"><b> We will revisit consent in more depth later on in Chapter 4 – Risks and Rights. </b> </p>
								<ul class="list-style-set list-circle mt-1">
									<li><b>Advanced Version</b></li>
									<li><b>Pleasure and Desire – different for men and women </b></li>
									<li><b>Power in sexual relationships  </b></li>
								</ul>



							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/jwd.png"
										alt=""
									/>
								</center>
							</div>




						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-23" />
								<NextBtn link="/module-05/chapter-03-screen-25" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>


		</>
	);
};

export default Chapter03Screen24;
