import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
const Chapter03Screen04 = () => {
  return (
    <>
      <div className="wrapper">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="2/5" percentage="50" star="30" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-center pt-20 mt-3">
              <div className="col-lg-12">
                <h3 className="h3">Learn</h3>
                <p className="mt-3">Patriarchy for men and women </p>
              </div>
              <div className="col-lg-6">
                <div className="para-color-box">
                  <h3 className="h3">Men </h3>
                  <ul className="without-list">
                    <li>Power </li>
                    <li>Privileges</li>
                    <li>Purity- considered superior</li>
                    <li>Property inheritance </li>
                    <li>Head of the family labour-</li>
                    <li>Highest positions across all spheres </li>
                    <li>Are considered strong </li>
                    <li>Enjoy freedom of mobility </li>
                    <li>Considered Protectors</li>
                    <li>Preferred choice of gender when a child is born </li>
                    <li>Parents support their education and career</li>
                    <li>Breadwinners of their families </li>
                    <li>
                      Could practice patriarchy and maintain the system system
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="para-color-box">
                  <h3 className="h3 mt-m-1">Women </h3>
                  <ul className="without-list">
                    <li>Subordinated</li>
                    <li>Lack Of rights</li>
                    <li>Considered Inferior</li>
                    <li>Considered property to be given away </li>
                    <li>
                      household chores, childcare, elderly care without pay
                    </li>
                    <li>
                    Responsible for all reproductive labour-  
                    </li>
                    <li>   household chores, childcare, elderly care without pay</li>
                    <li>Are structurally prevented from taking higher positions
</li>
                    <li> Are considered weak     </li>
                    <li>
                    Restricted Mobility
                    </li>
                    <li>Considered property to be protected</li>
                    <li>Girl child is not preferred by many expecting parents </li>
                    <li>Many parents do not support career and education </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-01/chapter-03-screen-03n5" />
                <NextBtn
                  link="/module-01/chapter-03-screen-05"
                  text="Go Next"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen04;
