import AppRoute from "./routes/AppRoute";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function App() {
  return (
    <>
      <DndProvider backend={HTML5Backend}>

        <AppRoute/>
      </DndProvider>
    </>
  );
}

export default App;
