import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";

const Chapter01Screen08 = () => {
	const navigate = useNavigate();
	const [checkedQues4, setCheckedQues4] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));


	const [pictureList, setPictureList] = useState([
		{ "id": "1", "name": "plan", "image": "face-img.png", "defaultValue": "pen" },
		{ "id": "2", "name": "plan", "image": "face-img.png", "defaultValue": "pencil" },
		{ "id": "3", "name": "plan", "image": "face-img.png", "defaultValue": "pen" },
		{ "id": "4", "name": "plan", "image": "face-img.png", "defaultValue": "pen" },
		{ "id": "5", "name": "plan", "image": "face-img.png", "defaultValue": "pen" },
		{ "id": "6", "name": "plan", "image": "face-img.png", "defaultValue": "pen" },
		{ "id": "7", "name": "plan", "image": "face-img.png", "defaultValue": "pen" },
		{ "id": "8", "name": "plan", "image": "face-img.png", "defaultValue": "pen" },
		{ "id": "9", "name": "plan", "image": "face-img.png", "defaultValue": "pen" },
		{ "id": "10", "name": "plan", "image": "face-img.png", "defaultValue": "pen" },
	])

	const [showResults, setShowResults] = useState(false)

	const saveRadio = (e) => {
		// alert(e.target.checked);
		setShowResults(true);

	}

	const ShowPopUp = () => {
		if (showResults === true) {

			return (
				<>

					<div className="alert text-center alert-primary text-center mt-2" role="alert">
						The 10 richest men in the world own more than the bottom 3.1 billion people. The wealth of the 10 richest men has doubled, while the incomes of 99% of humanity are worse off, because of COVID-19
						<div className="d-flex justify-content-center">
							<a href="https://oxfamilibrary.openrepository.com/bitstream/handle/10546/621341/bp-inequality-kills-170122-summ-en.pdf" target="_blank" className="text-center">
								<div className="d-flex  mt-3">
									<div>
										<img src="/assets/img/chapter1/list-icon.png" alt="" />
									</div>
									<div>
										<p className="text-white">
											&nbsp; Inequality Kills
											{/* Universal declaration of human rights */}
										</p>
									</div>
								</div>
							</a>
						</div>
					</div>
				</>
			)

		}
	}





	// console.log(showResults);

	const BtnDisabled = () => {
		if (showResults === true) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<NavLink to="/module-03/chapter-01-screen-09" className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</NavLink>
					</button>
				</>
			);
		} else if (showResults === false) {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next  <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper md-5-bg">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="5" star="4" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-center justify-content-center pt-20 mt-5">
							<div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">

							</div>

							<div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">


								<div className="alert alert-primary text-center mt-2" role="alert">
									Currently, the population of the world is 7.9 billion.
								</div>

								<div className="row align-items-center justify-content-center select-op-new mt-2 mb-3">

									{
										pictureList.map((item, index) => {
											return (
												<>
													<div className="col-3 col-lg-1 col-sm-2 mt-2 select-pset">
														<div className="plans ">
															<label className="plan basic-plan" htmlFor={"basic" + index}>
																<input type="radio" name="plan" id={"basic" + index} onClick={saveRadio} defaultValue={item.defaultValue} />
																<div className="plan-content"><img loading="lazy" src={"/assets/img/md5/" + item.image} alt="" /></div>
															</label>
														</div>
													</div>
												</>
											)
										})
									}




								</div>
								<p className="mb-2 mt-3">The first face represents the person with the most amount of economic wealth, and the last image represents the person with the least amount of economic wealth on earth. Please select the face that represents you on the scale. </p>
								<ShowPopUp />



							</div>

						</div>

					</div>
				</div>
			</div>


			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-03/chapter-01-screen-06" />

								{/* <NextBtn link="/module-03/chapter-01-screen-09" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen08;
