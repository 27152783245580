import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen11 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>


								<h3 className="h3">Learn: </h3>
								<p className="mb-2  mt-3">How we feel about our bodies often determines what we think is attractive or not, where we would like to be touched or not, what sexual behaviour we enjoy - just to give a few examples. This may be different for every person.  </p>

								<p className="mb-2  mt-3">Relationships based on mutual respect and consideration of each other’s values and sense of self can make us feel happy and good about ourselves. What are some other affirming behaviours in relationships? Let us take a look!  </p>




							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="55%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-3-11.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-10" />
								<NextBtn link="/module-05/chapter-03-screen-12" text="Go Next" />




							</div>
						</div>
					</div>
				</div>
			</div>




		</>
	);
};

export default Chapter03Screen11;
