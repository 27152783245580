import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter03Screen07 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="2/5" percentage="58" star="58" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between mt-4">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mt-3">
									Many organisations and individuals who believe in gender
									equity, diversity and inclusion have been writing reviews and
									challenging the way masculinity is represented in media
									whether it’s films, ads, OTT platforms or print and digital
									news. Thus, holding them accountable and bringing awareness to
									the viewers. Akshara center, an organization based in Mumbai
									started a gender rewrite competition for popular but
									problematic dialogues and songs promoting hegemonic
									masculinity. Some of the examples are shared below:
								</p>

								<a
									href="https://feminisminindia.com/2018/05/16/bollywoods-tryst-toxic-masculinity/"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p> Bollywood's Tryst With Toxic Masculinity{" "}
												<b> | Feminism In India</b></p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2020/09/21/men-masculinity-and-society-hindi/"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p> मर्द पैदा नहीं होते, मर्दानगी के पैमाने यह समाज गढ़ता है{" "}
												<b> | फेमिनिज़म इन इंडिया</b></p>
										</div>
									</div>
								</a>

								<a
									href="https://www.indianwomenblog.org/feminist-nandita-shah-dares-honey-singh-to-sing-about-consent-equality/"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon ">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p> Feminist Nandita Shah Dares Honey Singh To Sing About Consent & Equality</p>
										</div>
									</div>
								</a>


							</div>
							<div
								className="col-lg-5 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<iframe
									width="100%"
									height={300}
									src="https://www.youtube.com/embed/61bdro-rHWE"
									title="Let's Talk About Toxic Masculinity Again | Breakthrough India"
									frameBorder={0}
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen=""
								/>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-03-screen-06" />

									<NextBtn link="/module-04/chapter-03-screen-08" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen07;
