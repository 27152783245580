import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen08 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-4">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Learn:  </h3>
								<p className="mb-2  mt-3">People of diverse gender identities and sexual orientations especially those that are discriminated against and/ or marginalised may find it challenging, be fearful and/ or hesitant to ‘come-out’ to their family, friends, peer etc. Social norms, family pressures, mocking of friends,  fear of discrimination, harassment and stigma may stop them from identifying themselves as they feel. However, coming-out is a process, and the person may choose to do it at their own pace including choosing whom to come-out to. </p>

										<p>Let’s watch this clip from the film Dostana to see an example of this! </p>
								<a href="https://www.youtube.com/watch?v=6cCIcF8pXws" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p> Bahu Ya Damaad? - Dostana - Comedy Week.  </p>
										</div>
									</div>
								</a>

								{/* <a href="https://www.youtube.com/watch?v=6cCIcF8pXws" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p> Let’s watch this clip from the film Dostana to see an example of this!    </p>
										</div>
									</div>
								</a> */}

								{/* <p className="mb-2  mt-3"> <b>What are some of the cultural norms you have heard of that make it hard to accept diverse sexualities?</b> </p> */}



							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="60%"
										draggable="false"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-07" />
								<NextBtn link="/module-05/chapter-02-screen-09" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen08;
