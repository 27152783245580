import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import PrevBtn from "../../../common/PrevBtn";
import Header from '../../../header/Header';
import { useDrop } from "react-dnd";
// import Image from './Image';
import Chapter03Screen15_1 from './Chapter03Screen15_1';
import axios from 'axios';
import BaseURL from '../../../config/Baseurl';
import { useEffect } from 'react';
import NextBtn from '../../../common/NextBtn'
import { useDrag } from "react-dnd";


const Chapter03Screen15 = () => {
	const navigate = useNavigate();
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const [PictureList, setPictureList] = useState([
		{ "id": "1", "name": "The weather" },
		{ "id": "2", "name": "People you don’t like" },
		{ "id": "3", "name": "Your address" },
		{ "id": "4", "name": "Your sexual likes and dislikes" },
		{ "id": "5", "name": "Books/ movies you like" },
		{ "id": "6", "name": "Discomfort in your private parts" },
		{ "id": "7", "name": "Being sick" },
		{ "id": "8", "name": "Your family arguments" },
		{ "id": "9", "name": "Underwear" },
		{ "id": "10", "name": "Your sexual experiences" },
		{ "id": "11", "name": "About your work" },
		{ "id": "12", "name": "A sexual fantasy you have" },

	]);




	const [meArray, setMeArray] = useState([]);
	const [familyArray, setFamilyArray] = useState([]);
	const [friendsArray, setFriendsArray] = useState([]);
	const [acquaintanceArray, setAcquaintanceArray] = useState([]);
	const [interactionArray, setinteractionArray] = useState([]);
	const [strangerArray, setStrangerArray] = useState([]);


	// stranger

	const [{ isStrangerOver }, strangerdrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addStrangerArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addStrangerArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setStrangerArray((strangerArray) => [...strangerArray, picture_List[0]]);
	};

	const onItemRemoveStranger = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = strangerArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setStrangerArray(meArray =>
			strangerArray.filter(stranger => {
				return stranger.id !== id;
			}),
		);
	};


	// interaction

	const [{ isInteractionOver }, interactiondrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addInteractionArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addInteractionArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setinteractionArray((interactionArray) => [...interactionArray, picture_List[0]]);
	};

	const onItemRemoveInteraction = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = interactionArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setinteractionArray(meArray =>
			interactionArray.filter(interaction => {
				return interaction.id !== id;
			}),
		);
	};


	// acquaintance

	const [{ isAcquaintanceOver }, acquaintancedrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addAcquaintanceArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addAcquaintanceArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setAcquaintanceArray((acquaintanceArray) => [...acquaintanceArray, picture_List[0]]);
	};

	const onItemRemoveAcquaintance = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = acquaintanceArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setAcquaintanceArray(meArray =>
			acquaintanceArray.filter(acquaintance => {
				return acquaintance.id !== id;
			}),
		);
	};


	// for Me
	const [{ isMeOver }, medrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addMeArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addMeArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setMeArray((meArray) => [...meArray, picture_List[0]]);
	};

	const onItemRemoveMe = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = meArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setMeArray(meArray =>
			meArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};

	//  for family


	const [{ isFamilyOver }, familydrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addFamilyArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addFamilyArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setFamilyArray((meArray) => [...meArray, picture_List[0]]);
	};

	const onItemRemoveFamily = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = familyArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setFamilyArray(meArray =>
			familyArray.filter(family => {
				return family.id !== id;
			}),
		);
	};

	//  for friends


	const [{ isFriendsOver }, friendsdrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addFriendsArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addFriendsArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setFriendsArray((friendsArray) => [...friendsArray, picture_List[0]]);
	};

	const onItemRemoveFriends = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = friendsArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setFriendsArray(meArray =>
			friendsArray.filter(friends => {
				return friends.id !== id;
			}),
		);
	};










	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques24": {
					"ans": JSON.stringify(meArray),
					"ans2": JSON.stringify(familyArray),
					"ans3": JSON.stringify(friendsArray),
					"ans4": JSON.stringify(acquaintanceArray),
					"ans5": JSON.stringify(interactionArray),
					"ans6": JSON.stringify(strangerArray),
					"ques_id": "24"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-03-screen-16');
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,

			"ques_id": "24"
		});

		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				console.log(response.data);
				if (response.data.success === true) {
					// console.log()
					const ans = JSON.parse(response.data.data.chapter.ans)
					const ans2 = JSON.parse(response.data.data.chapter.ans2)
					const ans3 = JSON.parse(response.data.data.chapter.ans3)
					const ans4 = JSON.parse(response.data.data.chapter.ans4)
					const ans5 = JSON.parse(response.data.data.chapter.ans5)
					const ans6 = JSON.parse(response.data.data.chapter.ans6)


					setMeArray(ans);
					setFamilyArray(ans2);
					setFriendsArray(ans3);
					setAcquaintanceArray(ans4);
					setinteractionArray(ans5);
					setStrangerArray(ans6);

					if (ans.length > 0) {
						ans.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans2.length > 0) {
						ans2.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans3.length > 0) {
						ans3.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans4.length > 0) {
						ans4.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans5.length > 0) {
						ans5.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans6.length > 0) {
						ans6.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}




				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);


	const BtnDisabled = () => {
		if (meArray.length !== 0 && familyArray.length !== 0 && friendsArray.length !== 0
			&& acquaintanceArray.length !== 0 && interactionArray.length !== 0 && strangerArray.length !== 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-1">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">Engage </h3>
								<p className="mb-2  mt-3">This is a list of conversational topics. Drag and place them into the boundary circle you would feel comfortable discussing them in. Drag the statement into the box you think fits best!  </p>







								<div className="row">
									<div className="col-lg-7" >
										<div className="row" ref={medrop}>
											<div className="col-lg-5">
												<div className="box-drag">
													Me
												</div>
											</div>
											<div className="col-lg-7" >
												<div className="close-box-design list-inline mt-1" >
													<ul>

														{meArray.map((e) => {

															return (
																<>

																	<li>
																		<button
																			onClick={onItemRemoveMe}
																			data-id={e.id}
																			type="button"
																			className="btn-close"
																			aria-label="Close"
																		/>  {e.name}
																	</li>



																</>
															)


														})}


													</ul>
												</div>
											</div>
										</div>

										<div className="row" ref={familydrop}>
											<div className="col-lg-5">
												<div className="box-drag family-c">
													Family
												</div>
											</div>
											<div className="col-lg-7">
												<div className="close-box-design list-inline mt-1">
													<ul>
														{familyArray.map((e) => {

															return (
																<>

																	<li>
																		<button
																			onClick={onItemRemoveFamily}
																			data-id={e.id}
																			type="button"
																			className="btn-close"
																			aria-label="Close"
																		/>  {e.name}
																	</li>



																</>
															)


														})}
													</ul>
												</div>
											</div>
										</div>
										<div className="row" ref={friendsdrop}>
											<div className="col-lg-5">
												<div className="box-drag friend-c">
													Friends
												</div>
											</div>
											<div className="col-lg-7">
												<div className="close-box-design list-inline mt-1">
													<ul>
														{friendsArray.map((e) => {

															return (
																<>

																	<li>
																		<button
																			onClick={onItemRemoveFriends}
																			data-id={e.id}
																			type="button"
																			className="btn-close"
																			aria-label="Close"
																		/>  {e.name}
																	</li>



																</>
															)


														})}
													</ul>
												</div>
											</div>
										</div>

										<div className="row" ref={acquaintancedrop}>
											<div className="col-lg-5">
												<div className="box-drag acquaintance-c">
													Acquaintance
												</div>
											</div>
											<div className="col-lg-7">
												<div className="close-box-design list-inline mt-1">
													<ul>
														{acquaintanceArray.map((e) => {

															return (
																<>

																	<li>
																		<button
																			onClick={onItemRemoveAcquaintance}
																			data-id={e.id}
																			type="button"
																			className="btn-close"
																			aria-label="Close"
																		/>  {e.name}
																	</li>



																</>
															)


														})}
													</ul>
												</div>
											</div>
										</div>

										<div className="row" ref={interactiondrop}>
											<div className="col-lg-5">
												<div className="box-drag interaction-c">
													Interaction
												</div>
											</div>
											<div className="col-lg-7">
												<div className="close-box-design list-inline mt-1">
													<ul>
														{interactionArray.map((e) => {

															return (
																<>

																	<li>
																		<button
																			onClick={onItemRemoveInteraction}
																			data-id={e.id}
																			type="button"
																			className="btn-close"
																			aria-label="Close"
																		/>  {e.name}
																	</li>



																</>
															)


														})}
													</ul>
												</div>
											</div>
										</div>
										<div className="row" ref={strangerdrop}>
											<div className="col-lg-5">
												<div className="box-drag strangers-c">
													Strangers
												</div>
											</div>
											<div className="col-lg-7">
												<div className="close-box-design list-inline mt-1">
													<ul>
														{strangerArray.map((e) => {

															return (
																<>

																	<li>
																		<button
																			onClick={onItemRemoveStranger}
																			data-id={e.id}
																			type="button"
																			className="btn-close"
																			aria-label="Close"
																		/>  {e.name}
																	</li>



																</>
															)


														})}
													</ul>
												</div>
											</div>
										</div>

									</div>

									<div className="col-lg-5" >
										<ul class="list-style-set list-circle cursor-pointer ml-1">
											{
												PictureList.map((item, index) => {
													return (
														<>

															<Chapter03Screen15_1 key={item.id} id={item.id} imageName={item.name} />

														</>
													)
												})
											}

										</ul>
									</div>
								</div>




							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="60%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-3-19.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-13" />
								{/* <NextBtn link="/module-05/chapter-03-screen-16" text="Go Next"/> */}
								<BtnDisabled />




							</div>
						</div>
					</div>
				</div>
			</div>



		</>
	);
};

export default Chapter03Screen15;
