import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter04Screen23 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-5">

							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/chap02-4/23.jpg"
										alt=""
									/>
								</center>
							</div>
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>


								<p className="mb-2 mt-3">A <b>Comprehensive Sexuality Education (CSE)</b>  is a process of learning about the many, interconnected aspects of sexuality. As we said in this module, being curious about and exploring our sexuality and body is a commonly found human experience. Learning about sexuality helps understand our values, boundaries, self-image and attitudes to sex and relationships.  </p>
								<p className="mb-2 mt-3">It encourages more information about sexual health and safe sex practices. It helps us develop responsibilities as sexually active individuals. We can question and challenge expectations/ pressures that do not fit with our individual experience and/ or are discriminatory towards anyone who is perceived to not ‘adhere to norms’. We are more aware of our rights and how we can exercise them to live a happier, freer and affirmative sexual life!  </p>


							</div>




						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-22" />
								<NextBtn link="/module-05/chapter-04-screen-23n" text="Go Next" />



							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter04Screen23;
