import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
const Chapter04Screen12 = () => {

	const navigate = useNavigate();


	const [checkedQues7, setCheckedQues7] = useState([]);
	const [checkedQues7Option1, setCheckedQues7Option1] = useState('');
	const [checkedQues7Option2, setCheckedQues7Option2] = useState('');
	const [checkedQues7Option3, setCheckedQues7Option3] = useState('');
	const [checkedQues7Option4, setCheckedQues7Option4] = useState('');
	const [checkedQues7Option5, setCheckedQues7Option5] = useState('');
	const [checkedQues7Option6, setCheckedQues7Option6] = useState('');
	const [checkedQues7Option7, setCheckedQues7Option7] = useState('');
	const [checkedQues7Option8, setCheckedQues7Option8] = useState('');
	const [checkedQues7Option9, setCheckedQues7Option9] = useState('');
	const [checkedQues7Option10, setCheckedQues7Option10] = useState('');
	const [checkedQues7Option11, setCheckedQues7Option11] = useState('');
	const [checkedQues7Option12, setCheckedQues7Option12] = useState('');
	const [checkedQues7Option13, setCheckedQues7Option13] = useState('');

	const [checkedQues8, setCheckedQues8] = useState([]);
	const [checkedQues8Option1, setCheckedQues8Option1] = useState('');
	const [checkedQues8Option2, setCheckedQues8Option2] = useState('');
	const [checkedQues8Option3, setCheckedQues8Option3] = useState('');
	const [checkedQues8Option4, setCheckedQues8Option4] = useState('');



	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);
		checkedVal(quesId, e.target.value)
	}
	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;
		checkBoxChecked(res, checked, quesId);


		if (quesId === '8') {
			setCheckedQues8(checkedQues8 => [...checkedQues8, res]);
		}




	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},

				"ques8": {
					"ans": checkedQues8.join(','),
					"ques_id": "8"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsFinalQuiz',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-04-screen-13')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues8.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {


		if (param === '8') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues8(ansArr)
		}





	}
	const checkBoxChecked = (val, checked, quesId) => {




		if (quesId === '8') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues8Option1('checked');
				} else {
					setCheckedQues8Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues8Option2('checked');
				} else {
					setCheckedQues8Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues8Option3('checked');
				} else {
					setCheckedQues8Option3('');
				}
			}

			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues8Option4('checked');
				} else {
					setCheckedQues8Option4('');
				}
			}












		}


	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsFinalQuizResult',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])




	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="36" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-center justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>






								<h4 className="mb-2 mt-3 pn"> <span> <b>Q8.</b></span> <b>  Which of the following statements are false?</b></h4>

								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle"
											defaultValue="option1"

											onClick={saveGenderBased}
											checked={checkedQues8Option1}
											data-quesId="8"

										/>
										<label className="form-check-label" htmlFor="mycirle">Certain identities have more power and privileges historically and they are the ones who are largely in decision making positions in the society which often leads to securing all the resources for themselves hence violating the rights of others.</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle2"
											defaultValue="option2"


											onClick={saveGenderBased}
											checked={checkedQues8Option2}
											data-quesId="8"
										/>
										<label className="form-check-label" htmlFor="mycirle2">Intersections of identities are not important to consider when working on Human Rights </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle3"
											defaultValue="option3"
											onClick={saveGenderBased}
											checked={checkedQues8Option3}
											data-quesId="8"
										/>
										<label className="form-check-label" htmlFor="mycirle3">A man’s identity is the most powerful irrespective of the caste, class, religion and sexual identities </label>
									</div>
								</div>

								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle5"
											defaultValue="option4"
											onClick={saveGenderBased}
											checked={checkedQues8Option4}
											data-quesId="8"
										/>
										<label className="form-check-label" htmlFor="mycirle5">Intersections of identities with historically less power and privileges end up struggling to ensure their basic rights which often deprives them from growing beyond their struggles, leave alone make it to the decision making positions. This constant cycle leads to further rise in inequality </label>
									</div>
								</div>
							</div>

							<div
								className="col-lg-4 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										className="mt-0"
										width="80%"
										src="/assets/img/md3/md3-shap-4-07.png"
										alt=""
									/>
								</center>
							</div>


						</div>


					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-04-screen-11" />

									{/* <NextBtn link="/module-03/chapter-04-screen-13" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen12;
