import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";

const Chapter03Screen09 = () => {
  const navigate = useNavigate();
  const [checkedQues5, setCheckedQues5] = useState('');
  const [checkedQues6, setCheckedQues6] = useState('');
 
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",         
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",       
                
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",       
       
          "ques_id": "4"
        },
        "ques5": {
          "ans": checkedQues5,
          "ques_id": "5"
        },
        "ques6": {
          "ans": checkedQues6,
          "ques_id": "6"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/HumanRightsChapter03',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-03/chapter-03-screen-10')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/HumanRightsChapter03Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          results.map((result) => {
            console.log(result);
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const checkedVal = (param,ans) => {
    if (param === '5') {
      setCheckedQues5(ans)
   
    }
    if (param === '6') {
      setCheckedQues6(ans)
      
    }
  }
  const BtnDisabled = () => {
    if ( checkedQues5 !== ''||checkedQues6 != '') {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };


  return (
    <>
      <div className="wrapper md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="1/4" percentage="25" star="2" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-4">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
              <table className="table table-bordered tableset-td">
            
                <tbody>
                 
                  <tr>
                    <td>Has a stable monthly income that is adequate for their family needs</td>
                  </tr>
                  <tr>
                    <td>Has access to adequate and quality health care, including sexual and reproductive health care</td>
                  </tr>
                  <tr>
                    <td>Has never been discriminated based on their identity</td>
                  </tr>
                  <tr>
                    <td>Have never been stared at or harassed while walking down the street</td>
                  </tr>
                  <tr>
                    <td>Has access to apply for employment of their choice</td>
                  </tr>
                  <tr>
                    <td>Has access to adequate food, shelter, water</td>
                  </tr>
                  <tr>
                    <td>Can marry a person of their choice irrespective of caste, religion, gender, sexuality and ability status</td>
                  </tr>
                 
                </tbody>
              </table> 
              </div>
              <div
                className="col-lg-8 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <p className=" mt-3"> Which identity is the most ahead?</p>
                <div className="mt-1 positon-submit">
                  <textarea className="form-control" rows={1} placeholder="Type your Answer......." 
                  defaultValue={checkedQues5} 
                    onChange={(e)=>setCheckedQues5(e.target.value)}
                  />
                </div>

                <p className=" mt-3">Which identity is the most behind?</p>
                <div className="mt-1 positon-submit">
                  <textarea className="form-control" rows={1} placeholder="Type your Answer......." 
                  defaultValue={checkedQues6} 
                  onChange={(e)=>setCheckedQues6(e.target.value)}
                  />
                </div>
    
                


              </div>
             
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-03/chapter-03-screen-08"/>  
                {/* <NextBtn link="/module-03/chapter-03-screen-10" text="Go Next"/> */}
                <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen09;
