import React from "react";
import { useDrag } from "react-dnd";
import { NavLink } from 'react-router-dom';

function Image({ id, url,imageName }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "image",
    item: { id: id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
   <>
        

        <div className="col-lg-2" ref={drag}>
                      <NavLink to="">
                        <div className="img-box-text">
                          <div className="img-box-drag"><center>
                          <img 
                                 src={"/assets/img/md4/"+url}
                                draggable="false"
                                alt=""
                                id={id}
                                width="150px"
                                style={{ border: isDragging ? "5px solid pink" : "0px" }}
                          
                           />
                          </center></div>
                          <div className="img-box-content">{imageName} </div>
                        </div>
                      </NavLink>
                    </div>

   </>
  );
}

export default Image;
