import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen25 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3 mt-3">Learn:  </h3>
								<p className="mb-2 mt-3 pn">In our society, pleasure and desire have been constructed differently for men and women. Men have historically been allowed more freedom, choice and leeway in their sexuality and sexual activity/ behaviour. </p>
								<p className="mb-2 mt-3 pn">From an early age, girls are judged for their appearance, conduct and behaviour. Sexual expression is mostly absent. Girls are shamed for having or expressing sexual desires. They are taught that sexual activity is for the purpose of procreating and providing pleasure to only their partners thereby performing their “wifely duty”. Ideas of purity and virginity are found everywhere. Families, media and other influences socialise women to be docile, passive, and modest in their own sexuality; while catering to partners’ needs and wants. Pleasure and desire is rarely ever mutual.  </p>




							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="49%"
										draggable="false"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>




						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-24" />
								<NextBtn link="/module-05/chapter-03-screen-26" text="Go Next" />

							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter03Screen25;
