import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { Modal, Button } from "react-bootstrap";

const Chapter04Screen44 = () => {

	const navigate = useNavigate();

	const [checkedQues01Option1, setCheckedQues01Option1] = useState('');
	const [checkedQues01Option2, setCheckedQues01Option2] = useState('');

	const [checkedQues01Val, setCheckedQues01Val] = useState('');

	const [checkedQues02Option1, setCheckedQues02Option1] = useState('');
	const [checkedQues02Option2, setCheckedQues02Option2] = useState('');

	const [checkedQues02Val, setCheckedQues02Val] = useState('');

	const [checkedQues03Option1, setCheckedQues03Option1] = useState('');
	const [checkedQues03Option2, setCheckedQues03Option2] = useState('');

	const [checkedQues03Val, setCheckedQues03Val] = useState('');

	const [checkedQues04Option1, setCheckedQues04Option1] = useState('');
	const [checkedQues04Option2, setCheckedQues04Option2] = useState('');

	const [checkedQues04Val, setCheckedQues04Val] = useState('');




	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		checkedVal(quesId, e.target.value)
	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues01Val,
					"ques_id": "1"
				},
				"ques2": {
					"ans": checkedQues02Val,
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues03Val,
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues04Val,
					"ques_id": "4"
				},



			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityFinalQuiz',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-04-screen-45')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues01Val !== '' && checkedQues02Val !== '' && checkedQues03Val !== '' && checkedQues04Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '1') {
			if (ans === 'option1') {
				setCheckedQues01Option1('checked')
				setCheckedQues01Option2('');

			} else if (ans === 'option2') {
				setCheckedQues01Option1('')
				setCheckedQues01Option2('checked');

			}
			setCheckedQues01Val(ans)
		}

		if (param === '2') {
			if (ans === 'option1') {
				setCheckedQues02Option1('checked')
				setCheckedQues02Option2('');

			} else if (ans === 'option2') {
				setCheckedQues02Option1('')
				setCheckedQues02Option2('checked');

			}
			setCheckedQues02Val(ans)
		}

		if (param === '3') {
			if (ans === 'option1') {
				setCheckedQues03Option1('checked')
				setCheckedQues03Option2('');

			} else if (ans === 'option2') {
				setCheckedQues03Option1('')
				setCheckedQues03Option2('checked');

			}
			setCheckedQues03Val(ans)
		}

		if (param === '4') {
			if (ans === 'option1') {
				setCheckedQues04Option1('checked')
				setCheckedQues04Option2('');

			} else if (ans === 'option2') {
				setCheckedQues04Option1('')
				setCheckedQues04Option2('checked');

			}
			setCheckedQues04Val(ans)
		}




	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityFinalQuizResult',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3">Final quiz for the module </h3>
								<p className="mb-2 mt-3">Read the statements below, and mark Yes/ No based on what you think</p>

								<p className="mb-2 mt-3 pn"><span><b>1.</b></span> <b>	Sexuality is only connected with our physical bodies and sexual behaviour.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle" id="mycirle1"
											defaultValue="option1"


											onClick={saveRadio}
											checked={checkedQues01Option1}
											data-quesId="1"
										/>
										<label className="form-check-label" htmlFor="mycirle1">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle" id="mycirle2"
											defaultValue="option2"


											onClick={saveRadio}
											checked={checkedQues01Option2}
											data-quesId="1"

										/>
										<label className="form-check-label" htmlFor="mycirle2">No</label>
									</div>
								</div>

								<p className="mb-2 mt-3 pn"><span><b>2.</b></span> <b>	Some sexual identities are normal and natural, while others are abnormal or a deviation.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle2" id="mycirle3"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues02Option1}
											data-quesId="2"
										/>
										<label className="form-check-label" htmlFor="mycirle3">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle2" id="mycirle4"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues02Option2}
											data-quesId="2"
										/>
										<label className="form-check-label" htmlFor="mycirle4">No</label>
									</div>
								</div>

								<p className="mb-2 mt-3 pn"><span><b>3.</b></span> <b>	Different parts of sexuality work independently and do not interact with each other, for example, our feelings and thoughts (psychological) is not connected to sexual behaviour (biological).  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle5"
											defaultValue="option1"


											onClick={saveRadio}
											checked={checkedQues03Option1}
											data-quesId="3"

										/>
										<label className="form-check-label" htmlFor="mycirle5">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle6"
											defaultValue="option2"


											onClick={saveRadio}
											checked={checkedQues03Option2}
											data-quesId="3"

										/>
										<label className="form-check-label" htmlFor="mycirle6">No</label>
									</div>
								</div>


								<p className="mb-2 mt-3 pn"><span><b>4.</b></span> <b>	We are all socialised from a young age on what kinds of sexual identities are acceptable, what sexual behaviour is allowed etc.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle4" id="mycirle7"
											defaultValue="option1"


											onClick={saveRadio}
											checked={checkedQues04Option1}
											data-quesId="4"

										/>
										<label className="form-check-label" htmlFor="mycirle7">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle4" id="mycirle8"
											defaultValue="option2"


											onClick={saveRadio}
											checked={checkedQues04Option2}
											data-quesId="4"
										/>
										<label className="form-check-label" htmlFor="mycirle8">No</label>
									</div>
								</div>
							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="95%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-4-44.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-35" />
								{/* <NextBtn link="/module-05/chapter-04-screen-45" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>



		</>
	);
};

export default Chapter04Screen44;
