import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../config/Baseurl";
import swal from "react-bootstrap-sweetalert"
import Header from "../../header/Header";

const Faq = () => {
	let navigate = useNavigate();
	let location = useLocation();
	const [searchInput, setSearchInput] = useState();
	const [showMessage, setShowMessage] = useState();
	const [showErrorMessage, setShowErrorMessage] = useState();
	const [items, setItems] = useState([]);
	const [checkBlankData, setCheckBlankData] = useState("blank");
	// console.log(JSON.parse(localStorage.getItem('previous_link')));
	const previous_link = JSON.parse(localStorage.getItem('previous_link'));
	const searchFaq = (e) => {
		e.preventDefault();
		// console.log(searchInput);
		if (searchInput === undefined) {
			swal("Please Enter Something!", "You clicked the button!", "warning");
			return false;
		}
		const config = {
			method: "get",
			url: BaseURL + "/faq?search=" + searchInput,
			headers: {},
		};
		setItems([]);
		setShowErrorMessage('');
		// console.log(BaseURL+"/faq?search="+searchInput);
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					setItems(response.data.data);
					setSearchInput('');
					setCheckBlankData('filled');
				} else {
					setCheckBlankData('filled');
					setShowErrorMessage(response.data.message);
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const addFaq = (e) => {
		// alert();
		e.preventDefault();
		const user_details = JSON.parse(localStorage.getItem('user_details'));
		const user_id = (user_details != null) ? user_details.user_id : "";
		const data = JSON.stringify({
			user_id: user_id,
			question: searchInput,
		});
		var config = {
			method: "post",
			url: BaseURL + "/addfaq",
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		};
		setItems([]);
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					setShowMessage(response.data.message);
					setSearchInput('');
					setShowErrorMessage('');
					setCheckBlankData('blank');
				} else {
					setCheckBlankData('filled');
					setShowMessage(response.data.message);
				}
				// console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				console.log(error);
			});
	};
	const BtnFormDisabled = () => {
		if (checkBlankData !== 'blank') {
			return (
				<>


					<p className="mt-3">
						If you still didn't find your answer, kindly click on submit
						button.
					</p>
					<button className="button_su">
						<span className="su_button_circle"></span>
						{/* <NavLink to="#" className="button_su_inner">
                    <span className="button_text_container">
                      {" "}
                      Submit <i className="bi bi-arrow-right" />
                    </span>{" "}
                  </NavLink> */}

						<button className="button_su_inner " onClick={addFaq}>
							Submit
						</button>

					</button>

				</>
			)
		} else {
			return (
				<>
					<div className="d-flex mt-30">
						<div className="mr-20">
							<div className="gkw-img d-flex " >
								<NavLink to={previous_link}>
									<center><img src="/assets/img/icon/wapas-jayen.svg" alt="" /></center>
									<p className="button_su_inner">Back</p>
								</NavLink>
							</div>
						</div>
						<div >
							<div className="" >
								{/* <button  class="btnset disabled-btn" type="button" disabled> सबमिट करें !</button> */}
							</div>
						</div>
					</div>



				</>
			)
		}
	}


	return (
		<>
			<div className="wrapper ">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="0" star="0" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-center ">
							<div className="col-lg-9">
								<h2 className="h2">FAQs</h2>
								<div className="box-set-chat">
									<div className="chat-position">
										<div className="box-radius chat-type">
											<span className="success">{showMessage}</span>
											<span className="error">{showErrorMessage}</span>
											<div className="manage-height">
												<div className="content-area-chat">
													{items.map((item) => {
														return (
															<>
																<div className="chat-me " key={item.id}>
																	<div className="float-right">
																		<p className="">{item.question}</p>
																	</div>
																</div>
																<div className="chat-you">
																	<div className="">
																		<p>
																			{/* <span>A .</span> */}
																			{item.answers}
																		</p>
																	</div>
																</div>
															</>
														);
													})}
												</div>
											</div>
											<form onSubmit={searchFaq}>
												<div className="chat-area d-flex">
													<input
														type="text"
														name=""
														className="chat-input"
														id=""
														placeholder="Type Anything........"
														value={searchInput}
														onChange={(e) => {
															setSearchInput(e.target.value);
														}}
													/>
													{/* <NavLink to="" className="send-icon" onClick={searchFaq}>
                        <img src="/assets/img/icon/send.svg" alt="" />
                      </NavLink> */}
													<button className="send-icon" type="submit"><img src="/assets/img/send.svg" alt="" /></button>
												</div>
											</form>



											{/* <div >
                        <div className="camp-select">
                          <select className="selectcamp mycamp" data-style="btn-primary">
                            <option>campaign-1</option>
                            <option>campaign-2</option>
                            <option>campaign-3</option>
                          </select>
                        </div>
                      </div> */}





										</div>
									</div>
								</div>
								<BtnFormDisabled />








							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Faq;




