import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { Modal, Button } from "react-bootstrap";

const Chapter04Screen45 = () => {

	const navigate = useNavigate();

	const [checkedQues05Option1, setCheckedQues05Option1] = useState('');
	const [checkedQues05Option2, setCheckedQues05Option2] = useState('');

	const [checkedQues05Val, setCheckedQues05Val] = useState('');

	const [checkedQues06Option1, setCheckedQues06Option1] = useState('');
	const [checkedQues06Option2, setCheckedQues06Option2] = useState('');

	const [checkedQues06Val, setCheckedQues06Val] = useState('');

	const [checkedQues07Option1, setCheckedQues07Option1] = useState('');
	const [checkedQues07Option2, setCheckedQues07Option2] = useState('');

	const [checkedQues07Val, setCheckedQues07Val] = useState('');

	const [checkedQues08Option1, setCheckedQues08Option1] = useState('');
	const [checkedQues08Option2, setCheckedQues08Option2] = useState('');

	const [checkedQues08Val, setCheckedQues08Val] = useState('');

	const [checkedQues09Option1, setCheckedQues09Option1] = useState('');
	const [checkedQues09Option2, setCheckedQues09Option2] = useState('');

	const [checkedQues09Val, setCheckedQues09Val] = useState('');



	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		checkedVal(quesId, e.target.value)
	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques5": {
					"ans": checkedQues05Val,
					"ques_id": "5"
				},
				"ques6": {
					"ans": checkedQues06Val,
					"ques_id": "6"
				},
				"ques7": {
					"ans": checkedQues07Val,
					"ques_id": "7"
				},
				"ques8": {
					"ans": checkedQues08Val,
					"ques_id": "8"
				},
				"ques9": {
					"ans": checkedQues09Val,
					"ques_id": "9"
				},



			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityFinalQuiz',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-04-screen-46')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues05Val !== '' && checkedQues06Val !== '' && checkedQues07Val !== '' && checkedQues08Val !== '' && checkedQues09Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '5') {
			if (ans === 'option1') {
				setCheckedQues05Option1('checked')
				setCheckedQues05Option2('');

			} else if (ans === 'option2') {
				setCheckedQues05Option1('')
				setCheckedQues05Option2('checked');

			}
			setCheckedQues05Val(ans)
		}

		if (param === '6') {
			if (ans === 'option1') {
				setCheckedQues06Option1('checked')
				setCheckedQues06Option2('');

			} else if (ans === 'option2') {
				setCheckedQues06Option1('')
				setCheckedQues06Option2('checked');

			}
			setCheckedQues06Val(ans)
		}

		if (param === '7') {
			if (ans === 'option1') {
				setCheckedQues07Option1('checked')
				setCheckedQues07Option2('');

			} else if (ans === 'option2') {
				setCheckedQues07Option1('')
				setCheckedQues07Option2('checked');

			}
			setCheckedQues07Val(ans)
		}

		if (param === '8') {
			if (ans === 'option1') {
				setCheckedQues08Option1('checked')
				setCheckedQues08Option2('');

			} else if (ans === 'option2') {
				setCheckedQues08Option1('')
				setCheckedQues08Option2('checked');

			}
			setCheckedQues08Val(ans)
		}

		if (param === '9') {
			if (ans === 'option1') {
				setCheckedQues09Option1('checked')
				setCheckedQues09Option2('');

			} else if (ans === 'option2') {
				setCheckedQues09Option1('')
				setCheckedQues09Option2('checked');

			}
			setCheckedQues09Val(ans)
		}





	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityFinalQuizResult',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>


								<p className="mb-2 mt-3 pn"><span><b>5.</b></span> <b>	Media, family, institutions – these are some examples of socio-cultural influences on our sexuality.   </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle" id="mycirle1"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues05Option1}
											data-quesId="5"
										/>
										<label className="form-check-label" htmlFor="mycirle1">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle" id="mycirle2"
											defaultValue="option2"
											onClick={saveRadio}
											checked={checkedQues05Option2}
											data-quesId="5"

										/>
										<label className="form-check-label" htmlFor="mycirle2">No</label>
									</div>
								</div>

								<p className="mb-2 mt-3 pn"><span><b>6.</b></span> <b>	Sexual relationships between male and female are the only desirable kind for marriage and having children.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle2" id="mycirle3"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues06Option1}
											data-quesId="6"
										/>
										<label className="form-check-label" htmlFor="mycirle3">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle2" id="mycirle4"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues06Option2}
											data-quesId="6"

										/>
										<label className="form-check-label" htmlFor="mycirle4">Yes</label>
									</div>
								</div>

								<p className="mb-2 mt-3 pn"><span><b>7.</b></span> <b>	Sexuality standards for girls are different than for boys. Boys often have more freedom while girls’ sexuality is controlled and suppressed.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle5"
											defaultValue="option1"


											onClick={saveRadio}
											checked={checkedQues07Option1}
											data-quesId="7"
										/>
										<label className="form-check-label" htmlFor="mycirle5">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle6"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues07Option2}
											data-quesId="7"

										/>
										<label className="form-check-label" htmlFor="mycirle6">No</label>
									</div>
								</div>


								<p className="mb-2 mt-3 pn"><span><b>8.</b></span> <b>The media messages we receive on gender and sexuality are constructed and may be judgemental or have norms attached to them.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle4" id="mycirle7"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues08Option1}
											data-quesId="8"

										/>
										<label className="form-check-label" htmlFor="mycirle7">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle4" id="mycirle8"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues08Option2}
											data-quesId="8"

										/>
										<label className="form-check-label" htmlFor="mycirle8">No</label>
									</div>
								</div>

								<p className="mb-2 mt-3 pn"><span><b>9.</b></span> <b>	A person who is attracted to the same sex is only confused/ or going through a phase.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle5" id="mycirle9"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues09Option1}
											data-quesId="9"

										/>
										<label className="form-check-label" htmlFor="mycirle9">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle5" id="mycirle10"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues09Option2}
											data-quesId="9"

										/>
										<label className="form-check-label" htmlFor="mycirle10">No</label>
									</div>
								</div>


							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-4-45.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-44" />
								{/* <NextBtn link="/module-05/chapter-04-screen-46" text="Go Next"/> */}

								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>



		</>
	);
};

export default Chapter04Screen45;
