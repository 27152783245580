import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter02Screen12 = () => {
  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="1/5" percentage="42" star="42" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-2 mt-m-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <p>
                  When the traits, behaviours and characteristics of <b>Group A </b> are
                  displayed it’s called <b>Hegemonic Masculinity.</b> They feel
                  they are entitled to freedom of movement, speech, decision
                  making, resource management, use of violence, discriminating
                  against others.{" "}
                </p>
                <p className="mt-2">
                  Similarly, When the traits, behaviours and characteristics of
                  <b>Group B</b> are displayed it’s called <b>Complicit Masculinity</b>.
                </p>
                <p className="mt-3">
                  <b>Group C- Marginalised Masculinity </b> For eg: Dalit,
                  disadvantaged economic class, minotrity religion
                </p>
                <p className="mt-3">
                  <b>Group D- Subordinated Masculinity</b> For eg: Men who cross
                  dress, transmen, men who identify as homesexual
                </p>
                <p className="mt-3">
                <b> Group E: Inclusive and gender equitable Masculinity</b>{" "}
                </p>


                <p className="">
                  It is important to note that as part of the socialisation
                  process many of these behaviours are learnt and throughout
                  life new behaviours are learnt and old are unlearnt, therefore
                  one can see intersections of these Masculinities being
                  practiced through behaviours and actions in boys and men. It
                  is important to identify these behaviours as part of these
                  masculinities to understand the perspective and the
                  socialisation process one must have been through. This enables
                  building deeper understanding and to work towards building a
                  more inclusive and gender equitable Masculinity that respects
                  diversity. Boys and men belonging to upper caste, upper class,
                  majority religion are often socialised into practising
                  hegemonic masculinity ideals. Our systems and structures also
                  enable this for them and reward them however, even though they
                  enjoy more privileges and power they also have to bear the
                  burden throughout their lives and in the process lose their
                  humanity. Upcoming chapters will talk more about this in
                  detail.</p>
                  

              </div>
             
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-04/chapter-02-screen-11"/>                  
                
                <NextBtn link="/module-04/chapter-02-screen-13" text="Go Next"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen12;
