import React from 'react'
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter05Screen05 = () => {
  return (
    <>
    <div className="wrapper page-auto">
      <div
        className="fixed-header"
       
      >
        <div className="container-fluid cf-set">
        <Header leftChapter="4/5" percentage="82" star="64" />
        </div>
      </div>
      <div className="data-adjust">
        <div className="container-fluid cf-set">
          <div className="row align-items-top justify-content-between pt-20 mt-3">
            <div
              className="col-lg-12 wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              style={{
                visibility: "visible",
                animationDuration: "1s",
                animationDelay: "0.2s",
                animationName: "fadeInDown"
              }}
            >

            <h2 className="h2 mb-1">Engage</h2>
              <p className="mb-3">
                Payal was born into a family of five children- two boys and three
                girls. She was the first child and the eldest amongst her
                siblings. After her mother gave birth to 3 girls, her father and
                his family wanted her mother to give birth to a son. They stopped
                after she had 2 younger brothers. Her father worked on a farm and
                sometimes her mother supported him too along with taking care of
                all the household chores. Her father’s income wasn’t enough to
                ensure sufficient food for everyone in the family. As in most
                families in her community, her father and brothers were fed first,
                then she and her sisters ate what was left, and her mother ate
                last. Payal grew slowly, but this was considered normal. She
                studied in a government school near her house and after finishing
                education in class 8 she had to stop because her school was only
                till class 8 and her parents told her that it wasn’t safe for
                young girls to go to a school far from home, alone. They also did
                not have money to support her education any further. Her brothers
                and younger sisters continued, while Payal stayed home to help
                their mother with the livestock, household chores and caring for
                her younger siblings. By the time Payal was 16, the family was
                better off financially. They had learnt some new agricultural
                techniques and were selling more crops. Payal wanted to return to
                school, but her father would not let her because his family kept
                telling him it was inappropriate for a girl to be educated. The
                school was far away, and he was concerned about her travelling
                that distance. Also, there was only one female teacher at the
                school, and he did not find it appropriate that his daughter, who
                had reached puberty, be taught by men. Besides, he explained to
                his wife, Payal would be getting married soon – there was no need
                for her to go back to school, and no need to risk her being
                spoiled before marriage.
              </p>

              <p className="mb-5">
                  Payal was soon married to Sunil, a truck driver, and went to
                  live with his family. Within four months she was pregnant. By
                  the time she was 20, she had three daughters. She was always
                  tired, her health was poor, and she often felt isolated and
                  depressed. Though she couldn’t read, she had heard about
                  family planning and suggested to her husband that they
                  consider it so she could have a rest. Sunil became furious and
                  beat her. He pointed out that she had not yet provided him
                  with a son and that family planning was unnatural, anyway. His
                  mother agreed. Payal, feeling that she had been appropriately
                  reprimanded for her bold and presumptuous behaviour, did not
                  bring up the subject again. Payal’s health continued to
                  deteriorate. She was treated several times at the health
                  clinic for itchiness and discharge in her genital area. Each
                  time, the nurses at the clinic told her she should use condoms
                  to prevent this sickness. They would become quite annoyed that
                  she had not used them. But Payal knew that Sunil would refuse
                  them. Payal’s fourth child was a son and Sunil was very
                  pleased. He looked forward to his second and third sons.
                  Meanwhile, Payal became sadder and more tired.
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="button-fixed wow fadeInDown"
      data-wow-duration="1s"
      data-wow-delay="0.3s"
    >
      <div className="container-fluid cf-set">
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex align-items-top justify-content-end mt-4">
             
              <PrevBtn link="/module-01/chapter-05-screen-04" />
              <NextBtn link="/module-01/chapter-05-screen-07" text="Go Next" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  
  )
}

export default Chapter05Screen05