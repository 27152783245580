import React,{useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BaseURL from '../../config/Baseurl';

const Protected = (props) => {

    const navigate = useNavigate();
    const {Component} = props;
    const user_details = JSON.parse(localStorage.getItem("user_details"));

    
  // console.log('he');
    useEffect(()=>{
      checkUser();
        if(user_details === null)
        {
          localStorage.clear();

          navigate('/login');
          // location.reload();
        }
    })

    const checkUser = ()=>{
    
      
      var data = JSON.stringify({
        "user_id": user_details.user_id
      });
      
      var config = {
        method: 'post',
     
        url: BaseURL+'/checkUser',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        if(response.data.success === false)
        {
          localStorage.clear();
          navigate('/login');
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
      
    }

  return (
   <>
        <Component/>
   </>
  )
}

export default Protected