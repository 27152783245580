import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import PrevBtn from "../../../common/PrevBtn";
import Header from '../../../header/Header';
import { useDrop } from "react-dnd";
import Image from './Image';
import axios from 'axios';
import BaseURL from '../../../config/Baseurl';
import { useEffect } from 'react';

const Chapter01Screen022 = () => {

	const navigate = useNavigate();
	const [PictureList, setPictureList] = useState([
		{ "id": "1", "image": "sanitary-napkins.png", "name": "sanitary-napkins" },
		{ "id": "2", "image": "shirt.png", "name": "shirt" },
		{ "id": "3", "image": "film-magazine.png", "name": "Film Magazine" },
		{ "id": "4", "image": "skipping-rope.png", "name": "Skipping Rope" },
		{ "id": "5", "image": "runing-shoes.png", "name": "Running Shoes" },
		{ "id": "6", "image": "lip-bdm.png", "name": "Lip balm" },
		{ "id": "7", "image": "skirt.png", "name": "Skirt" },
		{ "id": "8", "image": "football.png", "name": "Football" },
		{ "id": "9", "image": "laptop.png", "name": "Laptop" },
		{ "id": "10", "image": "condoms.png", "name": "Condoms" },
		{ "id": "11", "image": "face-wash.png", "name": "Face Wash" },
		{ "id": "12", "image": "sling-bag.png", "name": "Sling Bag" },
		{ "id": "13", "image": "shaving-razor.png", "name": "Shaving Raozor" },
		{ "id": "14", "image": "wallet.png", "name": "wallet" },
	]);


	const [samarArray, setsamarArray] = useState([]);
	const [sahasArray, setsahasArray] = useState([]);
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const [{ isOver }, drop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addSamarArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const [{ isShasOver }, sahasdrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addSahasArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));


	// Add To Array Samar
	const addSamarArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setsamarArray((samarArray) => [...samarArray, picture_List[0]]);
	};

	// Add To sahas Array
	const addSahasArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setsahasArray((sahasArray) => [...sahasArray, picture_List[0]]);
	};

	const onItemRemoveSamar = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = samarArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setsamarArray(samarArray =>
			samarArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};

	const onItemRemoveSahas = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = sahasArray.filter((item) => item.id === id);
		console.log(_item);
		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setsahasArray(sahasArray =>
			sahasArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": JSON.stringify(sahasArray),
					"ans2": JSON.stringify(samarArray),
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-01-screen-02');
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var axios = require('axios');
		var data = JSON.stringify({
			"user_id": user_details.user_id,

			"ques_id": "1"
		});

		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const ans = JSON.parse(response.data.data.chapter.ans)
					const ans2 = JSON.parse(response.data.data.chapter.ans2)
					setsahasArray(ans);
					setsamarArray(ans2);
					if (ans.length > 0) {
						ans.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans2.length > 0) {
						ans2.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}


				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);

	console.log(samarArray.length);

	const BtnDisabled = () => {
		if (sahasArray.length !== 0 || sahasArray.length !== null && samarArray.length !== 0 || samarArray.length !== null) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};




	return (
		<>
			<div className="wrapper ">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="0" star="0" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-center pt-20 ">
							{/* <h3 className="h3 mt-3">Engage</h3> */}

							<div
								className="col-lg-12 text-center wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2 mb-1">Engage</h2>
								<p>
									Samar and Sahas are going for a trip.For their travel they need to pack their bags. What are the things Samar and Sahas will pack in their bags? Please devide & drag the things given in the list based on what you think Samar and Sahas will pack for their trips.
								</p>
							</div>
							<div
								className="col-lg-12 text-center wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="sahas">
									<div className="sahas-bag" ref={sahasdrop}>
										{" "}
										<img src="/assets/img/chapter1/sahas-bag.png" alt="" />{" "}
									</div>
									<div className="sahas-btn"> Sahas</div>
									<div className="close-box-design d-flex align-items-center mt-2">
										<ul>
											{sahasArray.map((e) => {
												return (
													<>
														<li>
															<button
																onClick={onItemRemoveSahas}
																data-id={e.id}
																type="button"
																className="btn-close"
																aria-label="Close"
															/>
															<img src={"/assets/img/chapter1/" + e.image} id={e.id} alt="" />

														</li>

													</>
												)

											})}
										</ul>
									</div>
								</div>
								<div className="box-mange-img">
									<div className="row align-items-center justify-content-center" >
										{
											PictureList.map((e) => {
												return (
													<Image key={e.id} url={e.image} id={e.id} imageName={e.name} alt="www" />
												)
											})
										}
									</div>
								</div>
								<div className="samar">
									<div className="d-flex justify-content-end">
										<div className="sahas-bag samar-bag " ref={drop}>
											{" "}
											<img src="/assets/img/chapter1/samar-bag.png" alt="" />{" "}
										</div>
									</div>
									<div className="d-flex justify-content-end">
										<div className="sahas-btn samar-btn"> Samar</div>
									</div>
									<div className="close-box-design d-flex align-items-center  mt-2">
										<ul  >
											{samarArray.map((e) => {
												return (
													<>
														<li key={e.id}>
															<button
																onClick={onItemRemoveSamar}
																data-id={e.id}
																type="button"
																className="btn-close"
																aria-label="Close"
															/>
															<img src={"/assets/img/chapter1/" + e.image} id={e.id} />
														</li>
													</>
												)
											})}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-01-screen-01" />

								{/* <button

									className={`button_su`}
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<span className="su_button_circle" />
									<button onClick={saveAnswers} className="button_su_inner">
										<span className="button_text_container">
											Go Next <i className="bi bi-arrow-right" />
										</span>
									</button>
								</button> */}

								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>


	)
}

export default Chapter01Screen022