import React,{useState} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { Modal, Button } from "react-bootstrap";

const Chapter03Screen03 = () => {

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showModalText,setShowModalText] = useState();

	const ClickOpenPopup = (e)=>{
		const dataYear = e.currentTarget.getAttribute("data-year");
		
		if(dataYear === '1999')
		{
		  setShowModalText('In the music video, ‘Babul’ a little girl holds up a mirror to society which often ignores the uncomfortable conversation around domestic violence. Through the eyes of a little girl, the video threw light on the double standards our society thrives on, with the aim to make the issue of domestic violence both mainstream and urgent to address.')
		}
		if(dataYear === '2003')
		{
		  setShowModalText('A story of the friendship of Muslim boy and a Hindu schoolteacher, set the music of Shubha Mudgal based on a poem by poet Kabir, Baagon Na Jaa was a music video about Communal Harmony, and bond that transcend religious differences.')
		}
	
		if(dataYear === '2008')
		{
		  setShowModalText('Domestic violence is considered a ‘personal matter’. Events that transpire behind closed doors carry on without the fear of accountability. Our “Bell Bajao” campaign aimed to inch away from this status quo, pushing boys and men to ‘ring the bell’ and interrupt incidents of abuse. We created a series of award-winning PSAs, which have been viewed by over 130 million people worldwide. In 2010, our video van took the messages to cities and villages engaging communities through street theatre and games.')
		}
		if(dataYear === '2012')
		{
		  setShowModalText('In urgent response to the fast-falling sex ratio - 919 girls per thousand boys in India, we launched the #MissionHazaar campaign. During our work on domestic violence, we found that many women were forced to go in for sex-selective abortions by family members, given the lack of agency they experienced in their lives. The need to address this issue was echoed by the Government of India which adopted our campaign as a part of the 2015 Beti Bachao Beti Padhao campaign.')
		}
		if(dataYear === '2017')
		{
		  setShowModalText('Gender-based violence needs to be addressed in the multiple spaces women inhabit. For women in the workforce, this meant addressing their homes, communities, workplace and public spaces. Violence and discrimination against women is a continuum which needs to addressed in all spaces they inhabit. Our StreeLink Programme works with various stakeholders to help alter perceptions and create a safe, secure workplace that upholds gender justice.Above statement is true. To add further- Gender is socially constructed on the basis of sex assigned at birth. It is a learnt behaviour, thought process, role expectation that can change and varies based on society, culture and time periods. It is a process of socialization and refers to the ways in which children are raised and encouraged to behave, act and dress in order to be categorized as “men” and “women”.')
		}

    if(dataYear === '2021')
		{
		  setShowModalText('The Generation Equality Forum launched a 5-year action journey to achieve irreversible progress towards gender equality, founded on a series of concrete, ambitious and transformative actions, including $40 billion in financial commitments. Breakthrough was selected as a co-lead on the Gender Based Violence Action Coalition along with over 90+ other partners from across the globe. The leaders along with UN Women are expected to contribute, lead and catalyze collective action; spark global and local conversations among generations; drive increased public & private investment; deliver concrete; game-changing results for girls and women over the next 5 years across 6 thematic areas of concern.')
		}

	
		setShow(true);
	
	   
	  }

  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="0/4" percentage="25" star="50" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-3">
              <div
                className="col-lg-9 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h3 className="h2 mb-1"> Breakthrough’s Journey</h3>
                <p>
                  Here is a glimpse of Breakthrough’s journey which started with
                  Mann ke Manjeere Music album{" "}
                </p>

                <div
                  className="road_map popup"
                  onClick={ClickOpenPopup}
                  data-year="1999"

                >
                  <div className="circul d-flex align-items-center justify-content-center">
                    1999
                  </div>
                </div>
                <div className="road_map"
					 onClick={ClickOpenPopup}
                  data-year = "2003"
				>
                  <div className="circul2 d-flex align-items-center justify-content-center">
                    2003
                  </div>
                </div>
                <div className="road_map"
					 onClick={ClickOpenPopup}
                  data-year = "2008"
				>
                  <div className="circul3 d-flex align-items-center justify-content-center">
                    2008
                  </div>
                </div>
                <div className="road_map" 
					 onClick={ClickOpenPopup}
                  data-year = "2012"
				>
                  <div className="circul4 d-flex align-items-center justify-content-center">
                    2012
                  </div>
                </div>
                <div className="road_map" 
					 onClick={ClickOpenPopup}
                  data-year = "2017"
				>
                  <div className="circul5 d-flex align-items-center justify-content-center">
                    2017
                  </div>
                </div>
                <div className="road_map"
					 onClick={ClickOpenPopup}
                  data-year = "2021"
				>
                  <div className="circul6 d-flex align-items-center justify-content-center">
                    2021
                  </div>
                </div>
                <img src="/assets/img/road.png" alt="" width="100%" />
              </div>

              <div
                className="col-lg-3 mt-m-3 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >
                <center>
                  <img
                    width="65%"
                    src="/assets/img/md6/md-60chap-1-3.png"
                    alt=""
                  />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-06/chapter-01-screen-05" />

                <NextBtn
                  link="/module-06/chapter-01-screen-07"
                  text="Go Next"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

	  <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Women are physically weak :</Modal.Title> */}
        </Modal.Header>

        <Modal.Body>
         <p>{showModalText}</p>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Chapter03Screen03;
