import React, { useState, useEffect } from "react";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Header from "../../../header/Header";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter04Screen28 = () => {


	const navigate = useNavigate();
	const [checkedQues32_1, setCheckedQues32_1] = useState('');
	const [checkedQues32_2, setCheckedQues32_2] = useState('');
	const [checkedQues32_3, setCheckedQues32_3] = useState('');
	const [checkedQues32_4, setCheckedQues32_4] = useState('');
	// const [checkedQues32_5, setCheckedQues11_5] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques32": {
					"ans": checkedQues32_1 + "," + checkedQues32_2 + "," + checkedQues32_3 + "," + checkedQues32_4,
					"ques_id": "32"
				}




			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-04-screen-29')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": "32"
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter.ans;
					const res = results.split(",");
					res.map((elem, i) => {
						console.log(i);
						if (i === 0) {
							setCheckedQues32_1(elem)
						}
						if (i === 1) {
							setCheckedQues32_2(elem)
						}
						if (i === 2) {
							setCheckedQues32_3(elem)
						}
						if (i === 3) {
							setCheckedQues32_4(elem)
						}

					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	const BtnDisabled = () => {
		if (checkedQues32_1 !== '' && checkedQues32_2 !== '' && checkedQues32_4 !== '' 
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper page-auto bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-0">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3">Apply:  </h3>
								<p className="mb-2  mt-3"> <b>How can partners discuss contraception? Read this conversation and fill in the blanks. Sheetal wants to use condoms and visit a doctor for an STI test. How can she bring this up? How can Raj respond in a way that is considerate of Sheetal and mindful of the risks involved? </b> </p>


								<p className="mb-2  mt-3">Sheetal and Raj recently become sexually active with each other. They are slowly moving towards having sex for the first time. But they have not yet talked about contraception. Sheetal is hesitant to bring up the topic but she knows it is important. </p>
								<p className="mb-2  mt-3">Sheetal: Before we have sex, I think we should talk about contraception. I like you a lot but I am concerned about our safety. </p>
								<p className="mb-2  mt-3">Raj: Arre but nothing will happen. It’s so not romantic to talk about these things. Sex is about passion, I promise I will pull out.  </p>

								<div className="row ">
									<div className="col-lg-9 ">
										<ul className="list-style-set list-input list-line-height mt-3 mb-3">
											<li>Sheetal: I don’t think pulling out is a reliable idea. Instead, we can think of </li>
											<li>
												<input type="text" placeholder onChange={(e) => { setCheckedQues32_1(e.target.value) }} data-quesId="32"
													defaultValue={checkedQues32_1} className="input-without-style form-control" />
											</li>

											<li className="mt-4">Raj:  You are right we should use condoms, but why get an STI test, that seems unnecessary.    </li>
											<li><input type="text" placeholder
												className="input-without-style form-control"
												onChange={(e) => { setCheckedQues32_2(e.target.value) }}
												data-quesId="32"
												defaultValue={checkedQues32_2}
											/></li>


											{/* <li>but why get an STI test, that seems unnecessary.   <input type="text"
                   placeholder 
                   className="input-without-style form-control" 
                   onChange={(e)=>{setCheckedQues32_3(e.target.value)}}
                      data-quesId="32"
                      defaultValue={checkedQues32_3} 
                   /></li> */}




											<li className="mt-4">Sheetal: I think we should because   </li>

											<li><input type="text" placeholder
												className="input-without-style form-control"
												onChange={(e) => { setCheckedQues32_4(e.target.value) }}
												data-quesId="32"
												defaultValue={checkedQues32_4}
											/></li>


											<li className="mt-4">I appreciate that we talked about this. </li>
										</ul>


									</div>
								</div>



							</div>

							{/* <div
                className="col-lg-2 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="80%"
                    draggable="false"
                    src="/assets/img/md4/md4-shap-2-3.png"
                    alt=""
                  />
                </center>
              </div> */}



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-26" />
								{/* <NextBtn link="/module-05/chapter-04-screen-29" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>



		</>
	);
};

export default Chapter04Screen28;
