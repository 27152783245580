import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter01Screen06 = () => {
	return (
		<>
			<div className="wrapper vidoe-bg">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="5" star="5" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.1s"
							>
								<h2 className="h2">Learn&nbsp;</h2>
								<p>
									Sex Assigned at Birth and Gender Identity:
								</p>
								<h5>What Is The Difference?</h5>





								<iframe
									width="100%"
									height="360"
									src="https://www.youtube.com/embed/Y19kYh6k7ls"
									title="Sex Assigned at Birth and Gender Identity: What Is The Difference?"
									frameborder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowfullscreen
								></iframe>



							</div>
							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.1s"
							>

								<center>
									<img
										width="65%"
										className="mb-4"
										draggable="false"
										src="/assets/img/md1/md1-shap-6.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-01-screen-05" />

								<NextBtn
									link="/module-01/chapter-01-screen-07"
									text="Go Next"
									classtype=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen06;
