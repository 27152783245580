import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";

const Chapter03Screen07 = () => {
	const navigate = useNavigate();
	const [checkedQues3_1, setCheckedQues3_1] = useState('');
	const [checkedQues3_2, setCheckedQues3_2] = useState('');
	const [checkedQues3_3, setCheckedQues3_3] = useState('');
	const [checkedQues3_4, setCheckedQues3_4] = useState('');
	const [checkedQues3_5, setCheckedQues3_5] = useState('');
	const [checkedQues3_6, setCheckedQues3_6] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",


					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3_1,
					"ans2": checkedQues3_2,
					"ans3": checkedQues3_3,
					"ans4": checkedQues3_4,
					"ans5": checkedQues3_5,
					"ans6": checkedQues3_6,
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-03-screen-08')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					results.map((result) => {
						console.log(result);
						checkedVal(result.ques_id.toString(), result.ans, result.ans2, result.ans3, result.ans4, result.ans5, result.ans6)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const checkedVal = (param, ans, ans2, ans3, ans4, ans5, ans6) => {
		if (param === '3') {
			setCheckedQues3_1(ans)
			setCheckedQues3_2(ans2)
			setCheckedQues3_3(ans3)
			setCheckedQues3_4(ans4)
			setCheckedQues3_5(ans5)
			setCheckedQues3_6(ans6)

		}




	}


	const BtnDisabled = () => {
		if (checkedQues3_1 !== '' || checkedQues3_2 !== '' || checkedQues3_3 !== '' || checkedQues3_4 !== '' || checkedQues3_5 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="25" star="2" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h4 className="mb-2"> <b>Let’s imagine Identities 1,2,3,4,5 and see if the following statements apply to them. If you think the statement holds true for one or more identities you can move them one step forward. Do this one statement at a time.</b></h4>
							</div>

							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<div className="row">
									<div className="col-lg-12 identities-design">
										<div className="plans ">
											<label className="plan basic-plan" htmlFor="basic">
												<input type="checkbox" name="select1" id="basic" data-quesid="2_1" defaultValue="pencil" />
												<div className="plan-content">Identity 1: A transgender woman, middle class, speaks English{" "}</div>
											</label>
										</div>
										<div className="plans ">
											<label className="plan basic-plan" htmlFor="basic2">
												<input type="checkbox" name="select2" id="basic2" data-quesid="2_1" defaultValue="pencil" />
												<div className="plan-content">Identity 2: A dalit man, street dweller with no formal education{" "}</div>
											</label>
										</div>
										<div className="plans ">
											<label className="plan basic-plan" htmlFor="basic3">
												<input type="checkbox" name="select3" id="basic3" data-quesid="2_1" defaultValue="pencil" />
												<div className="plan-content">Identity 3: A cisgender, heterosexual, upper class, Hindu man{" "} </div>
											</label>
										</div>
										<div className="plans ">
											<label className="plan basic-plan" htmlFor="basic4">
												<input type="checkbox" name="select4" id="basic4" data-quesid="2_1" defaultValue="pencil" />
												<div className="plan-content">Identity 4: 70 yrs old woman with Alzheimer living below poverty line{" "} </div>
											</label>
										</div>
										<div className="plans ">
											<label className="plan basic-plan" htmlFor="basic5">
												<input type="checkbox" name="select5" id="basic5" data-quesid="2_1" defaultValue="pencil" />
												<div className="plan-content">Identity 5: A homesexual woman with PhD and visually impaired{" "}</div>
											</label>
										</div>
										<div className="plans ">
											<label className="plan basic-plan" htmlFor="basic6">
												<input type="checkbox" name="select6" id="basic6" data-quesid="2_1" defaultValue="pencil" />
												<div className="plan-content">Identity 6: Yourself{" "}</div>
											</label>
										</div>
									</div>
									<div className="col-lg-11">
										<div className=" positon-submit mt-2">
											<textarea className="form-control" rows={1} placeholder="Write Something here....."
												defaultValue={checkedQues3_1}
												onChange={(e) => setCheckedQues3_1(e.target.value)}
											/>
										</div>
										<div className="mt-1 positon-submit">
											<textarea className="form-control" rows={1} placeholder="Write Something here....."
												defaultValue={checkedQues3_2}
												onChange={(e) => setCheckedQues3_2(e.target.value)}

											/>
										</div>
										<div className="mt-1 positon-submit">
											<textarea className="form-control" rows={1} placeholder="Write Something here....."
												defaultValue={checkedQues3_3}
												onChange={(e) => setCheckedQues3_3(e.target.value)}

											/>
										</div>
										<div className="mt-1 positon-submit">
											<textarea className="form-control" rows={1} placeholder="Write Something here....."
												defaultValue={checkedQues3_4}
												onChange={(e) => setCheckedQues3_4(e.target.value)}
											/>
										</div>

										<div className=" positon-submit">
											<textarea className="form-control" rows={1} placeholder="Write Something here....."
												defaultValue={checkedQues3_5}
												onChange={(e) => setCheckedQues3_5(e.target.value)}
											/>
										</div>
										<div className="mt-1 positon-submit">
											<textarea className="form-control" rows={1} placeholder="Write Something here....."
												defaultValue={checkedQues3_6}
												onChange={(e) => setCheckedQues3_6(e.target.value)}
											/>
										</div>


									</div>
								</div>

								

{/* 
								<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							> */}
								<p className="mb-2">For example: If the statement is-  <b>Can travel at night safely without fear</b> {" "} </p>
								{/* <h3 className="mb-2"> <b>This statement most likely only applies to identity 3 and so you can move them one step forward.</b> {" "} </h3> */}
							{/* </div> */}


							</div>

							
							<div
								className="col-lg-4 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="70%"
										draggable="false"
										src="/assets/img/md3/md3-shap-1-7.png"
										alt=""
									/>
								</center>
							</div>
						</div>

					</div>
				</div>

				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-03-screen-06" />
									{/* <NextBtn link="/module-03/chapter-03-screen-08" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen07;
