import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen17 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3 mb-2">Additional Resources  </h3>
								<a
									href="https://www.youtube.com/watch?v=i83VQIaDlQw"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>	Range of identities  </p>
										</div>
									</div>
								</a>

								<a
									href="https://www.apa.org/pi/lgbt/programs/safe-supportive/lgbt/key-terms.pdf"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>	Sexuality definitions  </p>
										</div>
									</div>
								</a>
								<a
									href="https://sexualityanddisability.org/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Gender and Sexuality Galaxy  </p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2020/12/23/myths-related-to-lgbtqia-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Myths around <b>LGBTQIA+ </b>identities   </p>
										</div>
									</div>
								</a>

								<a
									href="https://agentsofishq.com/what-is-heteronormativity-a-comic/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>	Heteronormativity and normalcy  </p>
										</div>
									</div>
								</a>

								<a
									href="https://www.youtube.com/watch?v=iplGW7MNNmI&t=120s"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>	History of alternate identities in India  </p>
										</div>
									</div>
								</a>

								<a
									href="https://www.youtube.com/watch?v=F4d6kGGfLvA"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>	Bollywood and sexual/gender representation </p>
										</div>
									</div>
								</a>

								<a
									href="https://www.youtube.com/watch?v=hYP8oFJB3SY"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>	Things Transgender And Non-Binary People Are Tired Of Hearing | BuzzFeed India</p>
										</div>
									</div>
								</a>

								{/* <a
                  href="https://www.42d.org/2020/07/21/42-degrees-glossary-and-lexicon-2/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="d-flex align-items-center mange-icon mt-2">
                    <div>
                      <img src="/assets/img/chapter1/list-icon.png" alt="" />
                    </div>
                    <div>
                      <p>	42 Degrees Glossary and Lexicon</p>
                    </div>
                  </div>
                </a> */}


							</div>

							<div
								className="col-lg-4 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="50%"
										draggable="false"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-23" />
								<NextBtn link="/module-05/chapter-03-screen-01" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen17;
