import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";

const Chapter03Screen30 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3 mt-3">Reflect  </h3>
								<NavLink to="#">
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>	Dil Dhadakne Do </p>
										</div>
									</div>
								</NavLink>

								<p className="mb-2  mt-3">What are the ideas of sexual relationships we see in this video? Between the 3rd couple, the man is shown to want sexual activity. We see that she does not want to. Finally, we see he reacts poorly, annoyed and aggressive. Recall the discussions on power and patriarchy we had in the gender module. These are very important in understanding how sex and sexuality are different based on gender. </p>

							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="90%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-17.png"
										alt=""
									/>
								</center>
							</div>




						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-29" />
								<NextBtn link="/module-05/chapter-03-screen-31" text="Go Next" />

							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter03Screen30;
