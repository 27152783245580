import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen18 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-2">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>


								<h3 className="h3 mt-3">Engage:  </h3>
								<p className="mb-2  mt-3">Write an assertive way to deal with each situation using what we read above. Try using this sentence:  </p>
								<ul className="list-style-set list-input mt-3 mb-3">
									<li>I feel  <input type="text" placeholder className="input-without-style form-control" /> when <input type="text" placeholder className="input-without-style form-control" /> and I want </li>
								</ul>
								<p className="mb-2  mt-3">Example: Your friend tells you to shut up.  </p>


								<p className="mb-2  mt-3"><i>I feel hurt when you tell me to “shut up” and I want us to talk without being harsh</i> </p>
								<ul className="list-style-set decimal mt-3 mb-3">
									<li>Your parents are arguing a lot and it is upsetting you </li>
									<li>You worry a friend likes you in a romantic way but you do not feel the same way</li>
									<li>Your partner wants to be in a physical relationship. You like them but are not ready yet</li>
									<li>You have concerns about your friend’s relationship and want to ask them how they feel without being pushy.  </li>
								</ul>

							</div>

							<div
								className="col-lg-5 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/chap02-3/17.jpg"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-17" />
								<NextBtn link="/module-05/chapter-03-screen-20" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter03Screen18;
