import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen17 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-2">
							<div
								className="col-lg-5 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3 mt-3">Learn </h3>

								<p className="mb-2  mt-3">The transgender community have been one of the worst sufferers of exploitation among the LGBTQ+ community due to an unchanging conception of binary male and female genders. Because they do not fit this gender binary, transgender individuals are subjected to exclusion, humiliation and violence at the hands of families, institutions and authorities in power. Suffering from the lowest educational, employment, economic and socio-cultural opportunity and practically no resources, they are vulnerable to risky sexual behaviour, sexual violence, beggary, trafficking, STDs and more.  </p>
								<p className="mb-2  mt-3">While a 2014 judgement of the Supreme Court recognising the third gender brought in a new ray of hope for the community, the passing of the 2018 IPC bill was a disappointment. It has been criticised for further oppression by the community. For the entire LGBTQ+ community, legal rights and affirmative provisions are necessary to be able to live a life of freedom. This has to be supported by an inclusive social, cultural and media ecosystem for complete equality and independence. </p>


							</div>

							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/chap02-2/22.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-21" />
								<NextBtn link="/module-05/chapter-02-screen-23" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen17;
