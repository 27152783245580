import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import BaseURL, { AdminUrl } from "../../../config/Baseurl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
const Chapter01Screen04 = () => {

	const navigate = useNavigate();
	const [checkedQues3, setCheckedQues3] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const [images, setImages] = useState([]);
	const [imageurl1, setImageUrl1] = useState();


	const onImageChange = async (e) => {



		setImages([...e.target.files]);



		const questId = '3';

		const user_details = JSON.parse(localStorage.getItem("user_details"));
		const user_id = user_details.user_id;
		const files = e.target.files;
		const formData = new FormData();


		formData.append("image1", files[0]);


		formData.append("user_id", user_id);
		formData.append("ques_id", questId);

		// console.log(user_details.user_id);

		fetch(BaseURL + "/selfImageUploadCh01", {
			method: "POST",
			body: formData,
		}).then((res) => {

			res.json().then((result) => {


			});
		});

		// console.log(questId);
	};


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3,
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-02/chapter-01-screen-05')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					results.map((result) => {
						console.log(result);
						if (result.ques_id === 3) {

							setImageUrl1(result.ans2)
						}
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const checkedVal = (param, ans) => {
		if (param === '3') {
			setCheckedQues3(ans)
		}




	}


	const BtnDisabled = () => {
		if (checkedQues3 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper bg-bd-2">

				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="3" star="2" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">



						<div className="row align-items-center justify-content-between pt-20 mt-2 ">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<p className="mb-2 mt-3"> Bring a pen and a paper.Draw a circle with me written in the centre. Now think out of all these identities which ones are currently close to you and which ones are distant to you. Write them on your Identity map keeping the ones close to you closer to ‘me’ and the ones distant from you distant from ‘me’. As showed in the example below </p>
								<div className="mt-4 positon-submit">
									<textarea
										className="form-control"
										rows={6}
										placeholder="Write Something here....."
										onChange={(e) => setCheckedQues3(e.target.value)}
										data-quesId="3"
										defaultValue={checkedQues3}
									/>

								</div>

								<div class="file-input mt-5">
									<input type="file" name="file-input" id="file-input5" class="file-input__input" data-image="image5" onChange={onImageChange} />
									<label class="file-input__label" for="file-input5">
										<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" class="svg-inline--fa fa-upload fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
											<path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
										</svg>
										<span>Upload file</span></label>
									<a target="__blank" href={AdminUrl + "/public/mascul/" + imageurl1}>{imageurl1}</a>
								</div>
							</div>
							<div
								className="col-lg-5 mt-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img width="45%" src="/assets/img/md2/md2-shap-1-7.png" alt="" />
								</center>
							</div>
						</div>








					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-01-screen-03" />

								{/* <NextBtn
                  link="/module-02/chapter-01-screen-05"
                  text="Go Next"
                /> */}

								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen04;
