import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";


const Chapter04Screen07 = () => {

	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="36" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">

						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h4 className="mb-3 h3 mt-3"> <b> Additional Resources  </b></h4>
							</div>

							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<a
									href="https://www.youtube.com/watch?v=ElTOEFuv7Lc&feature=youtu.be"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Human Rights Violation Salwa Judum</p>
										</div>
									</div>
								</a>
								<a
									href="https://www.youtube.com/watch?v=bHWfDAmUUPI&list=PLWqowEyMVW8ZUeG-7AXJT6Tj8gmrBC1z2&index=4"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Will India's Working Poor Be Heard? | #WeThePeople | Karwan e Mohabbat</p>
										</div>
									</div>
								</a>
								<a
									href="https://www.youtube.com/watch?v=xHv8V4s_QYY&list=PLWqowEyMVW8ZUeG-7AXJT6Tj8gmrBC1z2&index=8"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>#COVID19 | The Second Wave Of Hunger And Despair | Karwan e Mohabbat</p>
										</div>
									</div>
								</a>


								<a
									href="https://www.theadvocatesforhumanrights.org/Defenders"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p><b>For Human Rights Defenders</b></p>
										</div>
									</div>
								</a>
								<a
									href="https://pjp-eu.coe.int/documents/42128013/47262631/Amnesty+International+Community+Campaigns_Strategies+for+Human+Rights+Defenders.pdf/e495b5ee-5352-cafc-4a39-b15568c20e2b"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p><b>Strategies for human rights defenders</b></p>
										</div>
									</div>
								</a>

							</div>

							<div
								className="col-lg-4 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										className="mt-0"
										width="65%"
										src="/assets/img/md3/md3-shap-4-07.png"
										alt=""
									/>
								</center>
							</div>

						</div>


					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/chapter-04-screen-06" />

									<NextBtn link="/module-03/chapter-04-screen-08" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen07;
