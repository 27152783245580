import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
const Chapter02Screen06 = () => {
	const navigate = useNavigate();
	const [checkedQues1, setCheckedQues1] = useState([]);
	const [checkedQues1Option1, setCheckedQues1Option1] = useState('');
	const [checkedQues1Option2, setCheckedQues1Option2] = useState('');
	const [checkedQues1Option3, setCheckedQues1Option3] = useState('');
	const [checkedQues2, setCheckedQues2] = useState([]);
	const [checkedQues2Option1, setCheckedQues2Option1] = useState('');
	const [checkedQues2Option2, setCheckedQues2Option2] = useState('');
	const [checkedQues2Option3, setCheckedQues2Option3] = useState('');
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;
		checkBoxChecked(res, checked, quesId);
		if (quesId === '1') {
			setCheckedQues1(checkedQues1 => [...checkedQues1, res]);
		}
		if (quesId === '2') {
			setCheckedQues2(checkedQues2 => [...checkedQues2, res]);
		}
	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues1.join(','),
					"ques_id": "1"
				},
				"ques2": {
					"ans": checkedQues2.join(','),
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/ApproachFinalQuiz',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.success === true) {
					navigate('/module-06/chapter-02-screen-07')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues1.length > 0 && checkedQues2.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '1') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues1(ansArr)
		}
		if (param === '2') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues2(ansArr)
		}
	}
	const checkBoxChecked = (val, checked, quesId) => {
		if (quesId === '1') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues1Option1('checked');
				} else {
					setCheckedQues1Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues1Option2('checked');
				} else {
					setCheckedQues1Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues1Option3('checked');
				} else {
					setCheckedQues1Option3('');
				}
			}
		}
		if (quesId === '2') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues2Option1('checked');
				} else {
					setCheckedQues2Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues2Option2('checked');
				} else {
					setCheckedQues2Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues2Option3('checked');
				} else {
					setCheckedQues2Option3('');
				}
			}
		}
	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/ApproachFinalQuizResult',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2 mt-m-3">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2 mb-1">Based on the film above </h2>
								<p className="mb-2 mt-2 pn text-bold"> <span> <b>1.</b></span> <b> Identify what was the deep engagement with young people. Select the right option/s</b> </p>
								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											onClick={saveGenderBased}
											checked={checkedQues1Option1}
											data-quesId="1"
											name="namecircle"
											id="mycirle"
											defaultValue="option1"
										/>
										<label className="form-check-label" htmlFor="mycirle">Awareness on gender discrimination and importance of gender equality </label></div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle2"
											defaultValue="option2"
											onClick={saveGenderBased}
											checked={checkedQues1Option2}
											data-quesId="1"
										/>
										<label className="form-check-label" htmlFor="mycirle2">Strengthening their understanding of Maths and Science</label></div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle3"
											onClick={saveGenderBased}
											defaultValue="option3"
											checked={checkedQues1Option3}
											data-quesId="1"
										/>
										<label className="form-check-label" htmlFor="mycirle3">	Building their ability to negotiate and take their own decisions  </label></div>
								</div>
								<p className="mb-2 mt-2 pn text-bold"> <span> <b>2.</b></span> <b> Identify what was done to create an enabling environment.  Select the right option/s </b> </p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle4"
											onClick={saveGenderBased}
											defaultValue="option1"
											checked={checkedQues2Option1}
											data-quesId="2"
										/>
										<label className="form-check-label" htmlFor="mycirle4">Engagement with schools</label></div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle5"
											onClick={saveGenderBased}
											defaultValue="option2"
											checked={checkedQues2Option2}
											data-quesId="2"
										/>
										<label className="form-check-label" htmlFor="mycirle5"> Engagement with Parents </label></div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle6"
											onClick={saveGenderBased}
											defaultValue="option3"
											checked={checkedQues2Option3}
											data-quesId="2"
										/>
										<label className="form-check-label" htmlFor="mycirle6"> Engagement with Sarpanch </label></div>
								</div>
							</div>


							<div className="col-lg-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">
								<center>
									<img width="50%" draggable="false" src="/assets/img/md6/md-60chap-2-6.png" alt="" />
								</center>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-06/chapter-02-screen-05" />
								{/* <NextBtn link="/module-06/chapter-02-screen-07" text="Go Next"/> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Chapter02Screen06;
