import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";

const Chapter02Screen11 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2 hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-5">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3">Learn:  </h3>
								<p className="mb-2  mt-3">While we may have challenges in inter-generational communication with our parents, what about our peers or friends? Are we able to openly discuss our sexuality and identity with them? </p>

								<a
									href="https://www.youtube.com/watch?v=3DKWmJnDj7M"
									target="_blank"
								>
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Coming Out</p>
										</div>
									</div>
								</a>

								<p className="mb-2  mt-3">A person with a diverse sexual identity has many thoughts running in their mind on how they will be seen, accepted or loved when people know their sexual orientation. This includes the fear of losing family or friends. The fear of stigma and ostracism runs deep as this is a reality and can force people to hide and suppress their most sincere feelings and emotions from friends and family.  </p>




							</div>

							<div
								className="col-lg-3 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="65%"
										draggable="false"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
									{/* <img
                    width="100%"
                    draggable="false"
                    src="/assets/img/md5/thought.jpg"
                    alt=""
                  /> */}
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-10" />
								{/* <NextBtn link="/module-05/chapter-01-screen-06" text="Go Next"/> */}

								<button className="button_su" >
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>


							</div>
						</div>
					</div>
				</div>
			</div>




			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>
							<p className="text-white text-center">Text 1: Bhai, tujhe kuch batana hai par I don’t know how to say this. I feel embarrassed and scared to say it. I like boys. I don’t want ki hamari dosti khatam ho iske wajah se ya tujhe lage ki I’m weird.  </p>
							<p className="text-white text-center">Text 2: Yaar hum itne saal se dost hai, I’m so glad you told me! Koi farak nahi padta ki kisi ko kya lage, aur ko kya kahe. You’ll always be my friend. You are the same person and kuch bhi ho I will support you. </p>


							<div className=" d-flex justify-content-center mt-3">
								<button className="button_su m-auto" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="/module-05/chapter-02-screen-12" className="button_su_inner" ><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen11;
