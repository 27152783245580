import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter03Screen16 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-1">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3 mt-3">Share:  </h3>
								<p className="mb-2  mt-3">From all that we have learnt until now, can you think of some values around sexuality that are important to you? Have these ever been neglected in relationships? </p>
								<p className="mb-2  mt-3">Have you had any experiences communicating your boundaries to others? – This can be in sexual relationships or in relationships with friends/ family/ relatives. </p>
								<p className="mb-2  mt-3">You can also discuss with your peers on their values and boundary setting experiences.  </p>









							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="70%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-3.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-15" />
								<NextBtn link="/module-05/chapter-03-screen-17" text="Go Next" />



							</div>
						</div>
					</div>
				</div>
			</div>


		</>
	);
};

export default Chapter03Screen16;
