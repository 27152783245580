import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter01Screen10 = () => {

	const navigate = useNavigate();
	const [checkedQues18Option1, setCheckedQues18Option1] = useState('');
	const [checkedQues18Option2, setCheckedQues18Option2] = useState('');
	const [checkedQues18Val, setCheckedQues18Val] = useState('');

	const [checkedQues19Option1, setCheckedQues19Option1] = useState('');
	const [checkedQues19Option2, setCheckedQues19Option2] = useState('');
	const [checkedQues19Val, setCheckedQues19Val] = useState('');

	const [checkedQues20Option1, setCheckedQues20Option1] = useState('');
	const [checkedQues20Option2, setCheckedQues20Option2] = useState('');
	const [checkedQues20Val, setCheckedQues20Val] = useState('');





	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showModalText, setShowModalText] = useState();
	const user_details = JSON.parse(localStorage.getItem('user_details'));



	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		checkedVal(quesId, e.target.value)
	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques18": {
					"ans": checkedQues18Val,
					"ques_id": "18"
				},
				"ques19": {
					"ans": checkedQues19Val,
					"ques_id": "19"
				},
				"ques20": {
					"ans": checkedQues20Val,
					"ques_id": "20"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-01-screen-18th')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const checkedVal = (param, ans) => {
		if (param === '18') {
			if (ans === 'option1') {
				setCheckedQues18Option1('checked')
				setCheckedQues18Option2('')

			} else if (ans === 'option2') {
				setCheckedQues18Option1('')
				setCheckedQues18Option2('checked')

			}
			setCheckedQues18Val(ans)
		}

		if (param === '19') {
			if (ans === 'option1') {
				setCheckedQues19Option1('checked')
				setCheckedQues19Option2('')

			} else if (ans === 'option2') {
				setCheckedQues19Option1('')
				setCheckedQues19Option2('checked')

			}
			setCheckedQues19Val(ans)
		}

		if (param === '20') {
			if (ans === 'option1') {
				setCheckedQues20Option1('checked')
				setCheckedQues20Option2('')

			} else if (ans === 'option2') {
				setCheckedQues20Option1('')
				setCheckedQues20Option2('checked')

			}
			setCheckedQues20Val(ans)
		}






	}



	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	const BtnDisabled = () => {
		if (checkedQues18Val !== '' && checkedQues19Val !== '' && checkedQues20Val !== ''
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/2" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4 mt-m-3">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>



								<h3 className="h3">Engage </h3>
								<p className="mt-3">Let’s decode some media messages found in these popular Bollywood songs!  </p>
								<p className="mt-3">In the first two short clips, look at the visualisation of these songs. Think of how bodies are shown? Whose body are we seeing? How is another gender responding to this display?  </p>
								<p className="mt-3">Chikni Chameli  </p>

								<a href="https://www.youtube.com/watch?v=J4eZ0GNgv98" target="_blank">
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>	Hawkins ad </p>
										</div>
									</div>
								</a>
								<p className="mt-2">Baby Doll  </p>

								<p className="mt-3"> Is there a sexual presentation of the actresses’ body in these videos</p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="radio"
											name="inlineRadioOptions"
											id="inlineRadio1"
											onClick={saveRadio}
											defaultValue="option1"
											data-quesId="18"
											checked={checkedQues18Option1}
										/>
										<label className="form-check-label" htmlFor="inlineRadio1">Yes</label>
									</div>
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="inlineRadioOptions"
											id="inlineRadio2"
											onClick={saveRadio}
											defaultValue="option2"
											data-quesId="18"
											checked={checkedQues18Option2}
										/>
										<label className="form-check-label " htmlFor="inlineRadio2">No</label>
									</div>
								</div>

								<p className="mt-3"> Is it shown to be an object of male desire / enticing them?</p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="inlineRadioOptions2"
											id="inlineRadio3"
											onClick={saveRadio}
											defaultValue="option1"
											data-quesId="19"
											checked={checkedQues19Option1}

										/>
										<label className="form-check-label" htmlFor="inlineRadio3">Yes</label>
									</div>
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="radio" name="inlineRadioOptions2"
											id="inlineRadio4"
											onClick={saveRadio}
											defaultValue="option2"
											data-quesId="19"
											checked={checkedQues19Option2}

										/>
										<label className="form-check-label " htmlFor="inlineRadio4">No</label>
									</div>
								</div>

								<p className="mt-3"> Do the women’s bodies in these clips fit conventional ideas of sexiness / beauty (thin, fair, revealing clothing etc.) </p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="inlineRadioOptions3"
											id="inlineRadio5"
											onClick={saveRadio}
											defaultValue="option1"
											data-quesId="20"
											checked={checkedQues20Option1}
										/>
										<label className="form-check-label" htmlFor="inlineRadio5">Yes</label>
									</div>
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check"
											type="radio" name="inlineRadioOptions3"
											id="inlineRadio6"
											onClick={saveRadio}
											defaultValue="option2"
											data-quesId="20"
											checked={checkedQues20Option2}
										/>
										<label className="form-check-label " htmlFor="inlineRadio6">No</label>
									</div>
								</div>

							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="65%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-2-2.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>







				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-01-screen-18n" />
								{/* <NextBtn link="/module-05/chapter-01-screen-18th" text="Go Next"/> */}
								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen10;
