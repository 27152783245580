import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { Modal, Button } from "react-bootstrap";

const Chapter04Screen46 = () => {

	const navigate = useNavigate();

	const [checkedQues10Option1, setCheckedQues10Option1] = useState('');
	const [checkedQues10Option2, setCheckedQues10Option2] = useState('');

	const [checkedQues10Val, setCheckedQues10Val] = useState('');

	const [checkedQues11Option1, setCheckedQues11Option1] = useState('');
	const [checkedQues11Option2, setCheckedQues11Option2] = useState('');

	const [checkedQues11Val, setCheckedQues11Val] = useState('');

	const [checkedQues12Option1, setCheckedQues12Option1] = useState('');
	const [checkedQues12Option2, setCheckedQues12Option2] = useState('');

	const [checkedQues12Val, setCheckedQues12Val] = useState('');

	const [checkedQues13Option1, setCheckedQues13Option1] = useState('');
	const [checkedQues13Option2, setCheckedQues13Option2] = useState('');

	const [checkedQues13Val, setCheckedQues13Val] = useState('');

	const [checkedQues14Option1, setCheckedQues14Option1] = useState('');
	const [checkedQues14Option2, setCheckedQues14Option2] = useState('');

	const [checkedQues14Val, setCheckedQues14Val] = useState('');



	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		checkedVal(quesId, e.target.value)
	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques10": {
					"ans": checkedQues10Val,
					"ques_id": "10"
				},
				"ques11": {
					"ans": checkedQues11Val,
					"ques_id": "11"
				},
				"ques12": {
					"ans": checkedQues12Val,
					"ques_id": "12"
				},
				"ques13": {
					"ans": checkedQues13Val,
					"ques_id": "13"
				},
				"ques14": {
					"ans": checkedQues14Val,
					"ques_id": "14"
				},



			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityFinalQuiz',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-04-screen-47')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues10Val !== '' && checkedQues11Val !== '' && checkedQues12Val !== '' && checkedQues13Val !== '' && checkedQues14Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '10') {
			if (ans === 'option1') {
				setCheckedQues10Option1('checked')
				setCheckedQues10Option2('');

			} else if (ans === 'option2') {
				setCheckedQues10Option1('')
				setCheckedQues10Option2('checked');

			}
			setCheckedQues10Val(ans)
		}

		if (param === '11') {
			if (ans === 'option1') {
				setCheckedQues11Option1('checked')
				setCheckedQues11Option2('');

			} else if (ans === 'option2') {
				setCheckedQues11Option1('')
				setCheckedQues11Option2('checked');

			}
			setCheckedQues11Val(ans)
		}

		if (param === '12') {
			if (ans === 'option1') {
				setCheckedQues12Option1('checked')
				setCheckedQues12Option2('');

			} else if (ans === 'option2') {
				setCheckedQues12Option1('')
				setCheckedQues12Option2('checked');

			}
			setCheckedQues12Val(ans)
		}

		if (param === '13') {
			if (ans === 'option1') {
				setCheckedQues13Option1('checked')
				setCheckedQues13Option2('');

			} else if (ans === 'option2') {
				setCheckedQues13Option1('')
				setCheckedQues13Option2('checked');

			}
			setCheckedQues13Val(ans)
		}

		if (param === '14') {
			if (ans === 'option1') {
				setCheckedQues14Option1('checked')
				setCheckedQues14Option2('');

			} else if (ans === 'option2') {
				setCheckedQues14Option1('')
				setCheckedQues14Option2('checked');

			}
			setCheckedQues14Val(ans)
		}





	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityFinalQuizResult',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>


								<p className="mb-2 mt-3 pn"><span><b>10.</b></span> <b>	All persons have to feel some kind of sexual/ romantic attraction towards others.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle" id="mycirle1"
											defaultValue="option1"
											onClick={saveRadio}
											checked={checkedQues10Option1}
											data-quesId="10"
										/>
										<label className="form-check-label" htmlFor="mycirle1">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle" id="mycirle2"
											defaultValue="option2"
											onClick={saveRadio}
											checked={checkedQues10Option2}
											data-quesId="10"
										/>
										<label className="form-check-label" htmlFor="mycirle2">No</label>
									</div>
								</div>

								<p className="mb-2 mt-3 pn"><span><b>11.</b></span> <b>People with diverse sexualities face no discrimination/ pressures/ violence based on their identity.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle2" id="mycirle3"
											defaultValue="option1"
											onClick={saveRadio}
											checked={checkedQues11Option1}
											data-quesId="11"
										/>
										<label className="form-check-label" htmlFor="mycirle3">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle2" id="mycirle4"
											defaultValue="option2"
											onClick={saveRadio}
											checked={checkedQues11Option2}
											data-quesId="11"
										/>
										<label className="form-check-label" htmlFor="mycirle4">No</label>
									</div>
								</div>

								<p className="mb-2 mt-3 pn"><span><b>12.</b></span> <b>	Transgender individuals are freely able to express themselves and live as they please. They are not bound by the gender binary. </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle5"
											defaultValue="option1"

											onClick={saveRadio}
											checked={checkedQues12Option1}
											data-quesId="12"


										/>
										<label className="form-check-label" htmlFor="mycirle5">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle6"
											defaultValue="option2"
											onClick={saveRadio}
											checked={checkedQues12Option2}
											data-quesId="12"

										/>
										<label className="form-check-label" htmlFor="mycirle6">No</label>
									</div>
								</div>


								<p className="mb-2 mt-3 pn"><span><b>13.</b></span> <b>	In sexual relationships, women should cater to the needs and desires of their male partners as their duty.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle4" id="mycirle7"
											defaultValue="option1"
											onClick={saveRadio}
											checked={checkedQues13Option1}
											data-quesId="13"
										/>
										<label className="form-check-label" htmlFor="mycirle7">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle4" id="mycirle8"
											defaultValue="option2"

											onClick={saveRadio}
											checked={checkedQues13Option2}
											data-quesId="13"
										/>
										<label className="form-check-label" htmlFor="mycirle8">No</label>
									</div>
								</div>

								<p className="mb-2 mt-3 pn"><span><b>14.</b></span> <b>Every person’s ideas of pleasure, desires and boundaries are different. When partners communicate openly with each other it makes the relationship stronger.  </b> </p>
								<div className="d-flex mt-2">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle5" id="mycirle9"
											defaultValue="option1"
											onClick={saveRadio}
											checked={checkedQues14Option1}
											data-quesId="14"
										/>
										<label className="form-check-label" htmlFor="mycirle9">Yes</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle5" id="mycirle10"
											defaultValue="option2"
											onClick={saveRadio}
											checked={checkedQues14Option2}
											data-quesId="14"
										/>
										<label className="form-check-label" htmlFor="mycirle10">No</label>
									</div>
								</div>


							</div>

							<div
								className="col-lg-2 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="80%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-1-15.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-45" />
								{/* <NextBtn link="/module-05/chapter-04-screen-47" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>



		</>
	);
};

export default Chapter04Screen46;
