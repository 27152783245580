import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter04Screen32 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-center pt-20 mt-2">


							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3 mt-3">Engage</h3>
								<p className="mb-2 mt-3">In these images below, various forms of sexual/ gender stigma are portrayed. Can we see which factor (family, peers, media) and/ or which institution (law, healthcare) is perpetuating the stigma in each case?  </p>
							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>


								<p className="mb-2 mt-3 text-center pn"> <span> <b>1.</b></span> A women experiences discrimination on asking for contraception at a pharmacy. </p>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/chap02-4/w1.jpg"
										alt=""
									/>
								</center>
							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-2 mt-3 text-center pn"> <span> <b>2.</b></span> A teacher shames a student who has had an abortion in front of a class </p>
								<center>
									<img
										width="70%"
										draggable="false"
										src="/assets/img/md5/chap02-4/w2.jpg"
										alt=""
									/>
								</center>
							</div>


							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-2 mt-3 text-center pn"> <span> <b>3.</b></span> A nurse refuses medical care to a trans woman suffering from a vaginal infection.  </p>
								<center>
									<img
										width="70%"
										draggable="false"
										src="/assets/img/md5/chap02-4/w3.jpg"
										alt=""
									/>
								</center>
							</div>




						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-04-screen-31" />
								<NextBtn link="/module-05/chapter-04-screen-33" text="Go Next" />

							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter04Screen32;
