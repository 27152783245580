import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
const Chapter03Screen21 = () => {
	const navigate = useNavigate();
	const [checkedQues6Option1, setCheckedQues6Option1] = useState('');
	const [checkedQues6Option2, setCheckedQues6Option2] = useState('');
	const [checkedQues6Option3, setCheckedQues6Option3] = useState('');
	const [checkedQues6Option4, setCheckedQues6Option4] = useState('');
	const [checkedQues6Val, setCheckedQues6Val] = useState('');


	const [checkedQues7, setCheckedQues7] = useState([]);
	const [checkedQues7Option1, setCheckedQues7Option1] = useState('');
	const [checkedQues7Option2, setCheckedQues7Option2] = useState('');
	const [checkedQues7Option3, setCheckedQues7Option3] = useState('');
	const [checkedQues7Option4, setCheckedQues7Option4] = useState('');


	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showModalText, setShowModalText] = useState();
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		const res = e.target.value;
		const checked = e.target.checked;

		checkBoxChecked(res, checked, quesId);
		if (quesId === '7') {
			setShowModalText('Consent is voluntary and a mutual agreement to do something. Permission to engage in any type of sexual contact needs to be clearly asked for and given.')
			setShow(true);
			setCheckedQues7(checkedQues7 => [...checkedQues7, res]);

		}



	}

	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		// alert(quesId);
		if (quesId === '6') {
			setShowModalText('For consent to occur, a person has to have complete space to say “no”. If there is threat, blackmail, intimidation, guilt-tripping someone, consent cannot happen')
		}

		setShow(true);
		checkedVal(quesId, e.target.value)
	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques6": {
					"ans": checkedQues6Val,
					"ques_id": "6"
				},
				"ques7": {
					"ans": checkedQues7.join(','),
					"ques_id": "7"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-03-screen-22')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const checkedVal = (param, ans) => {
		if (param === '6') {
			if (ans === 'option1') {
				setCheckedQues6Option1('checked')
				setCheckedQues6Option2('')
				setCheckedQues6Option3('')
				setCheckedQues6Option4('')
			} else if (ans === 'option2') {
				setCheckedQues6Option1('')
				setCheckedQues6Option2('checked')
				setCheckedQues6Option3('')
				setCheckedQues6Option4('')
			} else if (ans === 'option3') {
				setCheckedQues6Option1('')
				setCheckedQues6Option2('')
				setCheckedQues6Option3('checked')
				setCheckedQues6Option4('')
			} else if (ans === 'option4') {
				setCheckedQues6Option1('')
				setCheckedQues6Option2('')
				setCheckedQues6Option3('')
				setCheckedQues6Option4('checked')
			}
			setCheckedQues6Val(ans)
		}

		if (param === '7') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues7(ansArr)
		}

		// if (param === '7') {
		//   if (ans === 'option1') {
		//     setCheckedQues7Option1('checked')
		//     setCheckedQues7Option2('')
		//     setCheckedQues7Option3('')
		//     setCheckedQues7Option4('')
		//   } else if (ans === 'option2') {
		//       setCheckedQues7Option1('')
		//     setCheckedQues7Option2('checked')
		//     setCheckedQues7Option3('')
		//     setCheckedQues7Option4('')
		//     }else if (ans === 'option3') {
		//       setCheckedQues7Option1('')
		//     setCheckedQues7Option2('')
		//     setCheckedQues7Option3('checked')
		//     setCheckedQues7Option4('')
		//     }else if (ans === 'option4') {
		//       setCheckedQues7Option1('')
		//     setCheckedQues7Option2('')
		//     setCheckedQues7Option3('')
		//     setCheckedQues7Option4('checked')
		//     }
		//   setCheckedQues7Val(ans)
		// }
	}

	const checkBoxChecked = (val, checked, quesId) => {
		if (quesId === '7') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues7Option1('checked');
				} else {
					setCheckedQues7Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues7Option2('checked');
				} else {
					setCheckedQues7Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues7Option3('checked');
				} else {
					setCheckedQues7Option3('');
				}
			}
			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues7Option4('checked');
				} else {
					setCheckedQues7Option4('');
				}
			}






		}



	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	const BtnDisabled = () => {
		if (checkedQues6Val !== '' && checkedQues7.length > 0
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								{/* Yahan Par Popup Rahega */}
								<p className="mb-2 mt-3 pn"><span><b>1.</b></span> <b> What are some of the words that describe consent? </b> </p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle7"
											onClick={saveRadio}
											defaultValue="option1"
											data-quesId="6"
											checked={checkedQues6Option1}
										/>
										<label className="form-check-label" htmlFor="mycirle7">Coercion, confusion, assumptions  </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle8"
											onClick={saveRadio}
											defaultValue="option2"
											data-quesId="6"
											checked={checkedQues6Option2}
										/>
										<label className="form-check-label" htmlFor="mycirle8">Manipulation, fear, threats </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3"
											id="mycirle9"
											onClick={saveRadio}
											defaultValue="option3"
											data-quesId="6"
											checked={checkedQues6Option3}
										/>
										<label className="form-check-label" htmlFor="mycirle9">	Mutual, voluntary, clear</label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle3" id="mycirle10"
											onClick={saveRadio}
											defaultValue="option4"
											data-quesId="6"
											checked={checkedQues6Option4}
										/>
										<label className="form-check-label" htmlFor="mycirle10">	All of the above</label>
									</div>
								</div>
								<p className="mb-2 mt-3 pn"><span><b>2.</b></span> <b> Which statements are an example of coercion?  </b> </p>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle4" id="mycirle7"
											onClick={saveGenderBased}
											defaultValue="option1"
											data-quesId="7"
											checked={checkedQues7Option1}
										/>
										<label className="form-check-label" htmlFor="mycirle7">	If you loved me, you would kiss me </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle4" id="mycirle8"
											onClick={saveGenderBased}
											defaultValue="option2"
											data-quesId="7"
											checked={checkedQues7Option2}
										/>
										<label className="form-check-label" htmlFor="mycirle8">	If you do not kiss me, I will break up with you </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="checkbox"
											name="namecircle4" id="mycirle9"
											onClick={saveGenderBased}
											defaultValue="option3"
											data-quesId="7"
											checked={checkedQues7Option3}
										/>
										<label className="form-check-label" htmlFor="mycirle9">	I want to kiss you. How do you feel about that? </label>
									</div>
								</div>
							</div>


							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="80%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-3-21.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-05/chapter-03-screen-20" />
								{/* <NextBtn link="/module-05/chapter-03-screen-22" text="Go Next"/> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					{/* <Modal.Title>Women are physically weak :</Modal.Title> */}
				</Modal.Header>
				<Modal.Body>
					<p>{showModalText}</p>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default Chapter03Screen21;
