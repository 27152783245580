import React, { useState } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { NavLink } from "react-router-dom";

const Chapter05Screen11 = () => {
	const [readMoreContent, setReadMoreContent] = useState();
	const [readMoreText, setReadMoreText] = useState("Read More...");

	const text = () => {
		return (
			<>
				<p>
					Another GBV she faced was getting married off early, before the legal
					age and by force. In her marital home she faced many forms of domestic
					violence including physical, emotional, psychological and sexual. This
					happened because she was forced to keep giving birth even when she was
					not physically fit and her husband refused to use condoms, was violent
					towards her hence further deteriorating her physical and mental
					health.
				</p>
			</>
		);
	};

	const addReadMore = () => {
		setReadMoreContent(text);
		setReadMoreText("");
	};

	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="94" star="76" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20 mt-3">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2 ">Learn</h2>
								<div className="para-scroll">
									<p>
										Payal faced many types and forms of gender based
										discrimination and violence in her family many of which are
										considered normal in the society. Since her childhood, her
										parents favoured her brothers over her and discriminated
										against her- as she, her sisters and her mother being the
										girls and woman in the family ate less (leftovers) and last
										in the family. This led to her under and slower growth. She
										was also discriminated against when it came to Education.
										Her education was stopped at an early age owing to multiple
										reasons- finances, control over her mobility and sexuality
										and a cultural patriarchal norm that girls should not be
										educated and be married off soon.
									</p>
									<p>Another GBV she faced was getting married off early, before the legal age and by force. In her marital home she faced many forms of domestic violence including physical, emotional, psychological and sexual. This happened because she was forced to keep giving birth even when she was not physically fit and her husband refused to use condoms, was violent towards her hence further deteriorating her physical and mental health.</p>


									<p className="mb-2">
										It is important to note the context, spaces and culture within
										which Payal grew up and lived. Her family being poor and
										patriarchal led to her being undernourished, uneducated,
										financially dependent and ending up in an abusive household
										which followed the same cycle of violence leading to her
										physical, psychological and emotional deterioration. In the
										context of Covid, as many people had no source of livelihood,
										pushing them further into poverty, all these forms and types
										of gender based violence increased manifold leading to
										increase in the number of- girls dropping out of school and
										child, early and forced marriages, leading to more
										discrimination against girls as they are not trusted with
										digital devices for school education and are burdened with
										household chores and livelihood generation. In many households
										due to lockdown people couldn’t go out, work and earn- many
										women faced the brunt of it in the form of domestic violence
										by their partners.
									</p>
									{/* {readMoreContent} */}

								</div>

								{/* <NavLink to="" className="watch-now" onClick={addReadMore}>
                    {readMoreText}
                  </NavLink> */}
							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img width="55%" src="/assets/img/md1/md1-shap-5-11.png" alt="" />
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-05-screen-09" />
								<NextBtn
									link="/module-01/chapter-05-screen-12"
									text="Go Next"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen11;
