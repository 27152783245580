import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";

const Chapter03Screen08 = () => {
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/5" percentage="60" star="36" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.1s"
							>
								<h2 className="h2 mb-1">Additional Resources </h2>
							</div>
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.1s"
							>

								<a
									href="https://www.youtube.com/watch?v=TXXVfGAzcYw"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Patriarchy Dehumanises Men | Kamla Bhasin</p>
										</div>
									</div>
								</a>
								<a
									href="https://hindi.feminisminindia.com/2017/05/17/patriarchy-explainer-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>पितृसत्ता क्या है? – आइये जाने</p>
										</div>
									</div>
								</a>

								<a
									href="https://feminisminindia.com/2021/08/06/poster-series-a-brief-history-of-sexism-in-the-olympics/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>A Brief History Of Sexism In The Olympics</p>
										</div>
									</div>
								</a>

								<a
									href="https://www.tarshi.net/inplainspeak/video-page-moustaches-unlimited/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>Moustaches Unlimited</p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2021/07/29/video-on-marriage-and-patriarchy-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>शादी की संस्था और हमारा पितृसत्तात्मक समाज</p>
										</div>
									</div>
								</a>




								<a
									href="https://www.youtube.com/watch?v=9ZdNbkcqR-0"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>Sorry Thank You Tata Bye-Bye! #EmpowermentNotAge</p>
										</div>
									</div>
								</a>

							</div>

							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.1s"
							>

								<iframe width="100%" height="360" src="https://www.youtube.com/embed/eTEDofFTJD4" title="ब्याह ना कराओ, बाबा।" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

							</div>

							{/* <div
               className="col-lg-5 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >
                <center>
                  <img
                    width="50%"
                    src="/assets/img/md1/md1-shap-3-8.png"
                    alt=""
                  />
                </center>
              </div> */}

						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-03-screen-07" />

								<NextBtn
									link="/module-01/chapter-04-screen-01"
									text="Go Next"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen08;
