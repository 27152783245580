import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";

const Chapter01Screen20 = () => {
  const navigate = useNavigate();
  const [checkedQues4, setCheckedQues4] = useState('');

  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const saveAns = () => {
  
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",         
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",         
          "ques_id": "3"
        },
        "ques4": {
          "ans": checkedQues4,
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        }
        
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/SelfChapter01',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-02/chapter-01-screen-06')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfChapter01Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const checkedVal = (param,ans) => {
    if (param === '4') {
      setCheckedQues4(ans)
    }
   
  

   
  }


  const BtnDisabled = () => {
    if (checkedQues4 !== '' ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div className="wrapper md-5-bg">
        <div
          className="fixed-header"
        
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="0/4" percentage="5" star="4" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            
          <div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-3">
              <div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">
                <h3 className="h3">Summary:</h3>
                <p>The options (responses) provided above are not an exhaustive list. There are several more rights. However, the tree has been used as an example to explain how rights, laws, policies, schemes, systems etc. are linked. For example if we look at the right to education, we will see that for everyone to be able to access and have equal opportunity for education, institutions like schools, colleges etc. need to be within reach and affordable. Further, if we bring in the lens of gender based discrimination, for most girls, education is restricted because of concerns around sexual safety, gender bias, poverty, and other such factors. Therefore, to address these working on mindset change, along with availability of schemes that address poverty, discourage early marriage (Prevention of Child Marriage Act), support from community members, teachers, principals etc. are equally important. ICDS (Integrated Child Development Scheme), anganwadi, mid-day meals at school, primary health centres etc. also help to enable the right to food and health. Therefore, having these systems (roots) in place is important for people to live a dignified life.  </p>

                <p className="mt-3">In addition, our rights are further ensured and claimed with the support and initiative of individuals, families, community members, the State, and legal and judiciary systems. While individuals, families, communities are beneficiaries, they can also act as pressure groups to hold the State accountable towards making these rights accessible, and the legal and judiciary system can further ensure that these rights are provided and made accessible, in short uphold the rights of citizens. We shall explore and understand some of these aspects later in the module. </p>

    
              </div>
  
            </div>

          </div>
        </div>
      </div>


      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
              <PrevBtn link="/module-03/chapter-01-screen-18"/>                  
                
                <NextBtn link="/module-03/chapter-01-screen-21" text="Go Next"/>
                {/* <BtnDisabled/> */}
                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter01Screen20;
