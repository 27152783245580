import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import HeaderHome from "../../header/HeaderHome";

import axios from "axios";
import BaseURL from "../../config/Baseurl";


const Otp = () => {
	const forgetdetails = JSON.parse(
		localStorage.getItem("forgot_password_details")
	);
	const navigate = useNavigate();
	const [inputValue, setIputValue] = useState();
	const [inputValueErr, setinputValueErr] = useState({});

	const [message, setMessage] = useState("");
	const [type, setType] = useState("");

	const matchOtp = (e) => {
		e.preventDefault();
		const isValid = formValidation();

		const data = JSON.stringify({
			message_id: forgetdetails.email_message_id,
			otp: inputValue,
		});

		var config = {
			method: "post",
			url: BaseURL + "/forgotPassword_OTP_Match",
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		};

		axios(config)
			.then(function (response) {
				// console.log(response);
				if (response.data.success === true) {
					navigate('/reset-password');

				} else {
					setMessage(response.data.message);
					setType("danger");
				}
				// console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	//   // form validation
	const formValidation = () => {
		const inputvalueErr = {};

		const inputValue1 = inputValue === undefined ? "" : inputValue;

		let isValid = true;

		// console.log(mobEmail.length);
		if (inputValue1.length === 0) {
			inputvalueErr.required = "Field Is Required";
			isValid = false;
		}

		setinputValueErr(inputvalueErr);

		return isValid;
	};

	//   console.log(forgetdetails.message);

	return (
		<>
			<div className="wrapper">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<HeaderHome />
					</div>
				</div>
				<form onSubmit={matchOtp}>
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-center pt-20">
								<div className="col-lg-5">
									<h2 className="h2 mb-5">Otp</h2>
									<span className="error">{message}</span>
									<div className="mb-3">
										{/* <label htmlFor="exampleInputEmail1" className="form-label">
											Otp
										</label> */}
										<input
											type="text"
											className="form-control"
											placeholder="Otp......"
											value={inputValue}
											onChange={(e) => {
												setIputValue(e.target.value);
											}}
										/>

										{Object.keys(inputValueErr).map((key) => {
											return (
												<span className="error">{inputValueErr[key]}</span>
											);
										})}
									</div>
									<button
										className="button_su mt-3 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<span className="su_button_circle" />
										<button type="submit" className="button_su_inner">
											<span className="button_text_container">
												{" "}
												Submit <i className="bi bi-arrow-right" />
											</span>
										</button>
									</button>

								</div>
								<div
									className="col-lg-3 offset-lg-1 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="100%"
											src="/assets/img/chapter1/Jhole-wali-didi.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default Otp;
