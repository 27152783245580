import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";

const Chapter03Screen28 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<h3 className="h3 mt-3">Learn </h3>
								<p className="mb-2  mt-3">Women who express their sexuality or engage in sexual behaviour/ relationships are labelled in derogatory ways. Both men and society impose various ideas on women – how they should dress, speak, act, conduct themselves etc. to be acceptable and seen as “good” girls/ and not as loose/ easy/ promiscuous </p>

								<h3 className="h3 mt-3">Apply:  </h3>
								<p className="mb-2  mt-3">Let’s look at an example of this! </p>
								<a
									href="https://www.youtube.com/watch?v=vyU8wEujJkc"
									target="_blank"

								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>	Stereotyping women:  </p>
										</div>
									</div>
								</a>
								<a
									href="https://www.youtube.com/watch?v=PxZgN6FOkmE"
									target="_blank"

								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>	Deepika Padukone wants to be an Indian bahu  </p>
										</div>
									</div>
								</a>




							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="75%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-4-9.png"
										alt=""
									/>
								</center>
							</div>




						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-03-screen-27" />
								{/* <NextBtn link="/module-05/chapter-03-screen-29" text="Go Next"/> */}
								<button className="button_su" >
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>



							</div>
						</div>
					</div>
				</div>
			</div>



			{/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>
           

              <div className="row mt-5">
                <div className="col-lg-6">
                  <iframe width="100%" height="300" src="https://www.youtube.com/embed/vyU8wEujJkc" title="Deepika Padukone wears salwar for mom in law" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="col-lg-6">
                  <iframe width="100%" height="300" src="https://www.youtube.com/embed/PxZgN6FOkmE" title="Deepika Padukone wants to be an Indian bahu" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>

           

            </div>
          </div>
        </div>
      </div> */}


			<div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							<p className="text-white text-left">In the film Cocktail, Veronica is shown as an empowered, sexy woman in charge of her own sexuality and having casual relationships. In these videos, we see how she tries to change and fit herself into society’s ideas of what kind of women are agreeable to marry.
							</p>

							<ul class="list-style-set list-circle mt-1">
								<li className="text-white">What do you think the man means in the first video – is there a perception that women that are sexually free are not for marriage but only for fun?</li>
								<li className="text-white">What does the second video tell us – about how “single” women are conditioned to behave?</li>
							</ul>

							<div class=" d-flex justify-content-center mt-3">
								<button className="button_su" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="/module-05/chapter-03-screen-29" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>




						</div>
					</div>
				</div>
			</div>


		</>
	);
};

export default Chapter03Screen28;
