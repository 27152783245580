import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";


const Chapter01Screen05 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="2" star="2" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 className="h2 mb-4">Let’s think more about it </h2>
								<p>Are all men masculine in a way that Mard ko Dard Nahi Hota?  Let’s watch a video to dig deeper</p>
								<a
									href="https://www.youtube.com/watch?v=nsVxKVta6wg"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>
												Indian Men Explain Masculinity <br />{" "}
												<b>#TribeTalk Part 1/4</b>
											</p>
										</div>
									</div>
								</a>
								<p className="mb-2 text-justify">
									As you can see in the video, different men have different notions about masculinity. Therefore, it is critical to understand the term Masculinities which encompasses experiences of all men and broadens the characteristics, meanings and idea of being a man. It also challenges the idea of one and only type of Masculinity which restricts and defines how a man should be. Therefore, in the first activity all objects could be associated with the term masculinity.{" "}
								</p>
							</div>
							<div
								className="col-lg-5 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="60%"
										draggable="false"
										src="/assets/img/md4/md4-shap-1-5.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-01-screen-04" />

									<NextBtn link="/module-04/chapter-01-screen-06" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen05;
