import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

const Chapter04Screen10 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="68" star="68" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-1">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="">
									Many men like Babu feel entitled about being violent towards
									their wives and children. If not wife and children it could be
									other subordinates- their junior, younger sibling, househelp
									etc. Having to apologise for their mistakes to other men who
									have more power over them makes them feel less in their
									manlihood. Therefore, it comes out in the form of violence
									towards their subordinates, who they have more power over,
									especially their wives and children. This can be seen in the
									context of family, state, countries, from micro to macro
									levels across. For example: Russia and Ukraine war. The
									President of Russia abused his power and attacked Ukraine
									which is a less powerful country. While it all begins with the
									process of socialisation which enables violent masculine
									behaviours in boys and men, it is systemically and
									structurally accepted and normalised and enabled through
									various institutions like family, media, state, law and
									educational institutes, workplaces.{" "}
								</p>
							</div>
							<div
								className="col-lg-7 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h4 className=" mt-1">
									<b>
										{" "}
										Let's find out through a video some of the unlawful,
										unacceptable behaviours which are completely normalised for
										boys and men.{" "}
									</b>
								</h4>
								{/* <a
                  href="https://www.youtube.com/watch?v=NRbWukCWcrI"
                  target="_blank"
                >
                  <div className="d-flex align-items-center mange-icon mt-3">
                    <div>
                      <img src="/assets/img/chapter2/video-icon.png" alt="" />
                    </div>
                    <div>
                      <p>
                        Will Boys be Boys? - <b>Sexual Harassment in India</b>
                      </p>
                    </div>
                  </div>
                </a> */}
							</div>
							<div
								className="col-lg-5 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<iframe width="100%" height="270" src="https://www.youtube.com/embed/NRbWukCWcrI" title="Will Boys be Boys? - Sexual Harassment in India" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-04-screen-09" />

									<NextBtn link="/module-04/chapter-04-screen-11" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen10;
