import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL, { AdminUrl } from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter01Screen08 = () => {

	const [imageUrl, setImageUrl] = useState();


	const [images, setImages] = useState([]);


	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const onImageChange = async (e) => {

		// setCheckBlankData("filled");

		setImages([...e.target.files]);



		const questId = '1';

		const user_id = user_details.user_id;
		const files = e.target.files;
		const formData = new FormData();


		formData.append("image1", files[0]);
		formData.append("user_id", user_id);
		formData.append("ques_id", questId);

		// console.log(user_details.user_id);

		fetch(BaseURL + "/ImageUpload", {
			method: "POST",
			body: formData,
		}).then((res) => {

			res.json().then((result) => {


			});
		});

		// console.log(questId);
	};

	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="0" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3 mt-m-3">
							<div
								className="col-lg-8  wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>



								
								<h2 className="h2">NEWS UPDATES  </h2>
								<h4 className="h4 mt-3">Gujarat high court grants protection to inter-caste couple cautions families & khap panchayats against taking law in hands</h4>



								<p className="mb-2 mt-3">In the headline above, there are three institutions mentioned – family, panchayat (political body), and legal system (court). The court’s influence is affirming since it protects the couple’s right to choose their partner.</p>


							</div>

							<div
								className="col-lg-4 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="75%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-1-4.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-01-screen-07" />
								<NextBtn link="/module-05/chapter-01-screen-09" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen08;
