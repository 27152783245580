import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";

const Chapter01Screen06 = () => {
  const navigate = useNavigate();
  const [checkedQues5, setCheckedQues5] = useState('');

  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const saveAns = () => {
  
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",         
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",         
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": checkedQues5,
          "ques_id": "5"
        }
        
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/SelfChapter01',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-02/chapter-01-screen-07')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfChapter01Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const checkedVal = (param,ans) => {
    if (param === '5') {
      setCheckedQues5(ans)
    }
   
  

   
  }


  const BtnDisabled = () => {
    if (checkedQues5 !== '' ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"
      
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="0/4" percentage="7" star="6" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between pt-20 mt-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h2 className="h3">Learn</h2>
                <p className=" mt-2"> All the experiences we go through in our family, education, career and in society at large, and what we make of them, form our identity. There are some identities we are proud of, those are close to us and we like to show them to others. There are other identities we are not proud of, those become distant to us and we do not like to show those in front of/to others. There are also some identities which are given to us and we live those identities more than the others and some which we want to choose but we are not able to live them as much.This is a continuous process which can keep changing throughout our lives as we learn, grow and change as individuals. Social identities like Caste, Class, Gender, Religion, Nationality etc. are also responsible for how we are perceived in the society and how we perceive society at large. We will learn more about some of these identities in the other modules and their linkages to rights, resources and opportunities.
                </p>

                <h2 className="h3">Reflect and Apply</h2>
                <p className=" mt-2">What is one identity that you would like to be closer to for yourself? Think about 2 steps or actions you can take to be closer to that identity or make it a reality for yourself.</p>
                





                <div className="mt-4 positon-submit">
                  <textarea
                    className="form-control"
                    rows={3}
                    placeholder="Write Something here....."
                    onChange={(e)=>setCheckedQues5(e.target.value)}
                    data-quesId="5"
                  defaultValue={checkedQues5}
                  />
                  
                </div>
                <p className="mb-2 mt-4">In the next chapter we will delve deeper into what are other things that make us us!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
              <PrevBtn link="/module-02/chapter-01-screen-05"/>                  
                
                {/* <NextBtn link="/module-02/chapter-01-screen-07" text="Go Next"/> */}
                <BtnDisabled/>
                 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter01Screen06;
