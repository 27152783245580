import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter03Screen03n5 = () => {
  const navigate = useNavigate();
  const [checkedQues10Opt1, setCheckedQues10Opt1] = useState('');
  const [checkedQues10Opt2, setCheckedQues10Opt2] = useState('');
  const [checkedQues10Opt3, setCheckedQues10Opt3] = useState('');
  const [checkedQues10Val, setCheckedQues10Val] = useState('');

  const [checkedQues11Opt1, setCheckedQues11Opt1] = useState('');
  const [checkedQues11Opt2, setCheckedQues11Opt2] = useState('');
  const [checkedQues11Opt3, setCheckedQues11Opt3] = useState('');
  const [checkedQues11Val, setCheckedQues11Val] = useState('');
  const [checkedQues12Opt1, setCheckedQues12Opt1] = useState('');
  const [checkedQues12Opt2, setCheckedQues12Opt2] = useState('');
  const [checkedQues12Opt3, setCheckedQues12Opt3] = useState('');
  const [checkedQues12Val, setCheckedQues12Val] = useState('');
  const [checkedQues13Opt1, setCheckedQues13Opt1] = useState('');
  const [checkedQues13Opt2, setCheckedQues13Opt2] = useState('');
  const [checkedQues13Opt3, setCheckedQues13Opt3] = useState('');
  const [checkedQues13Val, setCheckedQues13Val] = useState('');

  

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    
    checkedVal(quesId,e.target.value)
  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",         
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",        
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "", 
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": checkedQues10Val,
          "ques_id": "10"
        },
        "ques11": {
          "ans": checkedQues11Val,
          "ques_id": "11"
        },
        "ques12": {
          "ans": checkedQues12Val,
          "ques_id": "12"
        },
        "ques13": {
          "ans": checkedQues13Val,
          "ques_id": "13"
        },
        
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/GenderChapter03',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        if(response.data.success === true)
        {
          navigate('/module-01/chapter-03-screen-04')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/GenderChapter03Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  const BtnDisabled = () => {
    if (checkedQues10Val !=='' && checkedQues11Val !== '' && checkedQues12Val !== '' && checkedQues13Val !== ''  ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  

  const checkedVal = (param,ans) => {

    if (param === '10') {
      if (ans === 'option1') {
        setCheckedQues10Opt1('checked')
        setCheckedQues10Opt2('')
        setCheckedQues10Opt3('')
      } else if(ans === 'option2') {
        setCheckedQues10Opt1('')
        setCheckedQues10Opt2('checked')
        setCheckedQues10Opt3('')
      } else if(ans === 'option3') {
        setCheckedQues10Opt1('')
        setCheckedQues10Opt2('')
        setCheckedQues10Opt3('checked')
      }
      setCheckedQues10Val(ans)
    }

   
    if (param === '11') {
      
      if (ans === 'option1') {
        setCheckedQues11Opt1('checked')
        setCheckedQues11Opt2('')
        setCheckedQues11Opt3('')
      } else if(ans === 'option2') {
        
        setCheckedQues11Opt1('')
        setCheckedQues11Opt2('checked')
        setCheckedQues11Opt3('')
        
      } else if(ans === 'option3') {
        setCheckedQues11Opt1('')
        setCheckedQues11Opt2('')
        setCheckedQues11Opt3('checked')
        
      }
      setCheckedQues11Val(ans)
    }

    if (param === '12') {
      
      if (ans === 'option1') {
        setCheckedQues12Opt1('checked')
        setCheckedQues12Opt2('')
        setCheckedQues12Opt3('')
      } else if(ans === 'option2') {
        
        setCheckedQues12Opt1('')
        setCheckedQues12Opt2('checked')
        setCheckedQues12Opt3('')
        
      } else if(ans === 'option3') {
        setCheckedQues12Opt1('')
        setCheckedQues12Opt2('')
        setCheckedQues12Opt3('checked')
        
      }
      setCheckedQues12Val(ans)
    }

    if (param === '13') {
      
      if (ans === 'option1') {
        setCheckedQues13Opt1('checked')
        setCheckedQues13Opt2('')
        setCheckedQues13Opt3('')
      } else if(ans === 'option2') {
        
        setCheckedQues13Opt1('')
        setCheckedQues13Opt2('checked')
        setCheckedQues13Opt3('')
        
      } else if(ans === 'option3') {
        setCheckedQues13Opt1('')
        setCheckedQues13Opt2('')
        setCheckedQues13Opt3('checked')
        
      }
      setCheckedQues13Val(ans);
    }


    
   
     

  }
  

 

  return (
    <>
      <div className="wrapper hp">
        <div
          className="fixed-header"
          
        >
          <div className="container-fluid cf-set">
            <Header leftChapter="2/5" percentage="48" star="28" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-center pt-20 mt-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >

                
                <div className="wiout-border-table without-bg table-padding-0">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="padding-set-table">
                        <p className="pn"> <span> 5.&nbsp;a. </span> Patriarchy is dangerous for women</p>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques10option1"
                                id="ture"                     
                                onClick={saveRadio}
                                checked={checkedQues10Opt1}
                                defaultValue="option1"
                                data-quesId="10"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <p className="pn ml-1"> <span>b. </span>Patriarchy is dangerous for both women and men{" "}</p>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques10option2"
                                id="ture"                     
                                onClick={saveRadio}
                                checked={checkedQues10Opt2}
                                defaultValue="option2"
                                data-quesId="10"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <p className="pn ml-1"> <span>c. </span>Patriarchy is dangerous for men{" "}</p>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques10option3"
                                id="ture"                     
                                onClick={saveRadio}
                                checked={checkedQues10Opt3}
                                defaultValue="option3"
                                data-quesId="10"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                  










                      <tr>
                        <td className="padding-set-table">
                        <p className="pn"> <span> 6.&nbsp;a. </span> Patriarchy within the caste system in Indian
                          society ensures all men and all women are equal</p>
                        </td>
                        <td className="padding-set-table">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques11option1"
                                id="ture"                     
                               
                                onClick={saveRadio}
                                checked={checkedQues11Opt1}
                                defaultValue="option1"
                                data-quesId="11"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <p className="pn ml-1"> <span>b. </span>Patriarchy within the caste system in Indian society
                          gives most power to Dalit women</p>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques11option2"
                                id="ture"                     
                               
                                onClick={saveRadio}
                                checked={checkedQues11Opt2}
                                defaultValue="option2"
                                data-quesId="11"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <p className="pn ml-1"> <span>c. </span>Patriarchy within the caste system in Indian society
                          gives most power to Brahmin men{" "}</p>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques11option3"
                                id="ture"                     
                               
                                onClick={saveRadio}
                                checked={checkedQues11Opt3}
                                defaultValue="option3"
                                data-quesId="11"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="padding-set-table">
                        <p className="pn"> <span> 7.&nbsp;a. </span> Patriarchy could be perpetuated by all genders in
                          the society</p>
                        </td>
                        <td className="padding-set-table">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques12option1"
                                id="ture"                     
                               
                                onClick={saveRadio}
                                checked={checkedQues12Opt1}
                                defaultValue="option1"
                                data-quesId="12"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td><p className="pn ml-1"> <span>b. </span>Patriarchy is only perpetuated by men</p></td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques12option2"
                                id="ture"                     
                               
                                onClick={saveRadio}
                                checked={checkedQues12Opt2}
                                defaultValue="option2"
                                data-quesId="12"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td><p className="pn ml-1"> <span>c. </span>Patriarchy is only perpetuated by women</p></td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques12option3"
                                id="ture"                     
                               
                                onClick={saveRadio}
                                checked={checkedQues12Opt3}
                                defaultValue="option3"
                                data-quesId="12"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="padding-set-table">
                        <p className="pn"> <span> 8.&nbsp;a. </span>The opposite of Patriarchy is Matriarchy</p>
                        </td>
                        <td className="padding-set-table">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques13option1"
                                id="ture"                     
                               
                                onClick={saveRadio}
                                checked={checkedQues13Opt1}
                                defaultValue="option1"
                                data-quesId="13"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <p className="pn ml-1"> <span>b. </span>The opposite of Patriarchy is a gender just, equal,
                          diverse and inclusive society</p>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques13option2"
                                id="ture"                     
                               
                                onClick={saveRadio}
                                checked={checkedQues13Opt2}
                                defaultValue="option2"
                                data-quesId="13"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        <p className="pn ml-1"> <span>c. </span>The opposite of Patriarchy is submission of all men</p>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check ">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques13option3"
                                id="ture"                     
                               
                                onClick={saveRadio}
                                checked={checkedQues13Opt3}
                                defaultValue="option3"
                                data-quesId="13"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-01/chapter-03-screen-03n4" />

                {/* <NextBtn
                  link="/module-01/chapter-03-screen-04"
                  text="Go Next"
                /> */}
                <BtnDisabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen03n5;
