import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

const Chapter02Screen08 = () => {
  const navigate = useNavigate();
  const [checkedQues13, setCheckedQues13] = useState('');


  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",       
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",         
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",         
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans":"",
          "ques_id": "5"
        },
        "ques6": {
          "ans":  "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": "",
          "ques_id": "10"
        },
        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        },
        "ques13": {
          "ans": checkedQues13,
          "ques_id": "13"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/SelfChapter02',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-02/chapter-02-screen-09')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues13 !== '') {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                Go Next <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {
    if (param === '13') {
     
        setCheckedQues13(ans)
    }
    

  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfChapter02Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },

      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"
         
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="1/4" percentage="20" star="20" />
          </div>
        </div>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between pt-20 mt-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
               <h2 className="h3 mt-1">Learn</h2>
                <p className="mb-2 mt-2">
                  Behind every decision we make which is visible there is an
                  underlying value which is not visible. For example in the first
                  situation someone could choose option 1 because for them justice
                  or safety is a value which is important and choosing option 1
                  could ensure this value is fulfilled. Someone else could choose
                  option 2 because for them friendship, connection and love is an
                  important value and they may feel that they want to prioritise
                  this value in this situation. However, oftentimes both values
                  could be important to people and in such a scenario it could be
                  difficult for them to choose because they will have to think hard
                  before making their decision and being true to themselves. Their
                  decision will reflect which value they have prioritised in the
                  given situation. This is an internal process which takes place
                  when an individual is faced with a dilemma situation because both
                  values are important to them but they have to prioritise only one
                  to make a decision.
                </p>
              
                <p className="mb-2 mt-3">
                  Values are not negative or positive however, the decisions we
                  make and how others see them could have positive and negative
                  impact on ours or other people’s lives and so it is important
                  for us to weigh all the pros and cons before we make decisions
                  which are difficult.</p>
                  <h2 className="h3 mt-2">Apply</h2>
                  <p className="mt-3"> <b> Share about a dilemma situation you faced    in your life recently- the decision you made and the value  behind that decision in the space below. </b></p>

                  <div className="row">
                    <div className="col-lg-7 ">
                      <div className="mt-2 positon-submit">
                        <textarea
                          className="form-control"
                          rows={2}
                          placeholder="Write Something here....."
                          onChange={(e)=>setCheckedQues13(e.target.value)}
                          data-quesId="13"
                        defaultValue={checkedQues13}
                        />
                      </div>
                    </div>
                  </div>

                  
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
              <PrevBtn link="/module-02/chapter-02-screen-07"/>                  
                
                {/* <NextBtn link="/module-02/chapter-02-screen-09" text="Go Next"/> */}
                <BtnDisabled/>
             
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen08;
