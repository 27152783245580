import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
const Chapter03Screen02 = () => {
	const navigate = useNavigate();
	const [checkedQues1Hegemonic, setCheckedQues1Hegemonic] = useState('');
	const [checkedQues1Complicit, setCheckedQues1Complicit] = useState('');
	const [checkedQues1Subordinate, setCheckedQues1Subordinate] = useState('');
	const [checkedQues1Marginalised, setCheckedQues1Marginalised] = useState('');
	const [checkedQues1Inclusive, setCheckedQues1Inclusive] = useState('');
	const [checkedQues1Val, setCheckedQues1Val] = useState('');
	const [checkedQues2Hegemonic, setCheckedQues2Hegemonic] = useState('');
	const [checkedQues2Complicit, setCheckedQues2Complicit] = useState('');
	const [checkedQues2Subordinate, setCheckedQues2Subordinate] = useState('');
	const [checkedQues2Marginalised, setCheckedQues2Marginalised] = useState('');
	const [checkedQues2Inclusive, setCheckedQues2Inclusive] = useState('');
	const [checkedQues2Val, setCheckedQues2Val] = useState('');
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		checkedVal(quesId, e.target.value)
	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues1Val,
					"ques_id": "1"
				},
				"ques2": {
					"ans": checkedQues2Val,
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-04/chapter-03-screen-03')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues1Val !== '' && checkedQues2Val !== '') {
			return (
				<>
					<button className={`button_su`} data-wow-duration="1s" data-wow-delay="0.2s">
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								Go Next <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '1') {
			// alert(param);
			if (ans === 'Hegemonic') {
				setCheckedQues1Hegemonic('checked')
				setCheckedQues1Complicit('');
				setCheckedQues1Subordinate('')
				setCheckedQues1Marginalised('')
				setCheckedQues1Inclusive('')
			} else if (ans === 'Complicit') {
				setCheckedQues1Hegemonic('')
				setCheckedQues1Complicit('checked');
				setCheckedQues1Subordinate('')
				setCheckedQues1Marginalised('')
				setCheckedQues1Inclusive('')
			} else if (ans === 'Subordinate') {
				setCheckedQues1Hegemonic('')
				setCheckedQues1Complicit('');
				setCheckedQues1Subordinate('checked')
				setCheckedQues1Marginalised('')
				setCheckedQues1Inclusive('')
			} else if (ans === 'Marginalised') {
				setCheckedQues1Hegemonic('')
				setCheckedQues1Complicit('');
				setCheckedQues1Subordinate('')
				setCheckedQues1Marginalised('checked')
				setCheckedQues1Inclusive('')
			} else if (ans === 'Inclusive and gender equitable') {
				setCheckedQues1Hegemonic('')
				setCheckedQues1Complicit('');
				setCheckedQues1Subordinate('')
				setCheckedQues1Marginalised('')
				setCheckedQues1Inclusive('checked')
			}
			setCheckedQues1Val(ans)
		}
		if (param === '2') {
			// alert(param);
			if (ans === 'Hegemonic') {
				setCheckedQues2Hegemonic('checked')
				setCheckedQues2Complicit('');
				setCheckedQues2Subordinate('')
				setCheckedQues2Marginalised('')
				setCheckedQues2Inclusive('')
			} else if (ans === 'Complicit') {
				setCheckedQues2Hegemonic('')
				setCheckedQues2Complicit('checked');
				setCheckedQues2Subordinate('')
				setCheckedQues2Marginalised('')
				setCheckedQues2Inclusive('')
			} else if (ans === 'Subordinate') {
				setCheckedQues2Hegemonic('')
				setCheckedQues2Complicit('');
				setCheckedQues2Subordinate('checked')
				setCheckedQues2Marginalised('')
				setCheckedQues2Inclusive('')
			} else if (ans === 'Marginalised') {
				setCheckedQues2Hegemonic('')
				setCheckedQues2Complicit('');
				setCheckedQues2Subordinate('')
				setCheckedQues2Marginalised('checked')
				setCheckedQues2Inclusive('')
			} else if (ans === 'Inclusive and gender equitable') {
				setCheckedQues2Hegemonic('')
				setCheckedQues2Complicit('');
				setCheckedQues2Subordinate('')
				setCheckedQues2Marginalised('')
				setCheckedQues2Inclusive('checked')
			}
			setCheckedQues2Val(ans)
		}
	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="2/5" percentage="52" star="52" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="">
									<b>
										Which of the masculinities do you associate the given below
										images with. Select one out of the given options
									</b>{" "}
								</h3>
								<p className="">
									<b>
										Angry young man image represented through Amitabh Bachchan
										believing ‘Mard ko Dard Nahi Hota’
									</b>{" "}
								</p>
							</div>
							<div className="row align-items-center justify-content-between">
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<div className="d-flex ">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="namecircle"
												id="mycirle"
												defaultValue="Hegemonic"
												onClick={saveRadio}
												checked={checkedQues1Hegemonic}
												data-quesId="1"
											/>
											<label className="form-check-label" htmlFor="mycirle">
												{" "}
												Hegemonic{" "}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="namecircle"
												id="mycirle2"
												defaultValue="Complicit"
												onClick={saveRadio}
												checked={checkedQues1Complicit}
												data-quesId="1"
											/>
											<label className="form-check-label" htmlFor="mycirle2">
												Complicit
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="namecircle"
												id="mycirle3"
												defaultValue="Subordinate"
												onClick={saveRadio}
												checked={checkedQues1Subordinate}
												data-quesId="1"
											/>
											<label className="form-check-label" htmlFor="mycirle3">
												{" "}
												Subordinate
											</label>
										</div>
									</div>
									<div className="d-flex ">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="namecircle"
												id="mycirle4"
												defaultValue="Marginalised"
												onClick={saveRadio}
												checked={checkedQues1Marginalised}
												data-quesId="1"
											/>
											<label className="form-check-label" htmlFor="mycirle4">
												{" "}
												Marginalised
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="namecircle"
												id="mycirle5"
												defaultValue="Inclusive and gender equitable"
												onClick={saveRadio}
												checked={checkedQues1Inclusive}
												data-quesId="1"
											/>
											<label className="form-check-label" htmlFor="mycirle5">
												Inclusive and gender equitable
											</label>
										</div>
									</div>
								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="90%"
											draggable="false"
											src="/assets/img/md4/md4-3-2-img.png"
											alt=""
										/>
									</center>
								</div>
							</div>
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mt-2">
									<b>
										Manoj Bajpayee playing a professor in the film is suspended
										because he is homesexual.{" "}
									</b>{" "}
								</p>
							</div>
							<div className="row align-items-center justify-content-between">
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="namecircle2"
												id="mycirle6"
												defaultValue="Hegemonic"
												onClick={saveRadio}
												checked={checkedQues2Hegemonic}
												data-quesId="2"
											/>
											<label className="form-check-label" htmlFor="mycirle6">
												{" "}
												Hegemonic{" "}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="namecircle2"
												id="mycirle7"
												defaultValue="Complicit"
												onClick={saveRadio}
												checked={checkedQues2Complicit}
												data-quesId="2"
											/>
											<label className="form-check-label" htmlFor="mycirle7">
												Complicit{" "}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="namecircle2"
												id="mycirle8"
												defaultValue="Subordinate"
												onClick={saveRadio}
												checked={checkedQues2Subordinate}
												data-quesId="2"
											/>
											<label className="form-check-label" htmlFor="mycirle8">
												Subordinate
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="namecircle8"
												id="mycirle9"
												defaultValue="Marginalised"
												onClick={saveRadio}
												checked={checkedQues2Marginalised}
												data-quesId="2"
											/>
											<label className="form-check-label" htmlFor="mycirle9">
												Marginalised
											</label>
										</div>
									</div>
									<div className="d-flex ">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="namecircle2"
												id="mycirle10"
												defaultValue="Inclusive and gender equitable"
												onClick={saveRadio}
												checked={checkedQues2Inclusive}
												data-quesId="2"
											/>
											<label className="form-check-label" htmlFor="mycirle10">
												Inclusive and gender equitable{" "}
											</label>
										</div>
									</div>
								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="90%"
											draggable="false"
											src="/assets/img/md4/md4-3-2-2-img.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-03-screen-01" />
									{/* <NextBtn link="/module-04/chapter-03-screen-03" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Chapter03Screen02;
