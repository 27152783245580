import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

const Chapter02Screen17 = () => {
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-5">
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<p className="mb-2  mt-3">Even after Section 377 was decriminalised, the thought process is far from changed. Look at this headline! </p>


								<p className="mb-2  mt-3">We must remember that no sex act is exclusive to any sexual orientation and anal sex isn’t “gay sex”. Heterosexual and same-sex couples alike can engage in, enjoy or dislike anal sex. </p>


							</div>

							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="100%"
										draggable="false"
										src="/assets/img/md5/chap02-2/video.jpg"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-05/chapter-02-screen-18" />
								<NextBtn link="/module-05/chapter-02-screen-20" text="Go Next" />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen17;
