import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

function Chapter05Screen01() {
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="80" star="58" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-center pt-20 ">
							<div className="col-lg-9">
								<h1
									className="what-gender wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									{" "}
									What is Gender <br /> Based Violence?
								</h1>
								<p><span className="red"> Trigger Warning: </span>This chapter contains content with reference to Gender Based Violence and may contain graphic references in the form of images, text and videos. Participant discretion is advised.</p>

								<PrevBtn link="/module-01/chapter-04-screen-11" />
								<NextBtn
									link="/module-01/chapter-05-screen-02"
									text=" Let’s Start"
									classType="mt-5 wow fadeInDown"
								/>
							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="73%"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Chapter05Screen01;
